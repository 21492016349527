import React, { useMemo } from 'react';
import {
  PlanContainer,
  PlanPrice,
  PlanTitle,
  PlanDescription,
  Row,
  PlanFeatureContainer,
  PlanFeatureValue,
  PlanFeatureLabel,
} from '../styles';
import Plan, { PlanTerm } from 'models/IPlan';
import { useAdminTranslation } from 'hooks/use-translation';
import { useSelector } from 'react-redux';
import { getIsLegacyPlan, getIsUnlimitedPlan } from 'services/billing';

interface ISelfServiceFreeTrialPlanCardProps extends IPlanCardProps {
  nextBillingDateInMs: number;
  onPurchasePlanClicked: () => void;
}

interface IPlanCardProps {
  effectiveDate: number;
  name: string;
  nextBillingDateInMs?: number;
}

export const PlanCard: React.FC<{plan: Plan}> = ({ plan }) => {
  const planPrice = useMemo(() => plan.planItems.find((item) => item.unit === 'saas')?.unitPrice || 0, [plan]);
  const isLegacyPlan = useSelector(getIsLegacyPlan);
  const isUnlimitedPlan = useSelector(getIsUnlimitedPlan);
  const { t } = useAdminTranslation();

  const formatPrice = (price: number, term: PlanTerm) => {
    if (price === 0) {
      return t('ADMIN_UPGRADE_PLAN_FREE');
    }
    return term === 'monthly' ? t('ADMIN_UPGRADE_PLAN_MONTHLY_PRICE', { amount: price }) : t('ADMIN_UPGRADE_PLAN_ANNUAL_PRICE', { amount: price });
  };

  const formatMaxAdmins = (maxAdmins: number) => {
    if (maxAdmins === 1) {
      return t('ADMIN_UPGRADE_PLAN_ONE_MAX_ADMIN');
    }
    return maxAdmins > 100 ? t('ADMIN_UPGRADE_PLAN_UNLIMITED_MAX_ADMINS') : t('ADMIN_UPGRADE_PLAN_MAX_ADMINS', { amount: maxAdmins });
  };

  const formatKeepPercentage = (amount: number) => {
    const keepValue = 100 - Math.round(amount * 100);
    return t('ADMIN_UPGRADE_PLAN_KEEP_AMOUNT', { amount: keepValue });
  };

  const formattedPrice = formatPrice(planPrice, plan.term);
  return (
    <PlanContainer>
      <Row>
        <PlanTitle>{plan.name}</PlanTitle>
        <PlanDescription>{!isLegacyPlan ? plan.description : '(Legacy)'}</PlanDescription>
      </Row>
      <Row>
        {!isUnlimitedPlan && <PlanPrice>{formattedPrice}</PlanPrice>}
        <PlanFeatureContainer>
          <PlanFeatureValue>{formatMaxAdmins(plan.planFeatures.maxAmountOfAdmins)}</PlanFeatureValue>
        </PlanFeatureContainer>
        <PlanFeatureContainer>
          <PlanFeatureValue>{formatKeepPercentage(plan.payoutTerms.entitlements.revenueShare)}</PlanFeatureValue>
          <PlanFeatureLabel>{t('ADMIN_UPGRADE_PLAN_PLAN_CARD_TICKETS_AND_SUBS')}</PlanFeatureLabel>
        </PlanFeatureContainer>
        <PlanFeatureContainer>
          <PlanFeatureValue>{formatKeepPercentage(plan.payoutTerms.shopify.revenueShare)}</PlanFeatureValue>
          <PlanFeatureLabel>{t('ADMIN_UPGRADE_PLAN_PLAN_CARD_SHOPIFY')}</PlanFeatureLabel>
        </PlanFeatureContainer>
      </Row>
    </PlanContainer>
  );
};

import { ADMIN_SURFACE_5, ADMIN_TEXT_400, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import { FLEX_CENTER } from 'style/mixins';
import styled from 'styled-components';

export const DaysHeader = styled.div`
  ${FLEX_CENTER}
  flex-direction: row;
  justify-content: space-between;
  margin-left: 35px;
  margin-bottom: 20px;
  text-align: center;
  padding: 0px 10px;
`;

export const NumberContainer = styled.div<{ isActive?: boolean }>`
  ${FLEX_CENTER}
  flex-direction: row;
  justify-content: space-between;
  padding: 12.5px 10px;
  text-align: center;
  transition: all 0.1s ease-in-out;
  background-color: ${(props) => (props.isActive ? ADMIN_ACCENT_PRIMARY(props) : 'transparent')};
  width: 100%;
`;

export const DotContainer = styled.div`
  ${FLEX_CENTER}
  height: 100%;
  padding: 0px 10px;
`;

export const DotSelect = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid ${ADMIN_SURFACE_5};
`;

export const WeekContainer = styled.div<{ isActive?: boolean }>`
  ${FLEX_CENTER}
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  transition: all 0.1s ease-in-out;
  background-color: transparent;
  border: 1px solid ${(props) => (props.isActive ? ADMIN_TEXT_100 : 'transparent')};
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none;
  &:hover {
    border: 1px solid ${ADMIN_ACCENT_PRIMARY};
    ${NumberContainer} {
      background-color: ${ADMIN_SURFACE_5};
      ${DotSelect} {
        border: 2px solid ${ADMIN_ACCENT_PRIMARY};
      }
    }
  }
`;

export const DayHeaderTitle = styled.span`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${ADMIN_TEXT_100};
  width: 30px;
`;

export const DayText = styled.span<{ insideMonth: boolean; isActive?: boolean }>`
  width: 30px;
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${(props) => (props.isActive ? ADMIN_TEXT_100 : props.insideMonth ? ADMIN_TEXT_100 : ADMIN_TEXT_400)};
`;

export const Space = styled.div`
  width: 15px;
`;

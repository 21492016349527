import React, { useState, useEffect, useCallback } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { CodeInjectionEditor, Container, ErrorContainer, HelpContainer, HelpIcon, HelpLink, InjectionTitle, StyledError, StyledLinkText, StyledText, TextWrapper, WarningIcon, Wrapper } from './styles';
import { CODE_INJECTION_HELP_URL } from '../consts';
import { IDeveloperSetting } from 'services/developer/models';
import { DEVELOPER_TAB_CODE_INJECTION_SECTION_ID } from 'global-ids';

interface ICodeInjectionTab {
  newDeveloperSetting: IDeveloperSetting;
  setNewDeveloperSetting: React.Dispatch<React.SetStateAction<any>>;
}

const CodeInjectionTab = ({ newDeveloperSetting, setNewDeveloperSetting }: ICodeInjectionTab) => {
  const { codeInjection: { header, body } = {} } = newDeveloperSetting;
  // we put a 500kb limit on the size of the code injection
  const [
    overSizeLimit,
    setOverSizeLimit,
  ] = useState((header && header.length >= 500000) || (body && body.length >= 500000));

  useEffect(() => {
    setOverSizeLimit((header && header.length >= 500000) || (body && body.length >= 500000));
  }, [header, body]);

  useEffect(() => {
    if (overSizeLimit) {
      setNewDeveloperSetting({
        ...newDeveloperSetting,
        codeInjection: {
          ...newDeveloperSetting.codeInjection,
          overSizeLimit,
        },
      });
    }
  }, [overSizeLimit]);

  const updateHeader = useCallback((text: string) => {
    setNewDeveloperSetting({
      ...newDeveloperSetting,
      codeInjection: {
        ...newDeveloperSetting.codeInjection,
        header: text,
        overSizeLimit,
      },
    });
  }, [newDeveloperSetting, overSizeLimit]);

  const updateBody = useCallback((text: string) => {
    setNewDeveloperSetting({
      ...newDeveloperSetting,
      codeInjection: {
        ...newDeveloperSetting.codeInjection,
        body: text,
        overSizeLimit,
      },
    });
  }, [newDeveloperSetting, overSizeLimit]);

  return (
    <Wrapper
      id={DEVELOPER_TAB_CODE_INJECTION_SECTION_ID}
      data-testid={DEVELOPER_TAB_CODE_INJECTION_SECTION_ID}
    >
      <TranslatedText component={InjectionTitle} stringKey="ADMIN_LABEL_CODE_INJECTION" />
      <TranslatedText stringKey="ADMIN_TEXT_CODE_INJECTION_DESC" />
      <Container>
        <WarningIcon error={true} />
        <TranslatedText component={StyledText} stringKey="ADMIN_TEXT_CODE_INJECTION_WARN" />
      </Container>
      <TextWrapper>
        <TranslatedText stringKey="CODE_INJECTION_NOTE" />
      </TextWrapper>
      <CodeInjectionEditor
        labelKey="ADMIN_SETTINGS_CODE_INJECTION_HEADER"
        name="header"
        onChange={updateHeader}
        value={newDeveloperSetting.codeInjection?.header || ''}
      />
      <CodeInjectionEditor
        labelKey="ADMIN_SETTINGS_CODE_INJECTION_BODY"
        name="body"
        onChange={updateBody}
        value={newDeveloperSetting.codeInjection?.body || ''}
      />
      {overSizeLimit && (
        <ErrorContainer>
          <WarningIcon error={true} />
          <StyledError>
            <TranslatedText stringKey="CODE_INJECTION_ERROR" />
          </StyledError>
        </ErrorContainer>
      )}
      <HelpContainer>
        <HelpIcon />
        <HelpLink href={CODE_INJECTION_HELP_URL}>
          <TranslatedText component={StyledLinkText} stringKey="ADMIN_TEXT_CODE_INJECTION_HELP" />
        </HelpLink>
      </HelpContainer>
    </Wrapper>
  );
};

export default CodeInjectionTab;

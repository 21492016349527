import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';

import { default as DesignTabMain } from './DesignTab';
import CustomizeTheme from './CustomizeTheme';
import { getForkedTheme } from 'services/themes';
import { getPendingThemesDocs } from 'services/admin/selectors';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import { getPageType } from 'services/app';
import { PageType } from 'models';

const DesignTab = () => {
  const forkedTheme = useSelector(getForkedTheme);
  const pendingThemeDocs = useSelector(getPendingThemesDocs);
  const pageType = useSelector(getPageType);

  const content = useMemo(() => {
    if (!forkedTheme) {
      return <DesignTabMain />;
    }

    const customizingPendingTheme = pendingThemeDocs[forkedTheme._id!];
    const theme = customizingPendingTheme || forkedTheme;
    return <CustomizeTheme theme={theme} />;
  }, [forkedTheme]);

  const headerKey: TI18nKey = React.useMemo(() => {
    if (pageType === PageType.CHANNEL) {
      return 'ADMIN_LABEL_CHANNEL_SETTINGS';
    }

    return 'ADMIN_LABEL_PAGE_SETTINGS';
  }, [pageType]);

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        headerKey={headerKey}
        subtitleKey="ADMIN_LABEL_DESIGN"
        hasUnsavedChanges={false}
      />
      {content}
    </AdminBarTab>
  );
};

export default DesignTab;

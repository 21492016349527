import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangeProps } from '../..';
import {
  CalendarContainer,
  CalendarHeader,
  Title,
  Tip,
  CalendarYearInput,
  NextButton,
  PreviousButton,
  SelectorContainer,
  SelectorTitle,
} from 'components/admin2/DateRangePicker/styles';
import MonthAndYearOverlayPicker from '../MonthAndYearOverlayPicker';
import {
  CheckIcon,
  DotSelect,
  QuarterListContainer,
  QuarterMonth,
  QuarterRow,
  QuarterRowContent,
  QuarterRowTitle,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import { useAdminTranslation } from 'hooks/use-translation';

// tslint:disable-next-line: no-empty-interface
interface QuarterPickerProps extends DateRangeProps {}

function QuarterPicker({ isActive, onRangeChange, onTitleChange, setActive }: QuarterPickerProps) {
  const { t } = useAdminTranslation();
  const [date, setDate] = useState(moment());
  const [lookupDate, setLookupDate] = useState(moment());
  const [selectorTitle, setSelectorTitle] = useState('');
  const [isDatePickerActive, setDatePickerActive] = useState(false);

  useEffect(() => {
    handleTitleChange(date);
    handleDateChange(date);
  }, [date]);

  useEffect(() => {
    if (!isActive) {
      setDatePickerActive(false);
    }
  }, [isActive]);

  const handleTitleChange = (currentDate: Moment) => {
    let currentTitle = '';
    const month1 = currentDate.format('MMM');
    const month2 = currentDate.clone().add(1, 'M').format('MMM');
    const month3 = currentDate.clone().add(2, 'M').format('MMM');
    const quarter = date.quarter();
    currentTitle = `${getQuarterTitle(quarter)} - ${month1} | ${month2} | ${month3} ${currentDate.format('YYYY')}`;
    onTitleChange?.(currentTitle);
    setSelectorTitle(currentTitle);
  };

  const handleDateChange = (currentDate: Moment) => {
    const startDate = currentDate.clone().startOf('quarter');
    const endDate = currentDate.clone().endOf('quarter');
    onRangeChange?.(startDate, endDate);
  };

  const handleYearChange = (yearDate: Moment) => {
    setDatePickerActive(false);
    setLookupDate(moment(lookupDate).set({ year: yearDate.year() }));
  };

  const handleClickAddQuarter = () => {
    const newDate = moment(lookupDate).add(3, 'month');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const handleClickSubQuarter = () => {
    const newDate = moment(lookupDate).subtract(3, 'month');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const toggleDatePicker = () => {
    setDatePickerActive(!isDatePickerActive);
  };

  const getQuarterTitle = (quarter: number) => `Q${quarter}`;

  const renderRow = (quarter: number, startDate: Moment) => {
    const handleQuarterRowClick = () => {
      const newDate = startDate.clone();
      setDate(newDate);
      setLookupDate(newDate);

      setTimeout(() => setActive?.(false), 200);
    };
    const startDateYear = startDate.year();
    const dateYear = date.year();
    const isRowActive = quarter === date.quarter() && startDateYear === dateYear;
    return (
      <>
        <QuarterRow onClick={handleQuarterRowClick}>
          {!isRowActive ? <DotSelect /> : <CheckIcon />}
          <QuarterRowContent>
            <QuarterRowTitle>{getQuarterTitle(quarter)}</QuarterRowTitle>
            <QuarterMonth isActive={isRowActive}>{startDate.format('MMM')}</QuarterMonth>
            <QuarterMonth isActive={isRowActive}>{startDate.clone().add(1, 'M').format('MMM')}</QuarterMonth>
            <QuarterMonth isActive={isRowActive}>{startDate.clone().add(2, 'M').format('MMM')}</QuarterMonth>
          </QuarterRowContent>
        </QuarterRow>
      </>
    );
  };

  return (
    <CalendarContainer isActive={isActive}>
      <CalendarHeader>
        <Title isSmall={false}>
          <TranslatedText stringKey="PICKER_SELECT_QUARTER" />
        </Title>
        <CalendarYearInput
          onClick={toggleDatePicker}
          value={lookupDate?.format('YYYY') || t('ADMIN_LABEL_SELECT_YEAR')}
          isActive={isDatePickerActive}
        />
      </CalendarHeader>
      <SelectorContainer>
        <MonthAndYearOverlayPicker type={'Both'} isActive={isDatePickerActive} onDateChange={handleYearChange} />
        <SelectorTitle>{selectorTitle}</SelectorTitle>
        <PreviousButton onClick={handleClickSubQuarter} />
        <NextButton onClick={handleClickAddQuarter} />
      </SelectorContainer>
      <QuarterListContainer>
        <MonthAndYearOverlayPicker type={'Year'} isActive={isDatePickerActive} onDateChange={handleYearChange} />
        {renderRow(1, moment(`${lookupDate.format('YYYY')}-01-01`))}
        {renderRow(2, moment(`${lookupDate.format('YYYY')}-04-01`))}
        {renderRow(3, moment(`${lookupDate.format('YYYY')}-07-01`))}
        {renderRow(4, moment(`${lookupDate.format('YYYY')}-10-01`))}
      </QuarterListContainer>
      <Tip>{t('ADMIN_QUARTER_PICKER_TIP')}</Tip>
    </CalendarContainer>
  );
}

export default QuarterPicker;

import useAjax from 'hooks/use-ajax';
import { THEMES_SERVICE_SERVICE_BASE_URL } from 'config';
import { getPrimaryToken } from 'services/auth';
import { useSelector } from 'react-redux';
import { SITE_ID } from 'config';
import { ITheme } from 'models';
import { useEffect, useMemo, useState } from 'react';
import { CLASSIC_THEMES } from 'services/themes/constants';

interface IUseThemesRequest {
  limit?: number;
  order?: string;
  page: number;
  paginationEnabled?: boolean;
}

interface IThemesResponse {
  themes: ITheme[];
  totalCount: number;
}

interface IUseThemesResponse {
  data: ITheme[];
  error: Error | null;
  loaded: boolean;
  totalCount: number;
}

export const ADVANCED_THEMES_MAX_PER_PAGE = 8;
export const CUSTOM_THEMES_MAX_PER_PAGE = 4;

export const useCustomThemes = ({ page, paginationEnabled = true }: IUseThemesRequest): IUseThemesResponse => {
  const [totalCount, setTotalCount] = useState(0);
  const token = useSelector(getPrimaryToken);
  const offset = useMemo(() => page * CUSTOM_THEMES_MAX_PER_PAGE, [page]);

  const {
    data,
    error,
    loaded,
  } = useAjax<IThemesResponse>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: {
      ...(paginationEnabled && { limit: CUSTOM_THEMES_MAX_PER_PAGE, offset }),
    },
    url: `${THEMES_SERVICE_SERVICE_BASE_URL}/custom`,
  });

  useEffect(() => {
    if (!error && loaded && data) {
      if (totalCount !== data.totalCount) {
        setTotalCount(data.totalCount);
      }
    }
  }, [data, error, loaded]);

  return {
    data: data?.themes ?? [],
    error,
    loaded,
    totalCount,
  };
};


export const useAdvancedThemes = ({ page, paginationEnabled = true }: IUseThemesRequest): IUseThemesResponse => {
  const [totalCount, setTotalCount] = useState(0);
  const token = useSelector(getPrimaryToken);
  const offset = useMemo(() => page * ADVANCED_THEMES_MAX_PER_PAGE, [page]);

  const {
    data,
    error,
    loaded,
  } = useAjax<IThemesResponse>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: {
      ...(paginationEnabled && { limit: ADVANCED_THEMES_MAX_PER_PAGE, offset }),
    },
    url: `${THEMES_SERVICE_SERVICE_BASE_URL}/default`,
  });

  useEffect(() => {
    if (!error && loaded && data) {
      if (totalCount !== data.totalCount) {
        setTotalCount(data.totalCount);
      }
    }
  }, [data, error, loaded]);

  return {
    data: data?.themes ?? [],
    error,
    loaded,
    totalCount,
  };
};

export const useClassicThemes = (): IUseThemesResponse => {
  return {
    data: CLASSIC_THEMES,
    error: null,
    loaded: true,
    totalCount: CLASSIC_THEMES.length,
  };
};

import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Body,
  CheckMark,
  Description,
  Footer,
  FullWidthButton,
  Header,
  LinkButton,
  ButtonContainer,
  MessageContainer,
  MessageWrapper,
  ModalContent,
  StyledCloseButton,
  StyledModal,
  StyledSpinner,
  StyledTextInput,
  Title,
} from './styles';
import success from 'assets/success.png';
import TranslatedText from 'components/i18n/TranslatedText';
import UploadZone from 'components/admin2/UploadZone';
import { setLivestreamMetadata } from 'services/livestream/actions';
import { getPageName } from 'services/app/selectors';
import { getMetadataStatus } from 'services/livestream/selectors';
import { setMetadataStatus } from 'services/livestream/actions';
import { MetadataStatus } from 'services/livestream/constants';

const AddMetadataModal = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();
  const metadataStatus = useSelector(getMetadataStatus);
  const channelName = useSelector(getPageName);
  const showAddMetadataModal = metadataStatus !== null && metadataStatus !== MetadataStatus.REJECTED;
  const isMetadataIdle = metadataStatus === MetadataStatus.IDLE;

  const updateTitle = useCallback(
    (val) => setTitle(val),
    [setTitle],
  );

  const updateThumbnail = useCallback(
    (val) => setThumbnailUrl(val),
    [setThumbnailUrl],
  );

  const clearImage = useCallback(
    () => updateThumbnail(''),
    [updateThumbnail],
  );

  const getDefaultTitle = useCallback(() => {
    return `${moment().tz('America/Los_Angeles').format('YYYY-MM-DD hh:mm z')} ${channelName}`;
  }, [channelName]);

  const postMetadata = useCallback((thumbnail: string | null, broadcastTitle: string) => {
    dispatch(setLivestreamMetadata({ thumbnail, title: broadcastTitle }));
  }, []);

  const closeModal = useCallback(() => {
    dispatch(setMetadataStatus(null));
    setTitle('');
    setThumbnailUrl(undefined);
  }, [metadataStatus, postMetadata]);

  const handleSave = useCallback(() => {
    const broadcastThumbnail = thumbnailUrl;
    const broadcastTitle = title || getDefaultTitle();
    postMetadata(broadcastThumbnail ?? null, broadcastTitle);
  }, [thumbnailUrl, title, postMetadata]);

  const metadataForm = (
    <>
      <StyledTextInput
        // @ts-ignore
        inputTestId="stream-metadata-title-input"
        labelKey="ADMIN_LABEL_NAME"
        value={title}
        onChange={updateTitle}
      />
      <UploadZone
        canDrop={false}
        imagePreview={thumbnailUrl}
        labelKey="ADMIN_LABEL_THUMBNAIL"
        onClearImage={clearImage}
        onFileSubmit={updateThumbnail}
      />
      <FullWidthButton
        data-testid="stream-metadata-save-button"
        onClick={handleSave}
      >
        <TranslatedText stringKey="ACTION_SAVE" />
      </FullWidthButton>
    </>
  );

  const successMessage = (
    <>
      <MessageContainer>
        <MessageWrapper>
          <CheckMark />
          <TranslatedText stringKey="ADMIN_STREAM_GO_OFFLINE_SUCCESS" />
        </MessageWrapper>
      </MessageContainer>
      <FullWidthButton data-testid="stream-metadata-next-button" onClick={closeModal}>
        <TranslatedText stringKey="ACTION_NEXT" />
      </FullWidthButton>
    </>
  );

  let body: JSX.Element | null = null;
  let handler: () => void = handleSave;
  switch (metadataStatus) {
    case MetadataStatus.IDLE:
      body = metadataForm;
      break;
    case MetadataStatus.PENDING:
      body = <StyledSpinner />;
      break;
    case MetadataStatus.RESOLVED:
      body = successMessage;
      handler = closeModal;
      break;
    default:
  }

  if (showAddMetadataModal) {
    return (
      <StyledModal >
        <ModalContent data-testid="stream-metadata-modal-window">
          <ButtonContainer>
            <StyledCloseButton onClick={handler}/>
          </ButtonContainer>
          <Header>
            <img alt="" src={success} />
            <Title data-testid="stream-metadata-congrats-label">
              <TranslatedText stringKey="ADMIN_STREAM_GO_OFFLINE_TITLE" />
            </Title>
            <Description>
              <TranslatedText stringKey="ADMIN_STREAM_GO_OFFLINE_DESCRIPTION" />
            </Description>
          </Header>
          <Body>
            {body}
            <Footer>
              {
                isMetadataIdle ?
                (
                  <LinkButton data-testid="stream-metadata-skip-button" onClick={handler}>
                    <TranslatedText stringKey="SKIP" />
                  </LinkButton>
                ) : null
              }
            </Footer>
          </Body>
        </ModalContent>
      </StyledModal>
    );
  }
  return null;
};

export default AddMetadataModal;

import shortId from 'shortid';
import axios from 'axios';
import { SOCIAL_SERVICE_BASE_URL } from 'config';
import { getTweetIdFromUrl } from 'components/cards/TweetCard';

export default async ({
  renderer,
}) => {
  if (!renderer.tweetUrl) {
    return false;
  }

  const statusId = getTweetIdFromUrl(renderer.tweetUrl);
  const { data: status } = await axios.get(`${SOCIAL_SERVICE_BASE_URL}/twitter/${statusId}`);

  return {
    key: `preview-${shortId.generate()}`,
    payload: {
      channel: 'preview',
      data: {
        renderer: {
          ...renderer,
          status,
        },
      },
    },
    timeRemaining: renderer.duration,
  };
};

import React, { useCallback, useEffect, useState, useMemo, ChangeEvent } from 'react';
import { ACCOUNTS_SERVICE_BASE_URL } from 'config';
import axios from 'axios';
import { useDispatch, useSelector, useLegacyCollection, useRole } from 'hooks';
import usePagesV3Collection from 'hooks/use-pages-v3-collection';
import { getSiteId } from 'services/app/selectors';
import hash from 'json-stable-stringify';
import get from 'lodash/get';
import AdminBarTabHeader from 'components/admin2/ui/AdminBarTabHeader';
import JsonDebug from 'components/dev/JsonDebug';
import Checkbox from 'components/admin2/ui/Checkbox';
import Radio from 'components/admin2/ui/Radio';
import { showAdminErrorKey, showModal } from 'services/modals';
import {
  isUserAdmin,
  getUserId,
  getPrimaryToken,
  IAccount,
  RoleScope,
  IAccountRole,
} from 'services/auth';
import { getSiteRolesAction } from 'services/user-roles';
import {
  Wrapper,
  Container,
  StyledUser,
  RoleContainer,
  ChooseRole,
  RadioWrapper,
} from './styles';
import { IOption, ChannelAndPagesSelect } from 'components/ui/MultiSelectV2';
import { useAdminTranslation } from 'hooks/use-translation';
import { ModalKinds } from 'services/modals/types';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';

interface IConfigureUserTabProps {
  handleTabBack: (e: any) => void;
  user: IAccount & { siteOwner: boolean };
}

const removeScopes = (roles: IAccountRole[], scopes: string[]) =>
  roles.filter((r) => !scopes.includes(r.scope));

const ConfigureUserTab: React.FC<IConfigureUserTabProps> = ({
  handleTabBack,
  user = {},
}) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const siteId = useSelector(getSiteId);
  const userId = useSelector(getUserId);
  const token = useSelector(getPrimaryToken);
  const loggedInUserIsSiteAdmin = useSelector(isUserAdmin);
  const features = useSelector(getActiveSiteFeatures);
  const pagesHook = features[Feature.PAGES_V3] ? usePagesV3Collection : useLegacyCollection;
  const [pageData] = pagesHook({ collection: 'pages' });

  const [hasChanges, setHasChanges] = useState(false);
  const [saved, setSaved] = useState(false);

  const [currentRoles, setCurrentRoles] = useState(user.roles);

  // temp state in tab so we can show optimistic updates from initial without redux
  const [editingRoles, setEditingRoles] = useState<IAccountRole[]>(user.roles!);
  // state for individual roles
  const [siteAdminRole, toggleSiteAdminRole] = useRole(
    editingRoles,
    RoleScope.SiteAdmin,
  );
  const [chatModRole, toggleChatModRole, setChatModPageIds] = useRole(
    editingRoles,
    RoleScope.ChatModerator,
  );
  const [allPagesSelected, setAllPagesSelected] = useState(
    !chatModRole || chatModRole.pageId!.length === 0,
  );
  const [specificPageSelected, setSpecificPageSelected] = useState(
    !allPagesSelected,
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const tabBack = useCallback(
    (e) => {
      dispatch(getSiteRolesAction());
      handleTabBack(e);
    },
    [dispatch, handleTabBack],
  );

  useEffect(() => {
    const otherRoles = removeScopes(editingRoles, ['Chat_Moderator', '*']);
    const newRoles = [siteAdminRole, chatModRole, ...otherRoles].filter(
      (i) => i,
    );
    setEditingRoles(newRoles);
    const deepEq = hash(newRoles) === hash(currentRoles);
    setHasChanges(!deepEq);
    if (saved) {
      setSaved(false);
    }
  }, [siteAdminRole, chatModRole, currentRoles]);

  const onAllPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChatModPageIds([]);
    setSpecificPageSelected(!e.target.checked);
    setAllPagesSelected(e.target.checked);
  };

  const onSpecificPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChatModPageIds([]);
    setAllPagesSelected(!e.target.checked);
    setSpecificPageSelected(e.target.checked);
  };

  const handleToggleAdminRole = () => {
    if (user.siteOwner) {
      return;
    }
    toggleSiteAdminRole();
    if (chatModRole) {
      toggleChatModRole();
    }
  };

  const handleToggleChatRole = () => {
    if (!siteAdminRole) {
      toggleChatModRole();
    }
  };

  const updateUserRoles = useCallback(() => {
    setHasChanges(false);
    setIsUpdating(true);
    axios({
      data: {
        roles: editingRoles,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'x-maestro-client-id': siteId,
      },
      method: 'put',
      url: `${ACCOUNTS_SERVICE_BASE_URL}/roles/${user._id}`,
    })
      .then((res) => {
        const updatedRoles = get(res, 'data.roles', []);
        setEditingRoles(updatedRoles);
        setCurrentRoles(updatedRoles);
      })
      .catch((callError) => {
        setEditingRoles(user.roles!);
        if (callError?.response?.data?.message === 'Max admins reached') {
          dispatch(
            showModal({
              kind: ModalKinds.upgradePlan,
              data: {
                planWarningMessage:
                  'ADMIN_UPGRADE_PLAN_EXCEEDED_MAX_AMOUNT_OF_ADMINS',
              },
            }),
          );
          return;
        }
        dispatch(showAdminErrorKey('ADMIN_ERROR_GENERIC'));
      })
      .finally(() => {
        axios
          .get(
            `${ACCOUNTS_SERVICE_BASE_URL}/roles/query?admin=true&cachebust=true`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'x-maestro-client-id': siteId,
              },
            },
          )
          .finally(() => {
            setIsUpdating(false);
            setSaved(true);
          });
      });
  }, [
    dispatch,
    chatModRole,
    editingRoles,
    siteAdminRole,
    setEditingRoles,
    setHasChanges,
  ]);

  const onHandleSave = () => {
    setHasChanges(false);
    updateUserRoles();
  };

  const selectedChannelsParsed = useMemo(() => {
    const pageMap = pageData.reduce<{ [key: string]: string }>(
      (map, currentPage) => {
        map[currentPage._id] = currentPage.data.name!;
        return map;
      },
      {},
    );
    const specificChannels = (chatModRole?.pageId || []).map((channelId) => ({
      id: channelId,
      slug: pageMap[channelId],
    }));
    return specificChannels;
  }, [hash(pageData), hash(chatModRole?.pageId)]);

  const handleOnSelectedPageChange = (selectedOptions: IOption[]) => {
    setChatModPageIds(selectedOptions.map((option) => option.value));
  };

  const handleOnDeleteSelectedPages = (selectedOption: IOption) => {
    const filteredOptions = (chatModRole?.pageId || []).filter(
      (channelId) => channelId !== selectedOption.value,
    );
    setChatModPageIds(filteredOptions);
  };

  return (
    <Wrapper>
      <AdminBarTabHeader
        disabled={isUpdating}
        hasUnsavedChanges={hasChanges}
        headerKey="ADMIN_LABEL_MANAGE"
        history={[
          {
            nameKey: 'ADMIN_LABEL_ADMINS',
            navigate: tabBack,
          },
          {
            nameKey: 'ADMIN_LABEL_ADMIN_SETTINGS',
          },
        ]}
        onBack={tabBack}
        onSave={onHandleSave}
        saved={saved}
        subHeaderKey="ADMIN_LABEL_ADMIN_SETTINGS"
      />
      <Container>
        <StyledUser user={user} />
        <RoleContainer>
          <ChooseRole>Roles</ChooseRole>
          <Checkbox
            checked={!!siteAdminRole}
            descriptionKey="ADMIN_ROLE_SITE_ADMIN_DESC"
            disabled={
              !loggedInUserIsSiteAdmin || userId === user._id || isUpdating
            }
            label={t('ADMIN_INVITE_TAB_SITE_ADMIN')}
            onChange={handleToggleAdminRole}
          />
          <Checkbox
            checked={!!chatModRole || !!siteAdminRole}
            descriptionKey="ADMIN_ROLE_CHAT_ADMIN_DESC"
            disabled={!loggedInUserIsSiteAdmin || isUpdating}
            label={t('ADMIN_INVITE_TAB_MODERATOR')}
            onChange={handleToggleChatRole}
          />
          {chatModRole && !siteAdminRole ? (
            <>
              <RadioWrapper>
                <Radio
                  checked={allPagesSelected}
                  disabled={isUpdating}
                  labelKey="ADMIN_ROLE_CHAT_ADMIN_ENTIRE_SITE"
                  onChange={onAllPageChange}
                />
                <Radio
                  checked={specificPageSelected}
                  disabled={isUpdating}
                  labelKey="ADMIN_ROLE_CHAT_ADMIN_SPECIFIC_ACCESS"
                  onChange={onSpecificPageChange}
                />
              </RadioWrapper>
              {!allPagesSelected && (
                <ChannelAndPagesSelect
                  name="channelAndPagesTags"
                  placeholder={t('ADMIN_PLACEHOLDER_SEARCH_FOR_A_CHANNEL')}
                  isSearchable={true}
                  options={pageData.map((page) => ({
                    value: page._id,
                    label: page.data.name!,
                  }))}
                  value={selectedChannelsParsed.map((channel) => ({
                    value: channel.id,
                    label: channel.slug,
                  }))}
                  onChange={handleOnSelectedPageChange}
                  onDeleteOption={handleOnDeleteSelectedPages}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </RoleContainer>
      </Container>
      <JsonDebug value={editingRoles} />
    </Wrapper>
  );
};

export default ConfigureUserTab;

/* tslint:disable: jsx-boolean-value */
import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { ZoneSelect } from './styles';
import { parseRegion, TIMEZONE_OPTIONS } from './utils';

interface TimezoneSelectProps {
  isDisabled?: boolean;
  labelIcon?: string;
  timezone?: string;
  timezoneKey?: string;
  onChange(timezone: { timezone: string }): void;
}

const LOCAL_TIMEZONE = moment.tz.guess();

export default function TimezoneSelect({
  isDisabled = false,
  labelIcon,
  onChange,
  timezone = LOCAL_TIMEZONE,
  timezoneKey = 'ADMIN_LABEL_TIME_ZONE',
}: TimezoneSelectProps) {
  const zoneValue = useMemo(() => {
    const { region = '' } = parseRegion(timezone) || {};
    const { options: zones } = TIMEZONE_OPTIONS[region] || {};
    return zones.find(item => item.timezone === timezone);
  }, [timezone]);

  return (
    <ZoneSelect
      data-testid="timeZoneDropdownMenu"
      isDisabled={isDisabled}
      isSearchable
      labelKey={timezoneKey}
      labelIcon={labelIcon}
      labelPadding="6px"
      onChange={onChange as any}
      options={Object.values(TIMEZONE_OPTIONS)}
      padding="6px 0"
      value={zoneValue}
      valueKey="timezone"
    />
  );
}

import moment from 'moment-timezone';

export const getFormatedDate = (timestamp: number | null) => {
  const timezone = moment.tz.guess();
  const timezoneAbbr = moment.tz(timezone).format('z');
  const utcDifference = moment.tz(timezone).format('Z');
  const zoneNumber = Number(timezoneAbbr);
  const momentAbbrExists = zoneNumber !== 0 && !zoneNumber;
  const abbrString = momentAbbrExists ? timezoneAbbr : `UTC${utcDifference}`;

  if (!timestamp) {
    return `${moment(new Date().getTime())
      .format(`MMM Do - YYYY | h:mm A`)} ${abbrString}`;
  }

  return `${moment(timestamp).format(`MMM Do - YYYY | h:mm A`)} ${abbrString}`;
};

export const getTimestampWithoutSecondsAndMilliseconds = (timestamp: number | null) => {
  if (!timestamp) {
    return null;
  }

  const m = moment(timestamp);
  const seconds = m.seconds();
  const millisecond = m.millisecond();
  const calculatedTimestamp = timestamp - seconds * 1000 - millisecond;
  return calculatedTimestamp;
};

export interface ISelectedImageArgs {
  _id: string;
  created: number;
  ext: string;
  last_modified: number;
  name: string;
  type: string;
}

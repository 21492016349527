import Icon from 'components/ui/Icon';
import { ADMIN_SURFACE_5, ADMIN_TEXT_300, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100, ADMIN_SURFACE_6 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';
import { FLEX_CENTER } from 'style/mixins';
import styled from 'styled-components';

export const QuarterListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DotSelect = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid ${ADMIN_SURFACE_5};
`;

export const QuarterMonth = styled.div<{ isActive: boolean }>`
  ${FLEX_CENTER}
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${(props) => (props.isActive ? ADMIN_TEXT_100 : ADMIN_TEXT_300)};
  background-color: ${(props) => (props.isActive ? ADMIN_ACCENT_PRIMARY : ADMIN_SURFACE_5)};
  flex: 1;
  margin-left: 10px;
  padding: 5px 20px;
  border-radius: 5px;
  width: 60px;
  border: 1px solid ${(props) => (props.isActive ? ADMIN_ACCENT_PRIMARY : ADMIN_SURFACE_5)};
`;

export const QuarterRowContent = styled.div`
  ${FLEX_CENTER}
  flex-direction: row;
  flex: 1;
  border: 1px solid ${ADMIN_SURFACE_6};
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 15px;
`;

export const QuarterRow = styled.div`
  ${FLEX_CENTER};
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin: 10px 0px;
  width: 100%;
  &:hover {
    ${QuarterRowContent} {
      border: 1px solid ${ADMIN_ACCENT_PRIMARY};
      ${QuarterMonth} {
        border: 1px solid ${ADMIN_ACCENT_PRIMARY};
      }
    }
    ${DotSelect} {
      border: 2px solid ${ADMIN_ACCENT_PRIMARY};
    }
  }
`;

export const QuarterRowTitle = styled.div`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${ADMIN_TEXT_200};
  margin-left: 5px;
`;

export const CheckIcon = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
  name: 'checkmark',
}))`
  background: transparent;
  width: 15px;
  height: 15px;
  & svg {
    display: block;
    opacity: 1;
    width: 15px;
    height: 15px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AdminModal from 'components/admin2/ui/AdminModal';
import { LargeButton, CloseButton } from 'components/admin2/ui/Button';
import HexIcon from 'components/ui/HexIcon';
import TranslatedText from 'components/i18n/TranslatedText';
import { rgba } from 'colors';

import {
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_4,
  ADMIN_TEXT_400,
  ADMIN_TEXT_100,
} from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';
import { useSelector } from 'react-redux';
import { getAdminSurface1 } from 'services/themes';

const ConfirmButton = styled(LargeButton)`
  ${ADMIN_TEXT_LABEL_L_MEDIUM}
  padding: 0 8px;
  justify-content: center;
  margin: 0 5px;
`;

const StyledAdminModal = styled(AdminModal)`
  max-width: ${({ maxWidth = '40%' }) => maxWidth};
  @media screen and (max-width: 900px) {
    max-width: 80%;
  }
  padding: 67px 36px 50px 36px;
  background: ${ADMIN_SURFACE_4};
`;

const StyledCloseButton = styled(CloseButton).attrs({
  containerCss: css`
    position: absolute;
    top: 20px;
    right: 20px;
  `,
})`
  background: ${props => rgba(ADMIN_SURFACE_1(props), 0.3)};
  width: 19px;
  height: 19px;
  & > svg {
    width: 8px;
    height: 8px;
  }
`;

const Container = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
`;

const WarningText = styled.div`
  ${ADMIN_TEXT_LABEL_L_MEDIUM}
  color: ${ADMIN_TEXT_100};
  text-align: center;
`;

const DescriptionText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_400};
  margin-top: 25px;
`;

export default function AdminConfirmationModal({
  className,
  descriptionTextKey,
  onBack,
  onConfirm,
  textKey,
  continueKey,
  cancelKey,
  hideIcon,
  maxWidth,
}) {
  const adminSurface1 = useSelector(getAdminSurface1);

  return (
    <StyledAdminModal className={className} fixedDimensions maxWidth={maxWidth} onClose={onBack}>
      <StyledCloseButton onClick={onBack} />
      <Container>
        <Header>
          {!hideIcon && <HexIcon type="warning" />}
          <WarningText>
            <TranslatedText stringKey={textKey} />
            {
              descriptionTextKey && (
              <DescriptionText>
                <TranslatedText stringKey={descriptionTextKey} />
              </DescriptionText>
              )
            }
          </WarningText>
        </Header>
        <Footer>
          {onBack && (
          <ConfirmButton background={adminSurface1} data-testid="adminConfirmationNoButton" onClick={onBack}>
            <TranslatedText stringKey={cancelKey} />
          </ConfirmButton>
          )}
          <ConfirmButton data-testid="adminConfirmationYesButton" onClick={onConfirm}>
            <TranslatedText stringKey={continueKey} />
          </ConfirmButton>
        </Footer>
      </Container>
    </StyledAdminModal>
  );
}

AdminConfirmationModal.propTypes = {
  cancelKey: PropTypes.string,
  className: PropTypes.string,
  continueKey: PropTypes.string,
  descriptionTextKey: PropTypes.string,
  hideIcon: PropTypes.bool,
  maxWidth: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  textKey: PropTypes.string.isRequired,
};

AdminConfirmationModal.defaultProps = {
  cancelKey: 'ADMIN_ACTION_CANCEL',
  className: undefined,
  continueKey: 'ADMIN_LABEL_CONTINUE',
  hideIcon: false,
};

import React, { useState, useEffect } from 'react';
import DatetimePicker, { LayoutType } from 'components/admin2/DatetimePicker';
import TranslatedText from 'components/i18n/TranslatedText';
import ToggleSwitch from 'components/admin2/ui/ToggleSwitch';
import {
  Container,
  ModalTitle,
  ButtonsContainer,
  HeaderContainer,
  CancelButton,
  SaveButton,
} from '../styles';
import { getTimestampWithoutSecondsAndMilliseconds } from '../utils';
import { useAdminTranslation } from 'hooks/use-translation';

interface DateAndTimeModalProps {
  closeModal: () => void;
  handleTimeStampChange: (
    timeValue: number | null,
    isDateAndTimeActive: boolean,
  ) => void;
  isActive: boolean;
  timestamp: number | null;
}

const DateAndTimeModal = ({
  closeModal,
  handleTimeStampChange,
  timestamp,
  isActive,
}: DateAndTimeModalProps) => {
  const calculatedTimestamp = getTimestampWithoutSecondsAndMilliseconds(timestamp);
  const [timeValue, setTimeValue] = useState<number | null>(calculatedTimestamp);
  const [isDateAndTimeActive, setIsDateAndTimeActive] = useState(isActive);
  const { t } = useAdminTranslation();

  const handleOnTimeChange = (newTime: number | null) => {
    setTimeValue(newTime);
  };
  const handleContainerClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };
  const handleCloseClick = () => {
    closeModal();
  };
  const handleIsOutSideRange = () => false;
  const handleSave = () => {
    handleTimeStampChange(timeValue, isDateAndTimeActive);
    closeModal();
  };
  const handleToggleSwitch = () => {
    setIsDateAndTimeActive(!isDateAndTimeActive);
  };

  useEffect(() => {
    if (calculatedTimestamp !== timeValue) {
      setIsDateAndTimeActive(true);
    }
  }, [timeValue]);

  return (
    <Container onMouseDown={handleContainerClick}>
      <HeaderContainer>
        <ModalTitle>
          <TranslatedText stringKey="ACCESS_GATE_DATE_AND_TIME_MODAL_TITLE" />
        </ModalTitle>
        <ToggleSwitch
          data-testid="eventDataEndTimeToggle"
          checked={isDateAndTimeActive}
          onChange={handleToggleSwitch}
        />
      </HeaderContainer>
      <DatetimePicker
        dateSelectKey="ADMIN_LABEL_DATE"
        isDisabled={false}
        isOutsideRange={handleIsOutSideRange}
        onTimeChange={handleOnTimeChange}
        timeSelectKey="ADMIN_LABEL_TIME"
        timestamp={timeValue}
        layout={LayoutType.grid}
      />
      <ButtonsContainer data-testid="cancelAndConfirmButtons">
        <CancelButton onClick={handleCloseClick}>
          {t('ADMIN_ACTION_CANCEL')}
        </CancelButton>
        <SaveButton onClick={handleSave}>
          {t('ADMIN_ACTION_CONFIRM')}
        </SaveButton>
      </ButtonsContainer>
    </Container>
  );
};

export default DateAndTimeModal;

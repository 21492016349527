import styled, { css } from 'styled-components';

const GENERIC_CSS = css`
  max-height: 28px;
  object-fit: contain;
`;

export const Icon = styled.img`
  ${GENERIC_CSS}
  width: 28px;
`;

export const Logo = styled.img`
  ${GENERIC_CSS}
  max-width: 130px;
`;

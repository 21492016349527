import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  ColorMenuContainer,
  BackgroundControlContainer,
  Title,
  Label,
  FixedHeightUploadZone,
  InputContainer,
  IconInputContainer,
  Input,
  GradientMaskContainer,
  StyledIcon,
  StyledColorPicker,
  StyledCloseButton,
  ColorSectionWrapper,
} from './styles';
import hash from 'json-stable-stringify';
import EditModeRegionToggle from 'components/admin2/EditModeRegionToggle';
import { GateBackgroundEnum, IAccessGateBackground } from 'models/IGate';
import { MAESTRO_BLACK } from 'style/constants';
import { useSelector } from 'react-redux';
import { getAdminText500 } from 'services/themes';
import Radio from 'components/admin2/ui/Radio';
import { useAdminTranslation } from 'hooks/use-translation';

interface IProps {
  gateBackground?: IAccessGateBackground;
  onChange: (accessGateBackground: IAccessGateBackground) => void;
  onClose: () => void;
}

const PERCENTAGE_REGEX = /^[0-9]+[%]+/g;
const FULL_HEX_REGEX = /^#[0-9A-F]{6}/gi;
const MIN_HEX_REGEX = /^#[0-9A-F]{3}/gi;

export const DEFAULT_GATE_BACKGROUND = {
  type: GateBackgroundEnum.image,
  color: MAESTRO_BLACK,
  opacity: 1,
  useGradientMask: false,
  desktopImage: undefined,
  mobileImage: undefined,
} as IAccessGateBackground;

const BackgroundControl: React.FC<IProps> = ({
  gateBackground,
  onClose,
  onChange,
}) => {
  const { t } = useAdminTranslation();
  const [background, setBackground] = useState<IAccessGateBackground>(
    gateBackground || DEFAULT_GATE_BACKGROUND,
  );
  const adminText500 = useSelector(getAdminText500);
  const [tempColor, setTempColor] = useState(background?.color || adminText500);
  const [tempOpacity, setTemOpacity] = useState(`${(background?.opacity || 0 as number)  * 100}%`);
  const [isFocusColorInput, setIsFocusColorInput] = useState(false);
  const [isFocusOpacityInput, setIsFocusOpacityInput] = useState(false);
  const [colorError, setColorError] = useState(false);
  const [opacityError, setOpacityError] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const colorPickerParentRef = useRef(null);

  const onSelectBackgroundType = (backgroundType: GateBackgroundEnum) => {
    const newBackground = {
      ...background,
      type: backgroundType,
    };
    setBackground(newBackground);
    onChange(newBackground);
  };

  const onChangeUseGradientMask = () => {
    const newBackground = {
      ...background,
      useGradientMask: !background?.useGradientMask,
    };
    setBackground(newBackground);
    onChange(newBackground);
  };

  const onUpdateImageBackground =
    (type: 'mobile' | 'desktop') => (url?: string) => {
      const updatedBackground =
        type === 'mobile'
          ? { ...background, mobileImage: url }
          : { ...background, desktopImage: url };
      setBackground(updatedBackground);
      onChange(updatedBackground);
    };
  const onUpdateMobileImageBackground = onUpdateImageBackground('mobile');
  const onUpdateDesktopImageBackground = onUpdateImageBackground('desktop');

  const validateColorInput = (value: string) => {
    const isValid =
      Boolean(value.length === 4 && value.match(MIN_HEX_REGEX)) ||
      Boolean(value.length === 7 && value.match(FULL_HEX_REGEX));

    if (isValid) {
      setColorError(false);
    } else {
      setColorError(true);
    }
  };

  const validateOpacityInput = (value: string) => {
    const isValid = Boolean(value.match(PERCENTAGE_REGEX));
    if (isValid) {
      setOpacityError(false);
    } else {
      setOpacityError(true);
    }
  };

  const handleOnColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const color = e.target.value as string;
    if (!color) return setTempColor('#');
    if (color.length > 7) return;
    validateColorInput(color);
    return setTempColor(color);
  };

  const handleOnOpacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const opacity = e.target.value as string;
    if (!opacity) return setTemOpacity('%');
    if (opacity.length > 4) return;
    validateOpacityInput(opacity);
    return setTemOpacity(opacity);
  };

  useEffect(() => {
    if (!opacityError) {
      const opacity = parseInt(tempOpacity.replace('%', ''), 10) / 100;
      if (opacity !== background?.opacity) {
        const newBackground = {
          ...background,
          opacity,
        };

        setBackground(newBackground);
        onChange(newBackground);
      }
    }
  }, [tempOpacity]);

  useEffect(() => {
    if (!colorError) {
      if (tempColor !== background?.color) {
        const newBackground = {
          ...background,
          color: tempColor,
        };
        setBackground(newBackground);
        onChange(newBackground);
      }
    }
  }, [tempColor]);

  const onHandleFocusColorInput = (isFocus: boolean) => {
    if (isFocus) {
      setIsColorPickerOpen(true);
    }
    setIsFocusColorInput(isFocus);
  };
  const onHandleFocusOpacityInput = (isFocus: boolean) =>
    setIsFocusOpacityInput(isFocus);
  const onSelectColorBackground = () =>
    onSelectBackgroundType(GateBackgroundEnum.color);
  const onSelectImageBackground = () =>
    onSelectBackgroundType(GateBackgroundEnum.image);
  const onFocusColorInput = () => onHandleFocusColorInput(true);
  const onBlurColorInput = () => onHandleFocusColorInput(false);
  const closeColorPicker = () => setIsColorPickerOpen(false);
  const onFocusOpacity = () => onHandleFocusOpacityInput(true);
  const onBlurOpacity = () => onHandleFocusOpacityInput(false);
  const onClearDesktopImage = () => onUpdateDesktopImageBackground(undefined);
  const onUploadDesktopImage = (url: string) =>
    onUpdateDesktopImageBackground(url);
  const onClearMobileImage = () => onUpdateMobileImageBackground(undefined);
  const onUploadMobileImage = (url: string) =>
    onUpdateMobileImageBackground(url);

    const handleContainerClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      e.stopPropagation();
    };

  return (
    <BackgroundControlContainer onMouseDown={handleContainerClick}>
      <StyledCloseButton
        onClick={onClose}
        data-testid="closeBackgroundSettingsButton"
      />
      <TranslatedText
        component={Title}
        stringKey="ACCESS_BACKGROUND_CONTROL_MODAL_TITLE"
      />
      <ColorMenuContainer>
        <Radio
          checked={background?.type === GateBackgroundEnum.color}
          testId="colorRadioButtoon"
          labelKey="ADMIN_LABEL_COLOR_PICKER_COLOR"
          onChange={onSelectColorBackground}
        />
        <Radio
          checked={background?.type === GateBackgroundEnum.image}
          testId="imageRadioButtoon"
          labelKey="ADMIN_CARD_IMAGE_NAME"
          onChange={onSelectImageBackground}
        />
        <InputContainer isFocus={isFocusColorInput}>
          <IconInputContainer>
            <StyledIcon name="colorFill" />
          </IconInputContainer>
          <Input
            data-testid="colorHexInput"
            value={tempColor}
            onChange={handleOnColorChange}
            onFocus={onFocusColorInput}
            onBlur={onBlurColorInput}
            ref={colorPickerParentRef}
          />
          <StyledColorPicker
            parentMargin={{
              top: 50,
              left: -70,
            }}
            color={tempColor}
            isOpen={isColorPickerOpen}
            onClose={closeColorPicker}
            onResult={setTempColor}
            parentRef={colorPickerParentRef}
            titleKey="ACCESS_GATE_ADMIN_BACKGROUND_COLOR"
          />
        </InputContainer>
        <InputContainer isFocus={isFocusOpacityInput}>
          <IconInputContainer>
            <StyledIcon name="opacity" />
          </IconInputContainer>
          <Input
            data-testid="opacityPercentInput"
            value={tempOpacity}
            onChange={handleOnOpacityChange}
            onFocus={onFocusOpacity}
            onBlur={onBlurOpacity}
          />
        </InputContainer>
      </ColorMenuContainer>
      <GradientMaskContainer data-testid="gradientMaskToggle">
        <EditModeRegionToggle
          active={background?.useGradientMask}
          labelKey="ADMIN_LABEL_USE_GRADIENT_MASK"
          onChange={onChangeUseGradientMask}
        />
      </GradientMaskContainer>
      <ColorSectionWrapper>
        <TranslatedText
          component={Label}
          stringKey="ADMIN_SUBSCRIPTION_GATE_BACKGROUND_IMAGE_WEB_LABEL"
        />
        <FixedHeightUploadZone
          imagePreview={background?.desktopImage}
          onClearImage={onClearDesktopImage}
          onFileSubmit={onUploadDesktopImage}
          testIdAddImage="desktopImageUploadImageButton"
          testIdRemove="desktopImageRemoveButton"
          testIdSearchLibrary="desktopImageLibraryButton"
          title={parse(t('ADMIN_LABEL_DRAG_AND_DROP_SUBSCRIPTION_GATE_WEB_IMAGE_SIZE')) as string}
          hasDragAndDropLabel={false}
        />
        <TranslatedText
          component={Label}
          stringKey="ADMIN_SUBSCRIPTION_GATE_BACKGROUND_IMAGE_MOBILE_LABEL"
        />
        <FixedHeightUploadZone
          imagePreview={background?.mobileImage}
          onClearImage={onClearMobileImage}
          onFileSubmit={onUploadMobileImage}
          testIdAddImage="mobileImageUploadImageButton"
          testIdRemove="mobileImageRemoveButton"
          testIdSearchLibrary="mobileImageLibraryButton"
          title={parse(t('ADMIN_LABEL_DRAG_AND_DROP_SUBSCRIPTION_GATE_MOBILE_IMAGE_SIZE')) as string}
          hasDragAndDropLabel={false}
        />
      </ColorSectionWrapper>
    </BackgroundControlContainer>
  );
};

export default BackgroundControl;

import styled from 'styled-components';
import { SPACING_SMALL, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY } from 'style/constants';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const SectionContainer = styled.div`
  padding: ${SPACING_SMALL};
`;

export const Title = styled.div`
  ${ADMIN_TEXT_TITLE_M}
  letter-spacing: -0.03em;
  color: ${ADMIN_TEXT_200};
  margin-bottom: 14.5px;
`;

export const Description = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200} !important;
  margin-bottom: 25px;
`;

export const StyledIcon = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  margin-right: 12px;
`;

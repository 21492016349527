import Icon from 'components/ui/Icon';
import { ADMIN_SURFACE_5, ADMIN_ALERT_ERROR, ADMIN_TEXT_100, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_BODY_M_BOLD, ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_XL_BOLD, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import { FLEX_CENTER } from 'style/mixins';
import styled from 'styled-components';

export const Container = styled.div``;

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ADMIN_SURFACE_5};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  border: 1.5px solid ${ADMIN_ACCENT_PRIMARY};
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlanTitle = styled.span.attrs({
  'data-testid': 'billingPlanName',
})`
  ${ADMIN_TEXT_BODY_XL_BOLD}
  color: ${ADMIN_TEXT_100};
  margin-bottom: 10px;
`;

export const PlanDescription = styled.span`
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_TEXT_300};
`;

export const PlanPrice = styled.div`
  ${ADMIN_TEXT_BODY_XL_BOLD}
  color: ${ADMIN_ACCENT_PRIMARY};
  margin-bottom: 6px;
`;

export const PlanFeatureContainer = styled.span`
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
`;

export const PlanFeatureValue = styled.span`
  ${ADMIN_TEXT_BODY_M_BOLD};
  color: ${ADMIN_TEXT_100};
`;

export const PlanFeatureLabel = styled.span`
  ${ADMIN_TEXT_BODY_M_MEDIUM};
  color: ${ADMIN_TEXT_300};
`;

export const LoadingContainer = styled.div`
  ${FLEX_CENTER}
`;

export const WarningIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_ALERT_ERROR(props),
  name: 'warning',
}))`
  margin-right: 6.2px;
  & svg {
    width: 11.45px;
    height: 8.76px;
  }
`;

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'hooks';
import { AdminTabTextBlock } from 'components/admin2/ui/Text';
import TranslatedText from 'components/i18n/TranslatedText';
import { getApiKeyData } from 'services/developer/selectors';
import isEqual from 'lodash/isEqual';
import { createApiKey, deleteApiKey } from 'services/developer/actions';
import { getSiteId } from 'services/app/selectors';
import Label from 'components/admin2/ui/Label';
import moment from 'moment';
import AdminConfirmationModal from 'components/admin2/AdminConfirmationModal';
import {
  MAESTRO_BLACK,
  MAESTRO_WHITE,
} from 'style/constants';
import LoadingEllipse from 'components/admin2/LoadingEllipse';
import { DEVELOPER_DOCS_URL } from '../consts';
import { CopyApiKeyRow, CopySiteIdRow, IconContainer, LabelText, LoadingEllipseContainer, StyledCopyButton, StyledDescription, StyledInput, StyledLargeButton } from './styles';
import { DEVELOPER_TAB_API_KEY_SECTION_ID } from 'global-ids';
import Link from 'components/admin2/Link';

const ApiKeyAccess = () => {
  const dispatch = useDispatch();
  const { isDeleting, isGenerating, data: apiKey } = useSelector(getApiKeyData, isEqual) || {};
  const siteId = useSelector(getSiteId);
  const [shouldShowConfirmationModal, setShouldShowConfirmationModal] = useState(false);

  const generateApiKey = useCallback(() => {
    if (!isGenerating) {
      dispatch(createApiKey());
    }
  }, [isGenerating]);

  const revokeApiKey = useCallback(() => {
    dispatch(deleteApiKey());
    setShouldShowConfirmationModal(false);
  }, []);

  const copyToClipboard = useCallback((value: string) => {
    if (typeof (navigator.clipboard) === 'undefined') {
      const textArea = document.createElement('textarea');
      textArea.value = value;
      textArea.style.position = 'fixed'; // avoid scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
    navigator.clipboard.writeText(value);
  }, []);

  const getFormattedCreationDate = useCallback(() => {
    if (!apiKey) return '';
    return moment(apiKey.created).format('DD-MMM-YYYY');
  }, [apiKey]);

  const copySiteIdToClipboard = useCallback(() => {
    copyToClipboard(siteId);
  }, [siteId]);

  const copyAPIKeyToClipboard = useCallback(() => {
    if (!apiKey) return;
    copyToClipboard(apiKey.key);
  }, [apiKey]);

  const openConfirmationModal = useCallback(() => setShouldShowConfirmationModal(true), []);
  const closeConfirmationModal = useCallback(() => setShouldShowConfirmationModal(false), []);

  return (
    <AdminTabTextBlock
      id={DEVELOPER_TAB_API_KEY_SECTION_ID}
      data-testid={DEVELOPER_TAB_API_KEY_SECTION_ID}
    >
      <Label labelKey="ADMIN_TEXT_API_ACCESS_TITLE" uppercase={true} />
      <TranslatedText stringKey="ADMIN_TEXT_API_ACCESS_DESC" /><br />
      <Link href={DEVELOPER_DOCS_URL} target="_blank" >
        <TranslatedText stringKey="ADMIN_LABEL_DEVELOPER_API" /><br />
      </Link>
      <TranslatedText
        component={LabelText}
        stringKey="ADMIN_TEXT_API_ACCESS_CLIENT_ID"
      />
      <CopySiteIdRow>
        <StyledInput
          readOnly={true}
          value={siteId}
        />
        <IconContainer
          onClick={copySiteIdToClipboard}
          onMouseEnter={copySiteIdToClipboard}
        >
          <StyledCopyButton />
        </IconContainer>
      </CopySiteIdRow>
      {apiKey ? (
        <div>
          <TranslatedText
            component={LabelText}
            stringKey="ADMIN_TEXT_API_ACCESS_API_KEY"
          />
          <CopyApiKeyRow>
            <StyledInput
              readOnly={true}
              value={apiKey.key}
            />
            <IconContainer
              onClick={copyAPIKeyToClipboard}
              onMouseEnter={copyAPIKeyToClipboard}
            >
              <StyledCopyButton />
            </IconContainer>
          </CopyApiKeyRow>
          <StyledDescription>
            <TranslatedText stringKey="ADMIN_DEVELOPER_API_KEY_GENERATED_ON" />
            {` ${getFormattedCreationDate()} `}
            {
              apiKey.generatedBy && (
                <>
                  <TranslatedText stringKey="BY" />
                  {` ${apiKey.generatedBy}`}
                </>
              )
            }
          </StyledDescription>
          <StyledLargeButton
            disabled={isDeleting}
            disabledBackground={MAESTRO_BLACK}
            disabledColor={MAESTRO_WHITE}
            onClick={openConfirmationModal}
          >
            {
              isDeleting ? (
                <LoadingEllipseContainer>
                  <TranslatedText stringKey="ADMIN_REVOKING_API_KEY_BUTTON" />
                  <LoadingEllipse />
                </LoadingEllipseContainer>
              ) :
                <TranslatedText stringKey="ADMIN_REVOKE_API_KEY_BUTTON" />
            }
          </StyledLargeButton>
        </div>
      ) :
        (
          <StyledLargeButton
            disabled={isGenerating}
            disabledBackground={MAESTRO_BLACK}
            disabledColor={MAESTRO_WHITE}
            onClick={generateApiKey}
          >
            {isGenerating ? (
              <LoadingEllipseContainer>
                <TranslatedText stringKey="ADMIN_GENERATING_API_KEY_BUTTON" />
                <LoadingEllipse />
              </LoadingEllipseContainer>
            ) :
              <TranslatedText stringKey="ADMIN_GENERATE_API_KEY_BUTTON" />}
          </StyledLargeButton>
        )}
      {shouldShowConfirmationModal && (
        <AdminConfirmationModal
          descriptionTextKey="ADMIN_DEVELOPER_REVOKE_MODAL_TEXT"
          hideIcon={true}
          onBack={closeConfirmationModal}
          onConfirm={revokeApiKey}
          textKey="ADMIN_DEVELOPER_REVOKE_QUESTION_TEXT"
        />
      )}
    </AdminTabTextBlock>
  );
};

export default ApiKeyAccess;

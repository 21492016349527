import React from 'react';
import { SeparatorContainer } from './styles';

export type Color = 'default' | 'primary';

type Props = {
  color?:Color ;
  symbol?: string;
};

function Separator({ symbol, color }: Props) {
return <SeparatorContainer hasSymbol={!!symbol} color={color}>{symbol}</SeparatorContainer>;
}

export default Separator;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  ADMIN_SURFACE_4,
  SPACING_X_SMALL,
  SPACING_LARGE,
  SPACING_MEDIUM,
  ADMIN_TEXT_100,
  ADMIN_SURFACE_6,
  ADMIN_ACCENT_PRIMARY,
} from 'style/constants';
import { DIV_BUTTON } from 'style/mixins';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_XL_REGULAR, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_TITLE_L } from 'style/design-system/textStyles';
import {
  ANSWER_POLL,
  COMPLETE_QUEST,
  ENGAGE_OVERLAY,
  TIME_ON_SITE,
} from './constants';

const propTypes = {
  onSelect: PropTypes.func.isRequired,
};

const Container = styled.div`
  min-height: 411px;
  width: 644px;
  background-color: ${ADMIN_SURFACE_4};
  display: flex;
  flex-flow: column nowrap;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  margin-bottom: ${SPACING_LARGE};
`;

const HeaderText = styled.div`
  ${ADMIN_TEXT_TITLE_L}
  letter-spacing: 2px;
  text-transform: uppercase;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  padding: 0 ${SPACING_MEDIUM};
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  flex: 1 0 150px;
  margin: 0 ${SPACING_LARGE};
  ${DIV_BUTTON};
  justify-content: center;
  height: max-content;
  width: max-content;
  padding: 10px;
  border-radius: 5px;
  background-color: ${ADMIN_SURFACE_6};

  &:hover {
    outline: 1px solid ${ADMIN_ACCENT_PRIMARY};
  }
`;

const ActionIcon = styled(Icon).attrs(props => ({
  color: ADMIN_TEXT_100(props),
}))`
  ${ADMIN_TEXT_BODY_XL_REGULAR}
  height: 30px;
`;

const ActionTitle = styled.div`
  text-transform: uppercase;
  margin-bottom: ${SPACING_X_SMALL};
`;

const ActionDescription = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  text-align: center;
`;

const ACTIONS = [
  {
    copyStrKey: 'ADMIN_DESC_ANSWER_POLL',
    iconName: 'list-bullet',
    titleStrKey: 'ADMIN_LABEL_ANSWER_POLL',
    type: ANSWER_POLL,
  },
  {
    copyStrKey: 'ADMIN_DESC_ENGAGE_OVERLAY',
    iconName: 'pointer',
    titleStrKey: 'ADMIN_LABEL_ENGAGE_OVERLAY',
    type: ENGAGE_OVERLAY,
  },
  {
    copyStrKey: 'ADMIN_DESC_COMPLETE_QUEST',
    iconName: 'award',
    titleStrKey: 'ADMIN_LABEL_COMPLETE_QUEST',
    type: COMPLETE_QUEST,
  },
  {
    copyStrKey: 'ADMIN_DESC_TIME_ON_SITE',
    iconName: 'clock',
    titleStrKey: 'ADMIN_LABEL_TIME_ON_SITE',
    type: TIME_ON_SITE,
  },
];
const AchievementTypeSelector = (props) => {
  const { onSelect } = props;
  return (
    <Container>
      <Header>
        <HeaderText>
          <TranslatedText stringKey="ADMIN_LABEL_CHOOSE_ACTION" />
        </HeaderText>
      </Header>
      <ActionContainer>
        {
          ACTIONS.map((actionData) => {
            const { titleStrKey, copyStrKey, iconName, type } = actionData;
            return (
              <Action key={type} onClick={() => onSelect(type)}>
                <ActionIcon admin name={iconName} />
                <ActionTitle>
                  <TranslatedText stringKey={titleStrKey} />
                </ActionTitle>
                <ActionDescription>
                  <TranslatedText stringKey={copyStrKey} />
                </ActionDescription>
              </Action>
            );
          })
        }
      </ActionContainer>
    </Container>
  );
};

AchievementTypeSelector.propTypes = propTypes;

export default AchievementTypeSelector;

import { MAESTRO_WHITE } from 'style/constants';
import { ADMIN_TEXT_BODY_M_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { createAnimationComponent } from '../animation';

export const EngatementImpactContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EngagementItem = createAnimationComponent({
  component :  styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 12px 0 0;
  `,
  keyframes : `
    from {
      transform: translateY(24px);
      opacity:0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  `,
  easing : 'cubic-bezier(0, 0.55, 0.45, 1)',
});

export const EngagementImpactTitle = styled.div`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: ${MAESTRO_WHITE};
  margin-bottom: 20px;
`;

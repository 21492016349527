import React from 'react';
import styled from 'styled-components';
import warning from 'warning';
import AjaxFetch from 'components/core/AjaxFetch';
import { camelify } from 'shared/string-utils';
import {
  ADMIN_TEXT_100,
} from 'style/constants';
import { ADMIN_SERVICE_BASE_URL } from 'config';
import PropTypes from 'prop-types';
import CatalogLibrary from 'components/admin2/ui/CatalogLibrary';
import { kinds } from './CatalogPage';
import { overlayKindMap, OVERLAY_ENTRIES_LIMIT } from './constants';
import generateOverlay from './generateOverlay';

const Container = styled.div`
  align-items: stretch;
  color: ${ADMIN_TEXT_100};
  display: flex;
  flex-flow: column nowrap;
`;

export default class LibraryPage extends React.Component {
  static propTypes = {
    addPreviewOverlay: PropTypes.func.isRequired,
    broadcastOverlay: PropTypes.func.isRequired,
    focusedTabIndex: PropTypes.number.isRequired,
    objectId: PropTypes.string.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onPreviewClick: PropTypes.func.isRequired,
    onTabClick: PropTypes.func.isRequired,
    onUseClick: PropTypes.func.isRequired,
    primaryToken: PropTypes.string.isRequired,
    refreshKey: PropTypes.string.isRequired,
    removeEditingOverlay: PropTypes.func.isRequired,
    removePreviewOverlay: PropTypes.func.isRequired,
  };

  state = {
    focusedIndex: null,
    overlays: [],
    payload: null,
    search: '',
  };

  handleResponse = (response) => {
    this.setState({
      overlays: camelify(response.results),
    });
  };

  onPreview = async (renderer) => {
    const {
      addPreviewOverlay,
      removePreviewOverlay,
      primaryToken,
    } = this.props;
    const { focusedIndex, overlays } = this.state;
    const index = overlays.findIndex(overlay => overlay.renderer.id === renderer.id);
    if (focusedIndex !== null) {
      removePreviewOverlay();
      if (index === focusedIndex) {
        this.setState({ focusedIndex: null, payload: null });
        return;
      }
    }

    // TODO: some kind of validation
    if (!renderer.duration) {
      renderer.duration = 20;
    }
    try {
      const preview = await generateOverlay({
        primaryToken,
        renderer,
      });
      addPreviewOverlay(preview);
      this.setState({ focusedIndex: index, payload: preview.payload });
    } catch (err) {
      warning(false, err.message);
    }
  };

  onBroadcast = async (renderer) => {
    const {
      broadcastOverlay,
      objectId,
      primaryToken,
      removeEditingOverlay,
      removePreviewOverlay,
    } = this.props;
    const { payload } = this.state;

    removePreviewOverlay();
    removeEditingOverlay();
    // if preview matches selected
    if (payload && renderer.id === payload.renderer.id) {
      broadcastOverlay({
        ...payload,
        allChannels: Boolean(payload.renderer.allChannels),
        channel: objectId,
      });
      this.setState({ focusedIndex: null });
      return;
    }
    // have to generate the overlay otherwise
    if (!renderer.duration) {
      renderer.duration = 20;
    }
    try {
      const preview = await generateOverlay({
        primaryToken,
        renderer,
      });
      const { renderer: payloadRenderer } = preview.payload;
      broadcastOverlay({
        ...preview.payload,
        allChannels: Boolean(payloadRenderer.allChannels),
        channel: objectId,
      });
    } catch (err) {
      warning(false, err.message);
    }
  };

  onSearch = (value) => {
    this.setState({ search: value });
  };

  render() {
    const { overlays, search } = this.state;
    const {
      primaryToken,
      refreshKey,
      onEditClick,
      onPreviewClick,
      onUseClick,
      onTabClick,
      focusedTabIndex,
    } = this.props;

    const headers = {
      Authorization: `Bearer ${primaryToken}`,
    };
    return (
      <Container>
        <AjaxFetch
          headers={headers}
          method="get"
          onValue={this.handleResponse}
          params={{
            limit: OVERLAY_ENTRIES_LIMIT,
            search,
            sortDirection: 'desc',
          }}
          refreshKey={refreshKey}
          url={`${ADMIN_SERVICE_BASE_URL}/legacy/overlays`}
        />
        <CatalogLibrary
          actionsWidth={70}
          activeTabIndex={focusedTabIndex}
          catalogItems={kinds}
          catalogType="overlays"
          disableInternalSearch
          getItemIcon={item => overlayKindMap[item?.renderer?.overlayType]?.iconName}
          getItemLastModified={item => item?.lastModified}
          getItemName={item => item?.renderer?.name}
          getItemTypeKey={item => overlayKindMap[item?.renderer?.overlayType]?.nameKey}
          libraryItems={overlays}
          onCatalogPreview={onPreviewClick}
          onCatalogUse={onUseClick}
          onLibraryBroadcast={this.onBroadcast}
          onLibraryEdit={onEditClick}
          onLibrarySelect={this.onPreview}
          onSearch={this.onSearch}
          onTabChange={onTabClick}
          searchPlaceholderKey="ADMIN_OVERLAY_SEARCH_PLACEHOLDER"
          testIdCatalog="overlayCatalogButton"
          testIdCatalogItem="overlayCatalogItem"
          testIdLibrary="overlayLibraryButton"
          testIdLibraryBroadcast="overlayBroadCastButton"
          testIdLibraryEdit="overlayLibraryItemEdit"
          testIdLibraryItem="overlayLibraryItem"
          testIdLibraryItemTitle="overlayLibraryItemName"
        />
      </Container>
    );
  }
}

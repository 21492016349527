import React, { useMemo, useState } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { SectionTitle, StyledPageNavigation, ThemesCollectionWrapper } from '../../styles';
import { ADVANCED_THEMES_MAX_PER_PAGE, useAdvancedThemes } from '../../use-maestro-themes';
import SelectThemeButton from 'components/admin2/Themes/SelectThemeButton';
import { ITheme } from 'models';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { PLACEHOLDER_THEME } from '../../constants';

interface AdvancedThemesProps {
  activeThemeId: string;
  defaultThemeId?: string;
  onCustomize: (theme: ITheme) => void;
  onFavorite: (theme: ITheme) => void;
  onSelect: (theme: ITheme) => void;
}

interface WrapperProps {
  page: number;
  setPage: (page: number) => void;
  showPagination: boolean;
  totalPages: number;
}

const ROWS = ADVANCED_THEMES_MAX_PER_PAGE / 2;

const Wrapper: React.FC<WrapperProps> = ({ children, showPagination, page, setPage, totalPages }) => {
  const onNextPage = () => {
    setPage(page + 1);
  };

  const onPreviousPage = () => {
    setPage(page - 1);
  };

  return (
    <>
      <TranslatedText component={SectionTitle} stringKey="ADMIN_DESIGN_TAB_ADVANCED_THEMES" />
      <ThemesCollectionWrapper rows={showPagination ? ROWS : undefined}>
        {children}
      </ThemesCollectionWrapper>
      {showPagination && (
        <StyledPageNavigation
          admin={true}
          onChange={setPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          page={page}
          pages={totalPages}
        />
      )}
    </>
  );
};

const AdvancedThemes: React.FC<AdvancedThemesProps> = ({ activeThemeId, defaultThemeId, onCustomize, onFavorite, onSelect }) => {
  const [page, setPage] = useState(0);
  const { data, error, loaded, totalCount } = useAdvancedThemes({
    page,
  });

  const onSelectTheme = (theme: ITheme) => () => {
    onSelect(theme);
  };

  const onFavoriteTheme = (theme: ITheme) => () => {
    onFavorite(theme);
  };

  const onCustomizeTheme = (theme: ITheme) => () => {
    onCustomize(theme);
  };

  const placeholderCount = useMemo(() => {
    if (page === 0) {
      return ADVANCED_THEMES_MAX_PER_PAGE;
    }
    const result = totalCount - page * ADVANCED_THEMES_MAX_PER_PAGE;
    return Math.min(result, ADVANCED_THEMES_MAX_PER_PAGE);
  }, [page, totalCount]);

  const themes = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((child, index) => (
      <SelectThemeButton
        theme={child}
        isActive={child._id === activeThemeId}
        isFavorite={defaultThemeId === child._id}
        key={index}
        name={child.name}
        onSelect={onSelectTheme(child)}
        onFavorite={onFavoriteTheme(child)}
        onCustomize={onCustomizeTheme(child)}
      />
    ));
  }, [data, activeThemeId, defaultThemeId, onFavorite, onSelect]);

  const { showPagination, totalPages } = useMemo(() => {
    const total = Math.ceil(totalCount / ADVANCED_THEMES_MAX_PER_PAGE);
    return {
      showPagination: totalCount > ADVANCED_THEMES_MAX_PER_PAGE,
      totalPages: total,
    };
  }, [totalCount]);

  // first load
  if (totalCount === 0 && !error && !loaded) {
    return <LoadingSpinner />;
  }

  if (error || (loaded && !themes.length)) {
    return null;
  }

  // fetching new page
  if (!loaded) {
    return (
      <Wrapper showPagination={true} page={page} setPage={setPage} totalPages={totalPages}>
         {Array.from({ length: placeholderCount }).map((_, index) => (
          <SelectThemeButton
            theme={PLACEHOLDER_THEME}
            isActive={false}
            isFavorite={false}
            key={index}
            name=""
            isLoading={true}
          />
        ))}
      </Wrapper>
    );
  }

  return (
    <Wrapper showPagination={showPagination} page={page} setPage={setPage} totalPages={totalPages}>
      {themes}
    </Wrapper>
  );
};

export default AdvancedThemes;

/* tslint:disable:jsx-boolean-value */
import React, { ComponentProps, useCallback } from 'react';
import RangeButton from './RangeButton';
import { setOneDayRange, setSevenDayRange, setThirtyDayRange, setCustomRange, setCustomRangeType } from 'services/analytics';
import { Ranges } from 'services/analytics/models';
import { useDispatch } from 'hooks';
import moment from 'moment-timezone';
import { ContainerRange, Title, Wrapper, DateContainer, Container, RangeContainer, Label } from './styles';
import Schedule from 'components/admin2/ui/Schedule';
import TranslatedText from 'components/i18n/TranslatedText';
import { useAdminTranslation } from 'hooks/use-translation';

interface IProps {
  activeRange: Ranges;
  endTime: number;
  rangePickerActive: boolean;
  setRangePickerActive: (active: boolean) => void;
  startTime: number;
  stringKey: string;
}

const RangeSelectorTitle = ({
  stringKey,
  activeRange,
  startTime,
  endTime,
  rangePickerActive,
  setRangePickerActive,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();

  const onOneDayClick = useCallback(() => {
    dispatch(setOneDayRange());
  }, []);

  const onSevenDayClick = useCallback(() => {
    dispatch(setSevenDayRange());
  }, []);

  const onThirtyDayClick = useCallback(() => {
    dispatch(setThirtyDayRange());
  }, []);

  const onCustomDayClick = useCallback(() => {
    setRangePickerActive(true);
    dispatch(setCustomRangeType());
  }, []);

  const handleStartTime = (time: number | null) => {
    dispatch(setCustomRange({ rangeEnd: endTime, rangeStart: time || 0 }));
    dispatch(setCustomRangeType());
  };

  const handleEndTime = (time: number | null) => {
    dispatch(setCustomRange({ rangeEnd: time || 0, rangeStart: startTime }));
    dispatch(setCustomRangeType());
  };

  const handleRangeTime: ComponentProps<typeof Schedule>['onChange'] = ({ startTime: start, endTime: end }) => {
    dispatch(setCustomRange({ rangeEnd: end || 0, rangeStart: start || 0 }));
  };

  const displayDatePicker = activeRange === Ranges.CUSTOM && rangePickerActive;
  const onClickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <ContainerRange onClick={onClickHandler}>
      <Title>
        <TranslatedText stringKey={stringKey}/>
        <DateContainer>
          {`${moment(startTime || 0).format('dddd MMMM DD, yyyy')} - ${moment(endTime || 0).format('dddd MMMM DD, yyyy')}`}
        </DateContainer>
      </Title>
      <Container>
        <RangeContainer isActive={activeRange === Ranges.ONE_DAY}  >
          <RangeButton
            active={activeRange === Ranges.ONE_DAY}
            displayName={t('ADMIN_DATE_RANGE_LAST_24H')}
            onClick={onOneDayClick}
            left={true}
            range={Ranges.ONE_DAY}
            right={false}
          />
        </RangeContainer>
        <RangeContainer isActive={activeRange === Ranges.SEVEN_DAYS}>
          <RangeButton
            active={activeRange === Ranges.SEVEN_DAYS}
            displayName={t('ADMIN_DATE_RANGE_LAST_X_DAYS', { x: '7' })}
            onClick={onSevenDayClick}
            left={false}
            range={Ranges.SEVEN_DAYS}
            right={false}
          />
        </RangeContainer>
        <RangeContainer isActive={activeRange === Ranges.THIRTY_DAYS}>
          <RangeButton
            active={activeRange === Ranges.THIRTY_DAYS}
            displayName={t('ADMIN_DATE_RANGE_LAST_X_DAYS', { x: '30' })}
            onClick={onThirtyDayClick}
            left={false}
            range={Ranges.THIRTY_DAYS}
            right={false}
          />
        </RangeContainer>
        <RangeContainer  isActive={activeRange === Ranges.CUSTOM}>
          <RangeButton
            active={activeRange === Ranges.CUSTOM}
            displayName={t('ADMIN_LABEL_CUSTOM')}
            onClick={onCustomDayClick}
            left={false}
            range={Ranges.CUSTOM}
            right={true}
          />
        </RangeContainer>
        {displayDatePicker && (
          <Wrapper onClick={onClickHandler}>
            <Label><TranslatedText stringKey={'ADMIN_LABEL_CUSTOM_FILTER'} /></Label>
            <Schedule
              endDateProps={{
                dateSelectKey: 'ADMIN_LABEL_END_DATE',
                isOutsideRange: () => false,
                onTimeChange: handleEndTime,
                timeSelectKey: 'ADMIN_LABEL_END_TIME',
              }}
              hideTimezone
              onChange={handleRangeTime}
              startDateProps={{
                dateSelectKey: 'ADMIN_LABEL_START_DATE',
                isOutsideRange: () => false,
                onTimeChange: handleStartTime,
                timeSelectKey: 'ADMIN_LABEL_START_TIME',
              }}
              schedule={{
                endTime,
                startTime,
              }}
              timezoneProps={{
                isDisabled: true,
              }}
            />
          </Wrapper>
        )}
      </Container>
    </ContainerRange>
  );
};

export default RangeSelectorTitle;

import { useComponentVisible } from 'hooks/use-component-visible';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DateRangeProps } from 'components/admin2/DateRangePicker';
import DateRangeInput from 'components/admin2/DateRangePicker/DateRangeInput';
import { YearDropdownContainer, YearDropdwonEntry } from './styles';
import { YEAR_RANGE_INITIAL_YEAR } from '../../constants';
import { useAdminTranslation } from 'hooks/use-translation';
interface YearPickerProps extends DateRangeProps {
  initialDate?: Moment;
  isDisabled?: boolean;
  onChangeDate?: (date: Moment) => void;
}

function YearPicker({ isDisabled, onRangeChange, onChangeDate, initialDate, onTitleChange, ...props }: YearPickerProps) {
  const { t } = useAdminTranslation();
  const [date, setDate] = useState<Moment>(initialDate || moment());
  const { ref, isActive, setActive } = useComponentVisible(false);

  const setPickerActive = () => {
    if (!isActive) setActive(true);
  };

  useEffect(() => {
    onTitleChange?.(date?.format('YYYY'));
    if (date && onRangeChange) onRangeChange(date.clone().startOf('year'), date.clone().endOf('year'));
    if (date && onChangeDate) onChangeDate(date.clone());
  }, [date]);

  const yearList = useMemo(() => {
    const years: string[] = [];
    const yearsDiff = moment().year(YEAR_RANGE_INITIAL_YEAR);

    for (const m = moment(); m.diff(yearsDiff, 'year') >= 0; m.subtract(1, 'year')) {
      years.push(m.format('YYYY'));
    }
    return years;
  }, []);

  const renderYears = (year: string) => {
    const handleYearClick = () => {
      setDate(moment(`${year}-01-01 00:00:00`));
      setActive(false);
    };
    return (
      <YearDropdwonEntry key={year} onClick={handleYearClick}>
        {year}
      </YearDropdwonEntry>
    );
  };

  return (
    <DateRangeInput
      value={date?.format('YYYY') || t('ADMIN_LABEL_SELECT_YEAR')}
      isDisabled={isDisabled}
      isActive={isActive}
      onClick={setPickerActive}
      ref={ref}
      {...props}
    >
      <YearDropdownContainer isActive={isActive}>{yearList.map(renderYears)}</YearDropdownContainer>
    </DateRangeInput>
  );
}

export default YearPicker;

import styled from 'styled-components';
import {
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_200,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_5,
  SPACING_SMALL,
  ADMIN_SURFACE_1,
  ADMIN_TEXT_100,
} from 'style/constants';
import { ADMIN_TEXT_BODY_M_REGULAR, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const ContainerRange = styled.div`
  border-bottom: 1px solid ${ADMIN_SURFACE_5};
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-shrink: 0;
  position: relative;
`;

export const Title = styled.div`
  display: flex;
  ${ADMIN_TEXT_TITLE_M}
  color: ${ADMIN_TEXT_100} !important;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 12px;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  color: ${ADMIN_TEXT_300};
  padding-bottom: 5px;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  ${ADMIN_TEXT_BODY_M_REGULAR}
  text-align: center;
  overflow: hidden;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
`;

interface IRangeProps {
  isActive: boolean;
}

export const RangeContainer = styled.div<IRangeProps>`
  padding-bottom: ${props => props.isActive ? '16px' : '20px'};
  border-bottom: ${props => props.isActive ? `4px solid ${ADMIN_ACCENT_PRIMARY(props)}` : 'none'};
`;

export const Wrapper = styled.div`
  position: absolute;
  text-align: left;
  top: 69px;
  right: 1px;
  background: ${ADMIN_SURFACE_1};
  width: 544px;
  border-radius: 5px;
  padding: ${SPACING_SMALL};
  z-index: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);;
`;

export const Label = styled.div`
  padding: 10px 0px;
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_BODY_S_BOLD}
  display: flex;
  text-transform: uppercase;
`;

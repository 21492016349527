import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ActionButton from 'components/admin2/ActionButton';
import TranslatedText from 'components/i18n/TranslatedText';
import EditModeRegionToggle from 'components/admin2/EditModeRegionToggle';
import BackButton from 'components/admin2/NavControls/BackButton';
import {
  ADMIN_LIBRARY_MODAL_WIDTH_WITH_UNITS,
  ADMIN_SURFACE_3,
  SPACING_MEDIUM,
  ADMIN_ALERT_ERROR,
  ADMIN_SURFACE_1,
} from 'style/constants';
import AdminModal from 'components/admin2/ui/AdminModal';
import Divider from 'components/admin2/Divider';
import { useDispatch, useSelector } from 'react-redux';

import { saveSiteSettings, settingsTypes } from 'services/admin';
import { getSiteProfileImages, getSiteProfileSettings } from 'services/app/selectors';
import isEqual from 'lodash/isEqual';
import withConfirmation from 'components/core/withConfirmation';

const Container = styled.div`
  max-width: 500px;
  width: ${ADMIN_LIBRARY_MODAL_WIDTH_WITH_UNITS};
  background-color: ${ADMIN_SURFACE_3};
  display: flex;
  flex-flow: column nowrap;
`;

const EditImageContainer = styled.div`
    padding: 24px;
`;

const DeleteProfileImageButton = withConfirmation(styled.button`
&& {
  background-color: black;
  color: white;
  display: block;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 24px;
  text-align: center;
  width: 100%;
  padding: 16px;
}
&:hover {
  border: 1px solid ${ADMIN_ALERT_ERROR}
}
`);

const DeleteProfileImageText = styled.span`
  text-transform: uppercase;
`;

const DeleteProfileImageWarning = styled.span`
  font-size: 0.75em;
`;

const StyledImage = styled.div`
  margin: 5%;
  min-height: 250px;
  max-height: 95%;
  background: url(${props => props.src});
  background-position: center;
  background-size: contain;
  flex: 1;
  background-repeat: no-repeat;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 1px, ${ADMIN_SURFACE_1} 2px, transparent 3px);
`;

const Header = styled.div`
  background: ${ADMIN_SURFACE_1};
  display: flex;
  justify-content: space-between;
  padding: ${SPACING_MEDIUM};
`;

const SaveButtonWrapper = styled.div`
  display: flex;
`;

const SaveButton = styled(ActionButton)`
  &:not(:first-child) {
    margin-left: ${SPACING_MEDIUM};
  }
`;

const StyledEditModeRegionToggle = styled(EditModeRegionToggle)`
  margin-top: 12px;
`;

export const StyledDivider = styled(Divider)`
  margin: 15px 0;
  &&:last-of-type{
    margin-bottom: 24px;
  }
`;

const EditImageModal = (props) => {
  const dispatch = useDispatch();
  const handleSave = useCallback((data) => {
    dispatch(saveSiteSettings(settingsTypes.USER_PROFILES, data));
  }, [
    dispatch,
    saveSiteSettings,
  ]);
  const currentProfileImages = useSelector(getSiteProfileImages, isEqual);
  const currentProfileSettings = useSelector(getSiteProfileSettings, isEqual);

  const { image, index, onClose } = props;
  const [showSave, setShowSave] = useState(false);
  const [isAdminProfilePic, setIsAdminProfilePic] = useState(image.isAdmin);

  const onSave = useMemo(() => {
    return () => {
      const profileImages = [...currentProfileImages];
      const changedImage = {
        isAdmin: isAdminProfilePic,
        url: image.url,
      };
      profileImages[index] = changedImage;
      handleSave({ ...currentProfileSettings, profileImages });
      onClose();
    };
  }, [
    currentProfileSettings,
    currentProfileImages,
    showSave,
    handleSave,
    onClose,
  ]);

  const onDelete = useCallback(() => {
    const profileImages = [...currentProfileImages];
    profileImages.splice(index, 1);
    handleSave({ ...currentProfileSettings, profileImages });
    onClose();
  }, [
    currentProfileSettings,
    currentProfileImages,
    onClose,
    handleSave,
  ]);

  return (
    <AdminModal
      fixedHeight
      maxWidth="500px"
      onClose={onClose}
    >
      <Container>
        <Header>
          <BackButton onClick={onClose} />
          {
            showSave && (
              <SaveButtonWrapper>
                <SaveButton labelKey="ADMIN_ACTION_SAVE" onClick={onSave} />
              </SaveButtonWrapper>
            )
          }
        </Header>
        <ImageContainer>
          <StyledImage
            key={image.url}
            src={image.url}
          />
        </ImageContainer>
        <EditImageContainer>
          <StyledEditModeRegionToggle
            active={isAdminProfilePic}
            helperTextKey="ADMIN_TEXT_ADMIN_ONLY_TOGGLE"
            labelKey="ADMIN_LABEL_ADMIN_ONLY"
            onChange={(active) => {
              setIsAdminProfilePic(active);
              setShowSave(isAdminProfilePic !== active);
            }}
          />
          <StyledDivider />
          <DeleteProfileImageButton onClick={onDelete}>
            <TranslatedText component={DeleteProfileImageText} stringKey="ADMIN_LABEL_DELETE_PROFILE_IMAGE" />
            <br />
            <TranslatedText component={DeleteProfileImageWarning} stringKey="ADMIN_LABEL_DELETE_PROFILE_IMAGE_WARNING" />
          </DeleteProfileImageButton>
        </EditImageContainer>
      </Container>
    </AdminModal>
  );
};

EditImageModal.propTypes = {
  image: PropTypes.shape({
    isAdmin: PropTypes.bool,
    url: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditImageModal;

import styled from 'styled-components';
import AdminModal from 'components/admin2/ui/AdminModal';
import heroBgColor from 'assets/hero-bg-color.jpg';
import { CloseButton } from 'components/admin2/ui/Button';
import { Button, LargeButton } from 'components/admin2/ui/Button';
import Icon from 'components/ui/Icon';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import TextInput from 'components/admin2/TextInput';
import {
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_1,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_200,
  ADMIN_TEXT_500,
} from 'style/constants';
import {
  CIRCLE,
  FLEX_CENTER,
} from 'style/mixins';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_LABEL_L_MEDIUM, ADMIN_TEXT_TITLE_L } from 'style/design-system/textStyles';

export const StyledTextInput = styled(TextInput).attrs({
  compact: true,
})`
  input {
    background-color: ${ADMIN_SURFACE_1};
  }
`;

export const ModalContent = styled.div`
  height: 100%;
  width: 100%;
`;

export const StyledModal = styled(AdminModal).attrs({
  fixedDimensions: false,
  fixedHeight: true,
  fixedWidth: true,
  maxHeight: '751px',
  maxWidth: '627px',
})`
  border-radius: 10px;
  background: ${ADMIN_SURFACE_4};
  background-image: url(${heroBgColor});
  padding: 60px 90px 24px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
`;

export const StyledCloseButton = styled(CloseButton).attrs(() => ({
  color: `rgba(49, 50, 62, 0.3)`,
  containerCss: `
    position: absolute;
    top: 7px;
    right: 7px;
    height: 21px;
    width: 21px;
    & > span {
      padding: 0px;
    }
  `,
}))`
  position: absolute;
  border-radius: 3px;
`;

export const Header = styled.header`
  text-align: center;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_TITLE_L};
  margin: 12px 0;
`;

export const Description = styled.div`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

export const Body = styled.div`
  width: 337px;
  margin: 24px auto 0;
`;

export const Footer = styled.footer`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

export const LinkButton = styled(Button).attrs({
  disabledBackground: 'transparent',
})`
  background: transparent;
  text-decoration: underline;
  ${ADMIN_TEXT_LABEL_L_MEDIUM};
  && {
    color: ${ADMIN_ACCENT_PRIMARY};
  }
  &:hover {
    background-color: transparent;
  }
`;

export const FullWidthButton = styled(LargeButton)`
  width: 100%;
`;

export const MessageContainer = styled.div`
  background-color: ${ADMIN_SURFACE_1};
  border-radius: 5px;
  padding: 24px 20px;
  color: ${ADMIN_ACCENT_PRIMARY};
  margin-bottom: 10px;
`;

export const MessageWrapper = styled.div`
  display: flex;
`;

export const CheckMark = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_500(props),
  name: 'successCheckmark',
  radius: '16px',
}))`
  ${CIRCLE}
  ${FLEX_CENTER}
  background: ${ADMIN_ACCENT_PRIMARY};
  color: ${ADMIN_TEXT_500};
  padding: 3px;
  margin-right: 10px;
  & svg {
    width: 10px;
    height: 10px;
  }
`;

export const StyledSpinner = styled(LoadingSpinner).attrs(props => ({
  colorOverride: ADMIN_ACCENT_PRIMARY(props),
}))`
`;

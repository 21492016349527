import React from 'react';
import { SectionContainer, Title, Description, StyledIcon } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

type Props = {
    children: React.ReactNode;
    description?: string;
    icon: string;
    title: string;
};

const Section = ({ description, title, children, icon }: Props) => {
  return (
    <SectionContainer>
      <Title>
        <StyledIcon name={icon} />
        <TranslatedText stringKey={title}/>
      </Title>
      {description && (
        <Description>
          <TranslatedText stringKey={description}/>
        </Description>
      )}
      {children}
    </SectionContainer>
    );
};

export default Section;

import { useComponentVisible } from 'hooks/use-component-visible';
import React from 'react';
import DateRangeInput from 'components/admin2/DateRangePicker/DateRangeInput';
import { YearDropdownContainer, YearDropdwonEntry } from '../YearPicker/styles';
import { TimePeriod } from 'components/admin-bridge/EarningsDashboard/IEarnings';
import { titlelize } from 'shared/string-utils';
import { useAdminTranslation } from 'hooks/use-translation';


interface PeriodPickerProps {
  isDisabled?: boolean;
  onChange: (period: TimePeriod) => void;
  value: TimePeriod;
}

function PeriodPicker({ isDisabled, onChange, value, ...props }: PeriodPickerProps) {
  const { ref, isActive, setActive } = useComponentVisible(false);
  const { t } = useAdminTranslation();

  const setPickerActive = () => {
    if (!isActive) setActive(true);
  };

  const renderPeriods = (period: TimePeriod) => {
    const handlePeriodClick = () => {
      onChange(period);
      setActive(false);
    };
    return (
      <YearDropdwonEntry key={period} onClick={handlePeriodClick}>
        {titlelize(period)}
      </YearDropdwonEntry>
    );
  };

  return (
    <DateRangeInput
      value={titlelize(value.toString()) || t('ADMIN_DATE_RANGE_PICKER_SELECT_PERIOD')}
      isDisabled={isDisabled}
      isActive={isActive}
      onClick={setPickerActive}
      ref={ref}
      {...props}
    >
      <YearDropdownContainer isActive={isActive}>{Object.values(TimePeriod).map(renderPeriods)}</YearDropdownContainer>
    </DateRangeInput>
  );
}

export default PeriodPicker;

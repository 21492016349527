import styled from 'styled-components';
import { Title } from 'components/admin2/ui/Text';
import TextArea from 'components/admin2/TextArea';
import { ADMIN_ALERT_ERROR, ADMIN_TEXT_300, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_400 } from 'style/constants';
import Icon from 'components/ui/Icon';
import Link from 'components/ui/Link';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import { IStyledProps } from 'styled-components';

export const Wrapper = styled.div``;

export const CodeInjectionEditor = styled(TextArea)<{ name: string }>`
  height: 14em;
`;

export const InjectionTitle = styled(Title)`
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

export const TextWrapper = styled.div`
  padding-top: 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
  justify-content: center;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const StyledError = styled.span`
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_ALERT_ERROR};
;
`;

export const WarningIcon = styled(Icon).attrs((props: IStyledProps & {
  error: boolean;
}) => ({
  color: props.error ? ADMIN_ALERT_ERROR(props) : ADMIN_TEXT_400(props),
  name: 'warning',
}))<{ error: boolean }>`
  display: inline;
  float: left;
  margin-right: 6.27px;
  svg {
    width: 11.45px;
    height: 8.76px;
  }
  bottom: 10px;
`;

export const HelpIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_200(props),
  name: 'adminbarHelp',
}))`
   display: inline;
   float: left;
`;

export const StyledText = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  display: inline-block;
  color: ${ADMIN_TEXT_300};
`;

export const StyledLinkText = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  display: inline-block;
  color: ${ADMIN_ACCENT_PRIMARY};
  text-decoration: underline;
`;

export const HelpLink = styled(Link)`
  display: inline-block;
  padding-left: 10px;
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
`;
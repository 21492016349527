/* tslint:disable: jsx-boolean-value */
import React, { memo } from 'react';
import shortid from 'shortid';
import moment from 'moment-timezone';
import Calendar, { CalendarProps } from 'components/admin2/ui/Calendar';
import withLabel from 'components/core/withLabel';
import TimeInput, { Time } from 'components/admin2/ui/TimeInput';
import TimezoneSelect from './TimezoneSelect';
import { Column, Container, SelectContainer, InlineContainer } from './styles';
import Label from '../ui/Label';
import DateDisplayer from './DateDisplayer';
import ErrorMessage from '../ui/ErrorMessage/ErrorMessage';
import { useDateTimePicker } from './useDateTimePicker';
import { CALENDAR_WRAPPER } from 'css-classes';
import { TranslationKey } from 'hooks/use-translation';

export { default as TimezoneSelect } from './TimezoneSelect';

export enum LayoutType {
  default = 'default',
  inline = 'inline',
  grid = 'grid',
}

export interface DatetimePickerProps extends CalendarProps {
  calendarPosition?: 'right' | 'left' | 'top';
  dateSelectKey?: TranslationKey;
  errorMessageKey?: string;
  expandableBackGroundColor?: string;
  hideDateDisplayer?: boolean;
  hideTime?: boolean;
  hideTimezone?: boolean;
  iconButtonRight?: string;
  layout?: LayoutType;
  minutesFraction?: number;
  noLabel?: boolean;
  onTimeChange: (time: number | null) => void;
  required?: boolean;
  rowAlign?: boolean;
  showDangerErrorMessage?: boolean;
  timeSelectIcon?: string;
  timeSelectKey?: TranslationKey;
  timestamp?: number | null;
  timezone?: string;
  timezoneKey?: string;
  verticalTime?: boolean;
}

export const LOCAL_TIMEZONE = moment.tz.guess();
const ID = shortid.generate();

function DatetimePicker({
  closeOnPick = true,
  dateSelectKey = 'ADMIN_LABEL_CALENDAR',
  expandableBackGroundColor,
  footerTextKey,
  hideClearButton,
  hideTime,
  hideTimezone,
  layout = LayoutType.default,
  isDisabled = false,
  isOutsideRange,
  onTimeChange,
  rowAlign = false,
  timeSelectKey = 'ADMIN_LABEL_HOUR',
  timeSelectIcon = 'clock',
  timestamp = null,
  timezone: externalTimezone,
  timezoneKey = 'ADMIN_LABEL_TIME_ZONE',
  verticalTime,
  noLabel,
  showDangerErrorMessage,
  errorMessageKey,
  iconButtonRight,
  hideDateDisplayer = false,
  minutesFraction,
  calendarPosition,
  ...props
}: DatetimePickerProps) {
  const { datetime, setTimeValue, setTimezone, timeValue } = useDateTimePicker({
    externalTimezone,
    onTimeChange,
    timestamp,
  });

  const { dayMoment, timezone } = datetime;

  const handleDateChange = (value: moment.Moment | null) => {
    if (!value?.isValid()) {
      onTimeChange(null);
      return;
    }
    value.set(timeValue);
    onTimeChange(value.valueOf());
  };

  const handleTimeChange = (time: Time) => {
    setTimeValue(time);
  };

  const clearDate = () => {
    onTimeChange(null);
    setTimezone(LOCAL_TIMEZONE);
  };

  const handleTimezoneChange = ({ timezone: newZone }: { timezone: string }) => setTimezone(newZone);
  const renderErrorMessage = () => showDangerErrorMessage && errorMessageKey && <ErrorMessage errorMessageKey={errorMessageKey}/>;

  if (layout === LayoutType.inline || layout === LayoutType.grid) {
    return (
      <>
        {(layout !== LayoutType.grid && !hideDateDisplayer) && (
          <>
            <Label
              labelKey="ADMIN_SUBSCRIPTION_GATE_SET_DATE_TITLE"
              labelHintKey="ADMIN_LABEL_HINT_TITLE"
              padding="0"
            />
            <DateDisplayer dayMoment={dayMoment} {...timeValue} />
          </>
        )}
        <InlineContainer
          isGridLayout={layout === LayoutType.grid}
        >
          <Calendar
            expandableBackGroundColor={expandableBackGroundColor}
            iconButtonRight={iconButtonRight}
            noLabel={noLabel}
            date={dayMoment}
            hideClearButton={true}
            id={ID}
            isDisabled={isDisabled}
            isOutsideRange={isOutsideRange}
            labelIcon={LayoutType.grid ? '' : 'calendar'}
            labelKey={LayoutType.grid ? 'ACCESS_GATE_ADMIN_LABEL_CALENDAR' : dateSelectKey}
            labelPadding="6px"
            labelWhiteIcon
            onClear={clearDate}
            onDateChange={handleDateChange}
            padding="6px 0"
            placeholder="Set a date"
            calendarPosition={calendarPosition}
          />
          {datetime && (
            <TimeInput
              minutesFraction={minutesFraction}
              data-testid="timezoneInput"
              disabled={isDisabled}
              fullwidth
              labelIcon={LayoutType.grid ? '' : timeSelectIcon}
              labelKey={LayoutType.grid ? 'ACCESS_GATE_ADMIN_LABEL_HOUR' : timeSelectKey}
              labelPadding="6px"
              labelWhiteIcon
              onChange={handleTimeChange}
              time={timeValue}
            />
          )}
          {datetime && !hideTimezone && (
            <TimezoneSelect
              data-testid="timezoneInput"
              isDisabled={isDisabled}
              labelIcon={LayoutType.grid ? '' : 'adminbarLocalization'}
              onChange={handleTimezoneChange}
              timezone={timezone}
              timezoneKey={LayoutType.grid ? timezoneKey : 'ADMIN_GATE_DATETIME_PICKER_TIMEZONE'}
            />
          )}
        </InlineContainer>
        {
          renderErrorMessage()
        }
      </>
    );
  }

  return (
    <Container rowAlign={rowAlign} verticalTime={verticalTime} {...props}>
      <Column>
        <Calendar
          expandableBackGroundColor={expandableBackGroundColor}
          iconButtonRight={iconButtonRight}
          noLabel={noLabel}
          calendarPosition={calendarPosition}
          closeOnPick={closeOnPick}
          className={CALENDAR_WRAPPER}
          date={dayMoment}
          footerTextKey={footerTextKey}
          hideClearButton={hideClearButton}
          id={ID}
          isDisabled={isDisabled}
          isOutsideRange={isOutsideRange}
          labelIcon="calendar"
          labelKey={dateSelectKey}
          labelPadding="6px"
          labelWhiteIcon
          onClear={clearDate}
          onDateChange={handleDateChange}
          padding="6px 0"
          placeholder="Set a date"
        />
        {
          renderErrorMessage()
        }
      </Column>
      {!hideTime && (
        <Column>
          {datetime && (
            <SelectContainer>
              <TimeInput
                data-testid="timezoneInput"
                disabled={isDisabled}
                fullwidth
                labelIcon={timeSelectIcon}
                labelKey={timeSelectKey}
                labelPadding="6px"
                labelWhiteIcon
                onChange={handleTimeChange}
                time={timeValue}
              />
              {!hideTimezone && (
                <TimezoneSelect
                  data-testid="timezoneInput"
                  isDisabled={isDisabled}
                  onChange={handleTimezoneChange}
                  timezone={timezone}
                  timezoneKey={timezoneKey}
                />
              )}
            </SelectContainer>
          )}
        </Column>
      )}
    </Container>
  );
}

export default memo(withLabel(DatetimePicker));

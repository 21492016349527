import { MAESTRO_PURPLE, MAESTRO_WHITE } from 'style/constants';
import { ITheme } from 'models';
import { BORDER_RADIUS, TEXT_CAPITALIZATION, THEME_MODES, THEME_TYPES } from 'models/ITheme';

export const DEFAULT_FONT_FAMILY = 'noto-sans-regular';

const DARK_THEME: ITheme = {
  _id: '6274067af74b66a544018dac',
  button: {
    borderRadius: BORDER_RADIUS.rounded,
    textCapitalization: TEXT_CAPITALIZATION.uppercase,
  },
  colors: {
    accentPrimary: MAESTRO_PURPLE,
    accentSecondary: '#4C42AD',
    accentTertiary: MAESTRO_WHITE,
    alertConfirmation: '#43D48F',
    alertWarning: '#F4D73D',
    alertError: '#FF0000',
    highlightPrimary: '#F3F7FF',
    highlightSecondary: '#9DA0A6',
    highlightTertiary: '#0C0D0D',
    surface1: '#080A0C',
    surface2: '#12141A',
    surface3: '#1B1E26',
    surface4: '#242833',
    surface5: '#363C4D',
    surface6: '#3E4659',
    text100: '#F3F7FF',
    text200: '#C2C5CC',
    text300: '#919499',
    text400: '#616266',
    text500: '#0C0D0D',
  },
  name: 'Dark Mode',
  type: THEME_TYPES.CLASSIC,
  mode: THEME_MODES.DARK,
  typography: {
    body: DEFAULT_FONT_FAMILY,
    headline: DEFAULT_FONT_FAMILY,
  },
};

const LIGHT_THEME: ITheme = {
  _id: '62740674bf5d8f8ae49ff250',
  button: {
    borderRadius: BORDER_RADIUS.rounded,
    textCapitalization: TEXT_CAPITALIZATION.uppercase,
  },
  colors: {
    accentPrimary: MAESTRO_PURPLE,
    accentSecondary: '#4C42AD',
    accentTertiary: MAESTRO_WHITE,
    alertConfirmation: '#43D48F',
    alertWarning: '#F4D73D',
    alertError: '#FF0000',
    highlightPrimary: '#0D0D0D',
    highlightSecondary: '#A1A2A6',
    highlightTertiary: '#F7FAFF',
    surface1: '#D3D4D9',
    surface2: '#DFE1E6',
    surface3: '#E7E8ED',
    surface4: '#EEF0F5',
    surface5: '#F8FAFF',
    surface6: MAESTRO_WHITE,
    text100: '#0C0C0D',
    text200: '#323233',
    text300: '#636466',
    text400: '#949599',
    text500: '#F8FAFF',
  },
  name: 'Light Mode',
  type: THEME_TYPES.CLASSIC,
  mode: THEME_MODES.LIGHT,
  typography: {
    body: DEFAULT_FONT_FAMILY,
    headline: DEFAULT_FONT_FAMILY,
  },
};

export const CLASSIC_THEMES = [
  DARK_THEME,
  LIGHT_THEME,
];

export const PLACEHOLDER_THEME: ITheme = {
  _id: '6274067af74b66a544018dbc',
  button: {
    borderRadius: BORDER_RADIUS.rounded,
    textCapitalization: TEXT_CAPITALIZATION.uppercase,
  },
  colors: {
    accentPrimary: '#242833',
    accentSecondary: '#242833',
    accentTertiary: '#242833',
    alertConfirmation: '#242833',
    alertWarning: '#242833',
    alertError: '#242833',
    highlightPrimary: '#242833',
    highlightSecondary: '#242833',
    highlightTertiary: '#242833',
    surface1: '#080A0C',
    surface2: '#242833',
    surface3: '#242833',
    surface4: '#242833',
    surface5: '#242833',
    surface6: '#242833',
    text100: '#242833',
    text200: '#242833',
    text300: '#242833',
    text400: '#242833',
    text500: '#242833',
  },
  name: 'Loading...',
  type: THEME_TYPES.CLASSIC,
  mode: THEME_MODES.DARK,
  typography: {
    body: DEFAULT_FONT_FAMILY,
    headline: DEFAULT_FONT_FAMILY,
  },
};

import shortId from 'shortid';
import { getPoll } from 'services/polls/api';
import { SITE_ID } from 'config';
import { camelify } from 'shared/string-utils';

export default async ({
  renderer,
  primaryToken,
}) => {
  const { duration, pollId } = renderer;
  if (!pollId) {
    return false;
  }

  const poll = await getPoll(primaryToken, pollId, SITE_ID);

  return {
    key: `preview-${shortId.generate()}`,
    payload: {
      channel: 'preview',
      data: {},
      renderer: {
        ...renderer,
        isPreview: true,
        poll: camelify(poll),
      },
    },
    timeRemaining: duration,
  };
};

import styled from 'styled-components';
import ActionButton from 'components/admin2/ActionButton';
import Button from 'components/admin2/ui/Button';
import Link from 'components/ui/Link';
import {
  ADMIN_ACTION_COLOR,
  ADMIN_ACTION_COLOR_HOVER,
  ADMIN_SURFACE_1,
  SPACING_X_SMALL,
  ADMIN_TEXT_200,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_2,
  SURFACE_3,
} from 'style/constants';
import { ACTION_NORMAL, BACKGROUND_DEFAULT, FLEX_CENTER } from 'style/mixins';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';

interface IDotProps {
  userSelected: boolean | undefined;
}

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const PaymentContainer = styled.div`
  border: 2px solid ${ADMIN_SURFACE_1};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const CardContainer = styled.div`
  border: 2px solid ${BACKGROUND_DEFAULT};
`;

export const StyledActionButton = styled(ActionButton)`
`;

export const StyledMethodTitle = styled.div`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  text-transform: uppercase;
  color: ${ADMIN_TEXT_200};
  margin-bottom: 5px;
`;

export const StyledNextBillingDateLabel = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_300};
  margin-bottom: 10px;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_100};
  margin-bottom: 10px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding-bottom: ${SPACING_X_SMALL};
  padding-top: ${SPACING_X_SMALL};
`;

export const Dot = styled.div<IDotProps>`
  background: ${(props) => (props.userSelected ? ADMIN_ACTION_COLOR : SURFACE_3(props))};
  box-shadow: 0 0 0 2px ${ADMIN_ACTION_COLOR} inset;
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 5px 10px 5px 10px;
  height: 12px;
  width: 12px;
`;

export const StyledLink = styled(Link)`
  && {
    color: ${ADMIN_ACTION_COLOR};
  }
  &:hover {
    color: ${ADMIN_ACTION_COLOR_HOVER};
  }
  ${ACTION_NORMAL};
`;

export const Container = styled.div`
  flex: 1;
  color: ${ADMIN_TEXT_100};
`;

export const Footer = styled.div`
  ${FLEX_CENTER}
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  padding: 20px 40px;
  background-color: ${ADMIN_SURFACE_2};
  margin-top: 50px;
  margin: 0 -20px -50px -20px;
  margin-top: 50px;
  a {
    color: ${ADMIN_TEXT_200};
    text-decoration: none;
    text-align: center;
  }
`;

export const HelperText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_300};
`;

export const SubtitleText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  margin-top: 12px;
  color: ${ADMIN_TEXT_200};
  a {
    color: ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const TipText = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200};
`;

export const TipText1 = styled(TipText as any)`
 margin-bottom: 20px;
`;

export const TipText2 = styled(TipText as any)`
 margin-top: 15px;
`;

export const SettingsContainer = styled.div`
  min-height: 500px;
  display: flex;
  flex-flow: column nowrap;
`;

export const BalanceTextValue = styled.span`
  color: ${ADMIN_ACCENT_PRIMARY}
`;

export const UpgradePlanButton = styled(Button)`
  width: 100%;
  min-height: 40px;
  ${ADMIN_TEXT_BODY_S_BOLD}
`;

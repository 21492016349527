import React from 'react';
import { SectionContainer, Subtitle } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import KeyedListMapper from 'components/core/KeyedListMapper';
import Row from './Row';

interface IItem {
  type: string;
  uniquesEngaged: number;
  viewerId: number;
}

interface IProps {
  data: IItem[];
  stringKey: string;
}

const Table = ({ stringKey, data }: IProps ) => {

  return (
    <SectionContainer>
      <Subtitle>
        <TranslatedText stringKey={stringKey} />
      </Subtitle>
      <KeyedListMapper keyField="type" list={data}>
          {(key: string, item:IItem, index: number) => (
            <Row key={key} index={index + 1} item={item} total={item?.viewerId}/>
          )}
        </KeyedListMapper>
    </SectionContainer>
  );
};

export default Table;

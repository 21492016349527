import React, { useCallback } from 'react';
import useValidator from 'hooks/use-validator';
import BackButton from 'components/admin2/NavControls/BackButton';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  Container,
  ActionContainerLeft,
  StyledIcon,
  ActionContainerRight,
  SaveActionContainer,
  SaveIcon,
  UseButton,
  StyledStatusIndicator,
  Title,
  StyledActionButton,
} from './styles';
import { TranslationKey } from 'hooks/use-translation';

interface INavAction {
  enabled: boolean;
  labelKey: TranslationKey;
  nonMemoized: boolean;
  onClick: (value: any) => void;
  testId: string;
}

const NavAction: React.FC<INavAction> = ({
  enabled,
  labelKey,
  onClick,
  nonMemoized,
  testId,
}) => {
  const click = useCallback((e) => {
    if (enabled) {
      onClick(e);
    }
  }, [
    enabled,
  ]);

  return (
    <StyledActionButton
      data-testid={testId}
      disabled={!enabled}
      onClick={nonMemoized ? onClick : click}
    >
      <TranslatedText stringKey={labelKey} />
    </StyledActionButton>
  );
};

export interface IAction {
  enable?: boolean;
  labelKey: TranslationKey;
  noMemoizedClick?: boolean;
  onClick: () => void;
  testId?: string;
}

interface ICmsNavHeader {
  actions?: IAction[];
  backButtonKey?: TranslationKey;
  existing?: boolean;
  iconName?: string | null;
  onAdd?: () => void;
  onBack?: () => void;
  onSave?: (() => void) | null;
  titleKey?: TranslationKey;
  validationCollection?: string;
  validationEnabled?: boolean;
  validationValue?: any;
}

type getActionPropsFn = (action: IAction, valid: boolean) => {
  enabled: boolean;
  key: TranslationKey;
  labelKey: TranslationKey;
  onClick: (value: any) => void;
};
const getActionProps: getActionPropsFn = (action: IAction, valid: boolean) => {
  const key = action.labelKey;

  return {
    enabled: action.enable === undefined ? valid : (action.enable && valid),
    key,
    labelKey: key,
    onClick: action.onClick,
  };
};

const CmsNavHeader: React.FC<ICmsNavHeader> = ({
  actions = [],
  existing = false,
  onAdd,
  backButtonKey,
  iconName,
  onBack = null,
  onSave,
  titleKey,
  validationCollection = null,
  validationEnabled = false,
  validationValue = null,
}) => {
  const {
    error,
    fetching,
    valid,
  } = useValidator({
    collection: validationCollection,
    doc: validationValue,
    enabled: validationEnabled,
  });

  return (
    <Container>
      {onBack && (
        <ActionContainerLeft>
          {backButtonKey ? (
            <TranslatedText
              component={NavAction}
              componentProps={{ onClick: onBack }}
              stringKey={backButtonKey}
            />
          ) : (
            <BackButton data-testid="backButton" onClick={onBack} />
          )}
        </ActionContainerLeft>
      )}
      <Title>
        {iconName && <StyledIcon name={iconName} />}
        {titleKey && <TranslatedText stringKey={titleKey} />}
      </Title>
      <ActionContainerRight>
        {actions.map((action, index) => (
          <NavAction
            key={`nav-action-${index}`}
            nonMemoized={action.noMemoizedClick}
            testId={action.testId}
            {...getActionProps(action, valid)}
          />
        ))}
        {onSave && (
          <SaveActionContainer
            data-testid="saveToLibraryButton"
            onClick={onSave}
          >
            <SaveIcon />
            <TranslatedText
              stringKey={
                existing ? 'ADMIN_ACTION_SAVE' : 'ADMIN_ACTION_SAVE_TO_LIBRARY'
              }
            />
          </SaveActionContainer>
        )}
        {onAdd && <UseButton onClick={onAdd} />}
        {validationEnabled && (
          <StyledStatusIndicator
            errorKey={error!}
            fetching={fetching}
            fetchingLabelKey="ADMIN_LABEL_VALIDATING"
            loaded={valid}
          />
        )}
      </ActionContainerRight>
    </Container>
  );
};

export default CmsNavHeader;

import React from 'react';
import { ADMIN_SURFACE_6, ADMIN_TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { formatTime } from './utils';

const Container = styled.div`
  border-radius: 100px;
  border: 2px solid ${ADMIN_SURFACE_6};
  color: ${ADMIN_TEXT_300};
  ${ADMIN_TEXT_BODY_XS_BOLD}
  margin: 10px 0;
  padding: 5px 10px;
  width: fit-content;
`;

interface IProps {
  dayMoment: moment.Moment | null;
  hour: number;
  minute: number;
}

function DateDisplayer({ dayMoment, hour, minute }: IProps) {
  const { label } = formatTime(hour, minute);
  const date = dayMoment?.format(`MMM Do, YYYY`);
  const timezone = dayMoment?.format(`z`);

  return (
    <Container>
      {`${date} | ${label} ${timezone}`}
    </Container>
  );
}

export default DateDisplayer;

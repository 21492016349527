import styled, { css } from 'styled-components';
import { ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_WARNING, ADMIN_SURFACE_1, ADMIN_SURFACE_2, ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_TEXT_300, ADMIN_TEXT_400, MAESTRO_WHITE, SPACING_SMALL } from 'style/constants';
import { Variant } from '.';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XL_BOLD } from 'style/design-system/textStyles';

export const CardContainer = styled.div<{ size?: string, tip?: boolean, variant: Variant }>`
  height: 100%;
  border-radius: 10px;
  max-width: 169px;
  min-width: 133px;
  display: flex;
  flex-direction: column;
  padding: ${SPACING_SMALL};
  position: relative;
  background: ${({ variant, ...props }) =>
    variant === 'lighted'
      ? `linear-gradient(124.63deg, ${ADMIN_ACCENT_PRIMARY(props)} -4.3%, #9CA5FF 109.31%);`
      : variant === 'yellow'
      ? ADMIN_ALERT_WARNING
      : `${ADMIN_SURFACE_3}`};
  & svg {
    fill: ${({ variant }) =>
      variant === 'lighted'
      ? MAESTRO_WHITE
      : variant === 'yellow'
      ? ADMIN_SURFACE_2
      : ADMIN_TEXT_400};
  }
  ${({ tip }) =>
    tip ?
      css`&:hover {
        background: ${ADMIN_SURFACE_4};
        & > * {
          color: ${MAESTRO_WHITE} !important;
          fill: ${MAESTRO_WHITE} !important;
        }
        & svg {
          fill: ${MAESTRO_WHITE} !important;
        }
      }`
      :
      ``
  }
  ${({ size }) =>
    size === 'large' ?
      css`width: 190px`
      :
      css`width: 133px`
  }
`;

export const Description = styled.div<{ size?: string, variant: Variant }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  padding-right: 10px;
  color: ${({ variant }) =>
    variant === 'lighted'
      ? MAESTRO_WHITE
      : variant === 'yellow'
      ? `${ADMIN_SURFACE_1};`
      : MAESTRO_WHITE};
  width: ${({ size }) =>
    size === 'small'
      ? '74px'
      : size === 'large'
      ? '130px'
      : '94px'};
`;

export const Icons = styled.div<{ variant: Variant }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 10px;
  align-items: flex-end;
  & > * {
    margin-right:12px;
    width: 22px;
    display: block;
    position: absolute;
    font-size: 1.6em;
    fill: ${({ variant }) =>
      variant === 'lighted'
        ? MAESTRO_WHITE
        : variant === 'yellow'
        ? ADMIN_SURFACE_1
        : ADMIN_TEXT_300};
  }
  & svg {
    width: 30px;
  }
  & * {
    fill: ${({ variant }) =>
      variant === 'lighted'
        ? MAESTRO_WHITE
        : variant === 'yellow'
        ? ADMIN_SURFACE_1
        : ADMIN_TEXT_300};
  }
`;

export const Value = styled.div<{ variant: Variant }>`
  ${ADMIN_TEXT_BODY_XL_BOLD}
  margin-left: 3px;
  color: ${({ variant }) =>
    variant === 'lighted'
      ? MAESTRO_WHITE
      : variant === 'yellow'
      ? `${ADMIN_SURFACE_1}`
      : MAESTRO_WHITE};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InfoContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'hooks';
import PropTypes from 'prop-types';
import AdminModal from 'components/admin2/ui/AdminModal';
import TextInput from 'components/admin2/TextInput';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import { ACHIEVEMENT_SERVICE_BASE_URL } from 'config';
import axios from 'axios';
import { getPrimaryToken } from 'services/auth';
import AchievementTypeSelector from './AchievementTypeSelector';
import SpecificAchievementEditor from './SpecificAchievementEditor';
import {
  ANSWER_POLL,
  COMPLETE_QUEST,
  ENGAGE_OVERLAY,
  TIME_ON_SITE,
} from './constants';

const propTypes = {
  editingItemId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
};

const defaultProps = {
  editingItemId: null,
};

const CUSTOM_DATA_MAP = {
  [ANSWER_POLL]: {
    descriptionKey: 'ADMIN_DESC_ANSWER_POLL',
    titleKey: 'ADMIN_LABEL_ANSWER_POLL',
  },
  [COMPLETE_QUEST]: {
    descriptionKey: 'ADMIN_DESC_COMPLETE_QUEST',
    titleKey: 'ADMIN_LABEL_COMPLETE_QUEST',
  },
  [ENGAGE_OVERLAY]: {
    descriptionKey: 'ADMIN_DESC_ENGAGE_OVERLAY',
    titleKey: 'ADMIN_LABEL_ENGAGE_OVERLAY',
  },
  [TIME_ON_SITE]: {
    descriptionKey: 'ADMIN_DESC_TIME_ON_SITE',
    injectionComponent: (renderer, setRenderer) => {
      const { valueRequired } = renderer;
      return (
        <TextInput
          labelKey="ADMIN_LABEL_HOURS_ON_SITE"
          onChange={(text) => setRenderer({ ...renderer, valueRequired: Number(text) })}
          value={valueRequired}
        />
      );
    },
    titleKey: 'ADMIN_LABEL_TIME_ON_SITE',
  },
};

const INITIAL_ACHIEVEMENT_STATE = {
  activityFeedText: '',
  alwaysActive: true,
  iconName: '',
  name: '',
  pointsValue: '10',
  timeEnd: Date.now(),
  timeStart: Date.now(),
  type: null,
  valueRequired: 1,
};

const ONE_HOUR_IN_MS = 60 * 60 * 1000;

const formatValueRequired = (renderer) => {
  let formattedRenderer = renderer;
  if (renderer.type === TIME_ON_SITE) {
    // TODO validation handling
    const parsedHours = Number(renderer.valueRequired);
    formattedRenderer = {
      ...renderer,
      valueRequired: parsedHours * ONE_HOUR_IN_MS || ONE_HOUR_IN_MS,
    };
  }
  return formattedRenderer;
};

const handleSave = async (renderer, primaryToken, siteId, onSaveSuccess) => {
  const data = formatValueRequired(renderer);
  const baseCallObj = {
    data,
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
  };

  const editing = renderer._id;
  if (editing) {
    await axios({
      ...baseCallObj,
      method: 'PATCH',
      url: `${ACHIEVEMENT_SERVICE_BASE_URL}/achievements/${renderer._id}`,
    });
  } else {
    await axios({
      ...baseCallObj,
      method: 'POST',
      url: `${ACHIEVEMENT_SERVICE_BASE_URL}/achievements`,
    });
  }
  onSaveSuccess();
};

const parseValueRequired = (renderer) => {
  let parsedRenderer = renderer;
  if (renderer.type === TIME_ON_SITE) {
    const hoursInMs = renderer?.valueRequired;
    const parsedHours = hoursInMs / ONE_HOUR_IN_MS;
    parsedRenderer = {
      ...renderer,
      valueRequired: parsedHours,
    };
  }
  return parsedRenderer;
};

const setEditingAchievement = async ({
  id,
  primaryToken,
  setRenderer,
  setType,
  setShowSpinner,
  siteId,
}) => {
  const { data: achievement } = await axios({
    headers: {
      Authorization: `Bearer ${primaryToken}`,
      'x-maestro-client-id': siteId,
    },
    method: 'GET',
    url: `${ACHIEVEMENT_SERVICE_BASE_URL}/achievements/${id}`,
  });

  setRenderer(parseValueRequired(achievement));
  setType(achievement.type);
  setShowSpinner(false);
};

const RenderSpecificAchievementEditor = ({
  onClose,
  primaryToken,
  renderer,
  setRenderer,
  setType,
  siteId,
  type,
}) => {
  const {
    injectionComponent,
    titleKey,
    descriptionKey,
  } = CUSTOM_DATA_MAP[type];

  const clearData = () => {
    setType(null);
    setRenderer(INITIAL_ACHIEVEMENT_STATE);
    onClose();
  };

  return (
    <SpecificAchievementEditor
      descriptionKey={descriptionKey}
      injectionComponent={injectionComponent}
      onBack={clearData}
      onChange={setRenderer}
      onSave={() => handleSave(renderer, primaryToken, siteId, onClose)}
      renderer={{ ...renderer, siteId }}
      titleKey={titleKey}
    />
  );
};
RenderSpecificAchievementEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
  primaryToken: PropTypes.string.isRequired,
  renderer: PropTypes.shape({}).isRequired,
  setRenderer: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  siteId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const Spinner = styled(LoadingSpinner)`
  width: 400px;
  height: 200px;
`;

const AchievementEditor = (props) => {
  const { onClose, editingItemId, onSave, siteId } = props;

  const primaryToken = useSelector(getPrimaryToken);

  const [typeOfAchievement, setType] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const [renderer, setRenderer] = useState(INITIAL_ACHIEVEMENT_STATE);

  useEffect(() => {
    if (editingItemId) {
      setShowSpinner(true);
      setEditingAchievement({
        id: editingItemId,
        primaryToken,
        setRenderer,
        setShowSpinner,
        setType,
        siteId,
      });
    }
  }, [editingItemId]);

  const onSaveSuccess = () => {
    onSave();
    onClose();
  };

  return (
    <AdminModal fixedHeight fixedWidth maxHeight="90%" onClose={onClose}>
      {showSpinner && <Spinner fade={false} />}
      {!editingItemId && !typeOfAchievement && (
        <AchievementTypeSelector
          onSelect={(type) => {
            setRenderer({
              ...renderer,
              type,
            });
            setType(type);
          }}
        />
      )}
      {typeOfAchievement &&
        RenderSpecificAchievementEditor({
          onClose: onSaveSuccess,
          primaryToken,
          renderer,
          setRenderer,
          setType,
          siteId,
          type: typeOfAchievement,
        })}
    </AdminModal>
  );
};

AchievementEditor.propTypes = propTypes;
AchievementEditor.defaultProps = defaultProps;

export default AchievementEditor;

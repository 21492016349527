import React from 'react';
import {
  StyledPieChart,
  EngagementChartContainer,
  LabelsContainer,
  Separator,
} from './styled';
import Label from './label';
import { ICohorts } from '../types';
import { abbrNumber } from 'components/admin2/Analytics/utils';
import { useSelector } from 'react-redux';
import { getAdminAccentPrimary } from 'services/themes';
import { getAdminAlertWarning } from 'services/themes';

type Props = {
  data: ICohorts;
};

function EngagementImpactChart({ data }: Props) {
  const adminThemeAccentPrimary = useSelector(getAdminAccentPrimary);
  const adminAlertWarning = useSelector(getAdminAlertWarning);

  return (
    <EngagementChartContainer>
      <StyledPieChart
        animationDuration={2000}
        slices={[
          { color: '#7c87fa', value: data.uniquesNonEngaged },
          { color: adminAlertWarning, value: data.uniquesEngaged },
        ]}
      />
      <LabelsContainer>
        <Label
          animationDuration={.4}
          text={'ENGAGED_AUDIENCE'}
          value={data.uniquesEngaged}
          percentage={`${(data.uniquesAll ? abbrNumber((data.uniquesEngaged / data.uniquesAll) * 100) : 0 )}%`}
          color={adminAlertWarning}
        />
        <Separator />
        <Label
          animationDelay={.2}
          animationDuration={.4}
          text={'NON-ENGAGED_AUDIENCE'}
          value={data.uniquesAll - data.uniquesEngaged}
          percentage={`${(data.uniquesAll ? abbrNumber((data.uniquesAll - data.uniquesEngaged) / data.uniquesAll * 100) : 0)}%`}
          color={adminThemeAccentPrimary}
        />
      </LabelsContainer>
    </EngagementChartContainer>
  );
}

export default EngagementImpactChart;

import React from 'react';
import { PasswordGateAccessControlElements } from 'components/objects/Gate/PasswordGate';
import { LoginGateAccessControlElements } from 'components/objects/Gate/LoginGate';
import { AccessCodeGateAccessControlElements } from 'components/objects/Gate/AccessTokenGate';
import { SubscriptionTicketGateAccessControlElements } from 'components/objects/Gate/SubscriptionGate';
import { SetupAccessText, AccessControlElementsContainer } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import IGate, { GateKind } from 'models/IGate';
import ISubscription from 'models/ISubscription';
import { AmazonBenefitGateAccessControlElements } from 'components/objects/Gate/AmazonBenefitGate';

interface IAccessControlElements {
  accessGate: IGate;
  initialPassword: string | null;
  subscriptionWithMoreData: ISubscription[];
}

export default function AccessControlElements({
  accessGate,
  initialPassword,
  subscriptionWithMoreData,
}: IAccessControlElements) {
  const renderAccessControlElements = () => {
    switch (accessGate?.kind) {
      case GateKind.LOGIN: {
        return <LoginGateAccessControlElements />;
      }
      case GateKind.PASSWORD: {
        return (
          <PasswordGateAccessControlElements
            passwordValue={initialPassword}
          />
        );
      }
      case GateKind.ACCESSCODE: {
        return <AccessCodeGateAccessControlElements isTransparent={true} />;
      }
      case GateKind.SUBSCRIPTION: {
        const { bundles, hiddenEntitlements, hiddenBundles } = accessGate.gate!;
        return (
          <SubscriptionTicketGateAccessControlElements
            bundles={bundles || []}
            subscriptions={subscriptionWithMoreData}
            hiddenEntitlements={hiddenEntitlements || []}
            hiddenBundles={hiddenBundles || []}
          />
        );
      }
      case GateKind.AMAZONBENEFIT: {
        return <AmazonBenefitGateAccessControlElements loadedFromAdmin={true} />;
      }
      default: {
        return (
          <SetupAccessText>
            <TranslatedText stringKey="ACCESS_GATE_SET_UP_ACCESS_BUTTON_TITLE" />
          </SetupAccessText>
        );
      }
    }
  };

  return (
    <AccessControlElementsContainer>
      {renderAccessControlElements()}
    </AccessControlElementsContainer>
  );
}

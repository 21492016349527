import React from 'react';
import { IRangeParams } from '../../types';
import VideosTable from './VideosTable';

const Video = ({
  rangeEnd,
  rangeStart,
}: IRangeParams) => {
  return (
      <VideosTable
        rangeEnd={rangeEnd}
        rangeStart={rangeStart}
      />
  );
};

export default Video;

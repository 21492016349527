import React from 'react';
import { useSelector } from 'hooks';
import PropTypes from 'prop-types';

import { PAGE_SERVICE_BASE_URL, PAGE_SERVICE_V3_BASE_URL } from 'config';
import LibraryModalChannels from 'components/admin2/LibraryModalChannels';
import { getSiteId } from 'services/app/selectors';
import { getActiveSiteFeatures } from 'services/app/selectors/common';
import { Feature } from 'services/feature-gate';
import useLibraryColumns from './columns';

const propTypes = {
  disableEditing: PropTypes.bool.isRequired,
  disableSelecting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func,
};
const defaultProps = {
  onSelectItem: () => null,
};

const ChannelLibraryModal = (props) => {
  const siteId = useSelector(getSiteId);
  const features = useSelector(getActiveSiteFeatures);

  const channelModalConfig = {
    columns: useLibraryColumns().CHANNELS,
    endpoint: `${features[Feature.PAGES_V3] ?
      PAGE_SERVICE_V3_BASE_URL :
      PAGE_SERVICE_BASE_URL}/channel/channels-landings`,
    headerTextKey: 'ADMIN_LABEL_PAGE_CHANNEL_LIBRARY_HELP',
  };

  const {
    disableSelecting,
    disableEditing,
    onClose,
    onSelectItem,
  } = props;

  return (
    <LibraryModalChannels
      columns={channelModalConfig.columns}
      disableEditing={disableEditing}
      disableSelecting={disableSelecting}
      fetchObject={{
        endpoint: channelModalConfig.endpoint,
        headers: {
          'x-maestro-client-id': siteId,
        },
        method: 'GET',
      }}
      helperText={channelModalConfig.headerTextKey}
      onClose={onClose}
      onSelectItem={onSelectItem}
      searchPlaceholderKey="PLACEHOLDER_SEARCH"
      titleKey="ADMIN_LABEL_CHANNEL_LIBRARY"
    />
  );
};

ChannelLibraryModal.propTypes = propTypes;
ChannelLibraryModal.defaultProps = defaultProps;
export default ChannelLibraryModal;

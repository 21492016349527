import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TextInput from 'components/admin2/TextInput';
import Dropdown from 'components/admin2/ui/Dropdown';
import AdminSpinner from 'components/admin2/AdminSpinner';
import ProductCard from 'components/admin2/ui/ProductCard';
import { getShopifyCatalogs, isProductLoading } from 'services/shopify/selectors';
import useSelector from 'hooks/use-selector';

const Container = styled.div``;

const ProductSection = styled.div`
  padding: 16px 0;
`;

const ProductPreview = styled(ProductCard)`
  margin-top: 10px;
`;

const ShopifyOverlayEditor = (props) => {
  const { renderer, onChange } = props;
  const { product, variant } = renderer;
  const { products, variants, images } = useSelector(getShopifyCatalogs);
  const productsLoading = useSelector(isProductLoading);
  const [variantTypes, setVariantTypes] = useState([]);
  const [imageSelected, setimageSelected] = useState();
  const [titleSelected, setTitleSelected] = useState('NA');
  const [priceSelected, setPriceSelected] = useState('');

  const updatePanel = useCallback(key => val => {
    const numberOnly = /^\d*$/;
    if (key === 'duration' && !numberOnly.test(val)) {
      return;
    }
    onChange({
      ...renderer,
      [key]: val,
    });
  }, [renderer, onChange]);

  useEffect(() => {
    const { shopInfo } = props;
    if (product) {
      const variantsX = variants.find((item) => item.productId === product.value);
      setVariantTypes(variantsX.variants);

      const image = images.find((item) => item.productId === product.value);
      onChange({ ...renderer, image });
      setimageSelected(image);
      const { title } = shopInfo.products.find((item) => item.id === product.value);
      setTitleSelected(title);
    }
  }, [product]);

  useEffect(() => {
    if (variant) {
      setPriceSelected(variant.price.amount);
    }
  }, [variant]);

  return (
    <Container>
      { productsLoading && !products.length ? <AdminSpinner /> :
        (
          <ProductSection>
            <Dropdown
              labelKey="ADMIN_LABEL_SHOPIFY_PRODUCT"
              name="ADMIN_LABEL_SHOPIFY_PRODUCT"
              onChange={updatePanel('product')}
              options={products}
              placeholder="Select a product..."
              value={product}
            />
            <Dropdown
              labelKey="ADMIN_LABEL_SHOPIFY_VARIANT"
              name="ADMIN_LABEL_SHOPIFY_VARIANT"
              onChange={updatePanel('variant')}
              options={
                variantTypes &&
                variantTypes.map((vrt) => {
                  return { label: vrt.title, price: vrt.price.amount, value: vrt.id };
                })
              }
              placeholder="Select a variant..."
              value={variant}
            />
            <ProductPreview
              image={imageSelected?.src}
              price={priceSelected}
              title={titleSelected}
              variant={variant?.label}
            />
            <TextInput labelKey="ADMIN_LABEL_OVERLAY_DURATION" onChange={updatePanel('duration')} value={renderer.duration || ''} />
          </ProductSection>
        )}
    </Container>
  );
};

ShopifyOverlayEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  shopInfo: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default ShopifyOverlayEditor;

import React from 'react';
import { CardContainer, Value, Description, Icons, InfoContainer } from './styles';
import Icon from 'components/ui/Icon';
import TranslatedText from 'components/i18n/TranslatedText';

export type Variant = 'default' | 'lighted' | 'yellow' | undefined;

type Props = {
  description: string;
  icons?: string[];
  setScrollState: (state: boolean) => void;
  size?: string;
  tip?: boolean;
  value: string | number;
  variant?: Variant;
};

const MetricCard = ({ description, value, variant, icons, size, tip, setScrollState }: Props) => {
  const handleScroll = () => {
    setScrollState(false);
  };

  return (
    <CardContainer tip={tip} variant={variant} size={size} onMouseEnter={handleScroll}>
      {tip && (
      <InfoContainer><Icon name={'analyticsInfo'} /></InfoContainer>
      )}
      <Description variant={variant} size={size}>
        <TranslatedText stringKey={description} />
      </Description>
      {icons && (
        <Icons variant={variant}>
          {icons.map((iconName, index) => <Icon key={index} name={iconName} />)}
        </Icons>
      )}
      <Value variant={variant}>{value}</Value>
    </CardContainer>
  );
};

export default MetricCard;

// TODO flesh out
import React from 'react';
import PropTypes from 'prop-types';
import ActionLabel from 'components/admin2/ui/ActionLabel';
import Row from 'components/admin2/ui/Library/Row';
import RealtimeDocument from 'components/core/RealtimeDocument';

export default class FancyButtonView extends React.Component {
  static propTypes = {
    adminText200: PropTypes.string.isRequired,
    buttonPlaceholderKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    collection: PropTypes.string,
    docToButtonProps: PropTypes.func.isRequired,
    draggable: PropTypes.bool,
    icon: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
    collection: 'objects',
    draggable: false,
    icon: undefined,
    onEdit: undefined,
    onRemove: undefined,
    value: '',
  };

  handleRemove = (e) => {
    e.stopPropagation();
    const { onRemove } = this.props;
    onRemove();
  };

  handleEdit = (e) => {
    if (e) {
      e.stopPropagation();
    }
    const { onEdit } = this.props;
    onEdit();
  };

  render() {
    const {
      className,
      collection,
      draggable,
      icon,
      onAdd,
      onEdit,
      onRemove,
      value,
      buttonPlaceholderKey,
      docToButtonProps,
      adminText200,
    } = this.props;

    return (
      <RealtimeDocument collection={collection} id={value}>
        {
          (doc = {}) => {
            const {
              fallbackImageIcon,
              imageSrc,
              isCatalogItem,
              showImage,
              text,
              type,
            } = docToButtonProps(doc);

            return draggable ? (
              <Row
                className={className}
                draggable
                fallbackImageIcon={fallbackImageIcon}
                icon={icon}
                imageSrc={imageSrc}
                isCatalogItem={isCatalogItem}
                name={text}
                nameKey={text ? undefined : buttonPlaceholderKey}
                noDefaultIcon
                onAdd={value ? undefined : onAdd}
                onEdit={value ? onEdit && this.handleEdit : undefined}
                onRemove={value ? onRemove && this.handleRemove : undefined}
                type={type}
                verticalPositionActions={!!(imageSrc || fallbackImageIcon)}
              />
            ) : (
              <ActionLabel
                className={className}
                image={imageSrc}
                innerIcon={icon}
                innerLabel={text}
                innerLabelColor={adminText200}
                innerLabelKey={text ? undefined : buttonPlaceholderKey}
                isLarge={showImage}
                onEdit={value ? onEdit && this.handleEdit : undefined}
                onRemove={value ? onRemove && this.handleRemove : undefined}
                onSelect={value ? undefined : onAdd}
              />
            );
          }
        }
      </RealtimeDocument>
    );
  }
}

import styled, { css } from 'styled-components';
import Checkbox from 'components/admin2/ui/Checkbox';
import Icon from 'components/ui/Icon';
import { CloseButton } from 'components/admin2/ui/Button';
import {
  ADMIN_SURFACE_2,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_TEXT_200,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  TOOLBAR_HEIGHT_PX,
} from 'style/constants';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import UploadZone from 'components/admin2/UploadZone';
import ColorPicker from 'components/admin2/ColorPicker';
import { ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const BACKGROUND_TOP_POSITION = 15;
export const BACKGROUND_BOTTOM_POSITION = 15;

export const BackgroundControlContainer = styled.div`
 ${ADMIN_SCROLL_BAR};
  position: absolute;
  right: 30px;
  top: ${BACKGROUND_TOP_POSITION + TOOLBAR_HEIGHT_PX}px;
  background: ${ADMIN_SURFACE_4};
  border-radius: 10px;
  width: 375px;
  padding: 30px 30px 20px;
  z-index: 9999999;
  max-height:
    calc(100vh - ${TOOLBAR_HEIGHT_PX + BACKGROUND_TOP_POSITION +  BACKGROUND_BOTTOM_POSITION}px);
  overflow-y: auto;
  & > div:nth-child(n+2):nth-child(-n+4) {
    border-bottom: 1px solid ${ADMIN_SURFACE_5};
  }
`;

export const Title = styled.h1`
  ${ADMIN_TEXT_TITLE_M}
  margin: 0px;
`;

export const Label = styled.p`
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  color: ${ADMIN_TEXT_200};
  margin: 15px auto 10px;
`;

export const ColorMenuContainer = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 15px;
`;

export const FixedHeightUploadZone = styled(UploadZone).attrs({
  height: 150,
})``;

export const InputContainer = styled.div<{isFocus?: boolean}>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  ${({ isFocus }) => isFocus && css`
    & > * {
      border: 1px solid ${ADMIN_ACCENT_PRIMARY} !important;
    }
  `}
`;

export const IconInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: ${ADMIN_SURFACE_5};
  border-radius: 5px;
  margin-right: 5px;
`;

export const Input = styled.input`
  width: 107.5px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 15px;
  background: ${ADMIN_SURFACE_2};
  border-radius: 5px;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  color: white;
  border: transparent;
`;

export const GradientMaskContainer = styled.div`
  padding: auto 8px;
`;

export const StyledIcon = styled(Icon)`
    & svg {
    display: block;
    opacity: 1;
    width: 18px;
    height: 18px;
  }
`;

export const StyledColorPicker = styled(ColorPicker).attrs((props) => ({
  backgroundColor: ADMIN_SURFACE_3(props),
}))`
`;

export const ColorSectionWrapper = styled.div``;

export const StyledCloseButton = styled(CloseButton).attrs(() => ({
  containerCss: `
    position: absolute;
    top: 10px;
    right: 10px;
    height: 21px;
    width: 21px;
  `,
}))`
  background: rgba(0, 0, 0, 0.3);
`;

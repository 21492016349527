import styled, { css } from 'styled-components';
import {
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
} from 'style/constants';
import { TextBlock } from '../ui/Text';
import PageNavigation from 'components/ui/PageNavigation';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import DefaultDivider from 'components/admin2/ui/Divider';

export const StyledTextBlock = styled(TextBlock)`
  margin-top: 15px;
  margin-bottom: 30px;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
`;

export const ThemePlaceholder = styled.div`
  height: 130px;
`;

export const Divider = styled(DefaultDivider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ThemesCollectionWrapper = styled.div<{ rows?: number; }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${({ rows }) => rows && css`grid-template-rows: repeat(${rows}, 1fr);`};
  ${({ rows }) => rows && css`min-height: ${rows * 150}px;`};
  column-gap: 10px;
  row-gap: 20px;
`;

export const Title = styled.label`
  ${ADMIN_TEXT_BODY_S_BOLD};
  color: ${ADMIN_TEXT_100};
`;

export const SectionTitle = styled.label`
  ${ADMIN_TEXT_BODY_S_BOLD};
  color: ${ADMIN_TEXT_100};
  margin-bottom: 15px;
`;

export const SubSectionTitle = styled.label`
${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const StyledPageNavigation = styled(PageNavigation)`
  margin-top: 30px;
`;

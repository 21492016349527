import React, { useEffect, useMemo, useState } from 'react';
import { CustomPicker, CustomPickerProps, ColorResult } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
import TranslatedText from 'components/i18n/TranslatedText';
import useColorPickerPosition from './use-color-picker-position';
import {
  applyOpacity,
  calculateOpacityByRGBA,
  fromRGBToHex,
  fromRGBAToHex,
  isHexColor,
  isRGB,
  produceHex,
  removeAlpha,
  isRGBAColor,
} from './utils';
import {
  ColorTitleText,
  ColorSubtitleText,
  ColorInfoLabel,
  ColorInfoWrapper,
  ColorPaletteWrapper,
  ColorPickerWrapper,
  HueContainer,
  HuePointer,
  RelativeContainer,
  SaturationContainer,
  SaturationPointer,
  StyledCloseButton,
  StyledInput,
  QuickAccessColorsWrapper,
} from './styles';
import { MAESTRO_WHITE } from 'style/constants';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';
import ColorOption from 'components/ui/ColorOption';

export interface ColorPickerProps extends CustomPickerProps<any> {
  backgroundColor?: string;
  isOpen: boolean;
  onClose: () => void;
  onResult: (hex: string) => void;
  parentMargin?: {
    left: number;
    top: number;
  };
  parentRef: React.RefObject<any>;
  quickAccessColors?: string[];
  titleKey: TI18nKey;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  titleKey,
  backgroundColor,
  color,
  parentRef,
  parentMargin,
  isOpen,
  onResult,
  onClose,
  quickAccessColors,
  onChange,
  ...rest
}) => {
  const rgba = useMemo(() => {
    // If color is not defined, default value is coming as hsl
    if (!color || typeof color !== 'string') {
      return MAESTRO_WHITE;
    }
    if (isRGB((color as string))) {
      return fromRGBToHex(color as string);
    }
    if (isRGBAColor(color as string)) {
      return fromRGBAToHex(color as string);
    }
    return color?.toString();
  }, [color]);
  const [opacity, setOpacity] = useState(calculateOpacityByRGBA(rgba) || 100);
  const [localColor, setLocalColor] = useState(rgba);
  const position = useColorPickerPosition({
    isOpen,
    parentRef,
    parentMargin,
  });

  useEffect(() => {
    setLocalColor(produceHex(rgba));
  }, [rgba]);

  useEffect(() => {
    if (!rgba) {
      return;
    }
    const target = removeAlpha(produceHex(rgba));
    const newColor = applyOpacity(target, opacity);
    onResult(newColor);
  }, [opacity]);

  const handleChangeOpacity = (value: string | number) => {
    const parsedValue = value.toString();
    const containsPercentage = parsedValue.includes('%');
    if (!value) {
      value = 0;
    } else if (containsPercentage) {
      value = parsedValue.replace('%', '');
    } else if (parsedValue.length > 1) {
      value = parsedValue.slice(0, -1);
    }

    const newOpacityValue = Number(value);
    if (Number.isNaN(newOpacityValue)) {
      return;
    }

    const result = Math.min(100, Number(newOpacityValue));
    setOpacity(result);
  };

  const handleChangePointer = (
    result: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange?.(result, event);
    if (opacity < 100) {
      setOpacity(100);
    }
  };

  const handleBlur = () => {
    const result = isHexColor(localColor) ? localColor : MAESTRO_WHITE;
    setLocalColor(result);
    onResult(result);
    if (opacity < 100) {
      setOpacity(100);
    }
  };

  const handleBlurOpacity = () => {
    if (opacity < 10) {
      setOpacity(10);
    }
  };

  const handleClose = () => {
    handleBlurOpacity();
    onClose();
  };

  const rgb = useMemo(() => removeAlpha(localColor), [localColor]);

  const selectQuickAccessColor = (quickAccessColor: string) => () => {
    setLocalColor(quickAccessColor);
    onResult(quickAccessColor);
  };

  return (
    <ColorPickerWrapper position={position} backgroundColor={backgroundColor} className="color-picker-wrapper">
      <RelativeContainer>
        <StyledCloseButton onClick={handleClose} />
        <TranslatedText component={ColorTitleText} stringKey={titleKey} />
        {
          quickAccessColors && quickAccessColors.length > 0 && (
            <>
              <TranslatedText
                component={ColorSubtitleText}
                stringKey="THEME_COLORS"
              />
              <QuickAccessColorsWrapper>
                {
                  quickAccessColors.map((quickAccessColor, index) => (
                    <ColorOption
                      selected={quickAccessColor === localColor}
                      key={index}
                      color={quickAccessColor}
                      onClick={selectQuickAccessColor(quickAccessColor)}
                    />
                  ))
                }
              </QuickAccessColorsWrapper>
            </>
          )
        }
        <TranslatedText
          component={ColorSubtitleText}
          stringKey="ADMIN_LABEL_COLOR_PICKER_CHOOSE_COLOR"
        />
        <ColorPaletteWrapper>
          <SaturationContainer>
            <Saturation
              pointer={SaturationPointer}
              {...rest}
              color={rgb}
              onChange={handleChangePointer}
            />
          </SaturationContainer>
          <HueContainer>
            <Hue
              pointer={HuePointer}
              {...rest}
              color={rgb}
              onChange={handleChangePointer}
            />
          </HueContainer>
        </ColorPaletteWrapper>
        <ColorInfoWrapper>
          <TranslatedText
            component={ColorInfoLabel}
            stringKey="ADMIN_LABEL_COLOR_PICKER_COLOR"
          />
          <TranslatedText
            component={ColorInfoLabel}
            stringKey="ADMIN_LABEL_COLOR_PICKER_OPACITY"
          />
          <StyledInput
            inputId="color-hex"
            type="text"
            placeholderText={MAESTRO_WHITE}
            value={rgb}
            onChange={setLocalColor}
            onBlur={handleBlur}
          />
          <StyledInput
            inputId="color-opacity"
            type="text"
            value={`${opacity}%`}
            placeholderText="100%"
            onChange={handleChangeOpacity}
            onBlur={handleBlurOpacity}
            inputWidth={80}
          />
        </ColorInfoWrapper>
      </RelativeContainer>
    </ColorPickerWrapper>
  );
};

// @ts-ignore
export default CustomPicker<ColorPickerProps>(ColorPicker);

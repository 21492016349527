import { ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';
import styled from 'styled-components';
import { createAnimationComponent } from '../../../animation';

export const LabelContainer = createAnimationComponent({
  component: styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    & > * {
      margin: 2px 0;
    }
  `,
  keyframes: `
  from{
    opacity: 0;
    transform: translateY(-20px)
  }
  to{
    opacity:1;
    transform: translateY(0px)
  }
`,
  easing: 'cubic-bezier(0.16, 1, 0.3, 1)',

});
export const Text = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  color: ${ADMIN_TEXT_200};
`;

export const Bullet = styled.div<{ color: string }>`
  &::after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    border-radius: 50%;
    right: 218px;
    top: 15px;
    background: ${({ color }) => color};
  }
`;

export const Value = styled.div`
  ${ADMIN_TEXT_TITLE_M}
  color: ${ADMIN_TEXT_200};
  margin-top: 10px !important;
`;

export const Percentage = styled.span<{color: string}>`
  ${ADMIN_TEXT_TITLE_M}
  color: ${({ color }) => color};
  margin-right: 7px;
`;

import React, { useMemo, useRef } from 'react';
import ColorPicker from 'components/admin2/ColorPicker';
import { ColorCircle, ColorInputWrapper, ColorName, ColorNameWrapper, InfoIcon, SurfaceColorInfoIcon } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import type { TranslationKey } from 'hooks/use-translation';

interface IDesignColorProps {
  color: string;
  colorButtonDataTestId: string;
  labelKey: keyof typeof TOOLTIP_MAP;
  onChange: (color: string) => void;
  tooltipKey: TranslationKey;
}

const TOOLTIP_MAP = {
  'ADMIN_DESIGN_TAB_ACCENT_COLOR': InfoIcon,
  'ADMIN_DESIGN_TAB_HIGHLIGHT_COLOR': InfoIcon,
  'ADMIN_DESIGN_TAB_SURFACE_TONE': SurfaceColorInfoIcon,
};

const DesignColor: React.FC<IDesignColorProps> = ({
  color,
  tooltipKey,
  labelKey,
  onChange,
  colorButtonDataTestId,
}) => {
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const ref = useRef(null);

  const handleOpenColorPicker = () => {
    setColorPickerOpen(true);
  };

  const handleCloseColorPicker = () => {
    setColorPickerOpen(false);
  };

  const TooltipIcon = useMemo(() => {
    return TOOLTIP_MAP[labelKey] || InfoIcon;
  }, [labelKey]);

  return (
    <ColorInputWrapper>
      <ColorNameWrapper>
        <TranslatedText component={ColorName} stringKey={labelKey} />
        <TooltipIcon tooltipKey={tooltipKey} />
      </ColorNameWrapper>
      <ColorCircle
        ref={ref}
        color={color}
        onClick={handleOpenColorPicker}
        data-testid={colorButtonDataTestId}
      />
      <ColorPicker
        titleKey={labelKey}
        parentMargin={{
          top: 8,
          left: 15,
        }}
        color={color}
        isOpen={colorPickerOpen}
        onClose={handleCloseColorPicker}
        onResult={onChange}
        parentRef={ref}
      />
    </ColorInputWrapper>
  );
};

export default DesignColor;

import React from 'react';
import Table from './Table';
import { Container, Section } from './styles';
import { IEngagementBreakdown } from './hooks';

const EngagmentBreakdown = ({ panels, overlays }: IEngagementBreakdown) => {

  return (
    <Container>
      <Section>
        {!!panels.length && (
        <Table
          data={panels}
          stringKey="ADMIN_LABEL_TOP_THREE_PANELS"
        />
        )}
        {!!overlays.length && (
        <Table
          data={overlays}
          stringKey="ADMIN_LABEL_TOP_THREE_OVERLAYS"
        />
        )}
      </Section>
    </Container>
  );
};

export default EngagmentBreakdown;

import { TimePeriod } from 'components/admin-bridge/EarningsDashboard/IEarnings';
import { useComponentVisible } from 'hooks/use-component-visible';
import { Moment } from 'moment';
import React, { useState } from 'react';
import DateRangeInput from './DateRangeInput';
import DayPicker from './Pickers/DayPicker';
import MonthPicker from './Pickers/MonthPicker';
import QuarterPicker from './Pickers/QuarterPicker';
import WeekPicker from './Pickers/WeekPicker';
import YearPicker from './Pickers/YearPicker';

export interface DateRangeProps {
  isActive?: boolean;
  onRangeChange?: (startDate: Moment, endDate: Moment) => void;
  onTitleChange?: (title: string) => void;
  setActive?: React.Dispatch<React.SetStateAction<boolean>>
}

interface DateRangePickerProps extends DateRangeProps {
  isDisabled?: boolean;
  type: TimePeriod;
}

function DateRangePicker({ isDisabled, type, onRangeChange, onTitleChange }: DateRangePickerProps) {
  const { ref, isActive, setActive } = useComponentVisible(false);
  const [title, setTitle] = useState(`Select ${type}`);

  const handleClick = () => {
    setActive(true);
  };

  const handleRangeChange = (startDate: Moment, endDate: Moment) => {
    onRangeChange?.(startDate, endDate);
  };

  const handleChangeTitle = (value: string) => {
    setTitle(value);
    onTitleChange?.(value);
  };

  const renderComponentByType = () => {
    const pickerProps: DateRangeProps = {
      isActive,
      onRangeChange: handleRangeChange,
      onTitleChange: handleChangeTitle,
      setActive,
    };

    switch (type) {
      case TimePeriod.Day:
        return <DayPicker {...pickerProps} />;
      case TimePeriod.Week:
        return <WeekPicker {...pickerProps} />;
      case TimePeriod.Month:
        return <MonthPicker {...pickerProps} />;
      case TimePeriod.Quarter:
        return <QuarterPicker {...pickerProps} />;
      case TimePeriod.Year:
        return <YearPicker {...pickerProps} />;
      default:
        return null;
    }
  };

  if (type === TimePeriod.Year) {
    return <YearPicker onRangeChange={onRangeChange} onTitleChange={onTitleChange} />;
  }

  if (type === TimePeriod.AllTime) {
    return (
      <DateRangeInput value={'Select'} isDisabled={true} isActive={false} onClick={handleClick} ref={ref}>
        {renderComponentByType()}
      </DateRangeInput>
    );
  }

  return (
    <DateRangeInput value={title} isDisabled={isDisabled} isActive={isActive} onClick={handleClick} ref={ref}>
      {renderComponentByType()}
    </DateRangeInput>
  );
}

export default DateRangePicker;

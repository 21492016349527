import { useSelector } from 'react-redux';
import { INSIGHTS_SERVICE_BASE_URL, SITE_ID } from 'config';
import { getPrimaryToken } from 'services/auth/selectors';
import useAjax from 'hooks/use-ajax';
import { IAudience, IRevenue, IWatchBehavior } from '../toplineMetrics/types';

const useToplineMetrics = (startTime: number, endTime: number) => {
  const token = useSelector(getPrimaryToken);
  const { data, error, loaded } = useAjax<{
    audience: IAudience;
    broadcastTimeTotalHours: number;
    revenue: IRevenue;
    watchBehavior: IWatchBehavior;
  }>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: {
      startTime,
      endTime,
    },
    url: startTime && endTime ? `${INSIGHTS_SERVICE_BASE_URL}/sites/topline-metrics` : null,
  });
  return { data, error, loaded };
};

export default useToplineMetrics;

import React from 'react';
import { useSelector } from 'react-redux';
import { StyledHeader } from '../styles';
import { hasHeader } from 'services/app/selectors';
import useSiteLayout from 'hooks/use-site-layout';
import { getCalculatedVideoWidth } from 'services/device/selectors/common';
import useEditGate from 'hooks/use-edit-gate';
import { ILogoData, INavigationLegacyFromGate } from 'models/IGate';

const AccessHeader = () => {
  const { editingGateInformation, setGateNavigation, setGateLogo } = useEditGate();
  const showHeader = useSelector(hasHeader);
  const videoWidth = useSelector(getCalculatedVideoWidth);

  const { showMobileLayout } = useSiteLayout();

  const localGateLogoData = {
    logoLink: editingGateInformation?.logoLink,
    logoTitle: editingGateInformation?.logoTitle,
    logoUrl: editingGateInformation?.logoUrl,
  };

  const handleSaveGateNavigation = (data: INavigationLegacyFromGate) => {
    setGateNavigation(data);
  };

  const handleSaveGateLogo = (logo: ILogoData) => {
    setGateLogo(logo);
  };

  return (
    <StyledHeader
      hasHeader={showHeader && !showMobileLayout}
      width={videoWidth}
      localGateLogoData={localGateLogoData}
      saveGateNavigation={handleSaveGateNavigation}
      saveGateLogo={handleSaveGateLogo}
    />
  );
};

export default AccessHeader;

import styled, { css } from 'styled-components';
import withTooltip, { withTooltipProps } from 'components/core/withTooltip';
import MetricCard from './metric-card';
import { ADMIN_TEXT_200, MAESTRO_BLACK } from 'style/constants';
import { ADMIN_TEXT_BODY_M_MEDIUM } from 'style/design-system/textStyles';

export const ToplineMetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin: 12px 0;
  }
`;
export const MetricName = styled.div`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color:white;
  margin: 10px 0 0 0;
`;

export const MetricsRow = styled.div`
  display: flex;
  flex-direction: row;
  height:124px;
  & > * {
    height:100%;
    margin-right: 10px;
  }
`;

const TOOLTIP_STYLES = {
  admin: true,
  tooltipCss: css`
    text-align: left;
    left: 61%;
    width: 239px;
    ::after {
      left: 9% !important;
    }
  `,
  tooltipTextColor: css`${ADMIN_TEXT_200}`,
  tooltipBackground: MAESTRO_BLACK,
};

const LARGE_STYLES = {
  tooltipCss: css`
  text-align: left;
  left: 69%;
  width: 239px;
  ::after {
    left: 9% !important;
  }
`,
};

export const UniqueVisitors = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_UNIQUE_VISITOR',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const UniqueViewers = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_UNIQUE_VIEWERS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const LoggedInUsers = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_LOGGED_IN_USERS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const AnonymousDevices = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_ANONYMOUS_DEVICES',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const PeakConcurrents = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_PEAK_CONCURRENTS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const AverageConcurrents = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_AVERAGE_CONCURRENTS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const ViewerHours = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_VIEWER_HOURS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const LiveHours = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_LIVE_HOURS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const OnDemandHours = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_ON_DEMAND_HOURS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const AverageWatchTime = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_AVERAGE_WATCH_TIME',
  ...TOOLTIP_STYLES,
  ...LARGE_STYLES,
} as withTooltipProps);

export const Sessions = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_SESSIONS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const BroadcastHours = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_BROADCAST_HOURS',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const GrossEarnings = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_GROSS_REVENUE',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const NetEarnings = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_NET_REVENUE',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const MonetizedUniques = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_MONETIZED_UNIQUES',
  ...TOOLTIP_STYLES,
} as withTooltipProps);

export const AEPU = withTooltip(MetricCard, {
  tooltipKey: 'ANALYTICS_TIP_AEPU',
  ...TOOLTIP_STYLES,
  ...LARGE_STYLES,
} as withTooltipProps);

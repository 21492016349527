import styled from 'styled-components';
import { ADMIN_SCROLL_BAR } from 'style/mixins';
import { ADMIN_SURFACE_1, ADMIN_SURFACE_5 } from 'style/constants';

export const AnalyticsContainer = styled.div`
  ${ADMIN_SCROLL_BAR}
  width: 100%;
  height: 100%;
  background: ${ADMIN_SURFACE_1};
  padding: 36px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const LoadingWrapper = styled.div`
  height: 427px;
`;

export const Exterior = styled.div`
  z-index: 100000;
  height: 100%;
  width: 100%;
  overflow-x: auto;
`;

export const Interior = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const SectionSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${ADMIN_SURFACE_5};
  padding-top: 44px;
  margin-bottom: 20px;
`;

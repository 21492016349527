import React from 'react';
import { Container } from './styles';
import { getFormatedDate } from '../../utils';

interface IDateAndTime {
  className?: string;
  isFaded?: boolean;
  timeStamp: number | null;
}

export default function DateAndTime({
  className,
  timeStamp,
  isFaded,
}: IDateAndTime) {
  const formatedDate = getFormatedDate(timeStamp);
  return (
    <Container className={className} isFaded={isFaded}>
      {formatedDate}
    </Container>
  );
}

import withTooltip from 'components/core/withTooltip';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_200 } from 'style/constants';
import styled from 'styled-components';
import Label from 'components/admin2/ui/Label';

export const StyledLabel = styled(Label).attrs(() => ({
  color: ADMIN_TEXT_200,
}))``;

export const DesignFontWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
`;

export const LabelWrapper = styled.div`
  display: flex;
`;

export const InfoIcon = withTooltip(
  styled(Icon).attrs((props) => ({
    name: 'info',
    color: ADMIN_TEXT_200(props),
  }))`
    margin-left: 10px;
    color: ${({ color }) => color};
    & > svg {
      width: 12px;
      height: 12px;
    }
  `,
  {
    tooltipArrowCss: 'left: 5%;',
    tooltipCss: 'margin-left: 379px; margin-bottom: -10px;',
    admin: true,
  },
);

export const BodyFontInfoIcon = withTooltip(
  styled(Icon).attrs((props) => ({
    name: 'info',
    color: ADMIN_TEXT_200(props),
  }))`
    margin-left: 10px;
    color: ${({ color }) => color};
    & > svg {
      width: 12px;
      height: 12px;
    }
  `,
  {
    tooltipArrowCss: 'left: 5%;',
    tooltipCss: 'margin-left: 442px; margin-bottom: -10px;',
    admin: true,
  },
);


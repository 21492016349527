import React from 'react';
import PropTypes from 'prop-types';
import RendererEditor from 'components/admin2/RendererEditor';
import styled from 'styled-components';

const messageCtaField = {
  fieldName: 'ctaRenderer',
  labelKey: 'ADMIN_LABEL_CTA',
  type: 'switch',
  typeOptions: {
    optional: true,
    variantField: 'overlayCtaType',
    variants: [
      {
        id: 'open_link',
        labelKey: 'ADMIN_LABEL_CTA_OPEN_LINK',
        properties: [
          {
            fieldName: 'linkText',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
          },
          {
            fieldName: 'linkUrl',
            labelKey: 'ADMIN_LABEL_URL',
            type: 'string',
          },
        ],
      },
      {
        id: 'show_panel',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_PANEL',
        properties: [
          {
            fieldName: 'text',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
          },
          {
            fieldName: 'panelId',
            labelKey: 'ADMIN_LABEL_PANEL',
            type: 'panelId',
          },
        ],
      },
      {
        id: 'play_video',
        labelKey: 'ADMIN_LABEL_CTA_PLAY_VIDEO',
        properties: [
          {
            fieldName: 'text',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
          },
          {
            fieldName: 'videoId',
            labelKey: 'ADMIN_LABEL_VIDEO',
            type: 'videoId',
          },
        ],
      },
      {
        id: 'show_person',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_PERSON',
        properties: [
          {
            fieldName: 'text',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
          },
          {
            fieldName: 'personId',
            labelKey: 'ADMIN_LABEL_PERSON',
            type: 'personId',
          },
        ],
      },
      {
        id: 'show_group',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_GROUP',
        properties: [
          {
            fieldName: 'text',
            labelKey: 'ADMIN_LABEL_TEXT',
            type: 'string',
          },
          {
            fieldName: 'groupId',
            labelKey: 'ADMIN_LABEL_GROUP',
            type: 'groupId',
          },
        ],
      },
      {
        id: 'facebook_share',
        labelKey: 'ADMIN_LABEL_CTA_FACEBOOK_SHARE',
        properties: [],
      },
      {
        id: 'show_player_stats',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_PLAYER_STATS',
        properties: [
          {
            fieldName: 'playerId',
            labelKey: 'ADMIN_LABEL_PLAYER_ID',
            type: 'firebaseKey',
          },
        ],
      },
      {
        id: 'go_to_channel',
        labelKey: 'ACTION_GO_TO_CHANNEL',
        properties: [
          {
            fieldName: 'channelSlug',
            labelKey: 'ADMIN_LABEL_CTA_TARGET_CHANNEL',
            type: 'pageSlug',
          },
        ],
      },
      {
        id: 'login',
        labelKey: 'ACTION_LOG_IN',
        properties: [],
      },
      {
        id: 'tweet',
        labelKey: 'ADMIN_LABEL_CTA_TWEET',
        properties: [
          {
            fieldName: 'tweetMessage',
            labelKey: 'ADMIN_LABEL_CTA_TWEET_MESSAGE',
            type: 'string',
          },
        ],
      },
    ],
  },
};

const lowerThirdCtaField = {
  fieldName: 'ctaRenderer',
  labelKey: 'ADMIN_LABEL_CTA',
  type: 'switch',
  typeOptions: {
    optional: true,
    variantField: 'overlayCtaType',
    variants: [
      {
        id: 'open_link',
        labelKey: 'ADMIN_LABEL_CTA_OPEN_LINK',
        properties: [
          {
            fieldName: 'linkUrl',
            labelKey: 'ADMIN_LABEL_URL',
            type: 'string',
          },
        ],
      },
      {
        id: 'show_panel',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_PANEL',
        properties: [
          {
            fieldName: 'panelId',
            labelKey: 'ADMIN_LABEL_PANEL',
            type: 'panelId',
          },
        ],
      },
      {
        id: 'play_video',
        labelKey: 'ADMIN_LABEL_CTA_PLAY_VIDEO',
        properties: [
          {
            fieldName: 'videoId',
            labelKey: 'ADMIN_LABEL_VIDEO',
            type: 'videoId',
          },
        ],
      },
      {
        id: 'show_person',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_PERSON',
        properties: [
          {
            fieldName: 'personId',
            labelKey: 'ADMIN_LABEL_PERSON',
            type: 'personId',
          },
        ],
      },
      {
        id: 'show_group',
        labelKey: 'ADMIN_LABEL_CTA_SHOW_GROUP',
        properties: [
          {
            fieldName: 'groupId',
            labelKey: 'ADMIN_LABEL_GROUP',
            type: 'groupId',
          },
        ],
      },
      {
        id: 'facebook_share',
        labelKey: 'ADMIN_LABEL_CTA_FACEBOOK_SHARE',
        properties: [],
      },
      {
        id: 'go_to_channel',
        labelKey: 'ACTION_GO_TO_CHANNEL',
        properties: [
          {
            fieldName: 'channelSlug',
            labelKey: 'ADMIN_LABEL_CTA_TARGET_CHANNEL',
            type: 'pageSlug',
          },
        ],
      },
      {
        id: 'login',
        labelKey: 'ACTION_LOG_IN',
        properties: [],
      },
      {
        id: 'tweet',
        labelKey: 'ADMIN_LABEL_CTA_TWEET',
        properties: [
          {
            fieldName: 'tweetMessage',
            labelKey: 'ADMIN_LABEL_CTA_TWEET_MESSAGE',
            type: 'string',
          },
        ],
      },
    ],
  },
};

const nameField = {
  fieldName: 'name',
  labelKey: 'ADMIN_LABEL_NAME',
  type: 'string',
};

const durationField = {
  fieldName: 'duration',
  labelKey: 'ADMIN_LABEL_OVERLAY_DURATION',
  type: 'number',
};

const allChannelField = {
  fieldName: 'allChannels',
  type: 'toggle',
  typeOptions: {
    helperTextKey: 'ADMIN_ALL_CHANNEL_OVERLAY_HELP',
    labelKey: 'ADMIN_LABEL_ALL_CHANNEL_OVERLAY',
  },
};

const idsToFields = {
  highlight: [
    nameField,
    durationField,
    messageCtaField,
    {
      fieldName: 'description',
      labelKey: 'ADMIN_LABEL_DESCRIPTION',
      type: 'string',
    },
    allChannelField,
    {
      fieldName: 'imageUrl',
      labelKey: 'ADMIN_LABEL_IMAGE',
      type: 'image',
    },
  ],
  lower_third: [
    {
      fieldName: 'imageUrl',
      labelKey: 'ADMIN_LABEL_IMAGE',
      type: 'image',
    },
    nameField,
    durationField,
    lowerThirdCtaField,
    allChannelField,
  ],
  message: [
    nameField,
    {
      fieldName: 'message',
      labelKey: 'ADMIN_OVERLAY_MESSAGE_NAME',
      placeholderLabelKey: 'ADMIN_OVERLAY_MESSAGE_PLACEHOLDER',
      type: 'string',
      typeOptions: {
        maxLength: 140,
      },
    },
    {
      fieldName: 'theme',
      labelKey: 'ADMIN_OVERLAY_THEME_NAME',
      type: 'radio',
      typeOptions: {
        choices: [
          {
            id: 'light',
            text: 'ADMIN_LABEL_LIGHT',
          },
          {
            id: 'dark',
            text: 'ADMIN_LABEL_DARK',
          },
        ],
        default: 'light',
        name: 'overlay-message-theme',
      },
    },
    durationField,
    messageCtaField,
    allChannelField,
  ],
  poll: [
    {
      fieldName: 'pollId',
      labelKey: 'ADMIN_OVERLAY_POLL_HELP',
      type: 'libraryButton',
      typeOptions: {
        fancyButtonProps: {
          buttonPlaceholderKey: 'ADMIN_POLL_BUTTON_PLACEHOLDER',
          docToButtonProps: doc => ({
            showImage: false,
            text: doc?.name,
          }),
        },
        type: 'poll',
        valueField: '_id',
      },
    },
    durationField,
    allChannelField,
  ],
  poll_results: [
    {
      fieldName: 'broadcastId',
      labelKey: 'ADMIN_OVERLAY_POLL_HELP',
      type: 'pollResult',
    },
    durationField,
    allChannelField,
  ],
  prediction: [
    {
      fieldName: 'pollId',
      labelKey: 'ADMIN_OVERLAY_PREDICTION_HELP',
      type: 'libraryButton',
      typeOptions: {
        fancyButtonProps: {
          buttonPlaceholderKey: 'ADMIN_PREDICTION_BUTTON_PLACEHOLDER',
          docToButtonProps: doc => ({
            showImage: false,
            text: doc?.name,
          }),
        },
        type: 'prediction',
        valueField: '_id',
      },
    },
    durationField,
    allChannelField,
  ],
  raffle: [
    {
      fieldName: 'reward',
      labelKey: 'REWARD',
      type: 'string',
    },
    allChannelField,
  ],
  shopify: [
    nameField,
    {
      fieldName: 'imageUrl',
      labelKey: 'ADMIN_LABEL_IMAGE',
      type: 'image',
    },
    durationField,
    lowerThirdCtaField,
    allChannelField,
  ],
  trivia: [
    {
      fieldName: 'pollId',
      labelKey: 'ADMIN_OVERLAY_TRIVIA_HELP',
      type: 'libraryButton',
      typeOptions: {
        fancyButtonProps: {
          buttonPlaceholderKey: 'ADMIN_TRIVIA_BUTTON_PLACEHOLDER',
          docToButtonProps: doc => ({
            showImage: false,
            text: doc?.name,
          }),
        },
        type: 'trivia',
        valueField: '_id',
      },
    },
    durationField,
    allChannelField,
  ],
  tweet: [
    {
      fieldName: 'tweetUrl',
      labelKey: 'ADMIN_LABEL_URL',
      type: 'string',
    },
    durationField,
    allChannelField,
  ],
};

const Wrapper = styled.div`
  & > div {
    padding: 0;
  }
`;

export default class OverlayEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    overlayType: PropTypes.string.isRequired,
    renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    const {
      overlayType,
      onChange,
      renderer,
    } = this.props;

    const properties = idsToFields[overlayType];

    return (
      <Wrapper>
        <RendererEditor
          onChange={onChange}
          properties={properties}
          renderer={renderer}
        />
      </Wrapper>
    );
  }
}

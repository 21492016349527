import styled from 'styled-components';
import { TAB_WIDTH_MD, ADMIN_TEXT_300, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_BAR_TAB } from 'style/mixins';
import AdminCard from 'components/admin2/ui/AdminCard';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const Container = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_100};
  ${ADMIN_BAR_TAB}
  padding-top: 23px;
  display: flex;
  flex-flow: column nowrap;
  width: ${TAB_WIDTH_MD};
`;

export const ChooseRole = styled.div`
  ${ADMIN_TEXT_LABEL_M_BOLD}
  color: ${ADMIN_TEXT_300};
  text-transform: uppercase;
  padding: 30px 0 12px 0;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
`;

export const StyledUser = styled(AdminCard)`
  background: none;
  border: none !important;
  padding: 0;
`;

export const RoleContainer = styled.div`
  padding: 0 7px;
`;

import { ADMIN_ACCENT_PRIMARY } from 'style/constants';
import styled from 'styled-components';
import { ADMIN_TEXT_BODY_L_REGULAR } from 'style/design-system/textStyles';

export const Container = styled.div`
  margin: 20px 0px 20px 0px;
`;

export const Section = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_BODY_L_REGULAR}
  align-self: center;
  color: ${ADMIN_ACCENT_PRIMARY};
  margin-left: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
`;

import React from 'react';
import Nestable, { Item } from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import { DraggerWrapper } from './styles';

export interface INestableItem extends Item {
  children?: INestableItem[]; // items with children (2+ depth) must store them in this array
  id: string; // all items need an ID
}

export interface IDraggerProps<T extends INestableItem, U extends INestableItem> {
  disableDrag?: typeof Nestable.prototype.props.disableDrag;
  draggerRef?: React.RefObject<HTMLDivElement>;
  maxDepth?: number;
  onChange?: ((arg: {
    dragItem: U;
    items: T[];
    targetPath: number[];
  }) => void) | undefined
  onConfirmChange: typeof Nestable.prototype.props.confirmChange;
  renderItem: typeof Nestable.prototype.props.renderItem;
  source: string;
  treeData: T[];
}

/*
  go to component whenever you need drag and drop functionality
  regardless of the depth of the nesting.
*/

const Dragger = <T extends INestableItem, U extends INestableItem>({
  maxDepth = 1, // supports 1 to infinite levels of nesting/depth.
  onChange,
  onConfirmChange,
  renderItem,
  disableDrag,
  source,
  treeData,
  draggerRef,
}: IDraggerProps<T, U>) => {
  const handleChange = (arg: { dragItem: Item; items: Item[]; targetPath: number[]; }) => {
    if (onChange) {
      onChange({
        items: arg.items as T[],
        dragItem: arg.dragItem as U,
        targetPath: arg.targetPath,
      });
    }
  };

  return (
    <DraggerWrapper source={source} ref={draggerRef}>
      <Nestable
        className={`react-nestable react-nestable-${source}`}
        items={treeData}
        renderItem={renderItem}
        maxDepth={maxDepth}
        onChange={handleChange}
        confirmChange={onConfirmChange}
        disableDrag={disableDrag}
      />
    </DraggerWrapper>
  );
};

export default Dragger;

import shortId from 'shortid';

export default async ({
  renderer,
}) => {
  if (!renderer.message) {
    return false;
  }
  return {
    key: `preview-${shortId.generate()}`,
    payload: {
      channel: 'preview',
      data: {},
      renderer,
    },
    timeRemaining: renderer.duration,
  };
};

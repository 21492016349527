import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import warning from 'warning';
import { withT } from 'hooks/use-translation';
import OverlayEditor from 'components/admin2/BroadcastModeSheet/OverlayEditor';
import TranslatedText from 'components/i18n/TranslatedText';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import hash from 'json-stable-stringify';
import { LargeButton } from 'components/admin2/ui/Button';
import { createObjectId } from 'utils';
import { PollTypes } from 'services/polls/models';

import { TAB_PADDING } from 'style/constants';
import generateOverlayPreview from './generateOverlay';
import LibraryPage from './LibraryPage';
import { getPreviewOverlay } from './CatalogPage';
import ShopifyOverlayEditor from './ShopifyOverlayConnector';
import { overlayKindMap, tabIndexNameMap } from './constants';

const Header = styled.div``;

const DeleteContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const LibraryWrapper = styled.div`
  margin-inline: -${TAB_PADDING};
  margin-top: 20px;
`;

export default withT(
  class BroadcastModeSheet extends React.Component {
    static propTypes = {
      addPreviewOverlay: PropTypes.func.isRequired,
      broadcastOverlay: PropTypes.func.isRequired,
      editCollectionItem: PropTypes.func.isRequired,
      editOverlay: PropTypes.func.isRequired,
      objectId: PropTypes.string.isRequired,
      onSaveNewOverlay: PropTypes.func.isRequired,
      primaryToken: PropTypes.string.isRequired,
      refreshKey: PropTypes.string.isRequired,
      removeEditingOverlay: PropTypes.func.isRequired,
      removePreviewOverlay: PropTypes.func.isRequired,
    };

    state = {
      creatingRenderer: {},
      creatingType: null,
      focusedTabIndex: 0,
      hasChanges: false,
    };

    componentWillUnmount() {
      const { removeEditingOverlay, removePreviewOverlay } = this.props;
      removeEditingOverlay();
      removePreviewOverlay();
    }

    onTabClick = (focusedTabIndex) => {
      this.setState({ focusedTabIndex });
    };

    getHeaderHelpTextKey() {
      const { focusedTabIndex } = this.state;
      switch (focusedTabIndex) {
        case 0:
          return 'ADMIN_SELECT_OVERLAY_HELP';
        case 1:
          return 'ADMIN_BROADCAST_OVERLAY_HELP';
        case 2:
          return 'ADMIN_SCHEDULE_OVERLAY_HELP';
        default:
          return 'ADMIN_ACTION_BROADCAST';
      }
    }

    reset = () => {
      const { removeEditingOverlay, removePreviewOverlay } = this.props;
      this.setState({
        creatingRenderer: {},
        creatingType: null,
        hasChanges: false,
      });
      removeEditingOverlay();
      removePreviewOverlay();
    };

    handleCreatingTypeChange = (creatingType) => {
      const { removePreviewOverlay } = this.props;
      removePreviewOverlay();
      this.setState({ creatingType });
    };

    handleCreatingRendererChange = async (creatingRenderer) => {
      const { creatingType, creatingRenderer: oldCreatingRenderer } = this.state;
      const { editOverlay, removeEditingOverlay, primaryToken } = this.props;
      let type = creatingType;
      if ([PollTypes.Trivia, PollTypes.Prediction].includes(type)) {
        type = 'poll'; // these are only different in name
      }
      const renderer = {
        ...creatingRenderer,
        overlayType: type,
      };
      if (creatingRenderer?.duration > 600) {
        creatingRenderer.duration = 600;
      }
      const hasChanges = hash(oldCreatingRenderer) !== hash(creatingRenderer);
      try {
        this.setState({ creatingRenderer, hasChanges });
        const overlay = await generateOverlayPreview({
          primaryToken,
          renderer,
        });
        if (overlay) {
          editOverlay(overlay);
          return;
        }
        removeEditingOverlay();
      } catch (err) {
        warning(false, err.message);
      }
    };

    generateOverlay = ({ creatingRenderer, creatingType }) => {
      const rendererWithDuration = {
        ...creatingRenderer,
      };
      if (!rendererWithDuration.duration) {
        rendererWithDuration.duration = 20;
      }
      return {
        ...rendererWithDuration,
        overlayId: creatingRenderer.overlayId || createObjectId(),
        overlayType: creatingType,
      };
    };

    handleSaveNewOverlay = (creatingRenderer) => {
      const { onSaveNewOverlay } = this.props;
      const { creatingType } = this.state;
      const overlayRenderer = this.generateOverlay({
        creatingRenderer,
        creatingType,
      });
      onSaveNewOverlay(overlayRenderer);
    };

    handleEditExistingOverlay = (renderer, archivedTimestamp) => {
      const { editCollectionItem } = this.props;
      const id = renderer.overlayId;
      invariant(
        id,
        'handleEditExistingOverlay: renderer missing OverlayId',
      );
      editCollectionItem('overlay', id, renderer, archivedTimestamp);
    };

    handleSave = () => {
      const { handleSaveNewOverlay, handleEditExistingOverlay } = this;
      const { creatingRenderer } = this.state;
      if (creatingRenderer?.overlayId) {
        handleEditExistingOverlay(creatingRenderer);
      } else {
        handleSaveNewOverlay(creatingRenderer);
      }
      this.reset();
    };

    handleDeleteLibraryOverlay = (renderer) => {
      this.handleEditExistingOverlay(renderer, Date.now());
      this.reset();
    };

    handleBroadcast = async () => {
      const {
        broadcastOverlay,
        objectId,
        removeEditingOverlay,
        removePreviewOverlay,
      } = this.props;
      const { creatingRenderer, creatingType } = this.state;
      removeEditingOverlay();
      removePreviewOverlay();
      const renderer = this.generateOverlay({
        creatingRenderer,
        creatingType,
      });
      broadcastOverlay({
        allChannels: Boolean(renderer.allChannels),
        channel: objectId,
        renderer,
      });
    };

    handleLibraryEdit = (renderer) => {
      const { overlayType } = renderer;
      this.setState({
        creatingRenderer: renderer,
        creatingType: overlayType,
      });
    };

    onPreviewClick = (id, showPreview) => {
      const { addPreviewOverlay, removePreviewOverlay, t } = this.props;
      if (!showPreview) {
        removePreviewOverlay();
        return;
      }
      const overlay = getPreviewOverlay(t, id);
      if (overlay) {
        addPreviewOverlay(overlay);
      }
    };

    renderCreating() {
      const {
        creatingRenderer,
        creatingType,
      } = this.state;

      const isLibraryItem = Boolean(creatingRenderer.overlayId);

      return (
        <>
          {creatingType === 'shopify' ? (
            <ShopifyOverlayEditor
              onChange={this.handleCreatingRendererChange}
              overlayType={creatingType}
              renderer={creatingRenderer}
            />
          ) :
            (
              <OverlayEditor
                onChange={this.handleCreatingRendererChange}
                overlayType={creatingType}
                renderer={creatingRenderer}
              />
            )}
          {
            isLibraryItem && (
              <DeleteContainer>
                <LargeButton
                  fullwidth
                  onClick={() => this.handleDeleteLibraryOverlay(creatingRenderer)}
                >
                  <TranslatedText stringKey="ADMIN_ACTION_DELETE_OVERLAY" />
                </LargeButton>
              </DeleteContainer>
            )
          }
        </>
      );
    }

    render() {
      const {
        broadcastOverlay,
        primaryToken,
        addPreviewOverlay,
        refreshKey,
        removeEditingOverlay,
        removePreviewOverlay,
        objectId,
      } = this.props;
      const { creatingType, focusedTabIndex, hasChanges } = this.state;

      const history = [
        {
          nameKey: 'ADMIN_LABEL_OVERLAYS',
          navigate: this.reset,
        },
        {
          nameKey: tabIndexNameMap[focusedTabIndex],
          navigate: this.reset,
        },
      ];

      const { nameKey, iconName } = overlayKindMap[creatingType] || {};

      if (nameKey) {
        history.push({ nameKey });
      }

      return (
        <AdminBarTab>
          <AdminBarTabHeader
            hasUnsavedChanges={hasChanges && ['message', 'lower_third'].includes(creatingType)}
            headerKey="ADMIN_LABEL_ENGAGE_AND_MONETIZE"
            history={history}
            onBack={creatingType ? this.reset : undefined}
            onBroadcast={creatingType ? this.handleBroadcast : undefined}
            onSave={['message', 'lower_third'].includes(creatingType) && this.handleSave}
            subHeaderIcon={iconName}
            subHeaderKey={nameKey || 'ADMIN_LABEL_OVERLAYS_SETTINGS'}
          />
          {
            creatingType ? this.renderCreating() : (
              <>
                <TranslatedText component={Header} stringKey={this.getHeaderHelpTextKey()} />
                <LibraryWrapper>
                  <LibraryPage
                    addPreviewOverlay={addPreviewOverlay}
                    broadcastOverlay={broadcastOverlay}
                    focusedTabIndex={focusedTabIndex}
                    objectId={objectId}
                    onEditClick={this.handleLibraryEdit}
                    onPreviewClick={this.onPreviewClick}
                    onTabClick={this.onTabClick}
                    onUseClick={this.handleCreatingTypeChange}
                    primaryToken={primaryToken}
                    refreshKey={refreshKey}
                    removeEditingOverlay={removeEditingOverlay}
                    removePreviewOverlay={removePreviewOverlay}
                  />
                </LibraryWrapper>
              </>
            )
          }
        </AdminBarTab>
      );
    }
  },
);

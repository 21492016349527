import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import TranslatedText from 'components/i18n/TranslatedText';
import { FADE_IN } from 'style/animations';
import {
  SPACING_MEDIUM,
  ADMIN_TEXT_100,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_4,
} from 'style/constants';
import { castToPxString } from 'shared/string-utils';

import AdminButton from 'components/admin2/ui/Button';
import FixedModal from 'components/ui/FixedModal';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_L_MEDIUM, ADMIN_TEXT_TITLE_S } from 'style/design-system/textStyles';

const WIDTH = ({ widthPx }) => {
  if (widthPx) {
    return css`
      width: ${castToPxString(widthPx)};
    `;
  }
  return '';
};

const fadeInRule = css`
  animation: 0.3s ease-out ${FADE_IN};
`;

const Container = styled.div`
  ${fadeInRule}
  ${WIDTH};
`;

const Header = styled.div`
  background-color: ${ADMIN_SURFACE_3};
  color: ${ADMIN_TEXT_100};
  padding: ${SPACING_MEDIUM};
`;

const Content = styled.div`
  background-color: ${ADMIN_SURFACE_4};
  color: ${ADMIN_TEXT_100};
  padding: ${props => props.noContentPadding ? '0' : SPACING_MEDIUM};
`;

const UpperHeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  && {
      ${ADMIN_TEXT_TITLE_S}
      text-transform: uppercase;
    }
`;

const Button = styled(AdminButton)`
  margin-left: 10px;
`;

const Info = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
  margin-top: ${SPACING_MEDIUM};
`;

const ButtonWrapper = styled.div`
  display: flex;
  ${ADMIN_TEXT_LABEL_L_MEDIUM}
`;

const FooterTitle = styled.div`
  ${ADMIN_TEXT_BODY_XS_REGULAR};
  color: ${ADMIN_TEXT_200};
`;

export default function EditModeModal({
  className,
  children,
  infoKey,
  nameKey,
  noContentPadding,
  onSave,
  saveEnabled,
  widthPx,
  handleCancel,
  isFooterModal,
  testIdCancel,
  testIdSave,
}) {
  const TitleComponent = isFooterModal ? FooterTitle : Title;

  useEffect(() => {
    if (saveEnabled && !isFooterModal) {
      onSave?.();
    }
  }, [saveEnabled, isFooterModal]);

  return (
    <FixedModal onClick={onSave}>
      <Container className={className} widthPx={widthPx}>
        <Header>
          <UpperHeaderWrapper>
            <TranslatedText component={TitleComponent} stringKey={nameKey} />
            <ButtonWrapper>
              <TranslatedText component={Button} componentProps={{ 'data-testid': testIdCancel, onClick: handleCancel }} stringKey="ADMIN_ACTION_CANCEL" />
              {isFooterModal ? (
                <TranslatedText
                  component={Button}
                  componentProps={{ 'data-testid': testIdSave, disabled: !saveEnabled, onClick: onSave }}
                  stringKey="ADMIN_ACTION_ADD"
                />
              ) : null}
            </ButtonWrapper>
          </UpperHeaderWrapper>
          { infoKey && <TranslatedText component={Info} stringKey={infoKey} /> }
        </Header>
        <Content noContentPadding={noContentPadding}>
          { children }
        </Content>
      </Container>
    </FixedModal>
  );
}

EditModeModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleCancel: PropTypes.func,
  infoKey: PropTypes.string,
  isFooterModal: PropTypes.bool,
  nameKey: PropTypes.string.isRequired,
  noContentPadding: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool,
  testIdCancel: PropTypes.string,
  testIdSave: PropTypes.string,
  widthPx: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

EditModeModal.defaultProps = {
  className: '',
  handleCancel: undefined,
  infoKey: undefined,
  isFooterModal: false,
  noContentPadding: false,
  saveEnabled: true,
  testIdCancel: undefined,
  widthPx: undefined,
};

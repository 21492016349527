import React, { ReactNode } from 'react';
import { Portal } from 'react-portal';
import { ADMIN_BAR_TAB_PORTAL } from 'global-ids';

interface IAdminBarTabPortalProps {
  children: ReactNode;
}

export default function AdminBarTabPortal({
  children,
}: IAdminBarTabPortalProps) {
  return (
    <Portal node={document && document.getElementById(ADMIN_BAR_TAB_PORTAL)}>
      {children}
    </Portal>
  );
}


import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  ACTION_ACTIVE,
  ACTION_DISABLED,
  ACTION_NORMAL,
  ACTION_ICON_SVG,
} from 'style/mixins';
import { ADMIN_ACTION_COLOR, ADMIN_ACTION_COLOR_HOVER, ADMIN_SURFACE_1 } from 'style/constants';
import Icon from 'components/admin2/Icon';
import TranslatedText from 'components/i18n/TranslatedText';
import AdminIcon from 'components/admin2/AdminIcon';

const StyledIcon = styled(Icon)`
   margin: 0 ${props => props.hasLabel && '10px'};
    && {
      color: ${ADMIN_ACTION_COLOR};
    &:hover{
        color: ${ADMIN_ACTION_COLOR_HOVER};
      }
    }
`;

const StyledIconSvg = styled(AdminIcon)`
  ${ACTION_ICON_SVG}
`;

const getActionStateFormatting = (props) => {
  const { disabled, active } = props;
  if (active) {
    return ACTION_ACTIVE;
  }

  if (disabled) {
    return ACTION_DISABLED;
  }

  return ACTION_NORMAL;
};

const BIG_TITLE = css`
  background-color: ${ADMIN_SURFACE_1};
  display: flex;
  width: 100%;
  line-height: 40px;
  height: 40px;
  justify-content: center;
`;

const Wrapper = styled.button.attrs({
  type: 'button',
})`
  && {
    display: flex;
    flex-flow: row nowrap;
    ${getActionStateFormatting};
    ${props => props.bigSize && BIG_TITLE};
  }
`;

const ActionButton = (props) => {
  const {
    bigSize,
    iconName,
    labelKey,
    children,
    className,
    svgIconName,
    ...restProps
  } = props;
  return (
    <Wrapper
      bigSize={bigSize}
      className={className}
      {...restProps}
    >
      {
        children || (
          <>
            {svgIconName && <StyledIconSvg name={svgIconName} />}
            {iconName && <StyledIcon hasLabel={Boolean(labelKey)} name={iconName} />}
            {labelKey && <TranslatedText stringKey={labelKey} />}
          </>
        )
}
    </Wrapper>
  );
};

ActionButton.propTypes = {
  bigSize: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  iconName: PropTypes.string,
  labelKey: PropTypes.string,
  svgIconName: PropTypes.string,
};

ActionButton.defaultProps = {
  bigSize: false,
  children: undefined,
  className: undefined,
  iconName: undefined,
  labelKey: undefined,
  svgIconName: undefined,
};

export default ActionButton;

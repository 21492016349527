import styled from 'styled-components';
import { ADMIN_SURFACE_5, MAESTRO_WHITE } from 'style/constants';
import { ADMIN_TEXT_BODY_M_MEDIUM } from 'style/design-system/textStyles';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ColumnTitle = styled.div`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: ${MAESTRO_WHITE};
  margin-bottom: 14px;
`;

export const Divider = styled.div`
  height: 0px;
  border-top: 1px solid ${ADMIN_SURFACE_5};
  z-index: 0;
`;

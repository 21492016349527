import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import TranslatedText from 'components/i18n/TranslatedText';
import { PAYOUT_SERVICE_BASE_URL, SITE_ID } from 'config';
import { BalanceTextValue, StyledMethodTitle, SubtitleText, TipText2 } from '../styles';
import { displayAccounting } from 'shared/string-utils';
import {
  BalanceContainer,
  LoadingContainer,
  StripeConnectedContainer,
  StripeConnectedText,
  StripeConnectImage,
  StripeConnectText,
  StyledButton,
  Subtitle,
  StyledTertiaryButton,
} from './styles';
import { getContract } from 'services/billing/selectors';
import useAjax from 'hooks/use-ajax';
import { getUserAccountId } from 'services/user-profile';
import { getUserEmail } from 'services/auth';
import EllipsisLoader from 'components/admin2/ui/EllipsisLoader';
import parse from 'html-react-parser';
import shortid from 'shortid';
import { useAdminTranslation } from 'hooks/use-translation';

interface IStripeLinksResponse {
  data: {
    onboardingNeeded: boolean;
    url: string;
  };
  success: boolean;
}

const BALANCE_CALCULATION_URL = `${PAYOUT_SERVICE_BASE_URL}/calculation`;
const STRIPE_LINKS_URL = `${PAYOUT_SERVICE_BASE_URL}/stripe/links`;

const BillingPayout = () => {
  const [linksRefreshKey, setLinksRefreshKey] = useState(shortid.generate());
  const updateLinksRefreshKey = () => setLinksRefreshKey(shortid.generate());

  const contract = useSelector(getContract);
  const accountId = useSelector(getUserAccountId);
  const userEmail = useSelector(getUserEmail);
  const { t } = useAdminTranslation();

  const { data: calculationData, loaded: balanceLoaded } = useAjax<{ data: number }>({
    url: BALANCE_CALCULATION_URL,
  });

  const { data: stripeData, loaded: stripeLoaded } = useAjax<IStripeLinksResponse>({
    url: STRIPE_LINKS_URL,
    body: {
      accountId,
      email: userEmail,
      siteId: SITE_ID,
    },
    refreshKey: linksRefreshKey,
  });

  const balanceText = useMemo(() => displayAccounting(calculationData?.data || 0), [calculationData]);
  const isStripeConnected = useMemo(
    () => !!contract?.stripeAccountId && !stripeData?.data.onboardingNeeded,
    [contract, stripeData],
  );

  const onClickConnectStripe = () => {
    redirectToStripe();
    updateLinksRefreshKey();
  };

  const redirectToStripe = () => {
    window.open(stripeData!.data.url, '_blank');
  };

  const redirectToStripeAccount = () => {
    window.open(`${stripeData!.data.url}#/account`, '_blank');
  };

  const renderBalance = useCallback(() => {
    if (!balanceLoaded) {
      return <EllipsisLoader loading={true} />;
    }

    return <BalanceTextValue>{`${balanceText} USD`}</BalanceTextValue>;
  }, [balanceLoaded, balanceText]);

  const renderStripeContent = useCallback(() => {
    if (!stripeLoaded) {
      return (
        <LoadingContainer>
          <EllipsisLoader loading={true} />
        </LoadingContainer>
      );
    }

    if (!isStripeConnected) {
      return (
        <>
          <TranslatedText
            component={Subtitle}
            stringKey="ADMIN_SETTINGS_BILLING_PAYOUT_METHOD_HEADER"
          />
          <SubtitleText>
            {parse(t('ADMIN_SETTINGS_BILLING_STRIPE_PAYOUT_TEXT'))}
          </SubtitleText>
          <StyledTertiaryButton onClick={onClickConnectStripe}>
            <TranslatedText
              component={StripeConnectText}
              stringKey="ADMIN_SETTINGS_BILLING_PAYOUT_STRIPE_BUTTON"
            />
            <StripeConnectImage />
          </StyledTertiaryButton>
          <TranslatedText
            component={TipText2}
            stringKey="ADMIN_SETTINGS_BILLING_PAYOUT_METHOD_TIP"
          />
        </>
      );
    }

    return (
      <StripeConnectedContainer>
        <SubtitleText>
          {parse(t('ADMIN_SETTINGS_BILLING_STRIPE_PAYOUT_TEXT'))}
        </SubtitleText>
        <TranslatedText
          component={StripeConnectedText}
          stringKey="ADMIN_SETTINGS_BILLING_PAYOUT_STRIPE_CONNECTED"
        />
        <StyledButton onClick={redirectToStripe}>
          {t('ADMIN_SETTINGS_BILLING_PAYOUT_VIEW_HISTORY')}
        </StyledButton>
        <StyledButton onClick={redirectToStripeAccount}>
          {t('ADMIN_SETTINGS_BILLING_PAYOUT_VIEW_STRIPE_ACCOUNT')}
        </StyledButton>
      </StripeConnectedContainer>
    );
  }, [stripeLoaded, isStripeConnected, onClickConnectStripe, t]);

  return (
    <>
      <TranslatedText
        component={StyledMethodTitle}
        stringKey="ADMIN_SETTINGS_BILLING_PAYOUT_HEADER_TITLE"
      />
      <BalanceContainer>
        {`${t('ADMIN_SETTINGS_BILLING_PENDING_PAYOUTS')}:`}
        &nbsp;
        {renderBalance()}
      </BalanceContainer>

      {renderStripeContent()}
    </>
  );
};

export default BillingPayout;

import { TEXT_100 } from 'style/constants';
import { TEXT_BODY_M_BOLD } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Container = styled.div<{ isFaded?: boolean }>`
  padding: 5px 10px;
  border-radius: 15px;
  ${TEXT_BODY_M_BOLD}
  opacity: ${({ isFaded }) => (isFaded ? 0.1 : 1)};
  color: ${TEXT_100};
  border: 1px solid ${TEXT_100};
`;

import moment from 'moment';
import { Moment } from 'moment';
import React, { useMemo, useState } from 'react';
import { Container, Content, Column, OptionText } from './styles';
import { YEAR_RANGE_INITIAL_YEAR } from '../../constants';

interface MonthAndYearOverlayPickerProps {
  isActive?: boolean;
  onDateChange?: (date: Moment) => void;
  type: 'Month' | 'Year' | 'Both';
}

function MonthAndYearOverlayPicker({ onDateChange, type = 'Both', isActive }: MonthAndYearOverlayPickerProps) {
  const [date, setDate] = useState(moment());

  const isMonthVisible = useMemo(() => type === 'Both' || type === 'Month', [type]);
  const isYearVisible = useMemo(() => type === 'Both' || type === 'Year', [type]);

  const yearList = useMemo(() => {
    const years: Moment[] = [];
    const yearsDiff = moment().year(YEAR_RANGE_INITIAL_YEAR);

    for (const m = moment(); m.diff(yearsDiff, 'year') >= 0; m.subtract(1, 'year')) {
      years.push(m.clone());
    }
    return years;
  }, []);

  const handleDateChange = (newDate: Moment) => {
    newDate = newDate.set({ date: 1, hour: 0, minute: 0, second: 0 });
    onDateChange?.(newDate);
    setDate(newDate);
  };

  const renderMonthList = () => {
    return Array(12)
      .fill(null)
      .map((_, index) => {
        const value = moment().set({ month: index, date: 1 });
        const isSelected = date.month() === value.month();
        const handleMonthClick = () => handleDateChange(moment(date).set({ month: value.month() }));
        return (
          <OptionText key={value.valueOf()} onClick={handleMonthClick} isSelected={isSelected}>
            {value.format('MMMM')}
          </OptionText>
        );
      });
  };

  const renderYearsList = () => {
    return yearList.map((value) => {
      const isSelected = date.year() === value.year();
      const handleYearClick = () => handleDateChange(moment(date).set({ year: value.year() }));
      return (
        <OptionText key={value.valueOf()} onClick={handleYearClick} isSelected={isSelected}>
          {value.format('YYYY')}
        </OptionText>
      );
    });
  };

  return (
    <Container isActive={isActive}>
      <Content>
        {isMonthVisible && <Column>{renderMonthList()}</Column>}
        {isYearVisible && <Column>{renderYearsList()}</Column>}
      </Content>
    </Container>
  );
}

export default MonthAndYearOverlayPicker;

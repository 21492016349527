import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DateRangeProps } from 'components/admin2/DateRangePicker';
import {
  CalendarContainer,
  CalendarHeader,
  CalendarYearInput,
  Title, PreviousButton,
  NextButton,
  SelectorContainer,
  SelectorTitle,
} from 'components/admin2/DateRangePicker/styles';
import MonthAndYearOverlayPicker from '../MonthAndYearOverlayPicker';
import {
  DayText,
  DaysHeader,
  DayHeaderTitle,
  DaysContainer,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

// tslint:disable-next-line: no-empty-interface
interface DayPickerProps extends DateRangeProps {}

function DayPicker({ isActive, onRangeChange, onTitleChange, setActive }: DayPickerProps) {
  const [date, setDate] = useState<Moment>(moment());
  const [lookupDate, setLookupDate] = useState(moment());
  const [selectorTitle, setSelectorTitle] = useState('');
  const [isDatePickerActive, setDatePickerActive] = useState(false);

  useEffect(() => {
    handleDateChange();
  }, []);

  useEffect(() => {
    if (!date) return;
    handleDateChange();
    handleTitleChange();
  }, [date]);

  useEffect(() => {
    if (!isActive) {
      setDatePickerActive(false);
    }
  }, [isActive]);

  const handleDateChange = () => {
    const startOf = moment(date).set({ hour: 0, minute: 0, second: 0 });
    const endOf = moment(startOf).set({ hour: 23, minute: 59, second: 59 });
    onRangeChange?.(startOf, endOf);
  };

  const handleTitleChange = () => {
    const newTitle = date ? date!.format('MMMM Do, YYYY') : '';
    setSelectorTitle(newTitle);
    onTitleChange?.(newTitle);
  };

  const handleYearChange = (yearDate: Moment) => {
    setLookupDate(moment(lookupDate).set({ year: yearDate.year(), month: yearDate.month() }));
    setDatePickerActive(false);
  };

  const handleClickAddDay = () => {
    const newDate = moment(lookupDate).add(1, 'day');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const handleClickSubDay = () => {
    const newDate = moment(lookupDate).subtract(1, 'day');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const toggleDatePicker = () => {
    setDatePickerActive(!isDatePickerActive);
  };

  const calendar = useMemo(() => {
    const result: Moment[] = [];
    const startDay = lookupDate.clone().startOf('month').startOf('week');
    const endDay = lookupDate.clone().endOf('month').endOf('week');

    const currentDate = startDay.clone().subtract(1, 'day');

    while (currentDate.isBefore(endDay, 'day')) {
      result.push(currentDate.add(1, 'day').clone());
    }
    return result;
  }, [lookupDate]);

  const calendarHeader = useMemo(() => {
    return Array(7)
      .fill(null)
      .map((_, index) => {
        return moment().set({ day: index }).format('ddd');
      });
  }, []);

  const renderCalendar = () => {
    return calendar.map((day) => {
      const isSelected = day.format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
      const isDayInsideMonth = day.isBetween(lookupDate.clone().startOf('month'), lookupDate.clone().endOf('month'));

      const handleDayClick = () => {
        const newDate = day.clone();
        setDate(newDate);
        setLookupDate(newDate);

        setTimeout(() => setActive?.(false), 200);
      };

      return (
        <DayText key={day.valueOf()} insideMonth={isDayInsideMonth} isActive={isSelected} onClick={handleDayClick}>
          {day.format('DD')}
        </DayText>
      );
    });
  };

  return (
    <CalendarContainer isActive={isActive}>
      <CalendarHeader>
        <Title isSmall={false}>
          <TranslatedText stringKey="PICKER_SELECT_DATE" />
        </Title>
        <CalendarYearInput
          onClick={toggleDatePicker}
          value={lookupDate?.format('MMM YYYY') || 'Select date'}
          isActive={isDatePickerActive}
        />
      </CalendarHeader>
      <SelectorContainer>
        <MonthAndYearOverlayPicker type={'Both'} isActive={isDatePickerActive} onDateChange={handleYearChange} />
        <SelectorTitle>{selectorTitle}</SelectorTitle>
        <PreviousButton onClick={handleClickSubDay} />
        <NextButton onClick={handleClickAddDay} />
      </SelectorContainer>
      <DaysHeader>
        {calendarHeader.map((day) => {
          return <DayHeaderTitle key={day}>{day}</DayHeaderTitle>;
        })}
      </DaysHeader>
      <DaysContainer>{renderCalendar()}</DaysContainer>
    </CalendarContainer>
  );
}

export default DayPicker;

import React from 'react';
import { abbrNumber }  from '../../../utils';
import {
  ItemContainer,
  Rank,
  Name,
  ProgressBarContainer,
  ProgressBar,
  Percentage,
  StyledFlag,
} from './styles';

interface IProps {
  countryCode?: string;
  name: string;
  percentage: number;
  rank: number;
  title: string;
}

const Item = ({ name, rank, percentage, countryCode, title }: IProps) => {
  return (
    <ItemContainer hasCountryCode={!!countryCode}>
      <Rank hasCountryCode={!!countryCode}>{rank}</Rank>
      {countryCode && <StyledFlag code={countryCode} />}
      <Name title={title}>{name}</Name>
      <ProgressBarContainer>
        <ProgressBar percentage={percentage} />
      </ProgressBarContainer>
      <Percentage>{abbrNumber(percentage)}%</Percentage>
    </ItemContainer>
  );
};

export default Item;

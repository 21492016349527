import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { computeInfoFromDatetime, computeTimestampFromInfo } from './utils';

interface IDateTimePickerParams {
  externalTimezone?: string;
  onTimeChange: (timestamp: number | null) => void;
  timestamp: number | null;
}

export const LOCAL_TIMEZONE = moment.tz.guess();
export const useDateTimePicker = ({ externalTimezone, onTimeChange, timestamp }: IDateTimePickerParams) => {
  const [internalTimezone, setTimezone] = useState(LOCAL_TIMEZONE);

  const timezone = useMemo(() => {
    return externalTimezone || internalTimezone;
  }, [externalTimezone, internalTimezone]);

  const datetime = useMemo(
    () => computeInfoFromDatetime(timestamp, timezone, false),
    [timestamp, timezone],
  );

  const {
    hour,
    minute,
    second,
  } = datetime;

  const [timeValue, setTimeValue] = useState({ hour, minute, second });

  useEffect(() => {
    if (timeValue) {
      onTimeChange(
        computeTimestampFromInfo({ ...datetime, ...timeValue }),
      );
    }
  }, [
    timeValue,
    timezone,
  ]);

  return {
    setTimeValue,
    timeValue,
    setTimezone,
    datetime,
  };
};

import styled from 'styled-components';
import { ADMIN_SURFACE_5, ADMIN_TEXT_200 } from 'style/constants';
import { DISABLED } from 'style/mixins';
import { IconSvg } from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div<{ isDisabled?: boolean }>`
  ${({ isDisabled }) => (isDisabled ? DISABLED : '')};
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${ADMIN_SURFACE_5};
  height: 40px;
  width: 210px;
  border-radius: 5px;
  padding: 10px 15px;
  user-select: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

export const Arrow = styled(IconSvg).attrs((props) => ({
  name: 'downArrowGrad',
  color: ADMIN_TEXT_200(props),
}))<{ isActive?: boolean }>`
  transition: transform 0.25s ease-in-out;
  transform: rotate(${(props) => (props.isActive ? '180deg' : '0deg')});
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const Title = styled.span`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_200};
`;

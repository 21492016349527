import moment from 'moment-timezone';
import { DESC_KEY } from 'components/admin2/MediaLibrary/constants';
import { useAdminTranslation } from 'hooks/use-translation';
import { useMemo } from 'react';

const useLibraryColumns = () => {
  const { t } = useAdminTranslation();

  return useMemo(
    () => ({
      CHANNELS: [
        {
          accessor: (value) => {
            const name = value?.data?.name || value?.seo?.title || value?.slug || '';
            return name;
          },
          defaultThumbnail: 'picture',
          isTitle: true,
          minWidth: 230,
          thumbnailAccessor: data => data.channelSelectActiveImageUrl,
          title: t('NAME'),
        },
        {
          accessor: 'created',
          minWidth: 100,
          sort: DESC_KEY,
          title: t('ADMIN_CHANNEL_LIBRARY_ADDED'),
          transformValue: value => moment(value).fromNow(),
        },
      ],
    }),
    [t],
  );
};

export default useLibraryColumns;

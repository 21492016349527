import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { isAccessControlModeActive as isAccessControlModeActiveSelector } from 'services/admin/selectors';
import { DATE_TIME } from 'injection-classes';
import useEditGate from 'hooks/use-edit-gate';
import DateAndTime from '../SharedComponents/DateAndTime';
import { AccessSectionContainer, ClickableEditButton } from '../styles';
import DateAndTimeModal from '../Modals/DateAndTimeModal';
import { useAdminTranslation } from 'hooks/use-translation';
import GateTitle from 'components/objects/Gate/GateTitle';
import GateSubtitle from 'components/objects/Gate/GateSubtitle';

interface IAccessInformation {
  isDateAndTimeModalVisible: boolean;
  setIsDateAndTimeModalVisible: Dispatch<SetStateAction<boolean>>;
}

const AccessInformation: React.FC<IAccessInformation> = ({
  isDateAndTimeModalVisible,
  setIsDateAndTimeModalVisible,
}) => {
  const { t } = useAdminTranslation();
  const isAccessControlModeActive = useSelector(
    isAccessControlModeActiveSelector,
  );
  const {
    editingGateInformation,
    setGateDateAndTime,
  } = useEditGate();

  const currentTime = Date.now();
  const timeStamp = editingGateInformation?.subscriptionTimestamp || currentTime;
  const isDateAndTimeEnabled = Boolean(editingGateInformation?.subscriptionTimestamp);

  const toggleDateAndTimeModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setIsDateAndTimeModalVisible(!isDateAndTimeModalVisible);
    e.stopPropagation();
  };

  const closeDateAndTimeModal = () => {
    setIsDateAndTimeModalVisible(false);
  };

  const handleTimeStampUpdate = (
    newTimestamp: number | null,
    isDateAndTimeActive: boolean,
  ) => {
    const dateAndTime = isDateAndTimeActive ? newTimestamp : null;
    setGateDateAndTime(dateAndTime);
  };


  return (
    <>
      <AccessSectionContainer
        data-testid="dataAndTimeButton"
        sectionTitle={t('ACCESS_GATE_DATE_AND_TIME_REGION_TITLE')}
        isAccessControlMode={isAccessControlModeActive}
        width="fit-content"
      >
        {isAccessControlModeActive && (
          <>
            <ClickableEditButton onClick={toggleDateAndTimeModal} />
            {isDateAndTimeModalVisible && (
              <DateAndTimeModal
                timestamp={timeStamp}
                handleTimeStampChange={handleTimeStampUpdate}
                closeModal={closeDateAndTimeModal}
                isActive={isDateAndTimeEnabled}
              />
            )}
          </>
        )}
        {
          (isDateAndTimeEnabled || isAccessControlModeActive) &&
          (
          <DateAndTime
            className={DATE_TIME}
            timeStamp={timeStamp}
            isFaded={!isDateAndTimeEnabled}
          />
        )}
      </AccessSectionContainer>
      <GateTitle />
      <GateSubtitle />
    </>
  );
};

export default AccessInformation;

import React from 'react';
import {
  RowContainer,
  NumberContainer,
  TypeContainer,
  RowIcon,
  IconName,
  Column,
  DataContainer,
  Amount,
  EngagedText,
  Percentage,
  ICON_MAP,
  NAME_MAP,
} from './styles';
import { abbrNumber } from '../../utils';
import TranslatedText from 'components/i18n/TranslatedText';
import { IItem } from './hooks';
import { useAdminTranslation } from 'hooks/use-translation';

interface IProps {
  index: number,
  item: IItem,
  total: number,
}

const Row = ({ index, item: { type, uniquesEngaged }, total }: IProps) => {
  const { t } = useAdminTranslation();

  return (
    <RowContainer>
      <NumberContainer>
        {index}
      </NumberContainer>
      <TypeContainer>
        <RowIcon name={ICON_MAP[type] || 'checkv2'}/>
        {type && (
          <IconName>
            {
              type
                ? (
                  NAME_MAP[type]
                    ? t(NAME_MAP[type])
                    : type.replace(/_/g, ' ')
                )
                : 'N/A'
            }
          </IconName>
        )}
      </TypeContainer>
      <DataContainer>
        <Column>
          <Amount>
            {abbrNumber(uniquesEngaged)}
          </Amount>
          <EngagedText>
            <TranslatedText stringKey="ADMIN_LABEL_UNIQUES_ENGAGED" />
          </EngagedText>
      </Column>
      <Percentage>
        ({abbrNumber((uniquesEngaged/total) * 100)}%)
      </Percentage>
      </DataContainer>
    </RowContainer>
  );
};

export default Row;

import React from 'react';
import {
  ComparisonChartContainer,
  Charts,
  Chart,
  TopLabel,
  BottomLabel,
  Value,
  BottomName,
  Percentage,
} from './styled';
import TranslatedText from 'components/i18n/TranslatedText';
import Icon from 'components/ui/Icon';

type Data = {
  color: string;
  difference: string;
  fontColor?: string;
  name: string;
  percentage: number;
  value: string;
};

type Props = {
  leftData: Data;
  name: string;
  rightData: Data;
};

function ComparisonChart({ leftData, rightData, name }: Props) {
  return (
    <ComparisonChartContainer>
      <Charts>
        <Chart color={leftData.color} percentage={leftData.percentage} labelPosition="right">
          {leftData.percentage <= 10 && (
          <BottomLabel color={leftData.fontColor || leftData.color} labelPosition="right" percentage={leftData.percentage}>
            <Value position={leftData.percentage} labelPosition={'right'}>{leftData.value}</Value>
            <TranslatedText stringKey={leftData.name} />
          </BottomLabel>
          )}
          <TopLabel color={leftData.fontColor || leftData.color} labelPosition="right" percentage={leftData.percentage}>
            {leftData.percentage > 10 && (
            <>
              <TranslatedText stringKey={leftData.name} />
              <Value position={leftData.percentage} labelPosition={'right'}>{leftData.value}</Value>
            </>
            )}
          </TopLabel>
        </Chart>
        <Chart color={rightData.color} percentage={rightData.percentage} labelPosition="left">
          {rightData.percentage <=10 && (
          <BottomLabel color={rightData.fontColor || rightData.color} labelPosition="left" percentage={rightData.percentage}>
            <Value position={rightData.percentage} labelPosition={'right'}>{rightData.value}</Value>
            <TranslatedText stringKey={rightData.name} />
          </BottomLabel>
          )}
          <TopLabel color={rightData.fontColor || rightData.color} labelPosition="left" percentage={rightData.percentage}>
            { rightData.percentage > 10 && (
            <>
              <TranslatedText stringKey={rightData.name} />
              <Value position={rightData.percentage} labelPosition={'left'}>{rightData.value}</Value>
            </>
            )}
            {leftData.percentage < rightData.percentage && (
            <Percentage position={rightData.percentage} labelPosition={'left'}>({rightData.difference}%)
              <Icon name="analyticsChartArrow" />
            </Percentage>
            )}
          </TopLabel>
        </Chart>
      </Charts>
      <BottomName>{name}</BottomName>
    </ComparisonChartContainer>
  );
}

export default ComparisonChart;

import { ADMIN_ALERT_WARNING, ADMIN_SURFACE_5, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';

export const ComparisonChartContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Charts = styled.div`
  height: 275px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;


export const Chart = styled.div<{ color: string; labelPosition: 'left' | 'right', percentage: string | number, }>`
  position: relative;
  height: ${({ percentage }) => percentage}%;
  max-height: 100%;
  width: 13px;
  border-radius: 5px;
  margin: 0 7.5px;
  background: ${({ color }) => color};
  ${({ labelPosition }) => labelPosition === 'left' ? css`
    margin-right: 140px;
  ` : css`
    margin-left: 140px;
  `}
`;

export const TopLabel = styled.div<{ color: string, labelPosition: 'left' | 'right', percentage: string | number }>`
  position: absolute;
  top: ${({ percentage }) => percentage < 30 ? '0%' : '0%'};
  ${ADMIN_TEXT_LABEL_M_BOLD}
  width: 202px;
  color: white;
  ${({ labelPosition }) => labelPosition === 'left' ? css`
    padding-left: 28px;
    margin-left: 24px;
    left: 0;
  ` : css`
    padding-right: 28px;
    margin-right: 24px;
    right: 0;
    text-align:right;
  `}
  &::after{
    border-radius:50%;
    width: 8px;
    height: 8px;
    background: ${({ color }) => color};
    content: "";
    position: absolute;
    top: 5px;
    ${({ labelPosition }) => labelPosition === 'left' ? css`
    left: 0;
  ` : css`
    right: 0;
  `}
  }
`;

export const BottomLabel = styled.div<{ color: string, labelPosition: 'left' | 'right', percentage: string | number }>`
  position: absolute;
  top: -25px;
  ${ADMIN_TEXT_LABEL_M_BOLD}
  width: 202px;
  color: white;
  ${({ labelPosition }) => labelPosition === 'left' ? css`
    padding-left: 28px;
    margin-left: 24px;
    left: 0;
  ` : css`
    padding-right: 28px;
    margin-right: 24px;
    right: 0;
    text-align:right;
  `}
`;


interface IValueProps {
 labelPosition: 'left' | 'right'
 position: number;
}

interface IPercentageProps {
  labelPosition: 'left' | 'right';
  position: number;
 }

export const Value = styled.div<IValueProps>`
  ${ADMIN_TEXT_TITLE_M}
  color: ${ADMIN_TEXT_200};
  padding-top: 5px;
  ${({ labelPosition, position }) => position < 40 && labelPosition === 'left' ? css`
    position:relative;
    top: 0;
    `: css`
    position:relative;
  `};
   ${({ labelPosition, position }) => position < 40 && labelPosition === 'right' ? css`
    position:relative;
    bottom: 3px;
    `: css`
    top: 0%;
    position:relative;
  `};
`;

export const BottomName = styled.div`
  position:relative;
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  text-align: center;
  color: ${ADMIN_TEXT_200};
  padding-top: 24px;
  margin-top: 5px;
  &::before{
    top: 12px;
    position:absolute;
    content: "";
    width: 295px;
    height: 1px;
    background: ${ADMIN_SURFACE_5};
    left:0;
    right:0;
    margin:auto;
  }
`;

export const Percentage = styled.div<IPercentageProps>`
  ${ADMIN_TEXT_TITLE_M}
  color: ${ADMIN_ALERT_WARNING};
  width: 100px;
  & > * {
    left: 0;
    top: 24px;
    position: absolute;
    width: 30px;
  }
  & * {
    fill: ${ADMIN_ALERT_WARNING};
  }
  ${({ labelPosition, position }) => position < 40 && labelPosition === 'left' ? css`
    position:relative;
    bottom: 110px;
    `: css`
    top: 5px;
    position:relative;
  `};
   ${({ labelPosition, position }) => position < 40 && labelPosition === 'right' ? css`
    position:relative;
    bottom: 65px;
    `: css`
    top: 5px;
    position:relative;
  `};
`;

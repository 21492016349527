import React from 'react';
import { useFocusArea } from 'hooks';
import { ColorResult } from 'react-color';
import ColorPicker, { ColorPickerProps } from './ColorPicker';
import { RefContainer } from './styles';
import { applyOpacity, calculateOpacityByRGBA, isRGBA, removeAlpha } from './utils';

const ColorPickerFocusArea: React.FC<ColorPickerProps> = ({ onClose, onResult, color, ...rest }) => {
  const handleChange = (result: ColorResult) => onResult(result.hex);
  const handleClose = () => {
    const rgba = color?.toString() || '';
    if (isRGBA(rgba)) {
      const opacity = calculateOpacityByRGBA(rgba);
      if (opacity !== undefined && opacity < 10) {
        const rgb = removeAlpha(rgba);
        const newColor = applyOpacity(rgb, 10);
        onResult(newColor);
      }
    }
    onClose();
  };

  const pickerRef = useFocusArea({
    onExit: handleClose,
    active: true,
  });

  return (
    // @ts-ignore
    <RefContainer ref={pickerRef}>
      <ColorPicker
        ref={pickerRef}
        {...rest}
        color={color}
        onClose={handleClose}
        onResult={onResult}
        onChange={handleChange}
      />
    </RefContainer>
  );
};

const ColorPickerWrapper: React.FC<ColorPickerProps> = ({ isOpen, ...rest }) => {
  if (!isOpen) {
    return null;
  }

  return <ColorPickerFocusArea isOpen={isOpen} {...rest} />;
};

type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>;

export default ColorPickerWrapper as React.FC<MakeOptional<ColorPickerProps, 'onChange'>>;

import { getPrimaryToken } from 'services/auth';
import { getObjectId } from 'services/app/selectors';
import {
  writeToCollectionLegacy,
  editCollectionItem,
  getRefreshKey,
} from 'services/admin';
import { connect } from 'react-redux';
import {
  addPreviewOverlay,
  broadcastOverlay,
  editOverlay,
  removePreviewOverlay,
} from 'services/overlays';

import BroadcastModeSheet from './BroadcastModeSheet';

const mapStateToProps = state => ({
  objectId: getObjectId(state),
  primaryToken: getPrimaryToken(state),
  refreshKey: getRefreshKey(state),
});

const mapDispatchToProps = dispatch => ({
  addPreviewOverlay: (overlay) => {
    dispatch(addPreviewOverlay(overlay));
  },
  broadcastOverlay: (overlay) => {
    dispatch(broadcastOverlay(overlay));
  },
  editCollectionItem: (type, id, renderer, archivedTimestamp) => {
    dispatch(editCollectionItem(type, id, renderer, archivedTimestamp));
  },
  editOverlay: (overlay) => {
    dispatch(editOverlay(overlay));
  },
  onSaveNewOverlay: (overlayRenderer) => {
    const doc = {
      _id: overlayRenderer.overlayId,
      collection: 'overlays',
      data: { legacy: true },
      renderer: overlayRenderer,
      type: 'overlay',
    };
    dispatch(writeToCollectionLegacy(doc));
  },
  removeEditingOverlay: () => {
    dispatch(editOverlay(null));
  },
  removePreviewOverlay: () => {
    dispatch(removePreviewOverlay());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastModeSheet);

import React, { useCallback } from 'react';
import { useTable, Column, usePagination } from 'react-table';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import styled, { css } from 'styled-components';
import { DownArrow } from 'components/admin2/ui/Button';
import SectionError from './SectionError';
import SectionNoResults from './SectionNoResults';
import NumberPagination, { PaginationProps } from 'components/admin2/ui/NumberPagination';
import { ADMIN_SCROLL_BAR, ELLIPSIS } from 'style/mixins';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_3, ADMIN_TEXT_300, ADMIN_SURFACE_5, MAESTRO_WHITE } from 'style/constants';
import { ADMIN_TEXT_BODY_L_MEDIUM, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const ASC_KEY = 'asc';
export const DESC_KEY = 'desc';
export const NONE_KEY = 'none';

type Full<T> = {
  [P in keyof T]-?: T[P];
};
interface IProps<T extends object> {
  activeValue: string;
  columns: Column<T>[];
  data: T[];
  error: Error | null;
  loaded: boolean;
  order: string;
  sort: (value: any, data: T[], order: any) => void;
}

const SortIcon = styled(DownArrow as any).attrs(props => ({
  color: ADMIN_TEXT_300(props),
  spacing: '0 0 0 10px',
}))`
  ${({ active }) => active && css`
    & svg path {
      fill: ${ADMIN_ACCENT_PRIMARY};
    }` as any };
`;

const HeaderContainer = styled.div<{disabled: boolean}>`
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const DataContainer = styled.div`
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow: hidden;
  width: 100%;
  margin-bottom: 26px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
`;

const StyledTable = styled.table`
  color: white;
  flex-direction: column;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  overflow: hidden;
  text-align: left;
  width: 100%;
  height: 100%;
  display: flex;
`;

const StyledThead = styled.thead`
  display: table;
  table-layout: fixed;
  width: 100%;
  border-bottom: 1px solid ${ADMIN_SURFACE_5};
  color: #545A6D;
  margin-bottom: 27px;
  height: 33px;
  padding-bottom: 15px;
  ${ADMIN_TEXT_BODY_L_MEDIUM}
`;

const StyledTbody = styled.tbody`
  ${ADMIN_SCROLL_BAR}
  display: block;
  height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledTr = styled.tr`
  display: table;
  table-layout: fixed;
  max-height: 90px;
  min-height: 80px;
  border-radius: 5px;
  background: ${ADMIN_SURFACE_3};
  width: 100%;
  margin-bottom: 5px;
`;

const StyledTh = styled.th`
  ${ELLIPSIS};
  ${ADMIN_TEXT_BODY_S_BOLD}
  color: ${ADMIN_TEXT_300};
`;

const StyledTd = styled.th.attrs({
  maxLines: 2,
})`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  word-break: break-word;
  color: ${MAESTRO_WHITE};
  padding: 7px 10px 5px 10px;
`;

function DataTable<T extends object>({
  activeValue,
  sort,
  order,
  columns,
  data,
  error,
  loaded,
}: IProps<T>) {

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    ...instance
  } = useTable<T>({
    columns,
    data,
  }, usePagination);

  const {
    pageOptions,
    page,
    state,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = instance as any as Full<PaginationProps<T>>;

  const sortHandler = useCallback((value: string, videos: T[], newOrder: string) => (event: any) => {
    event.preventDefault();
    sort(value, videos, newOrder);
  }, []);

  return (
    <>
      <DataContainer>
        <Content>
          {!loaded ? (<LoadingSpinner colorOverride={'white'}/>) : null}
          {loaded && error ? (<SectionError message={error?.message} />) : null}
          {loaded && !error && !(data?.length) ? (<SectionNoResults />): null}
          {loaded && !error && data?.length ? (
            <StyledTable {...getTableBodyProps()} >
              <StyledThead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    // tslint:disable-next-line:jsx-key
                    <StyledTh style={{ width: column.width }} {...column.getHeaderProps()}>
                      <HeaderContainer disabled={column.id === 'thumbnail'} onClick={sortHandler(column.id, data, order)}>
                        {column.render('Header')}
                        {!(column.id === 'thumbnail') && (
                         <SortIcon up={order === ASC_KEY && column.id === activeValue} active={column.id === activeValue} />
                        )}
                      </HeaderContainer>
                    </StyledTh>
                  ))}
                </tr>
              ))}
              </StyledThead>
              <StyledTbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                 if(!row.original.name) {
                  return <StyledTr style={{ display: 'none' }}/>;
                }
                prepareRow(row);
                return (
                  // tslint:disable-next-line:jsx-key
                  <StyledTr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      // tslint:disable-next-line:jsx-key
                      return <StyledTd style={{ width: cell.column.width }} {...cell.getCellProps()}>{cell.render('Cell')}</StyledTd>;
                    })}
                  </StyledTr>
                );
              })}
              </StyledTbody>
            </StyledTable>
          ) : null}
        </Content>
      </DataContainer>
      <NumberPagination
        gotoPage={gotoPage}
        pageOptions={pageOptions}
        state={state}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
      />
    </>
  );
}

export default DataTable;

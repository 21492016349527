import withTooltip from 'components/core/withTooltip';
import Icon from 'components/ui/Icon';
import { ADMIN_SURFACE_6, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const ColorInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
`;

export const ColorName = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
`;

export const ColorCircle = styled.div<{ color: string; }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1px solid ${ADMIN_SURFACE_6};
  cursor: pointer;
`;

export const ColorNameWrapper = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon).attrs((props) => ({
  name: 'info',
  color: ADMIN_TEXT_200(props),
}))`
  margin-left: 10px;
  color: ${({ color }) => color};
  & > svg {
    width: 12px;
    height: 12px;
  }
`;

export const SurfaceColorInfoIcon = withTooltip(StyledIcon, {
  admin: true,
  tooltipArrowCss: 'left: 9%;',
  tooltipCss: 'width: 188px; text-align: left; margin-left: 165px;',
});

export const InfoIcon = withTooltip(StyledIcon, {
  admin: true,
  tooltipArrowCss: 'left: 9%;',
  tooltipCss: 'width: 164px; text-align: left; margin-left: 146px;',
});


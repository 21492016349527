// tslint:disable:no-console
import shortId from 'shortid';
import { injectT } from 'hooks/use-translation';
import calendarPoll from 'assets/calendar-poll.png';
import triviaImage1 from 'assets/trivia-option-1.png';
import triviaImage2 from 'assets/trivia-option-2.png';
import triviaImage3 from 'assets/trivia-option-3.png';
import triviaImage4 from 'assets/trivia-option-4.png';
import shopify from 'assets/shopify-image.png';
import calendarPoll2 from 'assets/calendar-poll2.png';
import lowerThird from 'assets/lower-third.png';
import { overlayKindMap } from './constants';

export const kinds = Object.entries(overlayKindMap).map(
  ([id, val]) => {
    const {
      iconName,
      infoKey,
      nameKey,
    } = val;
    return ({
      iconName,
      id,
      infoKey,
      nameKey,
    });
  },
);

export const getPreviewOverlay = injectT(
  (t) => (kind) => {
    const poll = {
      _id: '5beb93da1b532e0d004d5a0a',
      answers: [
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_POLL_ANSWER_1'),
          imageUrl: calendarPoll,
          pollId: '5beb93da1b532e0d004d5a0',
        },
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_POLL_ANSWER_2'),
          imageUrl: calendarPoll2,
          pollId: '5beb93da1b532e0d004d5a0',
        },
      ],
      name: t('ADMIN_OVERLAY_PREVIEW_POLL_TITLE'),
      open: true,
      question: t('ADMIN_OVERLAY_PREVIEW_POLL_QUESTION'),
      siteId: '59e514875e06264d1840b472',
      type: 'poll',
    };

    const trivia = {
      _id: '5becb203cff86b0d00bde8a4',
      answers: [
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_1'),
          correct: false,
          imageUrl: triviaImage1,
          pollId: '5becb203cff86b0d00bde8a4',
        },
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_2'),
          correct: false,
          imageUrl: triviaImage2,
          pollId: '5becb203cff86b0d00bde8a4',
        },
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_3'),
          correct: true,
          imageUrl: triviaImage3,
          pollId: '5becb203cff86b0d00bde8a4',
        },
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_TRIVIA_ANSWER_4'),
          correct: false,
          imageUrl: triviaImage4,
          pollId: '5becb203cff86b0d00bde8a4',
        },
      ],
      correctAnswerId: '',
      name: t('ADMIN_OVERLAY_PREVIEW_TRIVIA_TITLE'),
      open: true,
      question: t('ADMIN_OVERLAY_PREVIEW_TRIVIA_QUESTION'),
      siteId: '59e514875e06264d1840b472',
      type: 'trivia',
    };

    const prediction = {
      _id: '1234',
      answers: [
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_PREDICTION_ANSWER_1'),
          correct: false,
          pollId: '1234',
        },
        {
          _id: shortId.generate(),
          answer: t('ADMIN_OVERLAY_PREVIEW_PREDICTION_ANSWER_2'),
          correct: false,
          pollId: '1234',
        },
      ],
      correctAnswerId: '',
      name: t('ADMIN_OVERLAY_PREVIEW_PREDICTION_NAME'),
      open: true,
      question: t('ADMIN_OVERLAY_PREVIEW_PREDICTION_QUESTION'),
      siteId: '59e514875e06264d1840b472',
      type: 'prediction',
    };

    let renderer;
    let data: any = {};

    switch (kind) {
      case 'poll':
        renderer = {
          overlayType: 'poll',
          poll,
        };
        break;
      case 'poll_results':
        renderer = {
          overlayType: 'poll_results',
          poll,
        };
        data = {
          state: {
            1: 37,
            2: 63,
            count: 100,
            engaged: 100,
          },
        };
        break;
      case 'prediction':
        renderer = {
          overlayType: 'prediction',
          poll: prediction,
        };
        break;
      case 'lower_third':
        renderer = {
          imageUrl: lowerThird,
          overlayType: 'lower_third',
        };
        break;
      case 'shopify':
        renderer = {
          image: {
            src: shopify,
          },
          imageUrl: shopify,
          overlayType: 'shopify',
          product: {
            label: t('ADMIN_OVERLAY_PREVIEW_SHOPIFY_ITEM'),
          },
          variant: {
            label: t('ADMIN_OVERLAY_PREVIEW_SHOPIFY_ITEM_SIZE'),
            price: 20,
            value: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzU3NDk4MTg5NDk3OTg=',
          },
        };
        break;
      case 'raffle':
        renderer = {
          overlayType: 'raffle_active',
        };
        break;
      case 'highlight':
        renderer = {
          ctaRenderer: {
            overlayCtaType: 'show_player_stats',
            playerId: '0afbe608-940a-4d5d-a1f7-468718c67d91',
          },
          description: t('ADMIN_OVERLAY_PREVIEW_HIGHLIGHT_DESC'),
          imageUrl: '/media/v1/5bfc67697e931b0060edd810/5cd0cbb2b5015f0060e178a3.png',
          overlayType: 'highlight',
        };
        break;
      case 'message':
        renderer = {
          message: t('ADMIN_OVERLAY_PREVIEW_MESSAGE'),
          overlayType: 'message',
        };
        break;
      case 'trivia':
        renderer = {
          overlayType: 'trivia',
          poll: trivia,
        };
        break;
      case 'tweet':
        renderer = {
          overlayType: 'tweet',
          status: {
            id: '1058885493776113664',
            mediaUrl: 'https://pbs.twimg.com/media/DrHqud9U4AAgcs7.jpg',
            text: t('ADMIN_OVERLAY_PREVIEW_TWEET_TEXT'),
            user: {
              name: t('ADMIN_OVERLAY_PREVIEW_TWEET_USER_NAME'),
              profileImageUrl: 'https://pbs.twimg.com/profile_images/1037391493999210497/EVXmOtlV_normal.jpg',
              screenName: t('ADMIN_OVERLAY_PREVIEW_TWEET_USER_SCREENAME'),
            },
          },
          tweetUrl: 'https://twitter.com/overwatchleague/status/1058885493776113664',
        };
        data.renderer = renderer;
        break;
      default:
        console.warn(`${kind} overlay does not have a preview`);
    }

    if (!renderer) {
      return null;
    }

    return {
      key: `preview-${shortId.generate()}`,
      payload: {
        channel: 'preview',
        data,
        renderer,
      },
      timeRemaining: 0, // note: timeRemaining=0 when is default values to preview mode
    };
  },
);

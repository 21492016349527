import styled, { keyframes } from 'styled-components';

type AnimationProps = {
  component?: any,
  duration?:number,
  easing?: string,
  extraCss?: string,
  keyframes: string,
};

export const createAnimationComponent = ({
  keyframes: keyframesAnimation,
  component,
  easing: defaultEasing,
  duration: defaultDuration,
  extraCss,
}: AnimationProps) => (component ? styled(component) : styled.div)<{ animationDelay?: number, animationDuration: number, animationEasing: string }>`
  opacity: 0;
  animation: ${keyframes`${keyframesAnimation}`} ${({ animationDuration }) => animationDuration || defaultDuration || 0}s;
  animation-delay: ${({ animationDelay }) => animationDelay || 0}s;
  animation-fill-mode: forwards;
  ${({ animationEasing }) => (animationEasing || defaultEasing ? `animation-timing-function: ${animationEasing || defaultEasing};` : '')};
  ${extraCss}
`;

import React from 'react';
import GateToggle from 'components/admin-bridge/Toolbar/actions/GateToggle';
import AutoSave from 'components/admin-bridge/Toolbar/actions/AutoSave';
import Button from 'components/admin-bridge/Toolbar/actions/Button';
import PublishButton from 'components/admin-bridge/Toolbar/actions/PublishButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearEditTarget,
  publishPendingGateChanges,
  publishPendingChanges,
  setAdminMode,
} from 'services/admin/actions';
import useHandlers from 'components/admin-bridge/Toolbar/ToolbarView/Default/hooks/use-handlers';
import { ModeTypes } from 'services/admin/constants';
import { isEditing as isEditingSelector } from 'services/admin/selectors';

function AccessGateToolbar() {
  const dispatch = useDispatch();
  const isEditing = useSelector(isEditingSelector);

  const { clearPendingChanges } = useHandlers();

  const openEditor = () => dispatch(setAdminMode(ModeTypes.EDIT));

  const closeEditor = () => {
    dispatch(setAdminMode(ModeTypes.ACCESS_CONTROL));
    dispatch(clearEditTarget());
  };

  const onDiscardChanges = () => {
    clearPendingChanges();
  };

  return (
    <>
      <GateToggle
        shouldRender={true}
      />
      {isEditing ? (
        <>
          <AutoSave onClick={onDiscardChanges} shouldRender={true} />
          <Button
            onClick={closeEditor}
            data-testid="exitEditorButton"
            stringKey="ADMIN_ACTION_EXIT_EDITOR"
          />
          <PublishButton shouldRender={true} />
        </>
      ) : (
        <Button
          onClick={openEditor}
          data-testid="editGate"
          stringKey="ADMIN_LABEL_EDIT_GATE"
        />
      )}
    </>
  );
}

export default {
  toolbarRight: AccessGateToolbar,
};

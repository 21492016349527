import styled, { css } from 'styled-components';
import { CloseButton } from 'components/admin2/ui/Button';
import {
  ADMIN_TEXT_100,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_SURFACE_4,
  ADMIN_TEXT_200,
  SPACING_SMALL,
  ADMIN_SURFACE_1,
  ADMIN_FONT_FAMILY,
  ADMIN_HIGHLIGHT_SECONDARY,
} from 'style/constants';
import { ADMIN_SCROLL_BAR, ADMIN_THIN_SCROLL_BAR } from 'style/mixins';
import Icon from 'components/ui/Icon';
import withTooltip from 'components/core/withTooltip';
import { LargeButton } from 'components/admin2/ui/Button';
import ActionButton from 'components/admin2/ActionButton';
import { ADMIN_TEXT_BODY_M_BOLD, ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_HEADLINE_S, ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import Card from 'components/ui/Card';
import TextInput from '../TextInput';
import { MEDIA_LIBRARY_TABLE_TBODY } from 'css-classes';

export const AccessControlModalContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  font-family: ${ADMIN_FONT_FAMILY};
  color: ${ADMIN_TEXT_100};
  background-color: ${ADMIN_SURFACE_4};
  border-radius: 10px;
  max-height: calc(100vh - 50px) !important;
  ${ADMIN_THIN_SCROLL_BAR};

  & .${MEDIA_LIBRARY_TABLE_TBODY} {
    max-height: 50vh;
  }
`;

export const AccessControlTitle = styled.h1`
  ${ADMIN_TEXT_HEADLINE_S}
`;

export const AccessControlDescription = styled.p`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: ${ADMIN_TEXT_200};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const AdvancedOptionsText = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  text-decoration-line: underline;
  cursor: pointer;
  color: ${ADMIN_ACCENT_PRIMARY};

  &:hover {
    color: ${ADMIN_TEXT_100};
  }
`;

export const ChangeGateText = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  text-decoration-line: underline;
  cursor: pointer;
  color: ${ADMIN_ACCENT_PRIMARY};

  &:hover {
    color: ${ADMIN_TEXT_100};
  }
`;

export const CardItemsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 0.625rem;
  flex-wrap: wrap;
`;

export const CheckMarkIcon = styled(Icon)`
  svg {
    height: 20px;
    width: 20px;
  }

  svg circle {
    fill: ${ADMIN_ACCENT_PRIMARY};
  }

  svg path {
    fill: ${ADMIN_TEXT_100};
  }
`;

export const AccessControlCardItem = styled(Card)`
  display: flex;
  padding: 25px 30px 40px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch !;
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  gap: 10px;
  height: 28px;

  div:last-child {
    margin-left: auto;
  }
`;

export const ExitButton = styled(CloseButton)``;

export const PasswordSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
`;

export const AmazonBenefitSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
`;

export const LogInSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  overflow: visible;
`;

export const GateSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const GateSelection = styled.div`
  display: flex;
  padding: 5px 4px;
  width: auto;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  align-items: center;
  align-self: stretch;
  border-radius: 50px;
  background: var(--colors-surface-surface-05, #363C4D);
`;

export const IconContainer = styled.div`
  display: flex;
  width: 48px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const GateIcon = styled(Icon)`
  svg {
    width: 20px;
  }
`;

export const GateSettingsTitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  wrap: nowrap;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const GateSettingsTitleText = styled.span`
  ${ADMIN_TEXT_BODY_M_BOLD}
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
`;

export const PasswordText = styled.span`
  ${ADMIN_TEXT_BODY_M_BOLD}
`;

export const GateSettingsDescription = styled.p`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${ADMIN_TEXT_200};
`;

export const GateSettingsFieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
`;

export const GateSettingsFieldLabel = styled.span`
  color: var(--colors-texts-text-100, #F3F7FF);

  /* Labels/medium/medium - m */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
`;

export const PasswordInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const WarningIcon = styled(Icon).attrs({
  name: 'warning',
})`
  display: flex;
  margin-right: 6.27px;
  & svg {
    width: 10px;
    height: 8px;
    & > path {
      fill: ${ADMIN_TEXT_100};
    }
  }
`;

export const LockIcon = styled(Icon).attrs({
  name: 'nav2Access',
})`
  svg {
    height: 24px;
    width: 24px;
  }

  svg path {
    fill: ${ADMIN_TEXT_100};
  }
`;

export const AdvancedOptionsWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
`;

export const AccessCodeText = styled.strong`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
`;

export const NoSubscriptionsText = styled.div`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  text-align: center;
  margin-bottom: 31px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
`;

export const WideButton = styled(ActionButton)`
  width: 100%;
  justify-content: center;
  padding: 12px 15px;
  background-color: transparent;
  margin-top: ${SPACING_SMALL};
  font-size: 14px !important;
  line-height: 14px !important;
  color: ${ADMIN_TEXT_100} !important;
  border: 1.5px solid ${ADMIN_TEXT_100};
  border-radius: 5px;

  &:hover {
    background-color: ${ADMIN_SURFACE_1};
    border-color: ${ADMIN_SURFACE_1};
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
`;

export const DoneButton = styled(LargeButton)`
  width: 165px;
`;

export const NextButton = styled(LargeButton)`
  width: 165px;
`;

export const ChangesLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoVisibleEntitlementsWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${ADMIN_TEXT_100};
  width: 100%;
  display: flex;
  margin: 15px 0 30px 0;
  justify-content: center;
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
`;

const StyledInfoIcon = styled(Icon).attrs(props => ({
  name: 'info',
  color: ADMIN_HIGHLIGHT_SECONDARY(props),
}))`
  margin: 0 15px;

  & svg path {
    fill: ${ADMIN_HIGHLIGHT_SECONDARY};
  }
`;

export const TooltipInfoIcon = withTooltip(StyledInfoIcon, {
  tooltipArrowCss: 'left: 50%;',
  admin: true,
  tooltipWrapperCss: css`& div a { color: ${ADMIN_ACCENT_PRIMARY} !important; }`,
});

export const TicketSubscriptionBundleGateSettingsWrapper = styled.div`
  width: 100%;
  .-header {
    padding: 0;

    .rt-tr {
      background: none;
    }
  }

  .rt-tbody {
    > div {
      margin: 0 !important;
    }
  }
`;

export const TupleContainer = styled.div`
  display: flex;
  flex: 1
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  margin-bottom: 10px;
  margin-right: 5px;
`;

export const MultipleBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--corner-radius-radius-none, 0px);
`;

export const MultipleBenefitsLabel = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${ADMIN_TEXT_200};
`;

export const SeriesBenefitIdentifier = styled.div`
  display: flex;
  width: var(--max-heights-height-m, 44px);
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--corner-radius-radius-none, 0px);
  background: var(--colors-surface-surface-05, #363C4D);
`;

export const SeriesBenefitContainer = styled.div`
  display: flex;
  padding: 10px 14px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: var(--corner-radius-radius-none, 0px);
  border-right: 1px solid var(--colors-surface-surface-06, #3E4759);
  border-left: 1px solid var(--colors-surface-surface-06, #3E4759);
  background: var(--colors-surface-surface-05, #363C4D);
`;

export const SeriesBenefitInputGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: var(--corner-radius-radius-none, 0px);
`;

export const SeriesBenefitInputLabel = styled.span`
  color: var(--colors-texts-text-100, #F3F7FF);
  width: 12%;
  font-family: ${ADMIN_FONT_FAMILY};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const SeriesBenefitInputContainer = styled.div`
  display: flex;
  height: 40px;
  width: 85%;
  align-self: stretch;
  border-radius: 4px;
  background: var(--colors-surface-surface-03, #1B1E26);
`;

export const SeriesBenefitInput = styled(TextInput).attrs({
  padding: '5px 0px 5px 16px',
  stretch: true,
  type: 'text',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px !important;
  flex: 1 0 0;
  padding-top: 5px;
  align-self: stretch !important;
  border-radius: var(--corner-radius-radius-none, 0px) !important;
  color: var(--colors-texts-text-200, #C2C5CC);
  font-family: ${ADMIN_FONT_FAMILY};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const CopyIcon = styled(Icon).attrs({
  color: 'white',
  name: 'copy',
})`
  & > svg {
    width:  15px;
    height: 15px;
    & * {
      stroke: none;
    }
  }
`;

export const CopyIconContainer = withTooltip(
  styled.div`
    padding: 0 10px;
    cursor: pointer;
  `,
  { admin: true },
);

export const SeriesBenefitDeleteButton = styled.div`
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--corner-radius-radius-none, 0px);
  background: var(--colors-surface-surface-05, #363C4D);
`;

export const RemoveIcon = styled(Icon)
  .attrs({
    name: 'removeIconOutline',
  })``;

export const PlusIcon = styled(Icon).attrs({
  name: 'plus-thin',
})`
  width: 10px;
  height: 10px;
  position: relative;
  top: 2px;
  right: 2px;
  align-items: center;
  justify-content: center;

  & svg {
    width: 10px;
    height: 10px;
    & path {
      fill: ${ADMIN_TEXT_100};
    }
  }
`;

export const AddBenefitButton = styled(WideButton)`
  display: flex;
`;

export const TupleSet = styled.div`
  display: flex;
  position: relative;
  max-height: 240px;
  flex-direction: column;
  align-self: stretch;
  ${ADMIN_SCROLL_BAR}
`;

export const InstructionsItem = styled.li`
  color: ${ADMIN_TEXT_200}
  ${ADMIN_TEXT_BODY_M_MEDIUM}
`;

import React from 'react';
import { IAudience, IWatchBehavior, IRevenue } from './types';
import { abbrNumber, formatToTime, secondsToHour } from '../../utils';
import Separator from './separator/index';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  ToplineMetricsContainer,
  MetricName,
  MetricsRow,
  UniqueViewers,
  LoggedInUsers,
  AnonymousDevices,
  PeakConcurrents,
  AverageConcurrents,
  LiveHours,
  OnDemandHours,
  AverageWatchTime,
  Sessions,
  GrossEarnings,
  MonetizedUniques,
  AEPU,
  UniqueVisitors,
  ViewerHours,
} from './styles';

type Props = {
  audience: IAudience;
  broadcastTimeTotalHours: number;
  revenue: IRevenue;
  scrollState: boolean;
  setScrollState: (state: boolean) => void;
  watchBehavior: IWatchBehavior;
};

const ToplineMetrics = ({ audience, watchBehavior, revenue, scrollState, setScrollState }: Props) => {
  const maxSiteVisitor = Math.max(watchBehavior.total.viewers.uniques.totalViewers, audience.viewersUnique);
  return (
    <ToplineMetricsContainer>
      <MetricsRow>
        <UniqueVisitors disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_UNIQUE_VISITOR'} variant="lighted" icons={['analyticsEye']} value={abbrNumber(maxSiteVisitor)} />
      </MetricsRow>
      <MetricName>
        <TranslatedText stringKey={'ANALYTICS_LABEL_AUDIENCE'} />
      </MetricName>
      <MetricsRow>
        <UniqueViewers disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_UNIQUE_VIEWERS'} variant="lighted" icons={['analyticsEye']} value={abbrNumber(watchBehavior.total.viewers.uniques.totalViewers)} />
        <Separator color="primary" symbol="=" />
        <LoggedInUsers disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_LOGGED_IN_USERS'} value={abbrNumber(watchBehavior.total.viewers.uniques.loggedInViewers)} />
        <Separator symbol="+" />
        <AnonymousDevices disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_ANONYMOUS_DEVICES'} value={abbrNumber(watchBehavior.total.viewers.uniques.anonymousViewers)} />
        <Separator />
        <PeakConcurrents disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_PEAK_CONCURRENTS'} value={abbrNumber(audience.concurrentsPeak)} />
        <AverageConcurrents disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_AVERAGE_CONCURRENTS'} value={abbrNumber(audience.concurrentsAverage)} />
      </MetricsRow>
      <MetricName>
        <TranslatedText stringKey={'ADMIN_INSIGHTS_LABLE_WATCH_BEHAVIOR'} />
      </MetricName>
      <MetricsRow>
        <ViewerHours disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_VIEWERS_HOURS'} variant="lighted" icons={['analyticsClock']} value={`${abbrNumber(secondsToHour(watchBehavior.total.durations.totalWatchSeconds))} Hrs`} />
        <Separator color="primary" symbol="=" />
        <LiveHours disable={scrollState} setScrollState={setScrollState} tip={true} size={'small'} description={'ANALYTICS_LABEL_LIVE_HOURS'} value={`${abbrNumber(secondsToHour(watchBehavior.total.durations.livestreamWatchSeconds))} Hrs`} />
        <Separator symbol="+" />
        <OnDemandHours disable={scrollState} setScrollState={setScrollState} tip={true} description={'ADMIN_INSIGHTS_LABLE_ON_DEMAND_HOURS'} value={`${abbrNumber(secondsToHour(watchBehavior.total.durations.vodWatchSeconds))} Hrs`} />
        <Separator />
        <AverageWatchTime disable={scrollState} setScrollState={setScrollState} tip={true} size={'large'} description={'ANALYTICS_LABEL_AVERAGE_WATCH_TIME'} value={formatToTime(watchBehavior.total.durations.averageWatchTimeSeconds)} />
        <Sessions disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_SESSIONS'} value={abbrNumber(watchBehavior.total.viewers.uniques.sessions)} />
      </MetricsRow>
      <MetricName>
        <TranslatedText stringKey={'ADMIN_INSIGHTS_LABLE_REVENUE'} />
      </MetricName>
      <MetricsRow>
        <GrossEarnings disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_GROSS_EARNINGS'} variant="yellow" value={`$${abbrNumber(revenue.revenueGross)}`} />
        <MonetizedUniques disable={scrollState} setScrollState={setScrollState} tip={true} description={'ANALYTICS_LABEL_MONETIZED_UNIQUES'} value={`${abbrNumber(revenue.uniquesMonetizedTotal)}`} />
        <AEPU disable={scrollState} setScrollState={setScrollState} tip={true} size={'large'} description={'ANALYTICS_LABEL_AEPU'} value={`$${abbrNumber(revenue.revenueAveragePerUnique)}`} />
      </MetricsRow>
    </ToplineMetricsContainer>
  );
};

export default ToplineMetrics;

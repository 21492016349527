import React from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_XL_MEDIUM } from 'style/design-system/textStyles';

interface IProps {
  message: string;
}

const Exterior = styled.div`
  flex-grow: 1;
  height: 100%;
`;

const CancelIcon = styled(Icon)`
  font-size: 25px;
  margin-bottom: 10px;
`;

const Interior = styled.div`
  ${ADMIN_TEXT_BODY_XL_MEDIUM}
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const SectionError = ({
  message,
}: IProps) => {
  return (
    <Exterior>
      <Interior>
        <CancelIcon name={'cancel-thin'} />
        {message}
      </Interior>
    </Exterior>
  );
};

export default SectionError;

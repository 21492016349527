import styled, { css } from 'styled-components';
import Dropdown from 'components/admin2/ui/Dropdown';
import { DISABLED } from 'style/mixins';
import { PaddingWrapper } from 'components/core/withPadding/styles';

interface DatetimePickerStyleProps {
  rowAlign?: boolean;
  verticalTime?: boolean;
}

export const Column = styled.div`
  display: flex;
  flex: 1;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const InlineContainer = styled.div<{isGridLayout: boolean}>`
  display: grid;
  grid-template-columns: ${({ isGridLayout }) =>
    isGridLayout ? '1fr 1fr' : 'auto max-content auto'};
  grid-column-gap: 20px;

  ${({ isGridLayout }) => {
    return isGridLayout && css`
      div:last-child {
        grid-column: 1 / span 2;
      }

      margin-bottom: 17px;
    `;
  }}

  & ${PaddingWrapper} {
    width: unset;
  }
`;

export const Container = styled.div<DatetimePickerStyleProps>`
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  ${({ rowAlign, verticalTime }) => css`
  flex-direction: ${rowAlign ? 'column' : 'row'};
  margin-right: -10px;
  ${verticalTime && `
    flex-direction: column;
  `}
    & ${Column} {
      flex-direction: ${rowAlign ? 'row' : 'column'};
      margin-right: 10px;
      & ${SelectContainer} {
        flex-direction: ${rowAlign ? 'row' : 'column'};
        ${rowAlign && `
          & > *:first-child {
            padding-right: 5px;
          }
        `}
      }
    }
  `}
`;

export const TimeSelect = styled(Dropdown)`
  margin-bottom: 5px;
  ${props => props.isDisabled ? DISABLED : ''};
`;

export const ZoneSelect = styled(Dropdown)`
  ${props => props.isDisabled ? DISABLED : ''}
`;

import moment from 'moment-timezone';

const SUPPORTED_REGIONS = [
  'Africa',
  'America',
  'Asia',
  'Atlantic',
  'Australia',
  'Europe',
  'Indian',
  'Pacific',
];

export const formatTime = (hr: number, min: number) => {
  if (hr === 0) {
    return { label: `12:${(`0${min}`).slice(-2)} AM`, time: { hour: 0, minute: min, second: 0 } };
  }

  const ampm = hr >= 12 ? 'PM' : 'AM';
  const formattedHr = hr > 12 ? hr - 12 : hr;
  const label = `${formattedHr}:${(`0${min}`).slice(-2)} ${ampm}`;

  return { label, time: { hour: hr, minute: min, second: 0 } };
};

export const formatFiveMinuteTime = (hr: number, min: number) => {
  const minString = min === 0 || min === 5 ? `0${min}` : String(min);
  if (hr === 0) {
    return { label: `12:${minString} AM`, time: { hour: 0, minute: min, second: 0 } };
  }

  const ampm = hr >= 12 ? 'PM' : 'AM';
  const formattedHr = hr > 12 ? hr - 12 : hr;
  return { label: `${formattedHr}:${minString} ${ampm}`, time: { hour: hr, minute: min, second: 0 } };
};

export const parseRegion = (timezone: string) => {
  const [region, ...cityArr] = timezone.split('/');
  if (!region || !SUPPORTED_REGIONS.includes(region)) {
    return null;
  }
  const zone = cityArr.join('/').replace(/_/gi, ' ');

  return { region, zone };
};

export const TIMEZONE_OPTIONS = moment.tz.names().reduce((output: Record<string, { options: { label: string, timezone: string }[] }>, timezone = '') => {
  const newOutput = { ...output };
  const { zone, region } = parseRegion(timezone) || {};
  if (region) {
    const timezoneAbbr = moment.tz(timezone).format('z');
    const utcDifference = moment.tz(timezone).format('Z');
    const zoneNumber = Number(timezoneAbbr);
    const momentAbbrExists = zoneNumber !== 0 && !zoneNumber;

    const regionData = newOutput[region] || { label: region, options: [] };
    const { options: regionZones } = regionData;
    newOutput[region] = {
      ...regionData,
      options: [
        ...regionZones,
        {
          label: `${zone} (${momentAbbrExists ? timezoneAbbr : `UTC${utcDifference}`})`,
          timezone,
        },
      ],
    };
  }
  return newOutput;
}, {});

export const computeTimestampFromInfo = ({
  dayMoment,
  hour,
  minute,
  second,
}: {
  dayMoment?: moment.Moment | null,
  hour: number,
  minute: number,
  second: number,
}) => {
  const m = moment(dayMoment);
  m.hour(hour);
  m.minute(minute);
  m.second(second);
  return m.valueOf();
};

export const computeInfoFromDatetime = (
  timecode: number | null,
  timezone: string,
  showSeconds = false,
) => {
  const m = moment(timecode).tz(timezone, true);
  const dayMoment = timecode ? moment(timecode).tz(timezone, true).startOf('day') : null;
  const hour = m.hour();
  const minute = m.minute();
  const second = showSeconds ? m.second() : 0;

  return {
    dayMoment,
    hour,
    minute,
    second,
    timezone,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SPACING_X_SMALL } from 'style/constants';
import { ADMIN_TEXT_TITLE_L } from 'style/design-system/textStyles';

const Title = styled.div`
  ${ADMIN_TEXT_TITLE_L}
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: ${SPACING_X_SMALL} 0;
`;

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: undefined,
};

// TODO i18n
export default function AdminBarTitle({ className, children }) {
  return (
    <Title className={className}>{children}</Title>
  );
}

AdminBarTitle.propTypes = propTypes;
AdminBarTitle.defaultProps = defaultProps;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'hooks';
import { getDefaultRange, getRangeTypeRange } from 'services/analytics';
import { IRange, Ranges } from 'services/analytics/models';
import ToplineMetrics from './toplineMetrics';
import { AnalyticsContainer, Exterior, Interior, LoadingWrapper, SectionSeparator } from './styles';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import Section from './Section';
import SectionError from './SectionError';
import useToplineMetrics from './hooks/use-topline-metrics';
import Header from '../Header';
import RangeSelectorTitle from '../RangeSelectorTitle';
import EngagmentBreakdown from './EngagmentBreakdown/view';
import EngagementImpact from './EngagementImpact';
import ViewershipBreakdown from './ViewershipBreakdown';
import useEngagementImpact from './hooks/use-engagement-impact';
import useEngagementBreakdown from './EngagmentBreakdown/hooks';
import useViewershipBreakdown from './hooks/use-viewership-breakdown';
import SectionNoResults from '../Videos/components/SectionNoResults';
import { setOneDayRange } from 'services/analytics';
import { useDispatch } from 'hooks';

function Insights() {
  const defaultRange: IRange = useSelector(getDefaultRange);
  const activeRange: Ranges = useSelector(getRangeTypeRange);
  const [rangePickerActive, setRangePickerActive] = useState(true);
  const [scrollState, setScrollState] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOneDayRange());
  }, []);

  const handleActive = () => {
    setRangePickerActive(false);
  };

  const handleScroll = () => {
    setScrollState(true);
  };

  const { data, loaded, error } = useToplineMetrics(defaultRange.rangeStart, defaultRange.rangeEnd);
  const { data: impactData, loaded: impactLoaded, error: impactError } = useEngagementImpact(defaultRange.rangeStart, defaultRange.rangeEnd);
  const { data: breakDownData, loaded: breakDownLoaded, error: breakDownError } = useEngagementBreakdown(defaultRange.rangeStart, defaultRange.rangeEnd);
  const { data: viewershipData, loaded: viewershipLoaded, error: viewershipError } = useViewershipBreakdown(defaultRange.rangeStart, defaultRange.rangeEnd);

  return (
    <Exterior onClick={handleActive} onScroll={handleScroll}>
      <Interior>
        <AnalyticsContainer>
          <Header />
          <RangeSelectorTitle
            stringKey="ADMIN_LABEL_INSIGHTS"
            activeRange={activeRange}
            startTime={defaultRange.rangeStart}
            endTime={defaultRange.rangeEnd}
            rangePickerActive={rangePickerActive}
            setRangePickerActive={setRangePickerActive}
          />
          <Section
            title={'TOPLINE_METRICS'}
            description={'TOPLINE_METRICS_DESCRIPTION'}
            icon={'topLineMetrics'}
          >
            {!loaded ? (<LoadingWrapper><LoadingSpinner colorOverride={'white'}/> </LoadingWrapper>): null}
            {loaded && error ? (<LoadingWrapper><SectionError message={error?.message} /></LoadingWrapper>) : null}
            {loaded && !error && !(data) ? (<SectionNoResults />): null}
            {data && (<ToplineMetrics {...data} scrollState={scrollState} setScrollState={setScrollState} />)}
          </Section>
          <SectionSeparator/>
          <Section
            title={'TOPLINE_ENGAGEMENT'}
            description={'ENGAGEMENT_IMPACT_DESCRIPTION'}
            icon={'magnet'}
          >
            {!impactLoaded ? (<LoadingWrapper><LoadingSpinner colorOverride={'white'}/> </LoadingWrapper>): null}
            {impactLoaded && impactError ? (<LoadingWrapper><SectionError message={impactError?.message} /></LoadingWrapper>) : null}
            {impactLoaded && !impactError && !(impactData?.audience?.uniquesAll) ? (<SectionNoResults />): null}
            {impactLoaded && !impactError && impactData?.audience?.uniquesAll ? <EngagementImpact {...impactData} /> : null}
          </Section>
          <SectionSeparator/>
          <Section
            title={'ENGAGEMENT_BREAKDOWN'}
            description={'ENGAGEMENT_BREAKDOWN_DESCRIPTION'}
            icon={'magnet'}
          >
            {!breakDownLoaded ? (<LoadingWrapper><LoadingSpinner colorOverride={'white'}/></LoadingWrapper>): null}
            {breakDownLoaded && breakDownError ? (<LoadingWrapper><SectionError message={breakDownError?.message} /></LoadingWrapper>) : null}
            {breakDownLoaded && !breakDownError && !(breakDownData?.panels?.length) ? (<SectionNoResults />): null}
            {breakDownLoaded && !breakDownError && breakDownData?.panels?.length ? <EngagmentBreakdown  {...breakDownData} /> : null}
          </Section>
          <SectionSeparator/>
          <Section
            title="VIEWERSHIP_BREAKDOWN_TITLE"
            description="VIEWERSHIP_BREALDOWN_DESCRIPTION"
            icon="viewershipEye"
          >
            {!viewershipLoaded ? (<LoadingWrapper><LoadingSpinner colorOverride={'white'}/></LoadingWrapper>): null}
            {viewershipLoaded && viewershipError ? (<LoadingWrapper><SectionError message={viewershipError?.message} /></LoadingWrapper>) : null}
            {viewershipLoaded && !viewershipError && !(viewershipData?.countries?.length) ? (<SectionNoResults />): null}
            {viewershipLoaded && !viewershipError && viewershipData?.countries?.length ? <ViewershipBreakdown breakdownData={viewershipData} /> : null}
          </Section>
        </AnalyticsContainer>
     </Interior>
    </Exterior>

  );
}

export default Insights;

import styled from 'styled-components';
import Flag from 'react-world-flags';
import { ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_WARNING, ADMIN_SURFACE_3, ADMIN_TEXT_200 } from 'style/constants';
import { ADMIN_TEXT_BODY_L_BOLD, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const ItemContainer = styled.div<{ hasCountryCode: boolean }>`
  display: grid;
  align-items: center;
  margin: 15px 0;
  grid-template-columns: ${({ hasCountryCode }) =>
    hasCountryCode ? 'auto auto auto' : ' auto auto'}  8fr auto;
`;

export const Rank = styled.div<{hasCountryCode: boolean}>`
  ${ADMIN_TEXT_BODY_L_BOLD}
  color: ${ADMIN_TEXT_200};
  margin-right: 10px;
`;

export const Name = styled.div<{title: string}>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  color: ${ADMIN_TEXT_200};
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  overflow: hidden;
  margin-right: 10px;
  width: ${({ title }) => title === 'TOP_FIVE_COUNTRIES' ? '90px' : title === 'TECHNOLOGY' ? '52px': '37px'};
`;

export const ProgressBarContainer = styled.div`
  height: 10px;
  background: ${ADMIN_SURFACE_3};
  border-radius: 5px;
  margin-right: 17px;
`;

export const ProgressBar = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => (percentage > 100 ? 100 : percentage)}%;
  height: 10px;
  background: linear-gradient(81.1deg, ${ADMIN_ACCENT_PRIMARY} -0.04%, #919aff 99.41%);
  border-radius: 5px;
`;

export const Percentage = styled.div`
  ${ADMIN_TEXT_TITLE_M}
  text-align: right;
  color: ${ADMIN_ALERT_WARNING};
  min-width: 55px;
`;

export const StyledFlag = styled(Flag)`
  width: 28px;
  height: 20px;
  object-fit: cover;
  margin-right: 13px;
`;

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITheme } from 'models';
import TranslatedText from 'components/i18n/TranslatedText';
import { deleteTheme, setDefaultSiteTheme, setForkedTheme } from 'services/themes';
import { setDraftTheme, getTheme,  getSettingsDefaultThemeId, setPreviewActiveTheme } from 'services/admin';

import ActiveTheme from './Sections/ActiveTheme';
import AdvancedThemes from './Sections/AdvancedThemes';
import ClassicThemes from './Sections/ClassicThemes';
import CustomThemes from './Sections/CustomThemes';
import { StyledTextBlock, Title } from './styles';

const DesignTab = () => {
  const activeTheme = useSelector(getTheme);
  const defaultThemeId = useSelector(getSettingsDefaultThemeId);
  const dispatch = useDispatch();

  const activeThemeId = useMemo(() => activeTheme._id!, [activeTheme]);

  const handleCustomizeTheme = (theme: ITheme) => {
    dispatch(setForkedTheme({ theme }));
    dispatch(setDraftTheme(theme));
  };

  const handleDeleteTheme = (theme: ITheme) => {
    dispatch(deleteTheme({ themeId: theme._id! }));
  };

  const handleSelectTheme = (theme: ITheme) => {
    if (activeTheme._id !== theme._id) {
      dispatch(setPreviewActiveTheme(theme));
    }
  };

  const handleFavoriteTheme = (theme: ITheme) => {
    if (defaultThemeId !== theme._id) {
      dispatch(setDefaultSiteTheme({ themeId: theme._id! }));
    }
  };

  return (
    <>
      <TranslatedText component={Title} stringKey="ADMIN_DESIGN_TAB_TITLE" />
        <TranslatedText
          component={StyledTextBlock}
          componentProps={{ compact: true }}
          stringKey="ADMIN_DESIGN_TAB_DESCRIPTION"
        />
        <ActiveTheme activeTheme={activeTheme} onCustomize={handleCustomizeTheme} />
        <ClassicThemes
          activeThemeId={activeThemeId}
          defaultThemeId={defaultThemeId}
          onSelect={handleSelectTheme}
          onFavorite={handleFavoriteTheme}
          onCustomize={handleCustomizeTheme}
        />
        <CustomThemes
          activeThemeId={activeThemeId}
          defaultThemeId={defaultThemeId}
          onDelete={handleDeleteTheme}
          onSelect={handleSelectTheme}
          onFavorite={handleFavoriteTheme}
          onCustomize={handleCustomizeTheme}
        />
        <AdvancedThemes
          activeThemeId={activeThemeId}
          defaultThemeId={defaultThemeId}
          onSelect={handleSelectTheme}
          onFavorite={handleFavoriteTheme}
          onCustomize={handleCustomizeTheme}
        />
    </>
  );
};

export default DesignTab;

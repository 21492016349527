import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { ADMIN_TEXT_BODY_XS_REGULAR, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import { ADMIN_TEXT_100, ADMIN_TEXT_200 } from 'style/constants';
import TextInput from 'components/admin2/TextInput';
import { Radio } from 'components/admin2/ui/Radio';
import { LabelText } from 'components/admin2/ui/Checkbox/styles';
import AdminBarTabHeader, { AdminBarTab } from '../ui/AdminBarTabHeader';

export const StyledRadio = styled(Radio)`
  & ${LabelText} {
    ${ADMIN_TEXT_LABEL_M_MEDIUM};
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const HeaderTitle = styled.h1`
  all: unset;
  ${ADMIN_TEXT_LABEL_M_BOLD}
  color: ${ADMIN_TEXT_100};
`;

export const HeaderDescription = styled.h1`
  all: unset;
  ${ADMIN_TEXT_BODY_XS_REGULAR}
  color: ${ADMIN_TEXT_200};
`;

export const StyledTextInput = styled(TextInput)<{ $pageLevel?: boolean }>`
  & input {
    margin-top: 12px;
  }
  ${(p) => (
    p.$pageLevel
      ? css`
        margin-left: 28px;
      `
      : ''
  )}
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const Wrapper: FC<PropsWithChildren<{ pageLevel: boolean }>> = (
  {
    pageLevel,
    children,
  },
) => {
  if (pageLevel) {
    return (
      <div>
        {children}
      </div>
    );
  }

  return (
    <AdminBarTab style={{ height: '100%' }}>
      <AdminBarTabHeader
        headerKey="SITE_WIDE_AD_SETTINGS"
        hasUnsavedChanges={false}
      />
      {children}
    </AdminBarTab>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { getStripePaymentMethods, getContract } from 'services/billing';
import PaymentMethods from 'components/payment-methods/PaymentMethodContainer';
import TranslatedText from 'components/i18n/TranslatedText';
import { STRIPE_SS_PUBLISHABLE_KEY } from 'config';
import { StyledMethodTitle, TipText1 } from '../styles';
import StripeElements from 'components/application/StripeElements';
import { getAdminTheme } from 'services/themes';

const BillingCreditCards: React.FC = () => {
  const creditCards = useSelector(getStripePaymentMethods);
  const contract = useSelector(getContract);
  const adminTheme = useSelector(getAdminTheme);
  const defaultPayment = contract?.paymentMethod ? contract.paymentMethod : '';

  return (
    <StripeElements
      apiKey={STRIPE_SS_PUBLISHABLE_KEY as string}
      theme={adminTheme}
    >
      <TranslatedText
        stringKey="ADMIN_SETTINGS_BILLING_PAY_HEADER_TITLE"
        component={StyledMethodTitle}
      />
      <TranslatedText stringKey="ADMIN_SETTINGS_BILLING_PAY_HEADER_TEXT" component={TipText1} />
      <PaymentMethods
        addCard={true}
        defaultPaymentId={defaultPayment}
        payObjects={creditCards}
        showForAdmin={true}
        hideTitle={true}
      />
    </StripeElements>
  );
};

export default BillingCreditCards;

import React, { useCallback } from 'react';
import styled from 'styled-components';
import hash from 'json-stable-stringify';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_3, ADMIN_TEXT_400, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_REGULAR } from 'style/design-system/textStyles';

export enum Ranges {
  CUSTOM = 'custom',
  ONE_DAY = 'one-day',
  SEVEN_DAYS = 'seven-days',
  THIRTY_DAYS = 'thirty-days',
  NINETY_DAYS = 'ninety-days',
}

interface IProps {
  active: boolean;
  displayName: string;
  left: boolean;
  onClick: (range: any) => void;
  range: Ranges;
  right: boolean;
}

const Container = styled.div<{
  active: boolean;
  left: boolean;
  range: Ranges;
  right: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ active }) => (
    active ? '5px' : 'unset'
  )};
  border-bottom-left-radius: ${({ active, left }) => left && !active  ? '5px !important' : 'none'};
  border-bottom-right-radius: ${({ active, right }) => right && !active  ? '5px !important' : 'none'};
  font-size: 16px;
  background-color: ${({ active, ...props }) => (
    active ? ADMIN_ACCENT_PRIMARY(props) : ADMIN_SURFACE_3
  )};
  color: ${({ active }) => active ? ADMIN_TEXT_100 : ADMIN_TEXT_400};
  cursor: pointer;
  ${ADMIN_TEXT_BODY_S_REGULAR}
  padding: 15px;
  width: 160px;
  height: 29px;
  text-align:center;
  &:hover {
    color: white;
  }
`;

const RangeButton = ({
  active,
  displayName,
  onClick,
  left,
  range,
  right,
}: IProps) => {

  const click = useCallback(() => {
    onClick(range);
  }, [hash(range)]);

  return (
    <Container active={active} onClick={click} range={range} left={left} right={right}>
      {displayName}
    </Container>
  );
};

export default RangeButton;

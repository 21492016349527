import React from 'react';
import styled from 'styled-components';
import { ADMIN_TEXT_BODY_XL_MEDIUM } from 'style/design-system/textStyles';
import { useAdminTranslation } from 'hooks/use-translation';

const Exterior = styled.div`
  flex-grow: 1;
  height: 250px;
`;

const Interior = styled.div`
  ${ADMIN_TEXT_BODY_XL_MEDIUM}
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const SectionNoResults = () => {
  const { t } = useAdminTranslation();

  return (
    <Exterior>
      <Interior>
        {t('ADMIN_ANALYTICS_NO_RESULTS')}
      </Interior>
    </Exterior>
  );
};

export default SectionNoResults;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import LabelToggle from 'components/admin2/ui/LabelToggle';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  opacity: ${props => props.disabled ? '.3' : '1'};
`;

export default class EditModeRegionToggle extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
    disabled: PropTypes.bool,
    helperTextKey: PropTypes.string,
    labelKey: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    'data-testid': undefined,
    disabled: false,
    helperTextKey: undefined,
    labelKey: undefined,
  };

  handleChange = () => {
    const { active, onChange, disabled } = this.props;
    if (disabled) {
      return;
    }
    onChange?.(!active);
  };

  render() {
    const { active, 'data-testid': testId, labelKey, helperTextKey, className, disabled } = this.props;
    const { handleChange } = this;
    return (
      <Container className={className} disabled={disabled}>
        <LabelToggle
          checked={active}
          data-testid={testId}
          descriptionKey={helperTextKey}
          disabled={disabled}
          labelKey={labelKey}
          labelUppercase
          onChange={handleChange}
        />
      </Container>
    );
  }
}

import React, { FC, useCallback } from 'react';
import { useCachedCallback } from 'hooks/use-cached-callback';

import Label from 'components/admin2/ui/Label';
import {
  EventsContainer,
  Subscriptions,
} from './styles';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import { TranslationKey } from 'hooks/use-translation';

type EventsProps = {
  disabled: boolean;
  hasApiKey: boolean;
  setSubscriptions: (
    update: (subs: EventsProps['subscriptions'])
      => EventsProps['subscriptions'],
  ) => unknown;
  subscriptions: {
    enabled: boolean | undefined;
    name: string;
    service: string;
  }[];
};

const Events: FC<EventsProps> = ({ disabled, hasApiKey, setSubscriptions, subscriptions }) => {
  const changeHandler = useCachedCallback(
    useCallback(
      (service: string) =>
        (value: boolean) =>
          setSubscriptions(
            (subs) => subs.map(
              (sub) => {
                if (sub.service === service)
                  sub.enabled = value;

                return sub;
              },
            ),
          ),
      [],
    ),
  );

  return (
    <EventsContainer>
      <Label labelKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_LABEL" uppercase={true} />
      <Subscriptions>
        {subscriptions.map(
          (sub) => (
            <LabelToggle
              key={sub.name}
              checked={sub.enabled && hasApiKey}
              disabled={disabled}
              labelKey={`ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENTS_${sub.name.toUpperCase()}` as TranslationKey}
              onChange={changeHandler(sub.service)}
            />
          ),
        )}
      </Subscriptions>
    </EventsContainer>
  );
};

export default Events;

import TranslatedText from 'components/i18n/TranslatedText';
import { USE_AMAZON_SERIES_KEY } from 'config';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import uuid from 'uuid/v4';
import { SeriesBenefit } from './AmazonBenefitGateSettings';
import {
  CopyIcon,
  CopyIconContainer,
  MultipleBenefitsContainer,
  MultipleBenefitsLabel,
  RemoveIcon,
  SeriesBenefitDeleteButton,
  SeriesBenefitIdentifier,
  SeriesBenefitInput,
  SeriesBenefitContainer,
  SeriesBenefitInputGroup,
  SeriesBenefitInputLabel,
  TupleContainer,
  SeriesBenefitInputContainer,
  PlusIcon,
  AddBenefitButton,
  TupleSet,
} from './styles';

export interface TupleEditorProps {
  inputError: boolean;
  inputErrorKey: string;
  inputLabelKey: string;
  inputPlaceholderKey: string;
  inputTestId: string;
  labelKey: string;
  setTuples: (tuples: SeriesBenefit[]) => void;
  tuples: SeriesBenefit[];
}

export const TupleEditor: React.FC<TupleEditorProps> = ({
  inputError,
  inputErrorKey,
  inputLabelKey,
  inputPlaceholderKey,
  inputTestId,
  labelKey,
  setTuples,
  tuples,
}) => {
  const bottomRef = useRef(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const addTuple = useCallback(() => {
    const newTuples = tuples ? [...tuples] : [];
    const newUUID = uuid();
    newTuples.push({ seriesId: newUUID, amazonBenefitId: '' });
    setTuples(newTuples);
  }, [tuples]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (linkCopied) {
      timer = setTimeout(() => setLinkCopied(false), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [linkCopied]);

  useEffect(() => {
    (bottomRef as any).current?.scrollIntoView({ behavior: 'smooth' });
  }, [tuples?.length]);

  const handleCopy = useCallback(
    (e: MouseEvent, index: number) => {
      navigator.clipboard.writeText(`${window.location.href}?${USE_AMAZON_SERIES_KEY}=${tuples[index].seriesId}`);
      setLinkCopied(true);
    },
    [tuples],
  );

  const handleDelete = useCallback(
    (index) => {
      const newTuples = tuples ? [...tuples] : [];
      newTuples.splice(index, 1);
      setTuples(newTuples);
    },
    [tuples],
  );

  const handleBenefitIdChange = useCallback(
    (index, value) => {
      const changedTuple = { ...tuples[index] };
      changedTuple.amazonBenefitId = value;
      const newTuples = [...tuples];
      newTuples[index] = changedTuple;
      setTuples(newTuples);
    },
    [tuples],
  );

  const copyLinkButtoni18nTextKey = linkCopied
    ? 'ADMIN_LINK_COPIED'
    : 'ADMIN_COPY_LINK';

  return (
    <MultipleBenefitsContainer>
      <MultipleBenefitsLabel>
        <TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_BENEFITS" />
      </MultipleBenefitsLabel>
      <TupleSet>
        {tuples && tuples.map((tuple, index) => (
          <TupleContainer key={tuple.seriesId}>
            <SeriesBenefitIdentifier>{index + 1}</SeriesBenefitIdentifier>
            <SeriesBenefitContainer>
              <SeriesBenefitInputGroup>
                <SeriesBenefitInputLabel>
                  <TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_BENEFIT_URL" />
                </SeriesBenefitInputLabel>
                <SeriesBenefitInputContainer>
                  <SeriesBenefitInput
                    type="text"
                    value={`${window.location.href}?${USE_AMAZON_SERIES_KEY}=${tuple.seriesId}`}
                    readOnly={true}
                  />
                  <CopyIconSet
                    alwaysVisible={false}
                    id="streamKey"
                    index={index}
                    onClick={handleCopy}
                    tooltip={
                      <TranslatedText stringKey={copyLinkButtoni18nTextKey} />
                    }
                    tooltipPosition="bottom"
                  />
                </SeriesBenefitInputContainer>
              </SeriesBenefitInputGroup>
              <SeriesBenefitInputGroup>
                <SeriesBenefitInputLabel>
                  <TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_BENEFIT_ID" />
                </SeriesBenefitInputLabel>
                <SeriesBenefitInputComponent
                  index={index}
                  value={tuple.amazonBenefitId}
                  onChange={handleBenefitIdChange}
                  testId={inputTestId}
                />
              </SeriesBenefitInputGroup>
            </SeriesBenefitContainer>
            <DeleteButton index={index} onClick={handleDelete} />
          </TupleContainer>
        ))}
        <div ref={bottomRef} />
      </TupleSet>
      <AddBenefitButton onClick={addTuple}>
        <PlusIcon />{' '}
        <TranslatedText stringKey="ADMIN_LABEL_ADD_ANOTHER_BENEFIT" />
      </AddBenefitButton>
    </MultipleBenefitsContainer>
  );
};

const CopyIconSet = ({
  alwaysVisible,
  id,
  index,
  onClick,
  tooltip,
  tooltipPosition,
}) => {
  const handleOnClick = useCallback((e: any) => {
    onClick(e, index);
  }, [index]);
  return (
    <CopyIconContainer
      alwaysVisible={alwaysVisible}
      id={id}
      onClick={handleOnClick}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
    >
      <CopyIcon />
    </CopyIconContainer>
  );
};

const DeleteButton = ({ index, onClick }) => {
  const handleOnClick = () => onClick(index);
  return (
    <SeriesBenefitDeleteButton>
      <RemoveIcon onClick={handleOnClick} />
    </SeriesBenefitDeleteButton>
  );
};

const SeriesBenefitInputComponent = ({ index, onChange, value, testId }) => {
  const handleBenefitIdChange = (current) => onChange(index, current);
  return (
    <SeriesBenefitInputContainer>
      <SeriesBenefitInput
        type="text"
        value={value}
        onChange={handleBenefitIdChange}
        data-testid={`${testId}-${index}`}
      />
    </SeriesBenefitInputContainer>
  );
};

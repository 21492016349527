import React, { FC, FormEventHandler, useCallback } from 'react';
import { useAdminTranslation } from 'hooks/use-translation';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import { VastAdConfig } from 'models/IAdsConfig';
import { ErrorMessage } from 'components/ErrorLabel/styles';
import useAdsConfig from './use-ads-config';
import { useSelector } from 'react-redux';
import { getObjectId } from 'services/app';
import {
  Content,
  HeaderDescription,
  HeaderTitle,
  Heading,
  StyledTextInput,
  Wrapper,
  RadioGroup,
  StyledRadio as Radio,
} from './styles';

export type AdsSettingsProps = {
  pageLevel?: boolean;
};

const AdsConfigTab: FC<AdsSettingsProps> = ({ pageLevel }) => {
  const { t } = useAdminTranslation();
  const currentPageId = useSelector(getObjectId);
  const { adsConfig, loading, error, handleChange } = useAdsConfig({
    pageId: pageLevel ? currentPageId : null,
  });
  const config = adsConfig.config as VastAdConfig;
  const [vastUrl, setVastUrl] = React.useState('');

  React.useEffect(() => {
    if (config.url !== vastUrl) {
      setVastUrl(config.url);
    }
  }, [config.url]);

  const handleBlur = () => {
    handleChange('config')({
      ...config,
      url: vastUrl || '',
    });
  };

  const handleSelectOption = useCallback<FormEventHandler<HTMLInputElement>>(
    (ev) => {
      if (!ev.currentTarget.checked) {
        return;
      }

      switch (ev.currentTarget.value) {
        case 'inherit':
          return handleChange('archived')(Date.now());
        case 'disable':
          return handleChange('enabled')(false);
        case 'override':
          return handleChange('enabled')(true);
        default:
          break;
      }
    },
    [handleChange],
  );

  const isOverrideSelected = !Boolean(adsConfig.archived) && Boolean(adsConfig.pageId) && adsConfig.enabled;

  const shouldShowVastUrl = (isOverrideSelected || !pageLevel) && adsConfig.enabled;

  return (
    <Wrapper pageLevel={Boolean(pageLevel)}>
      <Content>
        <Heading>
          <HeaderTitle>{t('ADMIN_LABEL_PAGE_ADVERTISING')}</HeaderTitle>
          <HeaderDescription>
            {
              pageLevel
                ? t('PAGE_ADS_DESCRIPTION')
                : t('SITE_ADS_DESCRIPTION')
            }
          </HeaderDescription>
        </Heading>
        {
          pageLevel
            ? (
              <RadioGroup>
                <Radio
                  checked={Boolean(adsConfig.archived) || !Boolean(adsConfig.pageId)}
                  labelKey="PAGE_ADS_OPTION_INHERIT"
                  onChange={handleSelectOption}
                  value={'inherit'}
                />
                <Radio
                  checked={!Boolean(adsConfig.archived) && Boolean(adsConfig.pageId) && !adsConfig.enabled}
                  labelKey="PAGE_ADS_OPTION_DISABLE"
                  onChange={handleSelectOption}
                  value={'disable'}
                />
                <Radio
                  checked={isOverrideSelected}
                  labelKey="PAGE_ADS_OPTION_OVERRIDE"
                  onChange={handleSelectOption}
                  value={'override'}
                />
              </RadioGroup>
            )
            : (
              <LabelToggle
                checked={adsConfig.enabled}
                onChange={handleChange('enabled')}
                labelKey="ENABLE_PRE_ROLL_ADS"
                padding="0"
                disabled={loading}
                data-testid="enable-pre-roll-ads-toggle"
              />
            )
        }
        {
          shouldShowVastUrl && (
            <StyledTextInput
              labelKey="VIDEO_AD_SERVING_TEMPLATE_URL"
              descriptionKey="VIDEO_AD_SERVING_TEMPLATE_URL_DESCRIPTION"
              placeholderText="https://sample-vast-url"
              padding="0"
              value={vastUrl}
              onChange={setVastUrl}
              onBlur={handleBlur}
              disabled={loading}
              data-testid="vast-url-input"
              $pageLevel={pageLevel}
            />
          )
        }
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Content>
    </Wrapper>
  );
};

export default AdsConfigTab;

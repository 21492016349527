import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import { getCustomerProfile } from 'services/app/selectors';
import { isFeatureEnabled, Feature } from 'services/feature-gate';
import { saveSiteSettings } from 'services/admin/actions';
import { settingsTypes } from 'services/admin/constants';
import { getAdminAlertError } from 'services/themes';
import isEqual from 'lodash/isEqual';
import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import { AdminTabTextBlock } from 'components/admin2/ui/Text';
import TranslatedText from 'components/i18n/TranslatedText';
import TextArea from 'components/admin2/TextArea';
import UploadZone from 'components/admin2/UploadZone';
import LabelUI from 'components/admin2/ui/Label';
import styled from 'styled-components';
import { ADMIN_TEXT_TITLE_FIELD_ERROR } from 'style/mixins';
import { SITE_SETTING_SERVICE_BASE_URL, SITE_ID } from 'config';
import { getPrimaryToken } from 'services/auth/selectors';
import useAjax from 'hooks/use-ajax';
import type { TranslationKey } from 'hooks/use-translation';
import { showAdminErrorModal } from 'services/modals/actions';
import isEmail from 'validator/lib/isEmail';
import { isUrl } from 'url-utils';
import IState from 'services/state';
import { ICustomerInfo, getAccessInstructions, getSupportInfo, setCustomerInfo } from 'services/customer-profile';
import TextInput from '../TextInput';

const Label = styled(LabelUI)`
  padding: 0px;
  & > div {
    padding: 0px;
  }
  label > div {
    font-weight: 500 !important;
  }
`;

const LabelWithDescription = styled(Label as any)`
  label > div {
    padding-top: 10px;
    padding-bottom: 12px;
  }
  &#support-contact-1, &#support-contact-2 > div > div {
    margin-bottom: 15px;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  ${ADMIN_TEXT_TITLE_FIELD_ERROR};
`;

interface InputItems extends ICustomerInfo {
  logo?: string;
  name?: string;
}

const CustomerProfileTab = () => {
  const dispatch = useDispatch();
  const maestroSupportEnabled = useSelector(
    (state: IState) => isFeatureEnabled(state, Feature.MAESTRO_SUPPORT),
  );
  const accessInstructions = useSelector(getAccessInstructions);
  const support = useSelector(getSupportInfo);
  const customerProfileItems = useSelector(getCustomerProfile, isEqual);
  const token = useSelector(getPrimaryToken);
  const alertError = useSelector(getAdminAlertError);
  const [inputItems, updateCustomerProfileSettings] = useState<InputItems>({ ...customerProfileItems, accessInstructions, support });
  const [isLoading, setIsLoading] = useState(false);
  const [saveSupportInfo, setSaveSupportInfo] = useState(false);
  const [emailErrorKey, setEmailErrorKey] = useState<TranslationKey | ''>('');
  const [urlErrorKey, setUrlErrorKey] = useState<TranslationKey | ''>('');
  const [saved, setSaved] = useState(false);

  const handleSave = useCallback(payload => {
    const { ...customerProfile } = payload;
    setIsLoading(true);
    dispatch(saveSiteSettings(settingsTypes.CUSTOMER_PROFILE, customerProfile));
    setSaveSupportInfo(true);
  }, []);

  const url = useMemo(() => {
    return saveSupportInfo ? `${SITE_SETTING_SERVICE_BASE_URL}/customerInfo` : null;
  }, [
    saveSupportInfo,
  ]);

  const { data, error } = useAjax({
    body: { accessInstructions: inputItems.accessInstructions, support: inputItems.support },
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'PUT',
    url,
  });

  useEffect(() => {
    if (data) {
      dispatch(setCustomerInfo(data as ICustomerInfo));
      setSaveSupportInfo(false);
      setIsLoading(false);
      setSaved(true);
    } else if (error) {
      switch (error.message) {
        case 'Support email cannot be empty if there is an entitlement gate active':
          if (!inputItems.support.email) {
            setEmailErrorKey('ADMIN_ERROR_REQUIRED_ENTITLEMENT_GATE');
          }
          break;
        default:
          dispatch(showAdminErrorModal(error.message || 'Network Error'));
          break;
      }
      setSaveSupportInfo(false);
      setIsLoading(false);
    }
  }, [hash(data), hash(error)]);

  useEffect(() => {
    if (saved) {
      setSaved(false);
    }
  }, [hash(inputItems), saved]);

  const setName = useCallback((text: string) => {
    updateCustomerProfileSettings({ ...inputItems, name: text });
  }, [
    hash(inputItems),
  ]);
  const clearImage = useCallback(() => {
    const { logo: oldImage, ...newInputs } = inputItems;
    updateCustomerProfileSettings(newInputs);
  }, [
    hash(inputItems),
  ]);
  const fileSubmit = useCallback((thumbnailUrl: string) => {
    updateCustomerProfileSettings({ ...inputItems, logo: thumbnailUrl });
  }, [
    hash(inputItems),
  ]);
  // tslint:disable-next-line: no-shadowed-variable
  const setAccessInstructions = useCallback((accessInstructions) => {
    if (accessInstructions.length <= 200) {
      updateCustomerProfileSettings({ ...inputItems, accessInstructions });
    }
  }, [
    hash(inputItems),
  ]);
  const setSupportEmail = useCallback((email) => {
    const isValidEmail = email === '' || (isEmail(email) && !/maestro.io/.test(email));
    if (!isValidEmail) {
      setEmailErrorKey('ADMIN_ERROR_INVALID_EMAIL');
    } else {
      setEmailErrorKey('');
    }
    updateCustomerProfileSettings({ ...inputItems, support: { ...inputItems.support, email } });
  }, [
    hash(inputItems),
  ]);
  const setSupportURL = useCallback((URL) => {
    const isValidURL = URL === '' || (isUrl(URL) && (!/maestro.io/.test(URL) || maestroSupportEnabled));
    if (!isValidURL) {
      setUrlErrorKey('ADMIN_ERROR_INVALID_URL');
    } else {
      setUrlErrorKey('');
    }
    updateCustomerProfileSettings({ ...inputItems, support: { ...inputItems.support, URL } });
  }, [
    hash(inputItems), maestroSupportEnabled,
  ]);

  const { logo, name } = inputItems;
  const hasChanges = !isEqual({ ...customerProfileItems, accessInstructions, support }, inputItems);

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        hasUnsavedChanges={!isLoading && hasChanges && !emailErrorKey && !urlErrorKey}
        headerKey="ADMIN_LABEL_SETTINGS"
        // tslint:disable-next-line: jsx-no-lambda
        onSave={() => handleSave(inputItems)}
        saved={saved}
        subHeaderKey="ADMIN_SETTINGS_CUSTOMER_PROFILE_TITLE"
        subtitleKey="ADMIN_LABEL_CUSTOMER_PROFILE"
      />
      <TranslatedText component={AdminTabTextBlock} stringKey="ADMIN_SETTINGS_CUSTOMER_PROFILE_HELPER_TEXT" />
      <TextInput
        disabled={isLoading}
        onChange={setName}
        value={name}
        labelKey="ADMIN_LABEL_CUSTOMER_PROFILE_NAME"
      />
      <UploadZone
        disabled={isLoading}
        imagePreview={logo}
        onClearImage={clearImage}
        onFileSubmit={fileSubmit}
        labelKey="ADMIN_LABEL_CUSTOMER_PROFILE_LOGO"
        descriptionKey="ADMIN_LABEL_CUSTOMER_PROFILE_LOGO_HELPER_TEXT"
      />
      <TextArea
        labelKey="ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_HELPER_TEXT"
        disabled={isLoading}
        descriptionKey="ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_DESCRIPTION"
        helperTextKey="ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_TEXTAREA_HELPER_TEXT"
        onChange={setAccessInstructions}
        placeholderKey="ADMIN_SITE_SETTINGS_ACCESS_INSTRUCTIONS_PLACEHOLDER"
        rows={4}
        value={inputItems?.accessInstructions}
      />
      <LabelWithDescription
        descriptionKey="ADMIN_SITE_SETTINGS_SUPPORT_CONTACT_SUBTITLE_HELPER_TEXT"
        labelKey="ADMIN_SITE_SETTINGS_SUPPORT_CONTACT_TITLE_HELPER_TEXT"
      />
      <TextInput
        labelKey="ADMIN_SITE_SETTINGS_SUPPORT_EMAIL_HELPER_TEXT"
        disabled={maestroSupportEnabled || isLoading}
        inputBorder={emailErrorKey ? `1px solid ${alertError}` : undefined}
        onChange={setSupportEmail}
        value={inputItems?.support?.email}
      />
      {emailErrorKey && (
        <ErrorMessage>
          <TranslatedText stringKey={emailErrorKey} />
        </ErrorMessage>
      )}
      <TextInput
        labelKey="ADMIN_SITE_SETTINGS_SUPPORT_LINK_HELPER_TEXT"
        disabled={maestroSupportEnabled || isLoading}
        inputBorder={urlErrorKey ? `1px solid ${alertError}` : undefined}
        onChange={setSupportURL}
        value={inputItems?.support?.URL}
      />
      {urlErrorKey && (
        <ErrorMessage>
          <TranslatedText stringKey={urlErrorKey} />
        </ErrorMessage>
      )}
    </AdminBarTab>
  );
};

export default CustomerProfileTab;

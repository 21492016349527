import React from 'react';
import { IBreakdown, IItem } from './types';
import { ViewershipBreakdownContainer, Space } from './styles';
import Column from './Column';

interface IProps {
  breakdownData: IBreakdown;
}

const View = ({ breakdownData }: IProps) => {

  const formatDemographics = (demographics: IItem[]) => {
    const tempDemographics: any = {};
    demographics.forEach((demographic: IItem) => {
      tempDemographics[demographic.title] = demographic;
    });

    if (tempDemographics['55-64'] && tempDemographics['65+']) {
      const { '55-64': a, '65+': b, ...rest } = tempDemographics;
      const merge = { title: '55+', sessions: a.sessions + b.sessions };
      return [ merge, ...Object.values(rest) ] as IItem[];
    } else {
      return [ ...Object.values(tempDemographics) ] as IItem[];
    }
  };

  const formatTechnology = (demographics: IItem[]) => {
    return [...demographics].map((demographic) => {
      if (demographic.title === 'bot') {
        demographic.title = 'Other';
      }
      return demographic;
    });
  };

  return (
    <ViewershipBreakdownContainer>
      {!!breakdownData?.countries.length && (
      <>
        <Column
          title="TOP_FIVE_COUNTRIES"
          columnData={breakdownData.countries}
          sessionsTotal={breakdownData.sessionsTotal}
          limit={5}
        />
        <Space/>
      </>
      )}
      {!!breakdownData?.devices.length && (
      <Column
        title="TECHNOLOGY"
        columnData={formatTechnology(breakdownData.devices)}
        sessionsTotal={breakdownData.sessionsTotal}
        limit={5}
      />
      )}
      {!!breakdownData.demographics.length && (
      <>
        <Space/>
        <Column
          title="DEMOGRAPHICS"
          columnData={formatDemographics(breakdownData.demographics)}
          sessionsTotal={breakdownData.demographicsSessionsTotal}
          limit={5}
        />
      </>
      )}
    </ViewershipBreakdownContainer>
  );
};

export default View;

import React, { useRef } from 'react';
import {
  EngatementImpactContainer,
  EngagementImpactTitle,
  EngagementItem,
} from './styled';
import { formatToTime, abbrNumber } from '../../utils';
import EngagementImpactChart from './engagement-impact-chart';
import ComparisonChart from './comparison-chart';
import TranslatedText from 'components/i18n/TranslatedText';
import { IEngagmentImpact } from './types';
import { useSelector } from 'react-redux';
import { getAdminAlertWarning, getAdminText300 } from 'services/themes';

const EngagementImpact = ({ audience, avgWatchTime }: IEngagmentImpact) => {
  const containerRef = useRef(null);
  const adminText300 = useSelector(getAdminText300);
  const adminAlertWarning = useSelector(getAdminAlertWarning);

  const largestAverageWatch = avgWatchTime.uniquesNonEngaged < avgWatchTime.uniquesEngaged ? avgWatchTime.uniquesEngaged : avgWatchTime.uniquesNonEngaged;
  return (
    <EngatementImpactContainer ref={containerRef}>
      <EngagementItem style={{ marginRight: '24px' }}>
        <EngagementImpactTitle style={{ textAlign: 'left' }}>
          <TranslatedText stringKey="ENGAGEMENT" />
        </EngagementImpactTitle>
        <EngagementImpactChart
          data={{ uniquesAll: audience.uniquesAll, uniquesEngaged:  audience.uniquesEngaged, uniquesNonEngaged: audience.uniquesNonEngaged }}
        />
      </EngagementItem>
      <EngagementItem animationDelay={.1} animationDuration={.3}>
        <EngagementImpactTitle>
          <TranslatedText stringKey="AVERAGE_WATCH_TIME_PER_USER" />
        </EngagementImpactTitle>
        <ComparisonChart
          name="Average Watch Time"
          leftData={{
            fontColor: adminText300,
            color: ' linear-gradient(360deg, #5C5C5C -0.04%, #C7C7CC 99.41%)',
            name: 'NON_ENGAGED',
            percentage: (avgWatchTime.uniquesNonEngaged / largestAverageWatch * 100),
            difference: '0',
            value: formatToTime(avgWatchTime.uniquesNonEngaged),
          }}
          rightData={{
            color: adminAlertWarning,
            name: 'ENGAGED',
            percentage: (avgWatchTime.uniquesEngaged / largestAverageWatch * 100 ),
            difference: (avgWatchTime.uniquesNonEngaged ? abbrNumber(((avgWatchTime.uniquesEngaged || 0) - avgWatchTime.uniquesNonEngaged) / avgWatchTime.uniquesNonEngaged * 100 ): '0') ,
            value: formatToTime(avgWatchTime.uniquesEngaged),
          }}
        />
      </EngagementItem>
    </EngatementImpactContainer>
  );
};

export default EngagementImpact;

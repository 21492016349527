import React, { useMemo } from 'react';
import { DropdownNoPaddingNoLabel as Dropdown } from 'components/admin2/ui/Dropdown';
import { IFonts } from 'hooks/use-fonts';
import { startCase } from 'lodash';
import { DesignFontWrapper, StyledLabel, LabelWrapper, InfoIcon, BodyFontInfoIcon } from './styles';
import type { TranslationKey } from 'hooks/use-translation';

interface IDesignFontProps {
  labelKey: keyof typeof TOOLTIP_MAP;
  onChange: (font: IFonts) => void;
  options: IFonts[];
  searchPlaceholderKey: TranslationKey;
  tooltipKey: TranslationKey;
  value: IFonts | undefined;
}

const TOOLTIP_MAP = {
  'ADMIN_DESIGN_TAB_BODY_FONT': BodyFontInfoIcon,
  'ADMIN_DESIGN_TAB_HEADLINE_FONT': InfoIcon,
};

const DesignFont: React.FC<IDesignFontProps> = ({ value, options, tooltipKey, labelKey, searchPlaceholderKey, onChange }) => {
  const TooltipIcon = useMemo(() => {
    return TOOLTIP_MAP[labelKey] || InfoIcon;
  }, [labelKey]);

  const handleGetOptionLabel = (option: IFonts) => {
    return startCase(option?.label || '');
  };

  return (
    <DesignFontWrapper>
      <LabelWrapper>
        <StyledLabel admin={true} labelKey={labelKey} />
        <TooltipIcon tooltipKey={tooltipKey} />
      </LabelWrapper>
      <Dropdown
        getOptionLabel={handleGetOptionLabel}
        isSearchable={true}
        onChange={onChange}
        options={options}
        searchPlaceholderKey={searchPlaceholderKey}
        value={value}
        shouldCheckDropDownOverflow={false}
      />
    </DesignFontWrapper>
  );
};

export default DesignFont;

/* eslint-disable sort-keys */

export const overlayKindMap = {
  shopify: {
    iconName: 'shopifyColored',
    infoKey: 'ADMIN_OVERLAY_SHOPIFY_INFO',
    nameKey: 'ADMIN_OVERLAY_SHOPIFY_NAME',
  },
  raffle: {
    iconName: 'ticket',
    infoKey: 'ADMIN_OVERLAY_RAFFLE_INFO',
    nameKey: 'ADMIN_OVERLAY_RAFFLE_NAME',
  },
  message: {
    iconName: 'chat',
    infoKey: 'ADMIN_OVERLAY_MESSAGE_INFO',
    nameKey: 'ADMIN_OVERLAY_MESSAGE_NAME',
  },
  poll: {
    iconName: 'check',
    infoKey: 'ADMIN_OVERLAY_POLL_INFO',
    nameKey: 'ADMIN_OVERLAY_POLL_NAME',
  },
  prediction: {
    iconName: 'ticket',
    infoKey: 'ADMIN_OVERLAY_PREDICTION_INFO',
    nameKey: 'ADMIN_OVERLAY_PREDICTION_NAME',
  },
  trivia: {
    iconName: 'play-circled',
    infoKey: 'ADMIN_OVERLAY_TRIVIA_INFO',
    nameKey: 'ADMIN_OVERLAY_TRIVIA_NAME',
  },
  poll_results: {
    iconName: 'pollResults',
    infoKey: 'ADMIN_OVERLAY_POLL_RESULTS_INFO',
    nameKey: 'ADMIN_OVERLAY_POLL_RESULTS_NAME',
  },
  lower_third: {
    iconName: 'lowerThird',
    infoKey: 'ADMIN_OVERLAY_LOWER_THIRD_INFO',
    nameKey: 'ADMIN_OVERLAY_LOWER_THIRD_NAME',
  },
  // HIDDEN IN THE NS-7546
  // tweet: {
  //   iconName: 'twitter',
  //   infoKey: 'ADMIN_OVERLAY_TWEET_INFO',
  //   nameKey: 'ADMIN_OVERLAY_TWEET_NAME',
  // },
  highlight: {
    iconName: 'chat',
    infoKey: 'ADMIN_OVERLAY_HIGHLIGHT_INFO',
    nameKey: 'ADMIN_OVERLAY_HIGHLIGHT_NAME',
  },
};

export const tabIndexNameMap = [
  'ADMIN_LABEL_CATALOG',
  'ADMIN_LABEL_LIBRARY',
];

export const OVERLAY_ENTRIES_LIMIT = 350;

import styled, { css } from 'styled-components';
import {
  ADMIN_SURFACE_4,
} from 'style/constants';

export const CollapsibleListContainer = styled.div<{ noBackground?: boolean; noPadding?: boolean; }>`
  ${({ noPadding }) => !noPadding && 'padding: 10px;'}
  ${({ noBackground }) => !noBackground && css`background: ${ADMIN_SURFACE_4};`};
  border-radius: 5px;
`;

export const calculateOpacityByRGBA = (rgba: string | undefined): number | undefined => {
  if (!rgba) {
    return;
  }
  if (!isRGBA(rgba)) {
    return;
  }

  const alpha = rgba.slice(-2);
  const opacity = getOpacityByHex(alpha);
  return opacity;
};

export const getOpacityByHex = (hex: string): number => {
  const alphaParsed = parseInt(hex, 16);
  const opacity = ((alphaParsed / 255) * 100).toFixed();
  return Number(opacity);
};

export const getHexByOpacity = (value: number): string => {
  const opacity = value / 100;
  return Number((opacity * 255).toFixed()).toString(16);
};

export const isHexColor = (value: string) => /^#(?:[0-9a-fA-F]{3,4}){1,2}$/.test(value);
export const isRGBA = (value: string) => value.length >= 8;
export const isRGB = (value: string) => /rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/.test(value.replace(/\s/g, ''));
export const isRGBAColor = (value: string) => /^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i.test(value.replace(/\s/g, ''));

export const removeAlpha = (value: string) => {
  const removedAlpha = isRGBA(value) ? value.slice(0, -2) : value;
  return removedAlpha;
};

/**
 *
 * @param hex hex color
 * @param opacity opacity value
 * @returns returns the new hex with the applied opacity converted as alpha
 */
export const applyOpacity = (hex: string, opacity: number) => {
  const alpha = getHexByOpacity(opacity);
  const paddedAlpha = alpha.length === 1 ? `0${alpha}` : alpha;
  return hex.concat('', paddedAlpha.toUpperCase());
};

/**
 *
 * @param value hex color
 * @returns returns hex with normal length. i.e. if value is #FFF, it returns #FFFFFF
 */
export const produceHex = (value: string) => {
  const isShortHex = value.length <= 4;
  if (!isShortHex) {
    return value;
  }
  return `#${value.slice(1).split('').map(unit => unit + unit).join('')}`;
};

/**
 *
 * @param rgb rgb value
 * @returns returns equivalent hex for the color
 * Code extracted from here: https://css-tricks.com/converting-color-spaces-in-javascript/
 */
export const fromRGBToHex = (rgb: string) =>  {
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  const parsedRGB = rgb.substring(4).split(')')[0].split(sep);

  let r = (+parsedRGB[0]).toString(16);
  let g = (+parsedRGB[1]).toString(16);
  let b = (+parsedRGB[2]).toString(16);

  if (r.length === 1)
    r = '0' + r;
  if (g.length === 1)
    g = '0' + g;
  if (b.length === 1)
    b = '0' + b;

  return '#' + r + g + b;
};

const trim = (str: string) => {
  return str.replace(/^\s+|\s+$/gm,'');
};

/**
 *
 * @param rgba rgb value
 * @returns returns equivalent hex for the color
 * Code extracted from here: https://gist.github.com/whitlockjc/9363016
 */
export const fromRGBAToHex = (rgba: string) => {
  const inParts = rgba.substring(rgba.indexOf('(')).split(',');
  const r = parseInt(trim(inParts[0].substring(1)), 10);
  const g = parseInt(trim(inParts[1]), 10);
  const b = parseInt(trim(inParts[2]), 10);
  const a = +parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);

  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255).toString(16).substring(0, 2),
  ];

  // Pad single-digit output values
  outParts.forEach((part, i) => {
    if (part.length === 1) {
      outParts[i] = '0' + part;
    }
  });

  return ('#' + outParts.join(''));
};


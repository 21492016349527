import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { SectionTitle, Divider } from '../../styles';
import ActiveThemeCard from 'components/admin2/Themes/ActiveThemeCard';
import { ITheme } from 'models';

interface ActiveThemeProps {
  activeTheme: ITheme;
  onCustomize: (theme: ITheme) => void;
}

const ActiveTheme: React.FC<ActiveThemeProps> = ({ activeTheme, onCustomize }) => {
  const onCustomizeTheme = () => {
    onCustomize(activeTheme);
  };

  return (
    <>
      <TranslatedText component={SectionTitle} stringKey="ADMIN_DESIGN_TAB_ACTIVE_THEME" />
      <ActiveThemeCard theme={activeTheme.colors} name={activeTheme.name} onCustomize={onCustomizeTheme} type={activeTheme.type} />
      <Divider />
    </>
  );
};

export default ActiveTheme;

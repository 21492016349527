import React from 'react';
import {
  Container,
  Content,
  Arrow,
  Title,
} from './styles';

interface DateRangeInputProps extends React.HTMLAttributes<HTMLDivElement>{
  isActive?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  value: string;
}

const DateRangeInput = React.forwardRef<HTMLDivElement, DateRangeInputProps>(
  ({ onClick, isActive, isDisabled, value, children, ...props }, ref) => {
    return (
      <Container isDisabled={isDisabled} {...props} onClick={onClick} ref={ref}>
        <Content>
          <Title>{value}</Title>
          <Arrow isActive={isActive} />
        </Content>
        {children}
      </Container>
    );
  },
);

export default DateRangeInput;

import { useEffect, useState } from 'react';
import { COLOR_PICKER_HEIGHT, COLOR_PICKER_WIDTH } from './styles';

interface IUseColorPickerPosition {
  isOpen: boolean;
  parentMargin?: {
    left: number;
    top: number;
  };
  parentRef: React.RefObject<any>;
}

const useColorPickerPosition = ({ parentRef, parentMargin, isOpen }: IUseColorPickerPosition) => {
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    if (!parentRef || !parentRef.current || !isOpen) {
      return;
    }
    const rect = parentRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const tooCloseToRight = (windowWidth - COLOR_PICKER_WIDTH) < rect.left;
    const left = tooCloseToRight ? (rect.left - COLOR_PICKER_WIDTH) : rect.left;

    const tooCloseToBottom = (windowHeight - COLOR_PICKER_HEIGHT) < rect.top;
    const top = tooCloseToBottom ? (rect.top - COLOR_PICKER_HEIGHT) : rect.top;

    setPosition({
      left: left + (parentMargin?.left || 0),
      top: top + (parentMargin?.top || 0),
    });
  }, [parentRef.current, isOpen]);

  return position;
};

export default useColorPickerPosition;

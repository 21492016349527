import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangeProps } from '../..';
import {
  CalendarContainer,
  CalendarHeader,
  Title,
  Tip,
  CalendarYearInput,
  NextButton,
  PreviousButton,
  SelectorContainer,
  SelectorTitle,
} from '../../styles';
import MonthAndYearOverlayPicker from '../MonthAndYearOverlayPicker';
import { MonthBox, MonthsContainer } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';

// tslint:disable-next-line: no-empty-interface
interface MonthPickerProps extends DateRangeProps {}

function MonthPicker({ isActive, onRangeChange, onTitleChange, setActive }: MonthPickerProps) {
  const [date, setDate] = useState(moment());
  const [lookupDate, setLookupDate] = useState(moment());
  const [selectorTitle, setSelectorTitle] = useState('');
  const [isDatePickerActive, setDatePickerActive] = useState(false);

  useEffect(() => {
    handleDateChange();
    handleTitleChange();
  }, [date]);

  useEffect(() => {
    if(!isActive) {
      setDatePickerActive(false);
    }
  }, [isActive]);

  const handleDateChange = () => {
    const lastDayOfMonth = moment(date).endOf('month').date();
    const startDate = moment(date).set({ date: 1, hour: 0, minute: 0, second: 0 });
    const endDate = moment(startDate).set({ date: lastDayOfMonth, hour: 23, minute: 59, second: 59 });
    onRangeChange?.(startDate, endDate);
  };

  const handleTitleChange = () => {
    const newTitle = `${date.format('MMMM YYYY')}`;
    setSelectorTitle(newTitle);
    if (onTitleChange) onTitleChange(newTitle);
  };

  const handleYearChange = (yearDate: Moment) => {
    setDatePickerActive(false);
    setLookupDate(moment(lookupDate).set({ year: yearDate.year() }));
  };

  const handleClickAddMonth = () => {
    const newDate = moment(lookupDate).add(1, 'month');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const handleClickSubMonth = () => {
    const newDate = moment(lookupDate).subtract(1, 'month');
    setLookupDate(newDate);
    setDate(newDate);
  };

  const toggleDatePicker = () => {
    setDatePickerActive(!isDatePickerActive);
  };

  const renderMonths = () => {
    return Array.from({ length: 12 }).map((_, monthIndex) => {
      const handleMonthClick = () => {
        const newDate = moment(lookupDate).set({ month: monthIndex });
        setDate(newDate);
        setLookupDate(newDate);

        setTimeout(() => setActive?.(false), 200);
      };
      const isRowActive = lookupDate.month() === monthIndex && lookupDate.year() === date.year();
      return (
        <MonthBox onClick={handleMonthClick} key={monthIndex} isActive={isRowActive}>
          {moment(lookupDate).set({ month: monthIndex }).format('MMMM')}
        </MonthBox>
      );
    });
  };

  return (
    <CalendarContainer isActive={isActive}>
      <CalendarHeader>
        <Title isSmall={false}>
          <TranslatedText stringKey="PICKER_SELECT_MONTH" />
        </Title>
        <CalendarYearInput
          onClick={toggleDatePicker}
          value={lookupDate?.format('YYYY') || 'Select date'}
          isActive={isDatePickerActive}
        />
      </CalendarHeader>
      <SelectorContainer>
        <MonthAndYearOverlayPicker type={'Both'} isActive={isDatePickerActive} onDateChange={handleYearChange} />
        <SelectorTitle>{selectorTitle}</SelectorTitle>
        <PreviousButton onClick={handleClickSubMonth} />
        <NextButton onClick={handleClickAddMonth} />
      </SelectorContainer>
      <MonthsContainer>
        <MonthAndYearOverlayPicker type={'Year'} isActive={isDatePickerActive} onDateChange={handleYearChange} />
        {renderMonths()}
      </MonthsContainer>
      <Tip>Select the Month for filtering and displaying your earnings overview</Tip>
    </CalendarContainer>
  );
}

export default MonthPicker;

import styled from 'styled-components';
import React from 'react';

import {
  SPACING_SMALL,
  ADMIN_SURFACE_6,
} from 'style/constants';

const Divider = styled.hr`
  border: none;
  border-bottom: 1px solid ${ADMIN_SURFACE_6};
  margin: ${SPACING_SMALL} 0;
`;

// eslint-disable-next-line react/prop-types
export default ({ className = null }) => <Divider className={className} />;

import React, { useEffect } from 'react';
import { LogInSettingsContainer, GateSettingsDescription } from './styles';
import { AccessControlGateSettingsHeader } from './AccessControlGateSettingsHeader';
import TranslatedText from 'components/i18n/TranslatedText';

interface LogInGateSettingsProps {
  handleChangeGateClick: () => void;
  setHasGateError: (value: boolean) => void;
  setIsGateOnFinalStep: (value: boolean) => void;
}


export const LogInGateSettings: React.FC<LogInGateSettingsProps> = ({
  handleChangeGateClick,
  setIsGateOnFinalStep,
}) => {
  useEffect(() => {
    setIsGateOnFinalStep(true);
  }, []);

  return (
    <LogInSettingsContainer>
      <AccessControlGateSettingsHeader
        gateText="ACCESS_CONTROL_LOGIN_TITLE"
        handleChangeGateClick={handleChangeGateClick}
        iconName="accessControlLogin"
      />
      <GateSettingsDescription>
        <TranslatedText stringKey="ACCESS_CONTROL_LOGIN_DESCRIPTION" />
      </GateSettingsDescription>
    </LogInSettingsContainer>
  );
};
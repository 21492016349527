import { ADMIN_TEXT_300, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import { ADMIN_SCROLL_BAR, FLEX_CENTER } from 'style/mixins';
import styled from 'styled-components';

export const Container = styled.div<{ isActive?: boolean }>`
  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background-color: ${(props) => (props.isActive ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.0)')};
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  transition: all 0.25s ease-in-out;
`;

export const Content = styled.div`
  ${FLEX_CENTER}
  flex-direction: row;
  align-items: flex-start;
  padding: 140px 20px 80px 20px;
  width: 100%;
  height: 100%;
`;

export const Column = styled.div`
  ${ADMIN_SCROLL_BAR}
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  text-align: center;
  max-height: 240px;
`;

export const OptionText = styled.span<{ isSelected?: boolean }>`
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  transition: all 0.25s ease-in-out;
  color: ${(props) => (props.isSelected ? ADMIN_ACCENT_PRIMARY : ADMIN_TEXT_300)};
  margin: 10px 0px;
  cursor: pointer;
  &:hover {
    color: ${(props) => (props.isSelected ? ADMIN_ACCENT_PRIMARY : ADMIN_TEXT_100)};
  }
`;


import { useSelector } from 'react-redux';
import { INSIGHTS_SERVICE_BASE_URL, SITE_ID } from 'config';
import { getPrimaryToken } from 'services/auth/selectors';
import { IBreakdown } from '../ViewershipBreakdown/types';
import useAjax from 'hooks/use-ajax';

const useViewershipBreakdown = (startTime: number, endTime: number) => {
  const token = useSelector(getPrimaryToken);
  const { data, error, loaded } = useAjax<IBreakdown>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: {
      startTime,
      endTime,
    },
    url: startTime && endTime ? `${INSIGHTS_SERVICE_BASE_URL}/sites/viewership-breakdown` : null,
  });
  return { data, error, loaded };
};

export default useViewershipBreakdown;

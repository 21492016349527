import styled from 'styled-components';

export const ViewershipBreakdownContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Space = styled.div`
  margin-left: 50px;
`;

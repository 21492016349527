import styled, { css } from 'styled-components';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_6 } from 'style/constants';

export const DraggerWrapper = styled.div<{ source: string }>`
  & .react-nestable {
    & .is-dragging::before {
      border: none;
      background: unset;
    }
  }

  ${({ source }) => ['site_navigation', 'navigation_block-parents', 'navigation_block-links'].includes(source) && css`
    & .is-dragging {
      background-color: transparent;
      border-bottom: 1px solid ${ADMIN_ACCENT_PRIMARY};
    }
    li.nestable-item > ol.nestable-list {
      padding-left: 20px;
      border-left: 1px solid ${ADMIN_SURFACE_6};
      margin-left: 3px;
    }
  `}

  .nestable-drag-layer {
    & > .nestable-list {
      margin-top: -30px !important;
    }
  }
`;

import React, { useState, useEffect } from 'react';
import Video from './views/Video';
import { useSelector } from 'hooks';
import { getDefaultRange, getRangeTypeRange } from 'services/analytics';
import { IRange, Ranges } from 'services/analytics/models';
import RangeSelector from 'components/admin2/Analytics/RangeSelectorTitle';
import Header from 'components/admin2/Analytics/Header';
import TranslatedText from 'components/i18n/TranslatedText';
import { setOneDayRange } from 'services/analytics';
import { useDispatch } from 'hooks';
import { useAdminTranslation } from 'hooks/use-translation';
import {
  Container,
  DescriptionContianer,
  Rows,
  Exterior,
  Interior,
  Title,
  IconsContainer,
  HelpText,
  StyledIcon,
} from './styles';

const Videos = () => {
  const { t } = useAdminTranslation();
  const defaultRange: IRange = useSelector(getDefaultRange);
  const activeRange: Ranges = useSelector(getRangeTypeRange);
  const [rangePickerActive, setRangePickerActive] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOneDayRange());
  }, []);

  const handleActive = () => {
    setRangePickerActive(false);
  };

  return (
    <Exterior onClick={handleActive}>
      <Interior>
        <Container>
          <Header/>
          <RangeSelector
            stringKey="ADMIN_LABEL_VIDEOS"
            activeRange={activeRange}
            startTime={defaultRange.rangeStart}
            endTime={defaultRange.rangeEnd}
            rangePickerActive={rangePickerActive}
            setRangePickerActive={setRangePickerActive}
          />
          <DescriptionContianer>
            <Rows>
              <IconsContainer>
                <StyledIcon name="adminbarVideos" />
              </IconsContainer>
              <Title>
                {t('ADMIN_LABEL_VIDEOS')}
              </Title>
            </Rows>
            <HelpText>
              <TranslatedText stringKey="ADMIN_LABEL_VIDEO_ANALYTICS_DESCRIPTION"/>
            </HelpText>
          </DescriptionContianer>
          <Video
            rangeEnd={defaultRange.rangeEnd}
            rangeStart={defaultRange.rangeStart}
          />
        </Container>
      </Interior>
    </Exterior>
  );
};

export default Videos;

import styled, { css } from 'styled-components';

export interface ActionContainerStyleProps {
  reverse?: boolean;
  spacing?: number;
  vertical?: boolean;
}

export const Container = styled.div<ActionContainerStyleProps>`
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: min-content;
  ${({ spacing = 5, vertical, reverse }) => css`
    flex-flow: ${vertical ? 'column' : 'row'}${reverse && '-reverse'};
    & > * {
      margin-${vertical ? 'bottom' : 'right'}: ${spacing}px;
      &:${reverse ? 'first' : 'last'}-child {
        margin: 0;
      }
    }
  `}
`;

import axios from 'axios';
import shortId from 'shortid';
import { ADMIN_SERVICE_BASE_URL, FIREBASE_PRIMARY_SHARD, SITE_ID } from 'config';
import { camelify } from 'shared/string-utils';
import { getPoll } from 'services/polls/api';

export default async ({
  renderer,
  primaryToken,
}) => {
  const { duration, broadcastId } = renderer;
  const { data: broadcast } = await axios.get(
    `${ADMIN_SERVICE_BASE_URL}/objects/${broadcastId}`, {
      headers: {
        Authorization: `Bearer ${primaryToken}`,
      },
    },
  );

  const pollId = broadcast?.data?.poll?._id;
  const poll = await getPoll(primaryToken, pollId, SITE_ID);

  const broadcastObject = `objects/${broadcastId}/data/state`;
  const { data: results } = await axios.get(
    `https://${FIREBASE_PRIMARY_SHARD}.firebaseio.com/${broadcastObject}.json`,
  );

  if (!results) {
    // TODO warn the admin there's no results for their poll
    return false;
  }
  results.engaged = results.count;

  return {
    key: `preview-${shortId.generate()}`,
    payload: {
      channel: 'preview',
      data: {
        state: {
          count: 0,
          engaged: 0,
          ...results,
        },
      },
      duration,
      renderer: {
        ...renderer,
        poll: camelify(poll),
      },
    },
    timeRemaining: duration,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import TranslatedText from 'components/i18n/TranslatedText';
import InputLabel from 'components/admin2/InputLabel';
import ButtonSelect from 'components/admin2/ui/ButtonSelect';

const propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

const TimeFrame = (props) => {
  const {
    children,
    onChange,
    value,
  } = props;
  return (
    <div>
      <InputLabel>
        <TranslatedText stringKey="ADMIN_LABEL_TIME_FRAME" />
      </InputLabel>
      <ButtonSelect
        onChange={onChange}
        options={[
          {
            label: 'ADMIN_LABEL_ALWAYS_ACTIVE',
            value: true,
          }, {
            label: 'ADMIN_LABEL_TIME_RANGE',
            value: false,
          },
        ]}
        value={value}
      >
        {children}
      </ButtonSelect>
    </div>
  );
};

TimeFrame.propTypes = propTypes;

export default TimeFrame;

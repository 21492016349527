import React, { useMemo } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { SectionTitle, Divider, ThemesCollectionWrapper, SubSectionTitle } from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { setAccentColor } from 'services/themes';
import { MAESTRO_PURPLE } from 'style/constants';
import { getEditingPageDoc } from 'services/admin/selectors';
import SelectThemeButton from 'components/admin2/Themes/SelectThemeButton';
import { ITheme } from 'models';
import { useClassicThemes } from '../../use-maestro-themes';
import ColorOptions from 'components/admin2/ui/ColorOptions';


interface ClassicThemesProps {
  activeThemeId: string;
  defaultThemeId?: string;
  onCustomize: (theme: ITheme) => void;
  onFavorite: (theme: ITheme) => void;
  onSelect: (theme: ITheme) => void;
}

const MAESTRO_PURPLE_ACCENT_SECONDARY = '#4C42AD';
const ACCENT_BLUE = '#2480FF';
const ACCENT_PINK = '#ED24FF';
const ACCENT_RED = '#FF2424';
const ACCENT_ORANGE = '#FF8024';
const ACCENT_YELLOW = '#FFCC33';
const ACCENT_GREEN = '#88C756';

const accentColors = [
  MAESTRO_PURPLE,
  ACCENT_BLUE,
  ACCENT_PINK,
  ACCENT_RED,
  ACCENT_ORANGE,
  ACCENT_YELLOW,
  ACCENT_GREEN,
];

const ClassicThemes: React.FC<ClassicThemesProps> = ({ activeThemeId, defaultThemeId, onCustomize, onSelect, onFavorite }) => {
  const dispatch = useDispatch();
  const object = useSelector(getEditingPageDoc);

  const { data, error, loaded } = useClassicThemes();

  const { classicAccentPrimary, classicAccentSecondary } = useMemo(() => {
    const primary = object.data.theme?.classicThemeOptions?.accentPrimary || MAESTRO_PURPLE;
    const secondary = object.data.theme?.classicThemeOptions?.accentSecondary || MAESTRO_PURPLE_ACCENT_SECONDARY;
    return {
      classicAccentPrimary: primary,
      classicAccentSecondary: secondary,
    };
  }, [object]);

  const handleColorChange = (color: string) => {
    if (color !== classicAccentPrimary) {
      dispatch(setAccentColor({ color }));
    }
  };

  const onSelectTheme = (theme: ITheme) => () => {
    onSelect(theme);
  };

  const onFavoriteTheme = (theme: ITheme) => () => {
    onFavorite(theme);
  };

  const onCustomizeTheme = (theme: ITheme) => () => {
    onCustomize(theme);
  };

  const themes = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((theme, index) => {
      const serializedTheme: ITheme = {
        ...theme,
        colors: {
          ...theme.colors,
          accentPrimary: classicAccentPrimary,
          accentSecondary: classicAccentSecondary,
        },
      };
      return (
        <SelectThemeButton
          theme={serializedTheme}
          isActive={theme._id === activeThemeId}
          isFavorite={defaultThemeId === theme._id}
          key={index}
          name={theme.name}
          onSelect={onSelectTheme(theme)}
          onFavorite={onFavoriteTheme(theme)}
          onCustomize={onCustomizeTheme(theme)}
        />
      );
    });
  }, [data, activeThemeId, defaultThemeId, classicAccentPrimary, classicAccentSecondary]);

  if (error || !loaded) {
    return null;
  }

  return (
    <>
      <TranslatedText component={SectionTitle} stringKey="ADMIN_DESIGN_TAB_CLASSIC_THEMES" />
      <ThemesCollectionWrapper>
        {themes}
      </ThemesCollectionWrapper>
      <TranslatedText component={SubSectionTitle} stringKey="ADMIN_DESIGN_TAB_ACCENT_COLOR" />
      <ColorOptions
        colorPickerTitleKey="ADMIN_LABEL_COLOR_PICKER_ACCENT_COLOR"
        colors={accentColors}
        onChange={handleColorChange}
        selectedColor={classicAccentPrimary}
        enableColorPicker={true}
      />
      <Divider />
    </>
  );
};

export default ClassicThemes;

import styled from 'styled-components';
import StatusIndicator from 'components/admin2/StatusIndicator';
import Icon from 'components/ui/Icon';
import { SubHeader } from 'components/admin-bridge/SidebarEditSheet/styles';
import { Button } from 'components/admin2/ui/Button';
import {
  ADMIN_TEXT_200,
  ADMIN_ALERT_WARNING,
  ADMIN_TEXT_300,
  ADMIN_TEXT_100,
  ADMIN_TEXT_500,
  ADMIN_SURFACE_4,
} from 'style/constants';
import { ADMIN_TEXT_BODY_L_REGULAR, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';


export const Container = styled(SubHeader)`
  background-color: ${ADMIN_SURFACE_4};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  line-height: 64px;
`;

export const ActionContainerRight = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: flex-end;
  position: relative;
`;

export const ActionContainerLeft = styled.div`
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  display: flex;
  color: ${ADMIN_TEXT_200};
  align-items: center;
`;

export const StyledActionButton = styled(Button).attrs({
  plain: true,
})`
  && {
    margin: 0 6px;
  }
`;

export const StyledStatusIndicator = styled(StatusIndicator)`
  display: flex;
  align-items: center;
  float: right;
  position: absolute;
  top: 100px;
`;

export const StyledIcon = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_100(props),
}))`
  ${ADMIN_TEXT_BODY_L_REGULAR}
  line-height: 0;
  display: flex;
  align-items: center;
  margin-right: 9px;
  & svg {
    width: 18px;
    height: 18px;
  }
  & i {
    color: ${ADMIN_TEXT_300};
    &:before {
      margin: 0;
      color: ${ADMIN_TEXT_100};
    }
  }
  &:before {
    margin: 0;
    color: ${ADMIN_TEXT_100};
  }
`;

export const SaveActionContainer = styled(Button)`
  padding: 0 10px;
  justify-content: space-between;
`;

export const SaveIcon = styled(Icon).attrs(() => ({
  name: 'librarySave',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  & svg {
    & > path {
      fill: ${ADMIN_TEXT_100};
    }
    width: 18px;
    height: 18px;
  }
`;

export const UseButton = styled(Icon).attrs((props) => ({
  color: ADMIN_TEXT_500(props),
  name: 'megaphone',
}))`
  cursor: pointer;
  background: ${ADMIN_ALERT_WARNING};
  border-radius: 4px;
  width: 24px;
  height: 24px;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  & svg {
    width: 18px;
    height: 18px;
  }
  display: none;
`;

import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import moment from 'moment-timezone';
import { getPrimaryToken } from 'services/auth';
import { IRangeParams } from '../../types';
import useAjax from 'hooks/use-ajax';
import { INSIGHTS_SERVICE_BASE_URL, SITE_ID, STATIC_BASE_URL } from 'config';
import DataTable from '../../components/DataTable';
import { secondsToFormattedTime } from 'utils';
import styled from 'styled-components';
import hash from 'json-stable-stringify';
import { ASC_KEY, DESC_KEY } from '../../components/DataTable';
import { useAdminTranslation, TTranslator } from 'hooks/use-translation';

const getWatchTime = (t: TTranslator, seconds: number) => {
  if (seconds > 3600) {
    const hour = parseFloat((seconds/3600).toFixed(2));
    if (hour === 1) {
      return t('ADMIN_LABEL_1_HOUR');
    }
    return t('ADMIN_LABEL_X_HOURS', { x: hour.toString() });
  }
  const minute = parseFloat((seconds/60).toFixed(2));
  if (minute === 1) {
    return t('ADMIN_LABEL_1_MINUTE');
    }
  return t('ADMIN_LABEL_X_MINUTES', { x: minute.toString() });
};

const Image = styled.img`
  border-radius: 10px;
  width: 133px;
  max-height: 70px;
  object-fit: cover;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
`;

export interface IData {
  durationSeconds: number;
  name: string;
  thumbnail: string;
  totalWatchTimeSeconds: number;
  videoCreated: number;
  views: number;
}

const VideosTable = ({
  rangeEnd,
  rangeStart,
}: IRangeParams) => {
  const { t } = useAdminTranslation();
  const token = useSelector(getPrimaryToken);
  const {
    data,
    error,
    loaded,
  } = useAjax<IData[]>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: {
      startTime: rangeStart,
      endTime: rangeEnd,
    },
    url: rangeEnd && rangeStart ? `${INSIGHTS_SERVICE_BASE_URL}/sites/video-summary` : null,
  });

  const [list, setList] = useState(data?.filter(video => video.name) || []);
  const [order, setOrder] = useState(DESC_KEY);
  const [activeValue, setActiveValue] = useState('views');

  useEffect(() => {
   setList(data?.filter(video => video.name) || []);
   setOrder(DESC_KEY);
   setActiveValue('views');
  }, [hash(data)]);

  const defaultImage = `${STATIC_BASE_URL}/media/5f32f379c04207002caf9c7f/5f6aa0146a9be500a5f3305f.jpg`;
  const handleImageError = useCallback((e) => {
    if (e.target.src !== defaultImage) {
      e.target.src = defaultImage;
    }
  }, [defaultImage]);

  const columns = useMemo<Column<IData>[]>(() => [{
    Header: t('ADMIN_LABEL_THUMBNAIL'),
    accessor: 'thumbnail',
    width: 200,
    Cell: ({ value }) => value ? <Image src={value} onError={handleImageError} /> : <Image src={defaultImage} />,
  }, {
    Header: t('NAME'),
    accessor: 'name',
    width: 300,
    Cell: ({ value }) => <Wrapper>{value || '<undefined>'}</Wrapper>,
  }, {
    Header: t('ADMIN_ANALYTICS_VIEWS'),
    Cell: ({ value }) => <>{value ? value.toLocaleString(): '0'}</>,
    accessor: 'views',
  }, {
    Header: t('ADMIN_ANALYTICS_WATCH_TIME'),
    accessor: 'totalWatchTimeSeconds',
    Cell: ({ value }) => <>{value ? getWatchTime(t, value) : ''}</>,
  }, {
    Header: t('ADMIN_LABEL_DURATION'),
    accessor: 'durationSeconds',
    Cell: ({ value }) => <>{value ? secondsToFormattedTime(value) : 'N/A'}</>,
  },
  {
    Header: t('ADMIN_ANALYTICS_DATE_ADDED'),
    accessor: 'videoCreated',
    Cell: ({ value }) => <>{value ? moment(value).fromNow(): 'N/A'}</>,
  },
], [t]);

const sort = (value: 'views'| 'videoCreated'| 'durationSeconds' | 'totalWatchTimeSeconds'| 'name', listData: IData[], orderType: 'asc' | 'desc') => {
  if (value === 'name') {
    setList([...listData].sort((a: IData, b: IData) =>
      orderType === ASC_KEY ? b.name?.localeCompare(a.name) : a.name?.localeCompare(b.name),
    ));
  } else {
    setList([...listData].sort((a: IData, b: IData) =>
      orderType === ASC_KEY ? (b[value] || 0) - (a[value] || 0) : (a[value] || 0) - (b[value] || 0),
    ));
  }
  setOrder(orderType === ASC_KEY ? DESC_KEY : ASC_KEY);
  setActiveValue(value);
};

  return (
    <DataTable
      activeValue={activeValue}
      order={order}
      sort={sort}
      columns={columns}
      data={list}
      error={error}
      loaded={loaded}
    />
  );
};

export default VideosTable;

import shortId from 'shortid';

export default async ({
  renderer,
}) => {
  const { ctaRenderer } = renderer;
  if (!(ctaRenderer.playerId || renderer.description)) {
    return false;
  }

  // todo: add player image

  return {
    key: `preview-${shortId.generate()}`,
    payload: {
      channel: 'preview',
      data: {
        renderer: {
          ...renderer,
          ctaRenderer: {
            ...ctaRenderer,
            playerData: {
              ...ctaRenderer.playerData,
              // image:
            },
          },
        },
      },
    },
    timeRemaining: renderer.duration,
  };
};

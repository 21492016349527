import React from 'react';
import { ColumnContainer, ColumnTitle, Divider } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import { ICountryItem, IItem } from '../types';
import Item from '../Item';

interface IProps {
  columnData: (ICountryItem | IItem)[];
  limit: number;
  sessionsTotal: number;
  title: string;
}

const getPercentage = (value: number, total: number) => {
  if (!total) {
    return 0;
  }
  const rate = value / total;
  return rate * 100;
};

const Column = ({ columnData, title, sessionsTotal, limit }: IProps) => {

  return (
    <ColumnContainer>
      <ColumnTitle>
        {!!columnData.length && (<TranslatedText stringKey={title}/>)}
      </ColumnTitle>
      {columnData.map(
        (item, index) =>
          index + 1 <= limit && (
            <>
              <Item
                title={title}
                key={index}
                countryCode={(item as ICountryItem).countryCode}
                name={
                  (item as ICountryItem).countryName || (item as IItem).title
                }
                percentage={getPercentage(item.sessions, sessionsTotal)}
                rank={index + 1}
              />
              <Divider key={index} />
            </>
          ),
      )}
    </ColumnContainer>
  );
};

export default Column;

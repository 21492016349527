import styled, { css } from 'styled-components';
import {
  ADMIN_TEXT_200,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_3,
  ADMIN_TEXT_100,
  ADMIN_ACCENT_PRIMARY,
  SPACING_X_SMALL,
  SPACING_MEDIUM,
} from 'style/constants';
import Icon from 'components/admin2/Icon';
import TextInput from 'components/admin2/TextInput';
import { EditButton, LargeButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const Container = styled.div``;

export const Description = styled.div`
  margin: 15px 0px;
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_BODY_S_REGULAR}
`;

export const UrlToNotify = styled.div`
  color: ${ADMIN_TEXT_200};
  font-weight: 500;
  line-height: 19.07px;
  font-size: 14px;
`;

export const TestURLButton = styled(LargeButton)`
  width: 100%;
`;

export const CheckIcon = styled(Icon)`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ADMIN_ACCENT_PRIMARY};

  & > svg {
    fill: ${ADMIN_SURFACE_3};
    height: 4.8px;
    width: 4.8px;
  }
`;

export const MessageToUser = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM};
  color: ${ADMIN_ACCENT_PRIMARY};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px 0px 0px 0px;
`;

export const EventSent = styled(MessageToUser)``;
export const WebhooksSaved = styled(MessageToUser)``;

export const MessageToUserIcon = styled(CheckIcon)`
  margin-right: 5px;
`;

export const WebhooksLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 8px 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  height: 40px;
  gap: 10px;
`;

export const StyledTextInput = styled(TextInput)<{ webhooksActive?: boolean }>`
  width: 100%;
  ${props => props.disabled && css`opacity: 0.5;`}
  input {
    color: ${ADMIN_TEXT_100};
  }

  input:disabled {
    color: ${props => props.webhooksActive ? ADMIN_TEXT_100 : ADMIN_TEXT_300};
  }
`;

export const ValidUrlIcon = styled(CheckIcon)`
  margin: 0px 15px 0px 0px;
`;

export const StyledEditButton = styled(EditButton)`
  margin-left: ${SPACING_X_SMALL};
  cursor: pointer;

  span {
    height: 40px;
    width: 40px;

    svg {
      height: 19.2px;
      width: 19.2px;
    }
  }
`;

export const EventsContainer = styled.div`
  margin-top: ${SPACING_MEDIUM};
`;

export const Subscriptions = styled.div`
  width: 60%;
`;

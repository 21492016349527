import React, { useState, useEffect } from 'react';
import {
  PasswordSettingsContainer,
  GateSettingsDescription,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import Input from 'components/admin2/TextInput';
import { useAdminTranslation } from 'hooks/use-translation';
import { AccessControlGateSettingsHeader } from './AccessControlGateSettingsHeader';

export interface IPasswordGateSettings {
  handleChangeGateClick: () => void;
  password: string;
  setHasGateError: (value: boolean) => void;
  setIsGateOnFinalStep: (value: boolean) => void;
  setPassword: (value: string) => void;
}

export default function PasswordGateSettings({
  handleChangeGateClick,
  password,
  setPassword,
  setHasGateError,
  setIsGateOnFinalStep,
}: IPasswordGateSettings) {
  const initialPasswordValue = password || '';
  const [passwordValue, setPasswordValue] = useState(initialPasswordValue);
  const [passwordError, setPasswordError] = useState(false);
  const { t } = useAdminTranslation();

  const handlePasswordChange = (passwordInputValue: string) => {
    setPasswordValue(passwordInputValue);
    if (passwordInputValue.length < 8) {
      setPasswordError(true);
      setHasGateError(true);
      return;
    }
    setPassword(passwordInputValue);
    setPasswordError(false);
    setHasGateError(false);
  };

  useEffect(() => {
    setIsGateOnFinalStep(true);
    if (passwordValue === '') {
      setHasGateError(true);
    }
    return () => setHasGateError(false);
  }, []);

  return (
    <PasswordSettingsContainer>
      <AccessControlGateSettingsHeader
        gateText="PASSWORD"
        handleChangeGateClick={handleChangeGateClick}
        iconName="accessControlPassword"
      />
      <GateSettingsDescription>
        <TranslatedText stringKey="ACCESS_CONTROL_PASSWORD_SETTING_DESCRIPTION" />
      </GateSettingsDescription>
      <Input
        data-testid="passwordTextField"
        onChange={handlePasswordChange}
        type="text"
        hasError={passwordError}
        placeholderKey="ENTER_PASSWORD"
        value={passwordValue}
        errorKey="ACCESS_CONTROL_PASSWORD_ERROR"
      />
    </PasswordSettingsContainer>
  );
}

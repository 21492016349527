import { ADMIN_TEXT_300, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';
import { FLEX_CENTER } from 'style/mixins';

import styled from 'styled-components';

export const MonthsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 15px 10px;
  margin-top: 10px;
`;

export const MonthBox = styled.div<{ isActive?: boolean }>`
  ${FLEX_CENTER}
  ${ADMIN_TEXT_BODY_XS_BOLD}
  background-color: ${(props) => (props.isActive ? ADMIN_ACCENT_PRIMARY(props) : 'transparent')};
  color: ${(props) => (props.isActive ? ADMIN_TEXT_100 : ADMIN_TEXT_300)};
  padding: 15px 20px;
  border: 1px solid ${(props) => (props.isActive ? 'transparent' : ADMIN_TEXT_300)};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${ADMIN_ACCENT_PRIMARY};
    color: ${ADMIN_TEXT_100};
  }
`;

import React from 'react';
import { useAccessFTUE } from 'hooks';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  GateCardWrapper,
  ExitButton,
  GateCardImageWrapper,
  StyledIcon,
  GateCardInfoWrapper,
  CardTitle,
  CardDescription,
  SetupAccessButton,
} from '../styles';

interface IProps {
  onSetupAccess: () => void;
}

const GateCard: React.FC<IProps> = ({ onSetupAccess }) => {
  const [, setAccessControlModeFTUE] = useAccessFTUE();

  const onHandleCloseCard = () => {
    setAccessControlModeFTUE();
  };

  const onHandleSetupAccessButton = () => {
    setAccessControlModeFTUE();
    onSetupAccess();
  };

  return (
    <GateCardWrapper>
      <ExitButton onClick={onHandleCloseCard} />
      <GateCardImageWrapper>
        <StyledIcon name="accessCodeImgPlaceholder" />
      </GateCardImageWrapper>
      <GateCardInfoWrapper>
        <CardTitle>
          <TranslatedText stringKey="ACCESS_GATE_ADMIN_FTUE_TITLE" />
        </CardTitle>
        <CardDescription>
          <TranslatedText stringKey="ACCESS_GATE_ADMIN_FTUE_DESCRIPTION" />
        </CardDescription>
        <SetupAccessButton
          data-testid="ftueGateSetupButton"
          onClick={onHandleSetupAccessButton}
        >
          <TranslatedText stringKey="ACCESS_GATE_SET_UP_ACCESS_BUTTON_TITLE" />
        </SetupAccessButton>
      </GateCardInfoWrapper>
    </GateCardWrapper>
  );
};

export default GateCard;

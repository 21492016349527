import ContentDropdown from 'components/admin2/ui/ContentDropdown';
import Label from 'components/admin2/ui/Label';
import UploadZone from 'components/admin2/UploadZone';
import { ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';
import styled, { css } from 'styled-components';
import DefaultDivider from 'components/admin2/ui/Divider';

const IMAGE_ACCEPTED_TYPES = 'image/jpeg, image/png, image/tiff, image/bmp, image/gif, image/jpg';

export const Divider = styled(DefaultDivider)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SubSectionTitle = styled.label`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_100};
  line-height: 16px;
`;

export const DesktopBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    exactDimensions: true,
    pixelHeight: 1080,
    pixelWidth: 1920,
  },
  height: 172,
}))``;

export const MobileBackgroundUploadZone = styled(UploadZone).attrs(() => ({
  acceptedTypes: IMAGE_ACCEPTED_TYPES,
  canDrop: true,
  dimensionRequirements: {
    exactDimensions: true,
    pixelHeight: 1080,
    pixelWidth: 1920,
  },
  height: 172,
}))``;

export const StyledContentDropdown = styled(ContentDropdown).attrs((props) => ({
  titleColor: ADMIN_TEXT_100(props),
}))<{ marginBottom?: number; }>`
  ${({ marginBottom }) => marginBottom && css`margin-bottom: ${marginBottom}px;`};
`;

export const StyledLabel = styled(Label).attrs(() => ({
  admin: true,
  compact: true,
}))`
  margin: 15px 0px 5px;
`;

import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_200, ADMIN_SURFACE_1 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const Container = styled.div`
  background-color: ${ADMIN_SURFACE_1};
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0 32px 40px 32px;
  overflow-y: hidden;
  display: flex;
  flex-flow: column nowrap;
  width:100%;
  height: 100%;
`;

export const DescriptionContianer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Rows = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Exterior = styled.div`
  z-index: 100000;
  height: 100%;
  width: 100%;
`;

export const Interior = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Title = styled.div`
 ${ADMIN_TEXT_TITLE_M}
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${ADMIN_TEXT_200};
  margin-left: 10px;
`;

export const IconsContainer = styled.div `
  display: flex;
  align-items: center;
`;

export const HelpText = styled.div `
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  display: flex;
  align-items: center;
  color: ${ADMIN_TEXT_200};
  margin-top: 15px;
`;

export const StyledIcon = styled(Icon).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
}))`
  & svg {
    width: 18px;
    height: 12px;
  }
`;

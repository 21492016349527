import React, { useCallback, useEffect, useState } from 'react';
import { AmazonBenefitSettingsContainer, GateSettingsDescription, GateSettingsFieldGroup, GateSettingsFieldLabel, InstructionsItem, RadioWrapper } from './styles';
import { AccessControlGateSettingsHeader } from './AccessControlGateSettingsHeader';
import Input from 'components/admin2/TextInput';
import TranslatedText from 'components/i18n/TranslatedText';
import { isUrl } from 'url-utils';
import Radio from 'components/admin2/ui/Radio';
import { TupleEditor } from './TupleEditor';

interface AmazonBenefitGateSettingsProps {
  amazonBenefitData: AmazonBenefitData;
  handleChangeGateClick: () => void;
  setAmazonBenefitData: (value: AmazonBenefitData) => void;
  setHasGateError: (value: boolean) => void;
  setIsGateOnFinalStep: (value: boolean) => void;
}

export const BenefitTypeData = [
  {
    stringKey: 'single',
    testId: 'singleAmazonBenefitButton',
    value: 'single',
  },
  {
    stringKey: 'multiple',
    testId: 'multipleAmazonBenefitButton',
    value: 'multiple',
  },
];

const SINGLE = BenefitTypeData[0];
const MULTIPLE = BenefitTypeData[1];

export interface AmazonBenefitData {
  amazonBenefitId: string;
  amazonBenefitPurchaseUrl: string;
  benefitType: string;
  seriesBenefitMapping: SeriesBenefit[];
}

export interface SeriesBenefit {
  amazonBenefitId: string;
  seriesId: string;
}


export const AmazonBenefitGateSettings: React.FC<AmazonBenefitGateSettingsProps> = ({
  handleChangeGateClick,
  amazonBenefitData,
  setAmazonBenefitData,
  setIsGateOnFinalStep,
}) => {
  const [inputError, setInputError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [amazonBenefitId, setAmazonBenefitId] = useState(amazonBenefitData.amazonBenefitId);
  const [amazonBenefitPurchaseUrl, setAmazonBenefitPurchaseURL] = useState(amazonBenefitData.amazonBenefitPurchaseUrl);
  const [seriesBenefitMapping, setSeriesBenefitMapping] = useState(amazonBenefitData.seriesBenefitMapping);
  const [benefitType, setBenefitType] = useState(amazonBenefitData.benefitType || 'single');

  useEffect(() => {
    setIsGateOnFinalStep(true);
    setAmazonBenefitData({ amazonBenefitId, amazonBenefitPurchaseUrl, benefitType, seriesBenefitMapping });
  }, []);

  const handleInputChange = useCallback((value: string) => {
    if (value === '') {
      setInputError(true);
    } else {
      setInputError(false);
    }
    setAmazonBenefitId(value);
    setAmazonBenefitData({
      amazonBenefitId: value,
      amazonBenefitPurchaseUrl,
      benefitType,
      seriesBenefitMapping,
    });
  }, [amazonBenefitData, amazonBenefitPurchaseUrl, benefitType, seriesBenefitMapping]);

  const handleUrlChange = useCallback((value: string) => {
    if (!isUrl(value)) {
      setUrlError(true);
    } else {
      setUrlError(false);
    }
    setAmazonBenefitPurchaseURL(value);
    setAmazonBenefitData({
      amazonBenefitId,
      amazonBenefitPurchaseUrl: value,
      benefitType,
      seriesBenefitMapping,
    });
  }, [amazonBenefitId, amazonBenefitData, benefitType, seriesBenefitMapping]);

  const onChangeBenefitType = useCallback((e: any) => {
      setBenefitType(e.target.value);
      setAmazonBenefitData({
        amazonBenefitId,
        amazonBenefitPurchaseUrl,
        benefitType: e.target.value,
        seriesBenefitMapping,
      });
  }, [amazonBenefitId, amazonBenefitPurchaseUrl, benefitType, seriesBenefitMapping]);

  const onChangeSeriesBenefitMapping = useCallback((value: SeriesBenefit[]) => {
    setSeriesBenefitMapping(value);
    setAmazonBenefitData({
      amazonBenefitId,
      amazonBenefitPurchaseUrl,
      benefitType,
      seriesBenefitMapping: value,
    });
  }, [amazonBenefitId, amazonBenefitPurchaseUrl, benefitType, seriesBenefitMapping]);

  return (
    <AmazonBenefitSettingsContainer>
      <AccessControlGateSettingsHeader
        gateText="AMAZON_BENEFIT"
        handleChangeGateClick={handleChangeGateClick}
        iconName="amazonIcon"
      />
      <GateSettingsDescription>
        <TranslatedText stringKey="ACCESS_CONTROL_AMAZONBEENFIT_SETTING_DESCRIPTION" />
      </GateSettingsDescription>
      <GateSettingsFieldGroup>
        <RadioWrapper>
          <Radio
            id="single"
            checked={benefitType === SINGLE.value}
            testId={SINGLE.testId}
            labelKey="ADMIN_LABEL_AMAZON_BENEFIT_TYPE_SINGLE"
            onChange={onChangeBenefitType}
            value={SINGLE.value}
          />
          <Radio
            id="multiple"
            checked={benefitType === MULTIPLE.value}
            testId={MULTIPLE.testId}
            labelKey="ADMIN_LABEL_AMAZON_BENEFIT_TYPE_MULTIPLE"
            onChange={onChangeBenefitType}
            value={MULTIPLE.value}
          />
        </RadioWrapper>
      </GateSettingsFieldGroup>
      { benefitType === 'single' && (
        <GateSettingsFieldGroup>
          <GateSettingsFieldLabel>
            <TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_ID" />
          </GateSettingsFieldLabel>
          <Input
            data-testid="amazonBenefitTextField"
            onChange={handleInputChange}
            type="text"
            hasError={inputError}
            value={amazonBenefitId}
            errorKey="ACCESS_CONTROL_AMAZONBENEFIT_ID_ERROR"
          />
        </GateSettingsFieldGroup>

      )}
      { benefitType === 'multiple' && (
        <GateSettingsFieldGroup>
          <div>
            <GateSettingsDescription>
              <TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_LABEL" />
            </GateSettingsDescription>
            <div>
              <ol>
                <InstructionsItem><TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_1" /></InstructionsItem>
                <InstructionsItem><TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_2" /></InstructionsItem>
                <InstructionsItem><TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_3" /></InstructionsItem>
                <InstructionsItem><TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_MULTIPLE_INSTRUCTIONS_4" /></InstructionsItem>
              </ol>
            </div>
          </div>
          <TupleEditor
            tuples={seriesBenefitMapping}
            setTuples={onChangeSeriesBenefitMapping}
            labelKey="ADMIN_LABEL_SERIES"
            inputLabelKey="ADMIN_LABEL_AMAZON_BENEFIT_ID"
            inputPlaceholderKey="ADMIN_LABEL_AMAZON_BENEFIT_ID"
            inputErrorKey="ADMIN_LABEL_AMAZON_BENEFIT_ID_ERROR"
            inputTestId="amazonBenefitIdInput"
            inputError={inputError}
          />
        </GateSettingsFieldGroup>
      )}
      <GateSettingsFieldGroup>
        <GateSettingsFieldLabel>
          <TranslatedText stringKey="ACCESS_CONTROL_AMAZONBENEFIT_PURCHASE_URL" />
        </GateSettingsFieldLabel>
        <Input
          data-testid="amazonBenefitPurchaseURLField"
          onChange={handleUrlChange}
          type="text"
          hasError={urlError}
          value={amazonBenefitPurchaseUrl}
          errorKey="ACCESS_CONTROL_AMAZONBENEFIT_PURCHASE_URL_ERROR"
        />
      </GateSettingsFieldGroup>
    </AmazonBenefitSettingsContainer>
  );
};

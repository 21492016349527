import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { GateSelectionContainer, GateSelection, IconContainer, GateSettingsTitleTextContainer, GateSettingsTitleText, ChangeGateText, CheckMarkIcon, GateIcon } from './styles';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';

interface AccessControlGateSettingsHeaderProps {
  gateText: TI18nKey;
  handleChangeGateClick: () => void;
  iconName: string;
}

export const AccessControlGateSettingsHeader: React.FC<AccessControlGateSettingsHeaderProps> = ({
  gateText,
  handleChangeGateClick,
  iconName,
}) => {
  return (
  <GateSelectionContainer>
    <GateSelection>
      <IconContainer>
        <GateIcon name={iconName} />
      </IconContainer>
      <GateSettingsTitleTextContainer>
        <GateSettingsTitleText>
          <TranslatedText stringKey={gateText} />
        </GateSettingsTitleText>
      </GateSettingsTitleTextContainer>
      <IconContainer>
        <CheckMarkIcon name="circledCheckmark" />
      </IconContainer>
    </GateSelection>
    <ChangeGateText onClick={handleChangeGateClick}>
      <TranslatedText stringKey="ACCESS_CONTROL_CHANGE_GATE" />
    </ChangeGateText>
  </GateSelectionContainer>
  );
};
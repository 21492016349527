import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_HEADLINE_M, ADMIN_TEXT_HEADLINE_S, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';
import { ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_WARNING, ADMIN_SURFACE_3, ADMIN_TEXT_200, MAESTRO_WHITE } from 'style/constants';
import type { TranslationKey } from 'hooks/use-translation';

export const ICON_MAP: Record<string, string> = {
  message: 'Chatv2',
  trivia: 'Triviav2',
  highlight: 'Highlightv2',
  tweet: 'Tweetv2',
  poll: 'Pollv2',
  poll_result: 'PollResultv2',
  raffle: 'Rafflev2',
  lower_third: 'LowerThirdv2',
  twitter: 'Twitter',
  chat: 'Chatv2',
  people: 'Personv2',
  person: 'Personv2',
  quest: 'Questv2',
  brackets: 'Iframev2',
  quests: 'Questv2',
  qanda: 'QandAv2',
  group: 'Personv2',
  peopleGroups: 'Personv2',
  groups: 'Personv2',
  image: 'Imagev2',
  image_v2: 'Imagev2',
  schedule: 'Schedulev2',
  shopify: 'shopifyColored',
  channel_select: 'ChannelSelectv2',
  now_playing: 'Playlistv2',
  quick_pick: 'checkv2',
  info_panel: 'checkv2',
  playlist: 'Playlistv2',
  social: 'Personv2',
  twitch_chat: 'Twitchv2',
  multiple_choice: 'checkv2',
  iframe: 'Iframev2',
  achievement_leaderboard: 'Leaderboardv2',
  donations: 'Donationsv2',
};

export const NAME_MAP: Record<string, TranslationKey | undefined> = {
  message: 'ADMIN_LABEL_MESSAGE',
  trivia: 'TRIVIA',
  highlight: 'ADMIN_OVERLAY_HIGHLIGHT_NAME',
  tweet: 'TWEET',
  poll: 'POLL',
  poll_result: 'ADMIN_ANALYTICS_POLL_RESULT',
  raffle: 'ADMIN_OVERLAY_RAFFLE_NAME',
  lower_third: 'ADMIN_OVERLAY_LOWER_THIRD_NAME',
  qanda: 'ADMIN_ANALYTICS_QUESTION_AND_ANSWER',
  donations: 'ADMIN_LABEL_DONATIONS',
  image: 'ADMIN_LABEL_IMAGE',
  image_v2: 'ADMIN_LABEL_IMAGE',
  brackets: 'ADMIN_ANALYTICS_BRACKETS',
  twitter: 'ADMIN_LABEL_TWITTER',
  chat: 'ADMIN_PANEL_CHAT_NAME',
  people: 'ADMIN_PANEL_PEOPLE_NAME',
  person: 'ADMIN_LABEL_PERSON',
  peopleGroups: 'ADMIN_ANALYTICS_PEOPLE_GROUPS',
  group: 'ADMIN_LABEL_GROUP',
  groups: 'ADMIN_LABEL_GROUPS',
  quest: 'ADMIN_PANEL_QUEST_NAME',
  quests: 'ADMIN_PANEL_QUEST_NAME',
  now_playing: 'NOW_PLAYING',
  info_panel: 'ADMIN_ANALYTICS_INFO_PANEL',
  schedule: 'SCHEDULE',
  shopify: 'ADMIN_PANEL_SHOPIFY_NAME',
  channel_select: 'ADMIN_PANEL_CHANNEL_SELECT_NAME',
  quick_pick: 'ADMIN_ANALYTICS_QUICK_PICK',
  playlist: 'ADMIN_PANEL_PLAYLIST_NAME',
  social: 'SOCIAL_LABEL',
  twitch_chat: 'TWITCH',
  multiple_choice: 'ADMIN_LABEL_MULTIPLE_CHOICE',
  iframe: 'ADMIN_PANEL_IFRAME_NAME',
  achievement_leaderboard: 'ADMIN_LABEL_LEADERBOARD',
  profile: 'DEFAULT_PROFILE',
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled.div`
  margin-top: 20.5px;
`;

export const RowContainer = styled.div`
  margin-top: 11px;
  display: flex;
  flex-direction: row;
`;

export const Title = styled.div`
  ${ADMIN_TEXT_HEADLINE_M}
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${MAESTRO_WHITE};
  margin-left: 10px;
`;

export const Subtitle = styled.div`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  display: flex;
  align-items: center;
  color: ${MAESTRO_WHITE};
  letter-spacing: -0.03em;
  color: ${MAESTRO_WHITE};
  padding-bottom: 9px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const IconsContainer = styled.div `
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
`;

export const NumberContainer = styled.div `
  ${ADMIN_TEXT_HEADLINE_S}
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  background: ${ADMIN_SURFACE_3};
  border-radius: 5px;
  color: ${ADMIN_ACCENT_PRIMARY};
  justify-content: center;
`;

export const TypeContainer = styled.div `
  display: flex;
  width: 199px;
  height: 50px;
  background: ${ADMIN_SURFACE_3};
  border-radius: 5px;
  margin-left: 9px;
`;

export const IconName = styled.div `
  display: flex;
  ${ADMIN_TEXT_BODY_S_MEDIUM}
  align-items: center;
  color: ${ADMIN_TEXT_200};
  margin-left: 13px;
`;

export const Column = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DataContainer = styled.div `
  display: flex;
  width: 315px;
  height: 50px;
  margin-left: 9px;
`;

export const Amount = styled.div `
  ${ADMIN_TEXT_TITLE_M}
  color: ${ADMIN_TEXT_200};
`;

export const EngagedText = styled.div `
  ${ADMIN_TEXT_BODY_S_BOLD}
  color: #C1C2C6;
  margin-top: 5px;
`;

export const Percentage = styled.div `
  display: flex;
  align-items: flex-end;
  ${ADMIN_TEXT_TITLE_M}
  color: ${ADMIN_ALERT_WARNING};
  margin-left: 15px;
  padding-bottom: 8px;
`;

export const RowIcon = styled(Icon)`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

// tslint:disable: no-console
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCachedCallback } from 'hooks/use-cached-callback';
import { DEFAULT_SITE_WIDE_ADS_CONFIG, IAdsConfig, VastAdConfig } from 'models/IAdsConfig';
import { getAdsConfig, upsertAdsConfig } from 'services/ads/api';
import { getPrimaryToken } from 'services/auth';
import { getSiteId } from 'services/app';

export type UseSiteAdsParams = {
  pageId: string | null;
};

const useAdsConfig = ({ pageId }: UseSiteAdsParams) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [adsConfig, setAdsConfig] = useState<IAdsConfig>(DEFAULT_SITE_WIDE_ADS_CONFIG);
  const primaryToken = useSelector(getPrimaryToken) || '';
  const siteId = useSelector(getSiteId);

  useEffect(() => {
    if (!primaryToken) {
      return;
    }

    let isMounted = true;

    getAdsConfig({
      pageId,
      videoId: null,
    })
      .then((ads) => {
        if (!isMounted || !ads) return;
        setAdsConfig(ads);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!isMounted) return;
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [primaryToken, pageId]);

  const cancelPendingRequest = useRef<() => void>();

  const handleChange = useCachedCallback(
    useCallback(
      <K extends keyof IAdsConfig>(key: K) => (value: IAdsConfig[K]) => {
        setLoading(true);

        setAdsConfig(
          (currentAds) => {
            const newAds: IAdsConfig = {
              ...currentAds,
              [key]: value,
              pageId,
              _id: undefined,
              siteId,
            };

            if (key === 'enabled') {
              delete newAds.archived;
            }

            if (!newAds.enabled) {
              (newAds.config as VastAdConfig).url = '';
            }

            cancelPendingRequest.current?.();

            setError(null);

            upsertAdsConfig({
              token: primaryToken,
              adsConfig: newAds,
              captureCanceler: (abortFn) => {
                cancelPendingRequest.current = abortFn;
              },
            })
              .then(() => {
                if (error) {
                  setError(null);
                }
              })
              .catch((err) => {
                setAdsConfig(currentAds);
                setError(err?.response?.data?.message || 'Something went wrong');
                console.error(err);
              })
              .finally(() => {
                setLoading(false);
              });

            return newAds;
          },
        );
      },
      [primaryToken, siteId, pageId],
    ),
  );

  return {
    adsConfig,
    handleChange,
    loading,
    error,
  };
};

export default useAdsConfig;

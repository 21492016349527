import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SPACING_MEDIUM, ADMIN_SURFACE_4 } from 'style/constants';
import TextInput from 'components/admin2/TextInput';
import IconDropdown from 'components/admin2/ui/IconDropdown';
import Divider from 'components/admin2/Divider';
import DateTimePicker from 'components/admin2/DatetimePicker';
import CmsNavHeader from 'components/admin2/CmsNavHeader';
import EditorHeader from './EditorHeader';
import TimeFrame from './TimeFrame';

const propTypes = {
  descriptionKey: PropTypes.string.isRequired,
  injectionComponent: PropTypes.node,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  renderer: PropTypes.shape({
    activityFeedText: PropTypes.string,
    alwaysActive: PropTypes.bool,
    iconName: PropTypes.string,
    name: PropTypes.string,
    pointsValue: PropTypes.number,
    timeEnd: PropTypes.number,
    timeStart: PropTypes.number,
  }).isRequired,
  titleKey: PropTypes.string.isRequired,
};

const defaultProps = {
  injectionComponent: null,
};

const Container = styled.div`
  width: 524px;
`;

const Body = styled.div`
  padding: ${SPACING_MEDIUM};
  background: ${ADMIN_SURFACE_4};
`;

const Halves = styled.div`
  display: flex;
  & > * {
    flex: 1;
  }
`;

const RightTextInput = styled(TextInput)`
  margin-left: ${SPACING_MEDIUM};
`;

const SpecificAchievementEditor = (props) => {
  const {
    renderer,
    onChange,
    onBack,
    injectionComponent,
    descriptionKey,
    titleKey,
    onSave,
  } = props;
  // TODO string constant these guys since they're keys..?? proptypes check?
  const {
    name,
    iconName,
    pointsValue,
    activityFeedText,
    alwaysActive,
    timeStart,
    timeEnd,
  } = renderer;

  const headerActions = [
    {
      labelKey: 'ADMIN_ACTION_SAVE',
      onClick: () => { onSave(); },
    },
  ];

  return (
    <Container>
      <CmsNavHeader
        actions={headerActions}
        onBack={onBack}
        validationCollection="achievements"
        validationEnabled
        validationValue={renderer}
      />
      <Body>
        <EditorHeader
          descriptionKey={descriptionKey}
          titleKey={titleKey}
        />
        <TextInput
          labelKey="ADMIN_LABEL_NAME"
          onChange={text => onChange({ ...renderer, name: text })}
          value={name}
        />
        <Halves>
          <IconDropdown
            onChange={iconNameStr => onChange({ ...renderer, iconName: iconNameStr })}
            value={iconName}
          />
          <RightTextInput
            labelKey="ADMIN_LABEL_POINTS"
            onChange={pointStr => onChange({ ...renderer, pointsValue: Number(pointStr.replace(/[^0-9]/, '')) })}
            value={pointsValue}
          />
        </Halves>
        <TextInput
          labelKey="ADMIN_LABEL_ACTIVITY_FEED_TEXT"
          onChange={text => onChange({ ...renderer, activityFeedText: text })}
          value={activityFeedText}
        />
        <Divider />
        {
          injectionComponent && (
            <>
              { injectionComponent(renderer, onChange) }
              <Divider />
            </>
          )
        }
        <TimeFrame
          onChange={value => onChange({ ...renderer, alwaysActive: value })}
          value={alwaysActive}
        />
        <DateTimePicker
          dateSelectKey="ADMIN_LABEL_START_DATE"
          isDisabled={alwaysActive}
          isOutsideRange={() => false}
          onTimeChange={timestamp => onChange({ ...renderer, timeStart: timestamp })}
          timeSelectKey="ADMIN_LABEL_START_TIME"
          timestamp={timeStart}
        />
        <DateTimePicker
          dateSelectKey="ADMIN_LABEL_END_DATE"
          isDisabled={alwaysActive}
          isOutsideRange={() => false}
          onTimeChange={timestamp => onChange({ ...renderer, timeEnd: timestamp })}
          timeSelectKey="ADMIN_LABEL_END_TIME"
          timestamp={timeEnd}
        />
      </Body>
    </Container>
  );
};

SpecificAchievementEditor.propTypes = propTypes;
SpecificAchievementEditor.defaultProps = defaultProps;

export default SpecificAchievementEditor;

import React from 'react';

import AdminBarTabHeader, { AdminBarTab } from 'components/admin2/ui/AdminBarTabHeader';
import Divider from 'components/admin2/ui/Divider';

import { Container, Footer, SettingsContainer, SubtitleText, Title } from './styles';
import BillingPlan from './BillingPlan';
import TranslatedText from 'components/i18n/TranslatedText';
import BillingCreditCards from './BillingCreditCards';
import { getIsPlanTypeSelfServiceOrProPlan } from 'services/billing';
import { useSelector } from 'react-redux';
import BillingPayout from './BillingPayout';
import parse from 'html-react-parser';
import { useAdminTranslation } from 'hooks/use-translation';
import Link from '../Link';

const BillingTab: React.FC = () => {
  const isSelfService = useSelector(getIsPlanTypeSelfServiceOrProPlan);
  const { t } = useAdminTranslation();

  return (
    <AdminBarTab>
      <AdminBarTabHeader
        hasUnsavedChanges={false}
        headerKey="ADMIN_LABEL_MANAGE"
        subtitleKey="ADMIN_LABEL_BILLING"
      />
      <Container>
        <TranslatedText stringKey="ADMIN_SETTINGS_BILLING" component={Title} />
        <TranslatedText stringKey="ADMIN_SETTINGS_BILLING_SUBTITLE" component={SubtitleText} />

        <SettingsContainer>
          <Divider />
          <BillingPlan />
          {isSelfService && (
            <>
              <Divider />
              <BillingCreditCards />
            </>
          )}
          <Divider />
          <BillingPayout />
        </SettingsContainer>
      </Container>
      <Footer>
        <Link
          href={'https://support.maestro.io/knowledge/kb-tickets/new'}
          target="__blank"
        >
          {parse(t('ADMIN_SETTINGS_BILLING_CANCEL_ACCOUNT'))}
        </Link>
      </Footer>
    </AdminBarTab>
  );
};

export default BillingTab;

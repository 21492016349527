import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'hooks';
import hash from 'json-stable-stringify';
import { isUrl } from 'url-utils';
import { isEqual } from 'lodash';
import { updateSaved, webhookUrlChanged } from 'services/developer/actions';
import { testEvent } from 'services/developer/api';
import { getDeveloperSetting, hasApiKeyData, webhookUrlHasChanged } from 'services/developer/selectors';

import Events from './Events';

import TranslatedText from 'components/i18n/TranslatedText';
import { LargeButton } from 'components/admin2/ui/Button';
import {
  Description,
  UrlToNotify,
  EventSent,
  WebhooksSaved,
  MessageToUserIcon,
  WebhooksLabelContainer,
  InputRow,
  InputContainer,
  StyledTextInput,
  ValidUrlIcon,
  StyledEditButton,
  Container,
  TestURLButton,
} from './styles';
import { getAdminSurface5, getAdminText300 } from 'services/themes';
import LabelToggle from 'components/admin2/ui/LabelToggle';
import { IDeveloperSetting } from 'services/developer/models';
import { DEVELOPER_TAB_WEBHOOKS_SECTION_ID } from 'global-ids';

const supportedServices = {
  AccessCode: 'accesscode-service-v1',
  Accounts: 'account-service-v3',
  Authentication: 'auth-service-v2',
  Billing: 'billing-service-v1',
  Channels: 'page-service-v2',
  Poll: 'poll-service-v3',
  Shopify: 'shopify-service-v1',
  Streaming: 'stream-service-v1',
  Video: 'video-service-v3',
};

export interface IWebhookSetting {
  enabled: boolean,
  webhookSubscriptions: string[];
  webhookUrl: string;
}

type WebhooksProps = {
  newDeveloperSetting: IDeveloperSetting;
  setNewDeveloperSetting: React.Dispatch<React.SetStateAction<IDeveloperSetting>>;
};

const Webhooks: FC<WebhooksProps> = ({
  newDeveloperSetting,
  setNewDeveloperSetting,
}) => {
  const currentDeveloperSetting = useSelector(getDeveloperSetting, isEqual);
  const {
    apiKey: { data: apiKeyData },
    webhooks: {
      data: { webhookUrl: currentWebhookUrl },
    },
  } = currentDeveloperSetting;
  const {
    webhooks: { enabled, webhookSubscriptions, webhookUrl } = {},
  } = newDeveloperSetting;

  const hasApiKey = useSelector(hasApiKeyData);
  const hasWebhookUrlChanged = useSelector(webhookUrlHasChanged);
  const adminThemeSurface5 = useSelector(getAdminSurface5);
  const adminText300 = useSelector(getAdminText300);

  const [editingWebhookUrl, setEditingWebhookUrl] = useState(webhookUrl);
  const [isWebhookUrlBeingEdited, setIsWebhookUrlBeingEdited] = useState(!currentWebhookUrl);
  const [testSent, setTestSent] = useState(false);
  const [subscriptions, setSubscriptions] = useState(() => Object.entries(supportedServices).map(([name, service]) => ({ // eslint-disable-line max-len
    enabled: webhookSubscriptions && webhookSubscriptions.includes(service),
    name,
    service,
  })));

  const webhooksActive = useMemo(() => {
    return hasApiKey && enabled;
  }, [hasApiKey, enabled]);

  const hasCurrentWebhookUrl = useMemo(() => {
    return Boolean(hasApiKey && currentWebhookUrl && currentWebhookUrl.length > 0);
  }, [hasApiKey, currentWebhookUrl]);

  const hasEditingWebhookUrlChanged = useMemo(() => {
    return !isEqual(editingWebhookUrl, currentWebhookUrl);
  }, [editingWebhookUrl, currentWebhookUrl]);

  const isUrlValid = useMemo(() => {
    return hasApiKey && editingWebhookUrl && isUrl(editingWebhookUrl);
  }, [hasApiKey, editingWebhookUrl]);

  const isTestButtonDisabled = useMemo(() => {
    return !webhooksActive || !isUrlValid;
  }, [webhooksActive, isUrlValid]);

  const isInputFieldDisabled = useMemo(() => {
    return !webhooksActive || !isWebhookUrlBeingEdited;
  }, [webhooksActive, isWebhookUrlBeingEdited]);

  const showValidUrlIcon = useMemo(() => {
    return webhooksActive && isWebhookUrlBeingEdited && hasEditingWebhookUrlChanged && isUrlValid;
  }, [webhooksActive, isWebhookUrlBeingEdited, hasEditingWebhookUrlChanged, isUrlValid]);

  const showEditIcon = useMemo(() => {
    return webhooksActive && (hasCurrentWebhookUrl || testSent);
  }, [webhooksActive, hasCurrentWebhookUrl, testSent]);

  const showWebhooksSavedMessage = useMemo(() => {
    return webhooksActive && hasWebhookUrlChanged;
  }, [webhooksActive, hasWebhookUrlChanged]);

  const showTestEventSentMessage = useMemo(() => {
    return webhooksActive && testSent;
  }, [webhooksActive, testSent]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSaved(false));
    dispatch(webhookUrlChanged(false));
  }, []);

  useEffect(() => {
    setIsWebhookUrlBeingEdited(!currentWebhookUrl);
  }, [currentWebhookUrl]);

  useEffect(() => {
    if (!apiKeyData) {
      const resetedSubscriptions = subscriptions.map((sub) => {
        sub.enabled = false;
        return sub;
      });
      setSubscriptions(resetedSubscriptions);
      setEditingWebhookUrl('');
      setNewDeveloperSetting({
        ...newDeveloperSetting,
        webhooks: {
          ...newDeveloperSetting.webhooks,
          enabled: false,
        },
      });
    }
  }, [hash(apiKeyData)]);

  useEffect(() => {
    setNewDeveloperSetting({
      ...newDeveloperSetting,
      webhooks: {
        ...newDeveloperSetting.webhooks,
        webhookSubscriptions: subscriptions.filter(sub => sub.enabled).map(sub => sub.service),
      },
    });
    dispatch(updateSaved(false));
  }, [subscriptions]);

  useEffect(() => {
    if (!currentWebhookUrl) {
      setEditingWebhookUrl('');
    }
  }, [currentWebhookUrl]);

  useEffect(() => {
    if (isUrlValid) {
      setNewDeveloperSetting({
        ...newDeveloperSetting,
        webhooks: {
          ...newDeveloperSetting.webhooks,
          webhookUrl: editingWebhookUrl!,
        },
      });
      dispatch(updateSaved(false));
    } else if (webhookUrl) {
      setNewDeveloperSetting({
        ...newDeveloperSetting,
        webhooks: {
          ...newDeveloperSetting.webhooks,
          webhookUrl: currentWebhookUrl,
        },
      });
    }
  }, [isUrlValid, editingWebhookUrl]);

  const handleTestEvent = () => {
    if (!webhookUrl || !apiKeyData?.key) return;

    testEvent(webhookUrl, apiKeyData.key);
    setTestSent(true);
    dispatch(webhookUrlChanged(false));
    setIsWebhookUrlBeingEdited(false);
  };

  const handleWebhooksToggle = (value: boolean) => {
    setNewDeveloperSetting({
      ...newDeveloperSetting,
      webhooks: {
        ...newDeveloperSetting.webhooks,
        enabled: value,
      },
    });
  };

  const handleEditClick = () => {
    if (!isWebhookUrlBeingEdited) {
      setTestSent(false);
      dispatch(updateSaved(false));
      dispatch(webhookUrlChanged(false));
    }
    setIsWebhookUrlBeingEdited(!isWebhookUrlBeingEdited);
  };

  const handleWebhookUrl = (value: string) => {
    setEditingWebhookUrl(value);
  };

  return (
    <Container
      id={DEVELOPER_TAB_WEBHOOKS_SECTION_ID}
      data-testid={DEVELOPER_TAB_WEBHOOKS_SECTION_ID}
    >
      <WebhooksLabelContainer>
        <LabelToggle
          checked={webhooksActive}
          disabled={!hasApiKey}
          onChange={handleWebhooksToggle}
          labelKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_LABEL"
        />
      </WebhooksLabelContainer>
      <TranslatedText
        component={Description}
        stringKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_DESCRIPTION"
      />
      <TranslatedText
        component={UrlToNotify}
        stringKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_URL_TO_NOTIFY_TITLE"
      />
      {showWebhooksSavedMessage ? (
        <WebhooksSaved>
          <MessageToUserIcon name="checkboxCheck" />
          <TranslatedText stringKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_SAVED" />
        </WebhooksSaved>
      ) : showTestEventSentMessage && (
        <EventSent>
          <MessageToUserIcon name="checkboxCheck" />
          <TranslatedText stringKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_EVENT_SENT" />
        </EventSent>
      )}
      <InputRow>
        <InputContainer>
          <StyledTextInput
            disabled={isInputFieldDisabled}
            onChange={handleWebhookUrl}
            placeholderKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_URL_TO_NOTIFY_PLACEHOLDER"
            value={editingWebhookUrl}
            webhooksActive={webhooksActive}
          />
          {showValidUrlIcon && <ValidUrlIcon name="checkboxCheck" />}
        </InputContainer>
        {showEditIcon && (
          <StyledEditButton onClick={handleEditClick} />
        )}
      </InputRow>
      <TestURLButton
        disabled={isTestButtonDisabled}
        disabledBackground={adminThemeSurface5}
        disabledColor={adminText300}
        onClick={handleTestEvent}
      >
        <TranslatedText stringKey="ADMIN_SETTINGS_DEVELOPER_WEBHOOKS_URL_TO_NOTIFY_BUTTON" />
      </TestURLButton>
      <Events
        disabled={!webhooksActive}
        hasApiKey={hasApiKey}
        setSubscriptions={setSubscriptions}
        subscriptions={subscriptions}
      />
    </Container>
  );
};

export default Webhooks;

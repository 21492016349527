import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getAdminThemeIcon, getAdminThemeLogo } from 'services/themes';
import { Icon, Logo } from './styles';

interface IBrand extends React.ImgHTMLAttributes<HTMLImageElement> {
  showIcon?: boolean;
}

const Brand: React.FC<IBrand> = ({ showIcon = false, alt, ...props }) => {
  const adminThemeLogo = useSelector(getAdminThemeLogo);
  const adminThemeIcon = useSelector(getAdminThemeIcon);

  React.useEffect(() => {
    // Preload images for better UX
    if (adminThemeLogo) {
      const img = new Image();
      img.src = adminThemeLogo;
    }
    if (adminThemeIcon) {
      const img = new Image();
      img.src = adminThemeIcon;
    }
  }, []);

  if (showIcon) {
    return <Icon {...props} src={adminThemeIcon} alt={alt || 'Icon'} />;
  }

  return (
    <Logo
      {...props}
      src={adminThemeLogo}
      alt={alt || 'Logo'}
    />
  );
};

export default memo(Brand);

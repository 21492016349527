import { ADMIN_FONT_FAMILY, ADMIN_SURFACE_2, ADMIN_SURFACE_4, ADMIN_TEXT_200, ADMIN_TEXT_100, ADMIN_SURFACE_1 } from 'style/constants';
import styled, { css } from 'styled-components';
import Input from 'components/admin2/TextInput';
import { CloseButton } from 'components/admin2/ui/Button';
import { ADMIN_TEXT_BODY_L_BOLD, ADMIN_TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

export const COLOR_PICKER_WIDTH = 353;
export const COLOR_PICKER_HEIGHT = 356;

export const ColorPickerWrapper = styled.div<{ backgroundColor?: string; position: { left: number; top: number; }}>`
  ${({ position }) => position.left === 0 && position.top === 0 && css`display: none;`};
  width: ${COLOR_PICKER_WIDTH}px;
  border-radius: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || ADMIN_SURFACE_4};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  left: ${({ position }) => position.left + 20}px;
  top: ${({ position }) => position.top}px;
  z-index: 100;
  min-height: ${COLOR_PICKER_HEIGHT}px;
`;

export const RelativeContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: ${ADMIN_TEXT_100};
  font-family: ${ADMIN_FONT_FAMILY};
  height: 100%;
  width: 100%;
  position: relative;
`;

// @ts-ignore
export const StyledCloseButton = styled(CloseButton).attrs({
  containerCss: `
    position: absolute;
    top: 8px;
    right: 8px;
    & span {
      background-color: rgba(0, 0, 0, 0.3);
    }
  `,
})``;

export const HuePointer = styled.div<{ color: string; }>`
  height: 18.78px;
  width: 9.39px;
  margin-top: -3px;
  border: 3px solid white;
  border-radius: 5px;
  cursor: pointer;
  transform: translateX(-50%);
  ${({ color }) => css`background-color: ${color};`};
`;

export const SaturationPointer = styled.div`
  width: 13.95px;
  height: 13.95px;
  border-radius: 50%;
  border: 3px solid white;
  cursor: pointer;
  transform: translateY(-10.95px) translateX(-5.5px);
`;

export const ColorTitleText = styled.span`
  ${ADMIN_TEXT_BODY_L_BOLD};
`;

export const ColorSubtitleText = styled.span`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${ADMIN_TEXT_200};
`;

export const QuickAccessColorsWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ColorPaletteWrapper = styled.div`
  background-color: ${ADMIN_SURFACE_1};
`;

export const RefContainer = styled.div`
  display: contents;
`;

export const SaturationContainer = styled.section`
  width: 100%;
  contain: content;
  height: 137.96px;
  position: relative;
`;

export const HueContainer = styled.section`
  height: 12.52px;
  margin-top: 6.26px;
  margin-bottom: 6.26px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const ColorInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
  column-gap: 5px;
  row-gap: 10px;
  margin-top: 15px;

  & > div {
    padding: 0;
  }
`;

export const ColorInfoLabel = styled.label`
  ${ADMIN_TEXT_BODY_S_MEDIUM};
  color: ${ADMIN_TEXT_200};
`;

export const StyledInput = styled(Input)<{ inputWidth?: number; }>`
  background-color: ${ADMIN_SURFACE_2};
  ${({ inputWidth }) => inputWidth && css`width: ${inputWidth}px;`};
`;

import shortId from 'shortid';

export default async ({
  renderer,
}) => {
  return {
    key: `preview-${shortId.generate()}`,
    payload: {
      channel: 'preview',
      data: {},
      renderer,
    },
    timeRemaining: 45, // hard-code for preview
  };
};

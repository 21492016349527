import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContract, getIsFreePlan, getPlan } from 'services/billing/selectors';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import {
  StyledMethodTitle,
  StyledNextBillingDateLabel,
  UpgradePlanButton,
} from '../styles';
import { Container, LoadingContainer, PlanContainer } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import EllipsisLoader from 'components/admin2/ui/EllipsisLoader';
import { IPurchasePlanCTALocation, useTrackPurchasePlanClicked } from 'hooks';
import { PlanCard } from './PlanCards';
import { useAdminTranslation } from 'hooks/use-translation';
import {
  getIsLegacyPlan,
  getIsUnlimitedPlan,
} from 'services/billing/selectors';

const BillingPlan: React.FC = () => {
  const plan = useSelector(getPlan);
  const contract = useSelector(getContract);
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();
  const trackPurchasePlanClicked = useTrackPurchasePlanClicked();
  const isLegacyPlan = useSelector(getIsLegacyPlan);
  const isUnlimitedPlan = useSelector(getIsUnlimitedPlan);
  const isOldFreeTrial = contract?.status === 'trial';
  const isFreePlan = useSelector(getIsFreePlan);

  const shouldDisplayPurchaseButton =
    !isUnlimitedPlan && !isLegacyPlan && !isOldFreeTrial;
  const shouldDisplayLegacyPurchaseModal = isOldFreeTrial && isLegacyPlan;

  const onOpenUgradePlan = useCallback(() => {
    dispatch(
      showModal({ data: { locked: false }, kind: ModalKinds.upgradePlan }),
    );
    return;
  }, []);

  const onOpenPurchasePlan = useCallback(() => {
    trackPurchasePlanClicked({
      location: IPurchasePlanCTALocation.BILLING_TAB,
      buttonTitle: t('ADMIN_SETTINGS_BILLING_UPGRADE_PLAN'),
    });
    dispatch(
      showModal({ data: { locked: false }, kind: ModalKinds.purchasePlan }),
    );
    return;
  }, []);

  const renderBillingCard = useCallback(() => {
    if (!plan || !contract) {
      return (
        <PlanContainer>
          <LoadingContainer>
            <EllipsisLoader loading={true} />
          </LoadingContainer>
        </PlanContainer>
      );
    }
    return <PlanCard plan={plan} />;
  }, [plan, contract]);

  const renderNextBillingDate = useCallback(() => {
    if (!contract || isFreePlan) {
      return null;
    }

    const nextBillingDateInMs = contract.term.nextBillingDate * 1000;
    const nextBillingDate = new Date(nextBillingDateInMs);
    const nextBillingDateFormatted = nextBillingDate.toLocaleDateString(
      'en-US',
      {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      },
    );

    return (
      <StyledNextBillingDateLabel>
        <TranslatedText stringKey="ADMIN_SETTINGS_BILLING_PLAN_NEXT_BILL" />
        :&nbsp;
        <span>{nextBillingDateFormatted}</span>
      </StyledNextBillingDateLabel>
    );
  }, [contract?.term?.nextBillingDate, isFreePlan]);

  return (
    <Container data-testid="billingPlanSection">
      <TranslatedText
        stringKey="ADMIN_SETTINGS_BILLING_PLAN_HEADER_TITLE"
        component={StyledMethodTitle}
      />
      {renderNextBillingDate()}
      {renderBillingCard()}
      {shouldDisplayPurchaseButton && (
        <UpgradePlanButton
          onClick={
            shouldDisplayLegacyPurchaseModal
              ? onOpenPurchasePlan
              : onOpenUgradePlan
          }
        >
          <TranslatedText
            stringKey={
              shouldDisplayLegacyPurchaseModal
                ? 'ADMIN_SETTINGS_BILLING_PLAN_FREE_TRIAL_BUY_PLAN'
                : 'ADMIN_SETTINGS_BILLING_UPGRADE_PLAN'

            }
          />
        </UpgradePlanButton>
      )}
    </Container>
  );
};

export default BillingPlan;

import styled from 'styled-components';
import { ADMIN_TEXT_100, ADMIN_SURFACE_5, ADMIN_SURFACE_1 } from 'style/constants';
import { ADMIN_INPUT_BOX_SHADOW, ADMIN_SCROLL_BAR } from 'style/mixins';
import { ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';

export const YearDropdownContainer = styled.div<{ isActive: boolean }>`
  ${ADMIN_SCROLL_BAR}
  ${ADMIN_INPUT_BOX_SHADOW}
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  max-height: 200px;
  top: 40px;
  left: 0;
  right: 0;
  background-color: ${ADMIN_SURFACE_1};
  border-radius: 5px;
  z-index: 2;
  user-select: false;
`;

export const YearDropdwonEntry = styled.div`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_100};
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${ADMIN_SURFACE_5};
  }
`;

import styled from 'styled-components';
import { CopyButton, LargeButton } from 'components/admin2/ui/Button';
import { DescriptionWithoutPadding } from 'components/admin2/ui/Text';
import {
  ADMIN_TEXT_200,
  SPACING_X_SMALL,
  SPACING_SMALL,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import TextInput from 'components/admin2/TextInput';
export const StyledLargeButton = styled(LargeButton)`
  margin-top: 15px;
  width: 100%;
`;

export const StyledCopyButton = styled(CopyButton)`
  width: 24px;
  height: 24px;
`;

export const InputRow = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  gap: 10px;
`;

export const StyledInput = styled(TextInput)`
  width: 100%;
`;

export const CopySiteIdRow = styled(InputRow)`
  margin-bottom: ${SPACING_SMALL};

  display: flex;
  justify-content: space-between;
`;

export const CopyApiKeyRow = styled(InputRow)`
  margin-top: ${SPACING_SMALL};
  margin-bottom: 5px;

  display: flex;
  justify-content: space-between;

  input {
    width: 260px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const LabelText = styled.div`
  color: ${ADMIN_TEXT_200};
  ${ADMIN_TEXT_LABEL_M_MEDIUM}
  margin-top: ${SPACING_X_SMALL};
  margin-bottom: ${SPACING_SMALL};
`;

export const IconContainer = styled.div`
  padding-right: 10px;
  cursor: pointer;
`;

export const LoadingEllipseContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;

export const StyledDescription = styled(DescriptionWithoutPadding)`
  padding-top: -10px;
`;

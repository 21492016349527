import React, { useMemo, useState } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { Divider, SectionTitle, StyledPageNavigation, ThemesCollectionWrapper } from '../../styles';
import { CUSTOM_THEMES_MAX_PER_PAGE, useCustomThemes } from '../../use-maestro-themes';
import { ITheme } from 'models';
import SelectThemeButton from 'components/admin2/Themes/SelectThemeButton';
import { PLACEHOLDER_THEME } from '../../constants';
import RealtimeDocument from 'components/core/RealtimeDocument';

interface ICustomThemesProps {
  activeThemeId: string;
  defaultThemeId?: string;
  onCustomize: (theme: ITheme) => void;
  onDelete: (theme: ITheme) => void;
  onFavorite: (theme: ITheme) => void;
  onSelect: (theme: ITheme) => void;
}

interface IWrapperProps {
  page: number;
  setPage: (page: number) => void;
  showPagination: boolean;
  totalPages: number;
}

const ROWS = CUSTOM_THEMES_MAX_PER_PAGE / 2;

const Wrapper: React.FC<IWrapperProps> = ({ children, showPagination, page, setPage, totalPages }) => {
  const onNextPage = () => {
    setPage(page + 1);
  };

  const onPreviousPage = () => {
    setPage(page - 1);
  };

  return (
    <>
      <TranslatedText component={SectionTitle} stringKey="ADMIN_DESIGN_TAB_CUSTOM_THEMES" />
      <ThemesCollectionWrapper rows={showPagination ? ROWS : undefined}>
        {children}
      </ThemesCollectionWrapper>
      {showPagination && (
        <StyledPageNavigation
          admin={true}
          onChange={setPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          page={page}
          pages={totalPages}
        />
      )}
      <Divider />
    </>
  );
};

const CustomThemes: React.FC<ICustomThemesProps> = ({ activeThemeId, defaultThemeId, onCustomize, onFavorite, onDelete, onSelect }) => {
  const [page, setPage] = useState(0);
  const { data, error, loaded, totalCount } = useCustomThemes({
    page,
  });

  const onSelectTheme = (theme: ITheme) => () => {
    onSelect(theme);
  };

  const onDeleteTheme = (theme: ITheme) => () => {
    onDelete(theme);
    setPage(0);
  };

  const onFavoriteTheme = (theme: ITheme) => () => {
    onFavorite(theme);
  };

  const onCustomizeTheme = (theme: ITheme) => () => {
    onCustomize(theme);
  };

  const placeholderCount = useMemo(() => {
    if (page === 0) {
      return CUSTOM_THEMES_MAX_PER_PAGE;
    }

    const result = totalCount - page * CUSTOM_THEMES_MAX_PER_PAGE;
    return Math.min(result, CUSTOM_THEMES_MAX_PER_PAGE);
  }, [page, totalCount]);

  const themesComponents = useMemo(() => {
    if (!data) {
      return [];
    }

    const results = data.map((childTheme, index) => (
      <RealtimeDocument collection="themes" id={childTheme._id} key={index}>
        {(doc: ITheme, ready) => {
          if (!ready || !doc) {
            return null;
          }

          return (
            <SelectThemeButton
              theme={doc}
              isActive={doc._id === activeThemeId}
              isFavorite={defaultThemeId === doc._id}
              key={index}
              name={doc.name}
              onSelect={onSelectTheme(doc)}
              onDelete={onDeleteTheme(doc)}
              onFavorite={onFavoriteTheme(doc)}
              onCustomize={onCustomizeTheme(doc)}
            />
          );
        }}
      </RealtimeDocument>
    )).filter(Boolean);

    return results;
  }, [data, defaultThemeId, activeThemeId, onFavorite, onDelete, onSelect]);

  const { showPagination, totalPages } = useMemo(() => {
    const pages = Math.ceil(totalCount / CUSTOM_THEMES_MAX_PER_PAGE);
    return {
      showPagination: totalCount > CUSTOM_THEMES_MAX_PER_PAGE,
      totalPages: pages,
    };
  }, [totalCount]);

  // first load
  if (totalCount === 0 || error) {
    return null;
  }

  // fetching new page
  if (!loaded) {
    return (
      <Wrapper
        showPagination={true}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      >
        {Array.from({ length: placeholderCount }).map((_, index) => (
          <SelectThemeButton
            theme={PLACEHOLDER_THEME}
            isActive={false}
            isFavorite={false}
            key={index}
            name=""
            isLoading={true}
          />
        ))}
      </Wrapper>
    );
  }

  return (
    <Wrapper
      showPagination={showPagination}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
    >
      {themesComponents}
    </Wrapper>
  );
};

export default CustomThemes;

import styled from 'styled-components';
import PieChart from '../pie-chart';

export const StyledPieChart = styled(PieChart)`
`;

export const EngagementChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  & * {
    margin-right: 12px;
  }
`;

export const LabelsContainer = styled.div`
  display: flex;
  width: 235px;
  align-self: center;
  flex-direction: column;
  & * {
    margin: 12px 0;
  }
`;

export const Separator = styled.div`
  width : 83%;
  height: 1px;
  margin: 0px !important;
  margin-left: 30px !important;
  background:#313542;
`;

import styled, { css } from 'styled-components';
import { Color } from '.';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_5, SPACING_SMALL } from 'style/constants';
import { ADMIN_TEXT_HEADLINE_S } from 'style/design-system/textStyles';

export const SeparatorContainer = styled.div<{color?: Color; hasSymbol: boolean;}>`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ADMIN_TEXT_HEADLINE_S}
  color: ${({ color }) => (color === 'primary' ? ADMIN_ACCENT_PRIMARY : 'white')};
  ${({ hasSymbol }) =>
    hasSymbol ? css`
      padding: 0 4px;
    ` : css`
      margin-left: 2px;
      margin-right: ${SPACING_SMALL};
      border-right: 1px solid ${ADMIN_SURFACE_5};
    `
  }
`;

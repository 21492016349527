import assert from 'assert';
import camelCase from 'lodash/camelCase';
import * as types from './types';

export default async ({
  primaryToken,
  renderer,
}) => {
  const { overlayType } = renderer;
  assert(types[camelCase(overlayType)], `invalid overlay kind: ${overlayType}`);
  return types[camelCase(overlayType)]({
    primaryToken,
    renderer,
  });
};

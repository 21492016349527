import styled from 'styled-components';
import { ADMIN_SURFACE_2, ADMIN_TEXT_100, ADMIN_TEXT_200, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_5 } from 'style/constants';
import { ADMIN_INPUT_BOX_SHADOW, FLEX_CENTER } from 'style/mixins';
import { IconSvg } from 'components/ui/Icon';
import { LeftArrow } from '../ui/Button';
import DateRangeInput from './DateRangeInput';
import { ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM, ADMIN_TEXT_TITLE_M } from 'style/design-system/textStyles';

export const CalendarContainer = styled.div<{ isActive?: boolean }>`
  ${ADMIN_INPUT_BOX_SHADOW}
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  min-width: 400px;
  top: 50px;
  left: 0;
  background-color: ${ADMIN_SURFACE_2};
  border-radius: 5px;
  padding: 20px 35px 35px 35px;
  z-index: 2;
`;

export const CalendarHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  margin-bottom: 10px;
  z-index: 10;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span<{ isActive?: boolean; isSmall?: boolean }>`
  ${ADMIN_TEXT_TITLE_M}
  font-size: ${(props) => (!props.isSmall ? '19px' : '12px')};
  color: ${(props) => (!props.isActive ? ADMIN_TEXT_100 : ADMIN_ACCENT_PRIMARY(props))};
  flex: 1;
`;

export const CalendarYearInput = styled(DateRangeInput)`
  width: 120px;
  height: 25px;
  padding: 5px 10px 5px 15px;
`;

export const Tip = styled.span`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_200};
  margin-top: 30px;
`;

export const Circle = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid ${ADMIN_SURFACE_5};
`;

export const CheckIcon = styled(IconSvg).attrs(props => ({
  color: ADMIN_ACCENT_PRIMARY(props),
  name: 'checkmark',
}))`
  background: transparent;
  width: 15px;
  height: 15px;
  & svg {
    display: block;
    opacity: 1;
    width: 15px;
    height: 15px;
  }
`;

export const PreviousButton = styled(LeftArrow as any).attrs(props => ({
  arrowColor: ADMIN_TEXT_100(props),
  hoverColor: ADMIN_ACCENT_PRIMARY(props),
}))`
  cursor: pointer;
  margin-right: -20px;
` as typeof LeftArrow;

export const NextButton = styled(LeftArrow as any).attrs(props => ({
  arrowColor: ADMIN_TEXT_100(props),
  hoverColor: ADMIN_ACCENT_PRIMARY(props),
}))`
  transform: rotate(180deg);
  margin-top: -5.5px;
  margin-right: -10px;
  cursor: pointer;
` as typeof LeftArrow;

export const SelectorContainer = styled.div`
  ${FLEX_CENTER}
  flex-direction:row;
  justify-content: space-between;
`;

export const SelectorTitle = styled.span`
${ADMIN_TEXT_BODY_S_BOLD}
  color: ${ADMIN_TEXT_200};
  flex: 1;
  text-align: left;
`;

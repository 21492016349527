import React, { useState, useMemo } from 'react';
import Toolbar from 'components/admin-bridge/Toolbar';
import AccessGateToolbar from './AccessGateToolbar';
import defaultBackground from 'assets/access_control_default_bg.jpg';
import hash from 'json-stable-stringify';
import { useSelector } from 'react-redux';
import {
  isAccessControlModeActive as isAccessControlModeActiveSelector,
} from 'services/admin/selectors';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  GateContainer,
  BackgroundButton,
  GateData,
  ChangeBackgroundIcon,
  GradientMask,
  GateItemsWrapper,
} from './styles';
import BackgroundControl, {
  DEFAULT_GATE_BACKGROUND,
} from './Modals/BackgroundControlModal';
import AccessHeader from './Sections/AccessHeader';
import AccessInformation from './Sections/AccessInformation';
import useEditGate from 'hooks/use-edit-gate';
import { IAccessGateBackground } from 'models/IGate';
import PageFetcher from 'components/admin-bridge/Toolbar/PageFetcher';
import AccessType from './Sections/AccessType';
import { useAccessFTUE } from 'hooks';
import GateCard from './Modals/GateCard';

const AccessGate = () => {
  const { editingGateInformation, setGateBackground } = useEditGate();
  const isAccessControlModeActive = useSelector(
    isAccessControlModeActiveSelector,
  );
  const [isBackgroundModalActive, setIsBackgroundModalActive] = useState(false);
  const [isAccessControlFTUE] = useAccessFTUE();
  const [isAccessControlModalActive, setIsAccessControlModalActive] =
    useState(false);

  const gateBackground = useMemo(() => {
    if (!editingGateInformation?.background) {
      return {
        ...DEFAULT_GATE_BACKGROUND,
        desktopImage:
          editingGateInformation?.subscriptionGateBackgroundWeb || defaultBackground,
        mobileImage:
          editingGateInformation?.subscriptionGateBackgroundMobile ||
          defaultBackground,
      };
    }
    return editingGateInformation.background;
  }, [hash(editingGateInformation?.background)]);

  const [isDateAndTimeModalVisible, setIsDateAndTimeModalVisible] = useState(false);

  const toggleBackgroundModal = () => {
    setIsBackgroundModalActive(!isBackgroundModalActive);
  };
  const handleOnSaveGateBackground = (background: IAccessGateBackground) => {
    setGateBackground(background);
  };

  const toggleAccessControlModalvisibility = () => {
    setIsAccessControlModalActive(!isAccessControlModalActive);
  };

  const closeOpenModals = () => {
    setIsDateAndTimeModalVisible(false);
    setIsBackgroundModalActive(false);
  };

  return (
    <>
      <GateContainer
        background={gateBackground}
        onMouseDown={closeOpenModals}
      >
        <PageFetcher />
        <Toolbar>
          {AccessGateToolbar}
        </Toolbar>
        {gateBackground?.useGradientMask ? (
          <GradientMask />
        ) : null}
        <AccessHeader />
        <GateData>
          <GateItemsWrapper>
            <AccessInformation
              isDateAndTimeModalVisible={isDateAndTimeModalVisible}
              setIsDateAndTimeModalVisible={setIsDateAndTimeModalVisible}
            />
            <AccessType
              isAccessControlModalActive={isAccessControlModalActive}
              toggleAccessControlModalVisibility={toggleAccessControlModalvisibility}
            />
          </GateItemsWrapper>
        </GateData>
        {isAccessControlModeActive && (
          <>
            <BackgroundButton
              data-testid="changeBackgroundButton"
              onClick={toggleBackgroundModal}
            >
              <ChangeBackgroundIcon name="changeBackground" />
              <TranslatedText stringKey="ACCESS_GATE_ADMIN_CHANGE_BACKGROUND" />
            </BackgroundButton>
            {isBackgroundModalActive && (
              <BackgroundControl
                onClose={toggleBackgroundModal}
                onChange={handleOnSaveGateBackground}
                gateBackground={gateBackground}
              />
            )}
          </>
        )}
        {isAccessControlModeActive && isAccessControlFTUE && (
          <GateCard onSetupAccess={toggleAccessControlModalvisibility} />
        )}
      </GateContainer>
    </>
  );
};

export default AccessGate;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import hash from 'json-stable-stringify';
import { AccessSectionContainer, ClickableEditButton } from '../styles';
import {
  inAccessMenu,
  isAccessControlModeActive as isAccessControlModeActiveSelector,
} from 'services/admin/selectors';
import AccessControlModal, {
  ISubscriptionData,
} from 'components/admin2/AccessControlModal';
import AccessControlElements from '../AccessControlElements';
import { GateKind } from 'models/IGate';
import ISubscription from 'models/ISubscription';
import useEditGate from 'hooks/use-edit-gate';
import { useAdminTranslation } from 'hooks/use-translation';
import { AmazonBenefitData } from 'components/admin2/AccessControlModal/AmazonBenefitGateSettings';

interface IProps {
  isAccessControlModalActive: boolean;
  toggleAccessControlModalVisibility: () => void;
}
const AccessType: React.FC<IProps> = ({
  isAccessControlModalActive,
  toggleAccessControlModalVisibility,
}) => {
  const {
    editingGate,
    editingGateInformation,
    editingPassword,
    setGateKindInformation,
    getSubscriptionGateWithMoreData,
  } = useEditGate();

  const { t } = useAdminTranslation();
  const isAccessControlModeActive = useSelector(
    isAccessControlModeActiveSelector,
  );
  const isInAccessMenu = useSelector(inAccessMenu);

  const [subscriptionsWithMoreData, setSubscriptionsWithMoreData] = useState<
    ISubscription[]
  >(editingGateInformation.subscriptions);

  /**
   * Some legacy pages does not have the password
   * or some fields (price, type and visible) on subscriptions
   * as part of the `app.object.gate` object.
   * So we need to make a request to the server to get those values.
   */
  useEffect(() => {
    getSubscriptionGateWithMoreData(
      editingGateInformation?.hiddenEntitlements,
      editingGateInformation?.subscriptions,
    ).then((subsWithMoreData) =>
      setSubscriptionsWithMoreData(subsWithMoreData),
    );
  }, [
    hash(editingGateInformation?.subscriptions),
    hash(editingGateInformation?.hiddenEntitlements),
  ]);

  const setAccessType = (
    kind: GateKind,
    password?: string,
    subscriptionsData?: ISubscriptionData,
    amazonBenefitData?: AmazonBenefitData,
  ) => {
    setGateKindInformation(kind, password, subscriptionsData, amazonBenefitData);
  };

  return (
    <AccessSectionContainer
      data-testid="accessControlButton"
      isAccessControlMode={isAccessControlModeActive}
      isSectionBlocked={isInAccessMenu && !isAccessControlModeActive}
      sectionTitle={t('ACCESS_GATE_ACCESS_CONTROL_REGION_TITLE')}
      width="60%"
    >
      {isAccessControlModeActive && (
        <ClickableEditButton onClick={toggleAccessControlModalVisibility} />
      )}
      <AccessControlElements
        accessGate={editingGate}
        initialPassword={editingPassword}
        subscriptionWithMoreData={subscriptionsWithMoreData}
      />
      {isAccessControlModeActive && isAccessControlModalActive && (
        <AccessControlModal
          accessGate={editingGate}
          initialPassword={editingPassword}
          handleClose={toggleAccessControlModalVisibility}
          setAccessType={setAccessType}
        />
      )}
    </AccessSectionContainer>
  );
};

export default AccessType;

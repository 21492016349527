import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import { SPACING_X_SMALL, SPACING_MEDIUM } from 'style/constants';
import { ADMIN_TEXT_LABEL_L_MEDIUM, ADMIN_TEXT_TITLE_L } from 'style/design-system/textStyles';

const propTypes = {
  descriptionKey: PropTypes.string.isRequired,
  titleKey: PropTypes.string.isRequired,
};

const Container = styled.div`
  margin-bottom: ${SPACING_MEDIUM};
`;

const Title = styled.div`
  ${ADMIN_TEXT_TITLE_L}
  letter-spacing: 2px;
  margin-bottom: ${SPACING_X_SMALL};
`;

const Description = styled.div`
  ${ADMIN_TEXT_LABEL_L_MEDIUM}
  opacity: 0.7;
`;

const EditorHeader = (props) => {
  const {
    titleKey,
    descriptionKey,
  } = props;
  return (
    <Container>
      <Title>
        <TranslatedText stringKey={titleKey} />
      </Title>
      <Description>
        <TranslatedText stringKey={descriptionKey} />
      </Description>
    </Container>
  );
};

EditorHeader.propTypes = propTypes;
export default EditorHeader;

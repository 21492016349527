import { ADMIN_SURFACE_5, ADMIN_TEXT_400, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_BODY_XS_BOLD, ADMIN_TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import { FLEX_CENTER } from 'style/mixins';
import styled from 'styled-components';

export const DaysContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  text-align: center;
`;

export const DaysHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  text-align: center;
  margin-bottom: 20px;
`;

export const DayHeaderTitle = styled.span`
  ${ADMIN_TEXT_BODY_XS_BOLD}
  color: ${ADMIN_TEXT_100};
  width: 40px;
`;

export const DayText = styled.span<{ insideMonth: boolean; isActive?: boolean }>`
  ${FLEX_CENTER}
  width: 40px;
  height: 40px;
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${(props) => (props.isActive ? ADMIN_TEXT_100 : props.insideMonth ? ADMIN_TEXT_100 : ADMIN_TEXT_400)};
  text-align: center;
  border-radius: 5px;
  background-color: ${props => props.isActive ? ADMIN_ACCENT_PRIMARY(props) : 'transparent' };
  cursor: pointer;
  &:hover {
    background-color: ${ADMIN_SURFACE_5};
  }
`;

export const Space = styled.div`
  width: 15px;
`;

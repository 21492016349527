import React from 'react';
import { LabelContainer, Text, Bullet, Value, Percentage } from './styled';
import { abbrNumber } from '../../../../utils';
import TranslatedText from 'components/i18n/TranslatedText';

type Props = {
  animationDelay?: number;
  animationDuration?: number;
  color: string;
  percentage?: string;
  state?:string;
  text: string;
  value: number;
};

function Label({ color, text, value, percentage, animationDuration, animationDelay }: Props) {
  return (
    <LabelContainer animationDelay={animationDelay} animationDuration={animationDuration}>
      <Text>
        <Bullet color={color}/>
        <TranslatedText stringKey={text} />
      </Text>
      <Value><Percentage color={color}>{percentage}</Percentage>({abbrNumber(value)})</Value>
    </LabelContainer>
  );
}

export default Label;

import { useSelector } from 'react-redux';
import { INSIGHTS_SERVICE_BASE_URL, SITE_ID } from 'config';
import { getPrimaryToken } from 'services/auth/selectors';
import useAjax from 'hooks/use-ajax';

export interface IItem {
  type: string;
  uniquesEngaged: number;
  viewerId: number;
}

export interface IEngagementBreakdown {
  overlays: IItem[];
  panels: IItem[];
  uniquesTotal: number;
}

function useEngagementBreakdown(startTime: number, endTime: number) {
  const token = useSelector(getPrimaryToken);
  const { data, error, loaded  } = useAjax<IEngagementBreakdown>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: {
      startTime,
      endTime,
    },
    url: startTime && endTime ? `${INSIGHTS_SERVICE_BASE_URL}/sites/engagement-breakdown` : null,
  });
  return { data, error, loaded };
}

export default useEngagementBreakdown;

/* tslint:disable: jsx-boolean-value */
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import withConfirmation from 'components/core/withConfirmation';
import {
  EditButton,
  AddButton,
  RemoveButton,
  DeleteButton,
  BroadcastButton,
  WatchButton,
} from 'components/admin2/ui/Button';
import {
  Container,
  ActionContainerStyleProps,
} from './styles';
import { getIsPendingOverlay } from 'services/overlays/selectors';

export interface Actions {
  onAdd?: MouseEventHandler;
  onBroadcast?: MouseEventHandler;
  onDelete?: MouseEventHandler;
  onEdit?: MouseEventHandler;
  onPreview?: MouseEventHandler;
  onRemove?: MouseEventHandler;
}

export interface ActionContainerProps extends Actions, ActionContainerStyleProps {
  className?: string;
  confirmRemoval?: boolean;
  testIdAdd?: string;
  testIdBroadcast?: string;
  testIdDelete?: string;
  testIdEdit?: string;
  testIdPreview?: string;
  testIdRemove?: string;
}

const ConfirmDeleteButton = withConfirmation(DeleteButton);
const ConfirmRemoveButton = withConfirmation(RemoveButton);

export default function ActionContainer({
  children,
  className,
  confirmRemoval,
  onAdd,
  onBroadcast,
  onDelete,
  onEdit,
  onPreview,
  onRemove,
  reverse,
  spacing,
  testIdAdd,
  testIdBroadcast,
  testIdDelete,
  testIdEdit,
  testIdPreview,
  testIdRemove,
  vertical,
}: PropsWithChildren<ActionContainerProps>) {
  const isPendingOverlay = useSelector(getIsPendingOverlay);

  return (
    <Container className={className} reverse={reverse} spacing={spacing} vertical={vertical}>
      {onEdit && <EditButton data-testid={testIdEdit} onClick={onEdit} />}
      {onAdd && <AddButton data-testid={testIdAdd} onClick={onAdd} />}
      {onRemove && (confirmRemoval ? (
        <ConfirmRemoveButton action={onRemove} data-testid={testIdRemove} messageKey="ADMIN_PROMPT_CONFIRM_REMOVAL" />
      ) : (
        <RemoveButton data-testid={testIdRemove} onClick={onRemove} />
      ))}
      {onDelete && <ConfirmDeleteButton action={onDelete} data-testid={testIdDelete} messageKey="ADMIN_PROMPT_CONFIRM_DELETION" />}
      {onBroadcast && <BroadcastButton data-testid={testIdBroadcast} onClick={onBroadcast} disabled={isPendingOverlay} />}
      {onPreview && <WatchButton data-testid={testIdPreview} onClick={onPreview} />}
      {children}
    </Container>
  );
}

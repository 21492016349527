import React, { memo, useEffect, useState, useRef, useMemo } from 'react';
import { percentageValue } from './helper';
import bezier from 'bezier-easing';

type Slice = {
  color: string;
  value: number;
};

type Props = {
  animationDuration?: number;
  className?: string,
  easing?: (t: number) => number,
  slices: Slice[];
};

const DEFAULT_EASING = bezier(0.16, 1, 0.3, 1);
const DEFAULT_ANIMATION_DURATION = 2000;

function PieChart({ slices, animationDuration, easing, className }: Props) {
  const startTimeRef = useRef<number>(Date.now());
  const [progress, setProgress] = useState<number>(0);
  const bezierFnRef = useRef(easing);
  const total = useMemo(() => slices.reduce((acc, { value }) => value + acc, 0), [slices]);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      const currentTime = Date.now();
      const startTime = startTimeRef.current as number;
      const newProgress = (currentTime - startTime) / (animationDuration || DEFAULT_ANIMATION_DURATION);

      const interpolatedProgress = (bezierFnRef.current || DEFAULT_EASING)(newProgress);
      if (progress <= 1) {
        setProgress(interpolatedProgress > 1 ? 1 : interpolatedProgress);
      }
    });

  }, [progress]);

  if (!progress) return <div className={className} />;

  let lastDashOffset: number;

  return (
    <div className={className}>
      <svg width="100%" height="100%" viewBox="0 0 42 42">
        <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" />
        <circle
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="transparent"
          stroke="transparent"
          stroke-width="4.5"
        />
        {slices.map(({ value, color }, index) => (
          <circle
            key={index}
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke={color}
            stroke-width="4.5"
            stroke-dasharray={`${percentageValue(value, total, progress)} ${(100 - percentageValue(value, total, progress))}`}
            stroke-dashoffset={slices[index - 1] ? lastDashOffset = 100 - percentageValue(slices[index - 1].value, total, progress) + lastDashOffset : lastDashOffset = 25}
          />
        ))}
      </svg>
    </div>
  );
}

export default memo(PieChart);

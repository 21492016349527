import styled from 'styled-components';
import { ACTION_ICON_SVG } from 'style/mixins';
// eslint-disable-next-line import/no-named-default
import Icon from 'components/ui/Icon';

// Used for elements that have on hover or active css styles
const AdminIcon = styled(Icon)`
  ${ACTION_ICON_SVG}
`;

export default AdminIcon;

import useSelector from 'hooks/use-selector';
import { INSIGHTS_SERVICE_BASE_URL, SITE_ID } from 'config';
import { getPrimaryToken } from 'services/auth/selectors';
import useAjax from 'hooks/use-ajax';

export interface ICohorts {
  uniquesAll: number;
  uniquesEngaged: number;
  uniquesNonEngaged: number;
}

export interface IEngagmentImpact {
  audience: ICohorts;
  avgWatchTime: ICohorts;
}

const useEngagementImpact = (startTime: number, endTime: number) => {
  const token = useSelector(getPrimaryToken);
  const { data, error, loaded } = useAjax<IEngagmentImpact>({
    headers: {
      authorization: `Bearer ${token}`,
      'x-maestro-client-id': SITE_ID,
    },
    method: 'GET',
    params: {
      startTime,
      endTime,
    },
    url: startTime && endTime ? `${INSIGHTS_SERVICE_BASE_URL}/sites/engagement-impact` : null,
  });
  return { data, error, loaded };
};

export default useEngagementImpact;

import React from 'react';
import { Container, Section, Title, TitleContainer } from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import Brand from 'components/admin2/Brand';

const AnalyticsHeader = () => {
  return (
    <Container>
      <Section>
        <TitleContainer>
          <Brand showIcon={true} />
          <Title>
            <TranslatedText stringKey="ADMIN_ANALYTICS_HERE_IS_YOUR_REPORT" />
          </Title>
        </TitleContainer>
        <Brand />
      </Section>
    </Container>
  );
};

export default AnalyticsHeader;

import React from 'react';
import RichTextEditorV2 from 'components/ui/RichTextEditorV2/RichTextEditorV2';
import { AccessSectionContainer } from '../../styles';
import styled from 'styled-components';
import { desktopOnly } from 'style/mixins';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { DynamicTranslationType, useEndUserInlineTextEditorTranslation } from 'hooks/use-translation';
import { getCurrentPageId } from 'services/app/selectors';
import { ToolbarButtons } from 'components/ui/RichTextEditorV2/utils';
import RichTextEditorV3 from 'components/ui/RichTextEditorV3';
import { TEXT_100, TEXT_400 } from 'style/constants';

interface IGateTextSection {
  codeInjectionClassName: string;
  data: string;
  isAccessControlMode: boolean;
  maxLength: number;
  onChange: (value: string) => void;
  sectionTitle: string;
  toolbarButtons: ToolbarButtons;
  type: 'title' | 'subtitle';
  useRawDataV2: boolean;
}

const GateTextSection = ({
  codeInjectionClassName,
  isAccessControlMode,
  onChange,
  data,
  maxLength,
  sectionTitle,
  toolbarButtons,
  type,
  useRawDataV2,
}: IGateTextSection) => {
  const isEditing = useSelector(isEditMode);

  const pageId = useSelector(getCurrentPageId);
  const { inlineTextEditorEndUserT } = useEndUserInlineTextEditorTranslation(isEditing);

  const dynamicType = type === 'title'
  ? DynamicTranslationType.gateTitle
  : DynamicTranslationType.gateSubtitle;

  const localizedData = inlineTextEditorEndUserT(dynamicType, data, { pageId });

  const handleChange = (newData: string | null) => {
    if (newData && onChange) {
      onChange(newData);
    }
  };

  return (
    <StyledAccessSectionContainer
      data-testid={`${type}GateField`}
      sectionTitle={sectionTitle}
      isAccessControlMode={isAccessControlMode}
      type={type}
    >
      {
        useRawDataV2 ? (
          <RichTextEditorV3
            data={data}
            onChange={handleChange}
            maxLength={maxLength}
            inline={true}
            sticking={false}
          />
        ) : (
          <RichTextEditorV2
            isEditMode={isEditing}
            codeInjectionClassName={codeInjectionClassName}
            onChange={onChange || emptyFn}
            maxLength={maxLength}
            data={localizedData}
            toolbarButtons={toolbarButtons}
            inline={true}
          />
        )}
    </StyledAccessSectionContainer>
  );
};

const StyledAccessSectionContainer = styled(AccessSectionContainer)<{ type: 'title' | 'subtitle' }>`
  ${desktopOnly`
    width: 33%;

    @media (max-width: 1200px) {
      width: 50%;
    }
  `};

  & .mce-content-body {
    color: ${({ type }) => type === 'title' ? TEXT_100 : TEXT_400};
  }
`;

const emptyFn = () => {
  // placeholder
};

export default GateTextSection;

export function abbrNumber(num: number) {
  if (!num) { return '0'; }
  const numberValue = +num;
  if (typeof numberValue !== 'number') { return '0.00';}
  const b = (numberValue).toPrecision(3).split('e');
  const k = b.length === 1 ? 0 : Math.floor(Math.min(parseFloat(b[1].slice(1)), 14) / 3);
  const c = parseFloat(k < 1 ? parseFloat(numberValue.toPrecision(3)).toFixed(3) : (numberValue / (10 ** (k * 3))).toFixed(2));
  const d = c < 0 ? c : Math.abs(c);
  const maxSigs: number = Number(d.toPrecision(3));
  const trim = maxSigs < 0.01 ? 0 : +(Math.round(+(maxSigs + 'e+2'))  + 'e-2');
  const e = trim + ['', 'K', 'M', 'B', 'T'][k];
  return e;
}

export function formatToTime(time: number) {
  const dateObj = new Date(time * 1000);
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  if (hours === 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
}

export function secondsToHour(seconds: number) {
  return seconds / 60 / 60;
}
